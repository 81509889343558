import { useQuery } from '@tanstack/react-query'

import {
  GetPatientIntakeFlowsParams,
  GetPatientIntakeFlowsReturns,
  GetPatientIntakeFlowsError,
} from 'src/features/msk/domain'
import { usePatientId } from 'src/features/shared/presentation'
import { useMSKUseCases } from 'src/features/msk/msk.service-locator'

export const GET_PATIENT_LAST_INTAKE_FLOW_QUERY_KEY = 'getPatientLastIntakeFlow'

export const DEFAULT_LAST_INTAKE_FLOW_LIMIT_PARAM = '1'
export const DEFAULT_LAST_INTAKE_FLOW_ORDER_BY_PARAM = 'createdAt'
export const DEFAULT_LAST_INTAKE_FLOW_ORDER_DIRECTION_PARAM = 'desc'

export const getPatientLastIntakeFlowParams: GetPatientIntakeFlowsParams = {
  limit: DEFAULT_LAST_INTAKE_FLOW_LIMIT_PARAM,
  orderBy: DEFAULT_LAST_INTAKE_FLOW_ORDER_BY_PARAM,
  orderDirection: DEFAULT_LAST_INTAKE_FLOW_ORDER_DIRECTION_PARAM,
}

type UseGetPatientLastIntakeFlowQueryProps = {
  patientId?: string
  throwGetPatientIdError?: boolean
}

export const useGetPatientLastIntakeFlowQuery = ({
  patientId,
  throwGetPatientIdError = true,
}: UseGetPatientLastIntakeFlowQueryProps = {}) => {
  const mskUseCases = useMSKUseCases()

  const _patientId = usePatientId({ patientId, throwGetPatientIdError })

  const { data, isLoading, isError } = useQuery<
    GetPatientIntakeFlowsReturns | null,
    GetPatientIntakeFlowsError
  >([GET_PATIENT_LAST_INTAKE_FLOW_QUERY_KEY, _patientId], () => {
    return _patientId
      ? mskUseCases.getPatientIntakeFlows(
          _patientId,
          getPatientLastIntakeFlowParams
        )
      : null
  })
  return {
    lastIntakeFlow:
      data && data.intakeFlows && data.intakeFlows.length
        ? data.intakeFlows[0]
        : null,
    previouslyTriedActions: data ? data.previouslyTriedActions : null,
    actionsOpenToTry: data ? data.actionsOpenToTry : null,
    questionsAndActions: data ? data.questionsAndActions : null,
    getPatientLastIntakeFlowIsLoading: isLoading,
    getPatientLastIntakeFlowIsError: isError,
  }
}
