import { FC, Fragment, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  Button,
  Divider,
} from '@mui/material'
import { toast } from 'react-toastify'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import {
  CreatedEnablingTherapy,
  Intake,
  IntakeFlowQuestionsAndActions,
  IntakeStatus,
} from 'src/features/msk/domain'
import { mapToPatientIntakeFlowData } from 'src/features/msk/adapters'
import {
  BackdropLoader,
  MSKChip,
  useGetNavigatorsQuery,
} from 'src/features/shared/presentation'
import {
  AdditionalQuestionsSummary,
  AdditionalQuestionsSummaryProps,
  LeftAlignedItem,
  PathQuestionItem,
  QuestionItem,
  QUESTIONS_LABELS,
  TwoColumnsItem,
  useUpdateIntake,
  CreateEnablingTherapyForm,
  useGetPatientIntakeFlowsQuery,
} from 'src/features/msk/presentation'
import { PatientIntakeFlowWithCreatedInfo } from 'src/features/patients/presentation'
import { Patient } from 'src/features/patients/domain'
import DOMPurify from 'dompurify'
import { patientHasLockedPathway } from 'src/features/shared/utils'

type PatientIntakeFlowDataAnswer = {
  question: string
  value: string
}

export type PatientIntakeFlowCondition = {
  answers: PatientIntakeFlowDataAnswer[]
  bodyRegion: string
  bodyRegionToNote: string
  carePathwayId: string | null
  createdInfo: string
  currentlyUsingOpioids?: string
  enablingTherapies: CreatedEnablingTherapy[]
  functionLevel: number
  id: string
  openToTrying: string
  otherPreviouslyTriedActionText?: string
  painLevel: number
  previouslyTried: string
  recommendedCarePathway: string
  recommendedType: string
}

export type PatientIntakeFlowAdditionalQuestions =
  AdditionalQuestionsSummaryProps & {
    createdInfo: string
  }

export type PatientIntakeFlowData = {
  conditions: PatientIntakeFlowCondition[]
  additionalQuestions?: PatientIntakeFlowAdditionalQuestions | null
}

type EnablingTherapyListProps = {
  condition: PatientIntakeFlowCondition
}

export const EnablingTherapyList: FC<EnablingTherapyListProps> = ({
  condition,
}) => {
  if (!condition.enablingTherapies.length) {
    return null
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '16px' }}>
      {condition.enablingTherapies.map((enablingTherapy) => (
        <Fragment key={enablingTherapy.id}>
          <Box
            key={enablingTherapy.id}
            sx={{ display: 'flex', flexDirection: 'column', rowGap: '16px' }}
          >
            <Typography variant="caption" color={'rgba(0, 0, 0, 0.38)'}>
              Enabling Therapy added {enablingTherapy.createdAt} by{' '}
              {enablingTherapy.createdBy}
            </Typography>
            <Box>
              <Typography variant="body1">
                Type:{' '}
                <Typography
                  variant="body1"
                  component={'span'}
                  fontWeight={'bold'}
                >
                  {enablingTherapy.therapy}
                </Typography>
              </Typography>
              <Typography variant="body1">
                Date of first appt: {enablingTherapy.dateOfFirstAppointment}
              </Typography>
              <Typography variant="body1">
                Related to:{' '}
                <Typography
                  variant="body1"
                  component={'span'}
                  fontWeight={'bold'}
                >
                  Condition: {condition.bodyRegion}{' '}
                </Typography>
                and{' '}
                <Typography
                  variant="body1"
                  component={'span'}
                  fontWeight={'bold'}
                >
                  Pathway: {enablingTherapy.pathway}
                </Typography>
              </Typography>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(enablingTherapy.notes),
                }}
              />
            </Box>
          </Box>
          <Divider />
        </Fragment>
      ))}
    </Box>
  )
}

type PatientIntakeFlowProps = {
  patient: Patient
  intakeFlow: PatientIntakeFlowWithCreatedInfo
  questionsAndActions: IntakeFlowQuestionsAndActions
}
export const PatientIntakeFlow: FC<PatientIntakeFlowProps> = ({
  patient,
  intakeFlow,
  questionsAndActions,
}) => {
  const { navigators } = useGetNavigatorsQuery()
  const { actionsOpenToTry, previouslyTriedActions } =
    useGetPatientIntakeFlowsQuery()
  const { updateIntake, updateIntakeIsLoading } = useUpdateIntake()

  const { conditions, additionalQuestions } = mapToPatientIntakeFlowData({
    intakeFlow,
    previouslyTriedActions,
    actionsOpenToTry,
    questionsAndActions,
    navigators,
  })

  const [showEnablingTherapyForm, setshowEnablingTherapyForm] = useState(
    Object.fromEntries(intakeFlow.intakes.map((intake) => [intake.id, false]))
  )

  const shouldShowEnablingTherapyFormButton = (conditionId: string) =>
    patientHasLockedPathway(patient) && !showEnablingTherapyForm[conditionId]

  const conditionStatusOptions: IntakeStatus[] = [
    'Active',
    'Completed',
    'Pending',
  ]

  const hasAtLeastOneEnablingTherapy = conditions.some(
    (condition) => condition.enablingTherapies.length
  )

  const getEnablingTherapyListId = (conditionId: string) =>
    `enabling_therapies_${conditionId}`

  const getIntakeStatus = (intakeId: Intake['id']) => {
    const intake = intakeFlow.intakes.find((intake) => intake.id === intakeId)
    return intake?.status ?? 'Pending'
  }

  const getConditionStatusChangeHandler =
    (intakeId: Intake['id']) => (event: SelectChangeEvent<IntakeStatus>) => {
      const status = event.target.value as IntakeStatus
      updateIntake(
        { intakeId, payload: { status } },
        {
          onSuccess: () => {
            toast.success('Condition status updated successfully')
          },
          onError: () => {
            toast.error('Failed to update condition status')
          },
        }
      )
    }

  const getEnablingTherapyClickHandler = (conditionId: string) => () => {
    setshowEnablingTherapyForm((prevState) => {
      return {
        ...prevState,
        [conditionId]: true,
      }
    })
  }

  const getEnablingTherapyOnCancelClickHandler =
    (conditionId: string) => () => {
      setshowEnablingTherapyForm((prevState) => {
        return {
          ...prevState,
          [conditionId]: false,
        }
      })
    }

  const getEnablingTherapySubmitSuccessHandler =
    (conditionId: string) => () => {
      setshowEnablingTherapyForm((prevState) => {
        return {
          ...prevState,
          [conditionId]: false,
        }
      })

      setTimeout(() => {
        const enablingTherapiesList = document.getElementById(
          getEnablingTherapyListId(conditionId)
        )
        enablingTherapiesList?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }, 3000)
    }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '16px',
      }}
    >
      {conditions.map((condition, index) => (
        <Fragment key={condition.id}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '16px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '16px',
                }}
              >
                <Box>
                  <Typography fontWeight={500} fontSize={18} color={'#F5B580'}>
                    CONDITION {index + 1}: {condition.bodyRegion}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '16px',
                }}
              >
                {shouldShowEnablingTherapyFormButton(condition.id) ? (
                  <Button
                    type="button"
                    color="primary"
                    variant="outlined"
                    onClick={getEnablingTherapyClickHandler(condition.id)}
                    sx={{
                      width: '200px',
                    }}
                  >
                    + ENABLING THERAPY
                  </Button>
                ) : null}
                <FormControl size={'small'} sx={{ width: 227 }}>
                  <InputLabel id={`intake_status_${condition.id}_label`}>
                    Status
                  </InputLabel>
                  <Select
                    labelId={`intake_status_${condition.id}_label`}
                    id={`intake_status_${condition.id}`}
                    label={'Status'}
                    value={getIntakeStatus(condition.id)}
                    onChange={getConditionStatusChangeHandler(condition.id)}
                  >
                    {conditionStatusOptions.map((status) => (
                      <MenuItem key={status} value={status}>
                        {status}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>

            {showEnablingTherapyForm[condition.id] &&
            patient.assignedPathwayId ? (
              <>
                <Divider />

                <CreateEnablingTherapyForm
                  intakeId={condition.id}
                  patientId={patient.patientId}
                  assignedPathwayId={patient.assignedPathwayId}
                  onCancelClick={getEnablingTherapyOnCancelClickHandler(
                    condition.id
                  )}
                  onSubmitSuccess={getEnablingTherapySubmitSuccessHandler(
                    condition.id
                  )}
                />

                <Divider />
              </>
            ) : null}

            <Accordion
              disableGutters
              sx={{
                display: 'flex',
                flexDirection: 'column',
                boxShadow: 'none',
                border: 'none',
                '&:before': {
                  display: 'none',
                },
                '& .MuiAccordionSummary-root': {
                  padding: '0 0 0 0',
                },
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box
                  sx={{
                    display: 'flex',
                    columnGap: '16px',
                  }}
                >
                  <MSKChip label={`Pain: ${condition.painLevel}`} />
                  <MSKChip label={`Function: ${condition.functionLevel}`} />
                  <MSKChip
                    label={`Recommendation: ${condition.recommendedCarePathway}`}
                  />
                </Box>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  padding: '16px 0 16px 0',
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '48px',
                }}
              >
                <Box>
                  <LeftAlignedItem
                    label={'Body Region'}
                    value={condition.bodyRegion}
                  />
                  <LeftAlignedItem
                    label={'Recommended Care Pathway'}
                    value={condition.recommendedCarePathway}
                  />
                  <LeftAlignedItem
                    label={'Recommended Type'}
                    value={condition.recommendedType}
                  />
                  {condition.carePathwayId ? (
                    <LeftAlignedItem
                      label={'Care Pathway ID'}
                      value={condition.carePathwayId}
                    />
                  ) : null}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '16px',
                  }}
                >
                  <TwoColumnsItem
                    left={'Previously Tried'}
                    right={condition.previouslyTried}
                  />
                  <TwoColumnsItem
                    left={'Open to Trying'}
                    right={condition.openToTrying}
                  />
                  {condition.otherPreviouslyTriedActionText ? (
                    <QuestionItem
                      question={QUESTIONS_LABELS.otherPreviouslyTriedActionText}
                      answer={condition.otherPreviouslyTriedActionText}
                    />
                  ) : null}
                  {condition.currentlyUsingOpioids ? (
                    <QuestionItem
                      question={QUESTIONS_LABELS.currentlyUsingOpioids}
                      answer={condition.currentlyUsingOpioids}
                    />
                  ) : null}
                  <QuestionItem
                    question={QUESTIONS_LABELS.bodyRegionToNote}
                    answer={condition.bodyRegionToNote}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '16px',
                  }}
                >
                  {condition.answers.map((answer, index) => (
                    <PathQuestionItem
                      index={index + 1}
                      key={index}
                      question={answer.question}
                      answer={answer.value}
                    />
                  ))}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Divider />
        </Fragment>
      ))}

      {additionalQuestions ? (
        <Box>
          <Accordion
            disableGutters
            sx={{
              boxShadow: 'none',
              border: 'none',
              '&:before': {
                display: 'none',
              },
              '& .MuiAccordionSummary-root': {
                padding: '0 0 0 0',
              },
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontWeight={500} fontSize={18} color={'#F5B580'}>
                ADDITIONAL INTAKE QUESTIONS
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                padding: '0 0 16px 0',
                display: 'flex',
                flexDirection: 'column',
                rowGap: '48px',
              }}
            >
              <AdditionalQuestionsSummary
                phQ1Question={additionalQuestions.phQ1Question}
                phQ1Answer={additionalQuestions.phQ1Answer}
                phQ2Question={additionalQuestions.phQ2Question}
                phQ2Answer={additionalQuestions.phQ2Answer}
                phScore={additionalQuestions.phScore}
                phAssessment={additionalQuestions.phAssessment}
                gad2Q1Question={additionalQuestions.gad2Q1Question}
                gad2Q1Answer={additionalQuestions.gad2Q1Answer}
                gad2Q2Question={additionalQuestions.gad2Q2Question}
                gad2Q2Answer={additionalQuestions.gad2Q2Answer}
                gad2Score={additionalQuestions.gad2Score}
                gad2Assessment={additionalQuestions.gad2Assessment}
                bhAnswer={additionalQuestions.bhAnswer}
                fallScreenQ1Question={additionalQuestions.fallScreenQ1Question}
                fallScreenQ1Answer={additionalQuestions.fallScreenQ1Answer}
                fallScreenQ2Question={additionalQuestions.fallScreenQ2Question}
                fallScreenQ2Answer={additionalQuestions.fallScreenQ2Answer}
                fallScreenResult={additionalQuestions.fallScreenResult}
                tabletComputerOrPhoneQuestion={
                  additionalQuestions.tabletComputerOrPhoneQuestion
                }
                tabletComputerOrPhoneAnswer={
                  additionalQuestions.tabletComputerOrPhoneAnswer
                }
                barriersToBeAwareOfQuestion={
                  additionalQuestions.barriersToBeAwareOfQuestion
                }
                barriersToBeAwareOfAnswer={
                  additionalQuestions.barriersToBeAwareOfAnswer
                }
                peopleAllowedToReachOutToQuestion={
                  additionalQuestions.peopleAllowedToReachOutToQuestion
                }
                peopleAllowedToReachOutToAnswer={
                  additionalQuestions.peopleAllowedToReachOutToAnswer
                }
              />
            </AccordionDetails>
          </Accordion>
        </Box>
      ) : null}

      {hasAtLeastOneEnablingTherapy ? (
        <Fragment>
          <Divider />
          {conditions.map((condition) =>
            condition.enablingTherapies.length ? (
              <Box
                key={`enabling-therapies-${condition.id}`}
                id={getEnablingTherapyListId(condition.id)}
              >
                <EnablingTherapyList condition={condition} />
              </Box>
            ) : null
          )}
        </Fragment>
      ) : null}

      <BackdropLoader open={updateIntakeIsLoading} />
    </Box>
  )
}
