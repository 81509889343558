import { Typography, Stack, Slider, SliderProps } from '@mui/material'
import { FC, ReactNode } from 'react'

type SliderFieldProps = {
  label: string
  friendlyMarks?: ReactNode
  sliderProps: SliderProps
}

export const SliderField: FC<SliderFieldProps> = ({
  label,
  friendlyMarks,
  sliderProps,
}) => {
  return (
    <Stack direction={'column'} rowGap={2}>
      <Typography variant={'body1'} fontWeight={'500'}>
        {label}
      </Typography>
      <Stack>
        <Slider {...sliderProps} />
        {friendlyMarks ? friendlyMarks : null}
      </Stack>
    </Stack>
  )
}
