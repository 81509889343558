import { useCallback } from 'react'

import {
  CreatePatientCustomNoteService,
  CreatePatientCustomNoteServiceResponse,
  CreatePatientIntakeNoteService,
  CreatePatientIntakeNoteServiceResponse,
  CreatePatientOrthoChartCheckNoteService,
  CreatePatientOrthoChartCheckNoteServiceResponse,
  CreatePatientOrthoTouchpointNoteService,
  CreatePatientOrthoTouchpointNoteServiceResponse,
  CreatePatientOutreachNoteService,
  CreatePatientOutreachNoteServiceResponse,
  CreatePatientPTTouchpointNoteService,
  CreatePatientPTTouchpointNoteServiceResponse,
  CreatePatientPostOpTouchpointNoteService,
  CreatePatientPostOpTouchpointNoteServiceResponse,
  CreatePatientPreOpTouchpointNoteService,
  CreatePatientPreOpTouchpointNoteServiceResponse,
  CreatePatientReferralUploadNoteService,
  CreatePatientReferralUploadNoteServiceResponse,
  CreatePatientSelfCareTouchpointNoteService,
  CreatePatientSelfCareTouchpointNoteServiceResponse,
  GetPatientNotesService,
  GetPatientNotesServiceResponse,
} from 'src/features/notes/infrastructure'
import { useCompassApiClient } from 'src/features/shared/infrastructure'
import * as settings from 'src/config/settings'

export const useNotesService = () => {
  const { getCompassApiClient } = useCompassApiClient()

  const getPatientNotes: GetPatientNotesService = useCallback(
    async (patientId) => {
      const compassClient = await getCompassApiClient()
      return await compassClient.get<GetPatientNotesServiceResponse>(
        settings.COMPASS_API_BASE_URL + `/notes/${patientId}`
      )
    },
    [getCompassApiClient]
  )

  const createPatientCustomNote: CreatePatientCustomNoteService = useCallback(
    async (payload) => {
      const compassClient = await getCompassApiClient()
      return await compassClient.post<CreatePatientCustomNoteServiceResponse>(
        settings.COMPASS_API_BASE_URL + '/notes',
        payload
      )
    },
    [getCompassApiClient]
  )

  const createPatientOrthoChartCheckNote: CreatePatientOrthoChartCheckNoteService =
    useCallback(
      async (payload) => {
        const compassClient = await getCompassApiClient()
        return await compassClient.post<CreatePatientOrthoChartCheckNoteServiceResponse>(
          settings.COMPASS_API_BASE_URL + '/notes',
          payload
        )
      },
      [getCompassApiClient]
    )

  const createPatientOrthoTouchpointNote: CreatePatientOrthoTouchpointNoteService =
    useCallback(
      async (payload) => {
        const compassClient = await getCompassApiClient()
        return await compassClient.post<CreatePatientOrthoTouchpointNoteServiceResponse>(
          settings.COMPASS_API_BASE_URL + '/notes',
          payload
        )
      },
      [getCompassApiClient]
    )

  const createPatientOutreachNote: CreatePatientOutreachNoteService =
    useCallback(
      async (payload) => {
        const compassClient = await getCompassApiClient()
        return await compassClient.post<CreatePatientOutreachNoteServiceResponse>(
          settings.COMPASS_API_BASE_URL + '/notes',
          payload
        )
      },
      [getCompassApiClient]
    )

  const createPatientSelfCareTouchpointNote: CreatePatientSelfCareTouchpointNoteService =
    useCallback(
      async (payload) => {
        const compassClient = await getCompassApiClient()
        return await compassClient.post<CreatePatientSelfCareTouchpointNoteServiceResponse>(
          settings.COMPASS_API_BASE_URL + '/notes',
          payload
        )
      },
      [getCompassApiClient]
    )

  const createPatientReferralUploadNote: CreatePatientReferralUploadNoteService =
    useCallback(
      async (payload) => {
        const compassClient = await getCompassApiClient()
        return await compassClient.post<CreatePatientReferralUploadNoteServiceResponse>(
          settings.COMPASS_API_BASE_URL + '/notes',
          payload
        )
      },
      [getCompassApiClient]
    )

  const createPatientPTTouchpointNote: CreatePatientPTTouchpointNoteService =
    useCallback(
      async (payload) => {
        const compassClient = await getCompassApiClient()
        return await compassClient.post<CreatePatientPTTouchpointNoteServiceResponse>(
          settings.COMPASS_API_BASE_URL + '/notes',
          payload
        )
      },
      [getCompassApiClient]
    )

  const createPatientPostOpTouchpointNote: CreatePatientPostOpTouchpointNoteService =
    useCallback(
      async (payload) => {
        const compassClient = await getCompassApiClient()
        return await compassClient.post<CreatePatientPostOpTouchpointNoteServiceResponse>(
          settings.COMPASS_API_BASE_URL + '/notes',
          payload
        )
      },
      [getCompassApiClient]
    )

  const createPatientPreOpTouchpointNote: CreatePatientPreOpTouchpointNoteService =
    useCallback(
      async (payload) => {
        const compassClient = await getCompassApiClient()
        return await compassClient.post<CreatePatientPreOpTouchpointNoteServiceResponse>(
          settings.COMPASS_API_BASE_URL + '/notes',
          payload
        )
      },
      [getCompassApiClient]
    )

  const createPatientIntakeNote: CreatePatientIntakeNoteService = useCallback(
    async (payload) => {
      const compassClient = await getCompassApiClient()
      return await compassClient.post<CreatePatientIntakeNoteServiceResponse>(
        settings.COMPASS_API_BASE_URL + '/notes',
        payload
      )
    },
    [getCompassApiClient]
  )

  return {
    createPatientCustomNote,
    createPatientIntakeNote,
    createPatientOrthoChartCheckNote,
    createPatientOrthoTouchpointNote,
    createPatientOutreachNote,
    createPatientPTTouchpointNote,
    createPatientPostOpTouchpointNote,
    createPatientPreOpTouchpointNote,
    createPatientReferralUploadNote,
    createPatientSelfCareTouchpointNote,
    getPatientNotes,
  }
}
