import { Box, Typography } from '@mui/material'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { Queue } from 'src/features/queues/domain'
import { useQueuePatientsStore } from 'src/features/shared/infrastructure'
import { ReferralSource, Stage } from 'src/features/shared/types'

const getQueueCountColor = (queueName: string) => {
  switch (queueName.toLowerCase()) {
    case 'pcp referrals':
    case 'ortho referrals':
      return 'teal.300'
    default:
      return '#8D929F'
  }
}

const getQueueDataTestId = (queueName: string) => {
  switch (queueName.toLowerCase()) {
    case 'pcp referrals':
      return 'launchpad_item_pcp_referrals'
    case 'ortho referrals':
      return 'launchpad_item_ortho_referrals'
    case 'assigned to me':
      return 'launchpad_item_assigned_to_me'
    default:
      return `launchpad_item_${queueName.toLowerCase().replaceAll(' ', '_')}`
  }
}

type QueueCountProps = {
  queue: Queue
}

export const QueueCount: FC<QueueCountProps> = ({ queue }) => {
  return (
    <Typography
      sx={{
        fontSize: '96px',
        fontWeight: '600',
        lineHeight: '116px',
        color: getQueueCountColor(queue.name),
      }}
    >
      {queue.count}
    </Typography>
  )
}

type LaunchpadItemProps = {
  queue: Queue
  'data-testid'?: string
}

export const LaunchpadItem: FC<LaunchpadItemProps> = ({ queue }) => {
  const navigate = useNavigate()
  const {
    setQueuePatientsSearchInputValue,
    setQueuePatientsSelectedReferralSourceFilter,
    setQueuePatientsSelectedStageFilter,
  } = useQueuePatientsStore()

  const pcpReferralsItemClickHandler = () => {
    setQueuePatientsSearchInputValue('')
    const referralSourceFilters: ReferralSource[] = [
      'PCP - One Medical',
      'PCP - Other',
    ]
    const stageFilters: Stage[] = ['Attempted', 'Pending Contact']
    setQueuePatientsSelectedReferralSourceFilter(
      referralSourceFilters.join(',')
    )
    setQueuePatientsSelectedStageFilter(stageFilters.join(','))
    const searchParamsFilters = new URLSearchParams({
      referralSource: referralSourceFilters.join(','),
      stage: stageFilters.join(','),
    })
    navigate(`/queues/patients?${searchParamsFilters}`)
  }

  const orthoReferralsItemClickHandler = () => {
    setQueuePatientsSearchInputValue('')
    const referralSourceFilters: ReferralSource[] = [
      'Orthopedic Surgeon - Resurgens',
      'Orthopedic Surgeon - Other',
    ]
    const stageFilters: Stage[] = ['Attempted', 'Pending Contact']
    setQueuePatientsSelectedReferralSourceFilter(
      referralSourceFilters.join(',')
    )
    setQueuePatientsSelectedStageFilter(stageFilters.join(','))
    const searchParamsFilters = new URLSearchParams({
      referralSource: referralSourceFilters.join(','),
      stage: stageFilters.join(','),
    })
    navigate(`/queues/patients?${searchParamsFilters}`)
  }

  const getLaunchpadItemClickHandler = (queueName: string) => () => {
    if (queueName.toLocaleLowerCase() === 'pcp referrals') {
      return pcpReferralsItemClickHandler()
    }
    if (queueName.toLocaleLowerCase() === 'ortho referrals') {
      return orthoReferralsItemClickHandler()
    }
  }

  return (
    <Box
      key={queue.position}
      sx={{
        p: '16px 16px 40px 16px',
        display: 'flex',
        flex: '0 1 258px',
        flexDirection: 'column',
        rowGap: '24px',
        alignItems: 'center',
        cursor: 'pointer',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        backgroundColor: '#FFFFFF',
        '&:hover': {
          backgroundColor: '#F5F7FA',
        },
      }}
      onClick={getLaunchpadItemClickHandler(queue.name)}
      data-testid={getQueueDataTestId(queue.name)}
    >
      <Typography
        sx={{
          fontFamily: 'Inter',
          fontSize: '20px',
          fontWeight: '600',
          letterSpacing: '1.5px',
          color: '#6A7682',
        }}
      >
        {queue.name.toUpperCase()}
      </Typography>
      <QueueCount queue={queue} />
    </Box>
  )
}
