import { Box, Divider, Stack, Typography } from '@mui/material'
import { FieldPath, FieldValues, useWatch } from 'react-hook-form'

import { filterNoneValueOptions } from 'src/features/notes/adapters'
import {
  AdditionalQuestionsFormValues,
  ControlledDatePicker,
  CustomSelectField,
  CustomTextField,
  InjectionTypeField,
  MissedAppointmentDetailsField,
  MissedAppointmentReasonField,
  MissedAppointmentRescheduledField,
  OutcomeNoteField,
  visitOutcomes,
  yesNo,
} from 'src/features/shared/presentation'

export type OrthoChartCheckQuestionsFormValues = Pick<
  AdditionalQuestionsFormValues,
  | 'completedAppointment'
  | 'reasonForMissingAppointment'
  | 'detailsOfMissedAppointment'
  | 'appointmentRescheduled'
  | 'outcomeOfVisit'
  | 'typeOfInjection'
  | 'outcomeNote'
  | 'appointmentDate'
  | 'surgeryDate'
  | 'typeOfInjectionText'
>

export const OrthoChartCheckAdditionalQuestionsForm = <
  T extends FieldValues
>() => {
  const completedAppointment = 'completedAppointment' as FieldPath<T>
  const outcomeOfVisit = 'outcomeOfVisit' as FieldPath<T>
  const appointmentDate = 'appointmentDate' as FieldPath<T>
  const surgeryDate = 'surgeryDate' as FieldPath<T>

  // Field watchers
  const watcher = useWatch()
  const watchCompletedAppointment = watcher.completedAppointment
  const watchOutcomeOfVisit = watcher.outcomeOfVisit
  const watchInjectionType = watcher.typeOfInjection

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          width: 170,
          whiteSpace: 'nowrap',
          flexDirection: 'column',
        }}
      >
        <Divider />
        <Typography pb={2} color={'grey'}>
          Chart Check Questions
        </Typography>
      </Box>
      <Stack direction={'column'} rowGap={2}>
        <CustomSelectField
          name={completedAppointment}
          id="completed-appointment"
          label={'Did you go to your most recent appointment?'}
          items={filterNoneValueOptions(yesNo)}
          selectStyle={{ width: '88px' }}
          labelStyle={{ fontWeight: 'bold', pb: 1 }}
        />
        {watchCompletedAppointment === 'No' ? (
          <>
            <MissedAppointmentReasonField />
            <MissedAppointmentDetailsField />
            <MissedAppointmentRescheduledField />
          </>
        ) : null}
        <CustomSelectField
          name={outcomeOfVisit}
          id="outcome-of-visit"
          label={'What was the outcome of the visit?'}
          items={visitOutcomes}
          selectStyle={{ width: '323px' }}
          labelStyle={{
            fontWeight: 'bold',
            pb: 1,
          }}
        />
        {watchOutcomeOfVisit === 'Pathway Change - Injection' ? (
          <>
            <InjectionTypeField />
            {watchInjectionType === 'Other' ? (
              <CustomTextField
                name={'typeOfInjectionText'}
                id="other-injection-type"
                placeholder="Other injection type"
                inputStyle={{ width: '100%' }}
              />
            ) : null}
          </>
        ) : null}

        {watchOutcomeOfVisit === 'Other' ? <OutcomeNoteField /> : null}
        <Stack direction={'row'} py={2} columnGap={2}>
          <ControlledDatePicker
            name={appointmentDate}
            datePickerProps={{
              label: 'Date of visit',
              slotProps: {
                textField: {
                  sx: {
                    flex: { xs: '0 1 265px' },
                  },
                },
              },
            }}
          />
          <ControlledDatePicker
            name={surgeryDate}
            datePickerProps={{
              label: 'Surgical Date (if Applicable)',
              slotProps: {
                textField: {
                  sx: {
                    flex: { xs: '0 1 265px' },
                  },
                },
              },
            }}
          />
        </Stack>
      </Stack>
    </>
  )
}
