import { FC } from 'react'

import { ControlledDatePicker } from 'src/features/shared/presentation'

export const ReferralDateField: FC = () => {
  return (
    <ControlledDatePicker
      name={'referralDate'}
      datePickerProps={{
        label: 'Date of referral',
      }}
    />
  )
}
