import { rest } from 'msw'

import {
  GetTodayTasksByUserIdServiceResponse,
  GetDoneTasksByUserIdServiceResponse,
  GetUpcomingTasksByUserIdServiceResponse,
  GetUrgentTasksByUserIdServiceResponse,
  GetOverdueTasksByUserIdServiceResponse,
} from 'src/features/tasks/infrastructure/'

import * as settings from 'src/config/settings'
import {
  getTodayTasksByUserIdServiceResponseMock,
  getDoneTasksByUserIdServiceResponseMock,
  getUpcomingTasksByUserIdServiceResponseMock,
  getOverdueTasksByUserIdServiceResponseMock,
  getUrgentTasksByUserIdServiceResponseMock,
} from 'src/__mocks__/responses/get-tasks-by-user-id-service-response'

const url = `${settings.COMPASS_API_BASE_URL}/tasks`

export const getTasksByUserIdServiceResponseHandler = rest.get<
  | GetTodayTasksByUserIdServiceResponse
  | GetDoneTasksByUserIdServiceResponse
  | GetUpcomingTasksByUserIdServiceResponse
  | GetUrgentTasksByUserIdServiceResponse
  | GetOverdueTasksByUserIdServiceResponse
>(url, async (req, res, ctx) => {
  const timeFrame = req.url.searchParams.get('timeFrame')

  if (timeFrame === 'done') {
    return res(
      ctx.status(200),
      ctx.json(getDoneTasksByUserIdServiceResponseMock)
    )
  }
  if (timeFrame === 'upcoming') {
    return res(
      ctx.status(200),
      ctx.json(getUpcomingTasksByUserIdServiceResponseMock)
    )
  }
  if (timeFrame === 'overdue') {
    return res(
      ctx.status(200),
      ctx.json(getOverdueTasksByUserIdServiceResponseMock)
    )
  }
  if (timeFrame === 'urgent') {
    return res(
      ctx.status(200),
      ctx.json(getUrgentTasksByUserIdServiceResponseMock)
    )
  }
  return res(
    ctx.status(200),
    ctx.json(getTodayTasksByUserIdServiceResponseMock)
  )
})
