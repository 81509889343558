import { useCallback, useMemo } from 'react'

import {
  useOverdueTasksByUserIdInfiniteQuery,
  useTodayTasksByUserIdInfiniteQuery,
  useUrgentTasksByUserIdInfiniteQuery,
  useDoneTasksByUserIdInfiniteQuery,
  useUpcomingTasksByUserIdInfiniteQuery,
} from 'src/features/tasks/presentation'

type RefreshTasksProps = {
  upcoming?: boolean
  today?: boolean
  urgent?: boolean
  overdue?: boolean
  done?: boolean
}

type UseRefreshTasks = () => {
  refreshTasks: (props?: RefreshTasksProps) => void
  isRefreshingTasks: boolean
}

export const useRefreshTasks: UseRefreshTasks = () => {
  const { doneTasksIsLoading, doneTasksIsFetching, refetchDoneTasks } =
    useDoneTasksByUserIdInfiniteQuery()
  const { todayTasksIsFetching, todayTasksIsLoading, refetchTodayTasks } =
    useTodayTasksByUserIdInfiniteQuery()
  const { urgentTasksIsFetching, urgentTasksIsLoading, refetchUrgentTasks } =
    useUrgentTasksByUserIdInfiniteQuery()
  const { overdueTasksIsFetching, overdueTasksIsLoading, refetchOverdueTasks } =
    useOverdueTasksByUserIdInfiniteQuery()
  const {
    upcomingTasksIsFetching,
    upcomingTasksIsLoading,
    refetchUpcomingTasks,
  } = useUpcomingTasksByUserIdInfiniteQuery()

  const refreshTasks = useCallback(
    (
      { upcoming, today, urgent, overdue, done }: RefreshTasksProps = {
        upcoming: true,
        today: true,
        urgent: true,
        overdue: true,
        done: true,
      }
    ) => {
      if (today) {
        refetchTodayTasks()
      }
      if (urgent) {
        refetchUrgentTasks()
      }
      if (overdue) {
        refetchOverdueTasks()
      }
      if (done) {
        refetchDoneTasks()
      }
      if (upcoming) {
        refetchUpcomingTasks()
      }
    },
    [
      refetchDoneTasks,
      refetchOverdueTasks,
      refetchTodayTasks,
      refetchUpcomingTasks,
      refetchUrgentTasks,
    ]
  )

  const isRefreshingTasks = useMemo(() => {
    return (
      doneTasksIsLoading ||
      doneTasksIsFetching ||
      todayTasksIsLoading ||
      todayTasksIsFetching ||
      urgentTasksIsLoading ||
      urgentTasksIsFetching ||
      overdueTasksIsLoading ||
      overdueTasksIsFetching ||
      upcomingTasksIsLoading ||
      upcomingTasksIsFetching
    )
  }, [
    doneTasksIsFetching,
    doneTasksIsLoading,
    todayTasksIsFetching,
    todayTasksIsLoading,
    urgentTasksIsFetching,
    urgentTasksIsLoading,
    overdueTasksIsFetching,
    overdueTasksIsLoading,
    upcomingTasksIsFetching,
    upcomingTasksIsLoading,
  ])

  return {
    refreshTasks,
    isRefreshingTasks,
  }
}
