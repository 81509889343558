import axios from 'axios'

import { TasksUseCases } from 'src/features/tasks/domain'
import { useTasksService } from 'src/features/tasks/infrastructure'
import {
  mapToGetTodayTasksByUserIdReturns,
  mapToGetDoneTasksByUserIdReturns,
  mapToGetUpcomingTasksByUserIdReturns,
  mapToUpdateTaskError,
  mapToUpdateTaskReturns,
  mapToUpdateTaskServiceArgs,
  mapToCreateTaskError,
  mapToCreateTaskReturns,
  mapToCreateTaskServicePayload,
  mapToGetUpcomingTasksByUserIdError,
  mapToGetDoneTasksByUserIdError,
  mapToGetTodayTasksByUserIdError,
  mapToGetDoneTasksServiceArgs,
  mapToGetTodayTasksServiceArgs,
  mapToGetUpcomingTasksServiceArgs,
  mapToGetOverdueTasksByUserIdError,
  mapToGetOverdueTasksByUserIdReturns,
  mapToGetOverdueTasksServiceArgs,
  mapToGetUrgentTasksByUserIdError,
  mapToGetUrgentTasksByUserIdReturns,
  mapToGetUrgentTasksServiceArgs,
} from 'src/features/tasks/adapters'
import { useAxiosErrorHandling } from 'src/features/shared/infrastructure'

export const useTasksServiceAdapter = () => {
  const tasksService = useTasksService()
  const { handleAxiosError } = useAxiosErrorHandling()

  const getTodayTasksByUserId: TasksUseCases['getTodayTasksByUserId'] = async (
    args
  ) => {
    try {
      const serviceArgs = mapToGetTodayTasksServiceArgs(args)
      const response = await tasksService.getTodayTasksByUserId(serviceArgs)
      return mapToGetTodayTasksByUserIdReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToGetTodayTasksByUserIdError)
      }
      throw error
    }
  }

  const getUrgentTasksByUserId: TasksUseCases['getUrgentTasksByUserId'] =
    async (args) => {
      try {
        const serviceArgs = mapToGetUrgentTasksServiceArgs(args)
        const response = await tasksService.getUrgentTasksByUserId(serviceArgs)
        return mapToGetUrgentTasksByUserIdReturns(response.data)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          handleAxiosError(error, mapToGetUrgentTasksByUserIdError)
        }
        throw error
      }
    }

  const getOverdueTasksByUserId: TasksUseCases['getOverdueTasksByUserId'] =
    async (args) => {
      try {
        const serviceArgs = mapToGetOverdueTasksServiceArgs(args)
        const response = await tasksService.getOverdueTasksByUserId(serviceArgs)
        return mapToGetOverdueTasksByUserIdReturns(response.data)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          handleAxiosError(error, mapToGetOverdueTasksByUserIdError)
        }
        throw error
      }
    }
  const getDoneTasksByUserId: TasksUseCases['getDoneTasksByUserId'] = async (
    args
  ) => {
    try {
      const serviceArgs = mapToGetDoneTasksServiceArgs(args)
      const response = await tasksService.getDoneTasksByUserId(serviceArgs)
      return mapToGetDoneTasksByUserIdReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToGetDoneTasksByUserIdError)
      }
      throw error
    }
  }

  const getUpcomingTasksByUserId: TasksUseCases['getUpcomingTasksByUserId'] =
    async (args) => {
      try {
        const serviceArgs = mapToGetUpcomingTasksServiceArgs(args)
        const response = await tasksService.getUpcomingTasksByUserId(
          serviceArgs
        )
        return mapToGetUpcomingTasksByUserIdReturns(response.data)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          handleAxiosError(error, mapToGetUpcomingTasksByUserIdError)
        }
        throw error
      }
    }

  const updateTask: TasksUseCases['updateTask'] = async (args) => {
    try {
      const serviceArgs = mapToUpdateTaskServiceArgs(args)
      const response = await tasksService.updateTask(serviceArgs)
      return mapToUpdateTaskReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToUpdateTaskError)
      }
      throw error
    }
  }

  const createTask: TasksUseCases['createTask'] = async (args) => {
    try {
      const payload = mapToCreateTaskServicePayload(args)
      const response = await tasksService.createTask(payload)
      return mapToCreateTaskReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToCreateTaskError)
      }
      throw error
    }
  }

  return {
    getTodayTasksByUserId,
    getUrgentTasksByUserId,
    getOverdueTasksByUserId,
    getDoneTasksByUserId,
    getUpcomingTasksByUserId,
    updateTask,
    createTask,
  }
}
