import { useCallback } from 'react'

import {
  CreateTaskService,
  CreateTaskServiceResponse,
  GetDoneTasksByUserIdService,
  GetDoneTasksByUserIdServiceResponse,
  GetOverdueTasksByUserIdService,
  GetOverdueTasksByUserIdServiceResponse,
  GetTodayTasksByUserIdService,
  GetTodayTasksByUserIdServiceResponse,
  GetUpcomingTasksByUserIdService,
  GetUpcomingTasksByUserIdServiceResponse,
  GetUrgentTasksByUserIdService,
  GetUrgentTasksByUserIdServiceResponse,
  UpdateTaskService,
  UpdateTaskServiceResponse,
} from 'src/features/tasks/infrastructure'
import { useCompassApiClient } from 'src/features/shared/infrastructure'
import * as settings from 'src/config/settings'

export const useTasksService = () => {
  const { getCompassApiClient } = useCompassApiClient()

  const getTodayTasksByUserId: GetTodayTasksByUserIdService = useCallback(
    async (args) => {
      const compassClient = await getCompassApiClient()
      return await compassClient.get<GetTodayTasksByUserIdServiceResponse>(
        settings.COMPASS_API_BASE_URL + '/tasks',
        {
          params: args?.params,
        }
      )
    },
    [getCompassApiClient]
  )

  const getUrgentTasksByUserId: GetUrgentTasksByUserIdService = useCallback(
    async (args) => {
      const compassClient = await getCompassApiClient()
      return await compassClient.get<GetUrgentTasksByUserIdServiceResponse>(
        settings.COMPASS_API_BASE_URL + '/tasks',
        {
          params: args?.params,
        }
      )
    },
    [getCompassApiClient]
  )

  const getOverdueTasksByUserId: GetOverdueTasksByUserIdService = useCallback(
    async (args) => {
      const compassClient = await getCompassApiClient()
      return await compassClient.get<GetOverdueTasksByUserIdServiceResponse>(
        settings.COMPASS_API_BASE_URL + '/tasks',
        {
          params: args?.params,
        }
      )
    },
    [getCompassApiClient]
  )

  const getDoneTasksByUserId: GetDoneTasksByUserIdService = useCallback(
    async (args) => {
      const compassClient = await getCompassApiClient()
      return await compassClient.get<GetDoneTasksByUserIdServiceResponse>(
        settings.COMPASS_API_BASE_URL + '/tasks',
        {
          params: args?.params,
        }
      )
    },
    [getCompassApiClient]
  )

  const getUpcomingTasksByUserId: GetUpcomingTasksByUserIdService = useCallback(
    async (args) => {
      const compassClient = await getCompassApiClient()
      return await compassClient.get<GetUpcomingTasksByUserIdServiceResponse>(
        settings.COMPASS_API_BASE_URL + '/tasks',
        {
          params: args?.params,
        }
      )
    },
    [getCompassApiClient]
  )

  const updateTask: UpdateTaskService = useCallback(
    async ({ taskId, payload }) => {
      const compassClient = await getCompassApiClient()
      return await compassClient.put<UpdateTaskServiceResponse>(
        settings.COMPASS_API_BASE_URL + `/tasks/${taskId}`,
        payload
      )
    },
    [getCompassApiClient]
  )

  const createTask: CreateTaskService = useCallback(
    async (payload) => {
      const compassClient = await getCompassApiClient()
      return await compassClient.post<CreateTaskServiceResponse>(
        settings.COMPASS_API_BASE_URL + `/tasks`,
        payload
      )
    },
    [getCompassApiClient]
  )

  return {
    getTodayTasksByUserId,
    getUrgentTasksByUserId,
    getOverdueTasksByUserId,
    getDoneTasksByUserId,
    getUpcomingTasksByUserId,
    updateTask,
    createTask,
  }
}
