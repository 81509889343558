import { ErrorMessage } from '@hookform/error-message'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form'
import { injectionTypes } from 'src/features/shared/presentation'

export const InjectionTypeField = <T extends FieldValues>() => {
  const name = 'typeOfInjection' as FieldPath<T>
  const { register } = useFormContext()

  return (
    <Controller
      name={name}
      {...register}
      render={({ field }) => (
        <FormControl size={'small'} sx={{ width: '417px' }}>
          <InputLabel id={'injection-type-label'}>
            What type of injection?
          </InputLabel>
          <Select
            labelId={'injection-type-label'}
            id={'injection-type'}
            label={' What type of injection?'}
            value={field.value}
            onChange={(event) => {
              field.onChange(event.target.value)
            }}
          >
            {injectionTypes.map((type) => (
              <MenuItem key={type.id} value={type.label}>
                {type.label}
              </MenuItem>
            ))}
          </Select>
          <ErrorMessage
            name={name}
            render={({ message }) => (
              <FormHelperText sx={{ m: 0 }} error={true}>
                {message}
              </FormHelperText>
            )}
          />
        </FormControl>
      )}
    />
  )
}
