interface AdditionalQuestionLabels {
  [key: string]: string
}

export const ADDITIONAL_QUESTION_LABELS: AdditionalQuestionLabels = {
  painLevelDecreased:
    'Has your pain level decreased since you started self-care?',
  newSymptoms: 'Do you have any new symptoms?',
  completedAppointment: 'Did you go to your most recent appointment?',
  reasonForMissingAppointment: 'What was the reason for missing?',
  detailsOfMissedAppointment: 'Further details on why missed',
  appointmentRescheduled: 'Was the appointment rescheduled?',
  completingPhysicalTherapyExercises:
    'Have you been doing your physical therapy exercises at home?',
  reasonForNotCompletingPTExercises: 'If No, why not?',
  painDuringPTExercise: 'What is your pain level while performing exercises?',
  outcomeOfVisit: 'What was the outcome of your visit?',
  outcomeNote: 'Outcome note:',
  improvementFromInjection:
    'How much do you feel your injection has helped your pain/function?',
  typeOfInjection: 'What type of injection?',
  appointmentDate: 'Date of visit?',
  surgeryDate: 'Surgical date?',
  isPainControlled: 'Do you feel your pain is controlled?',
  accessToMedicineToControlPain:
    'Do you feel you have access to the medication you need to control your pain?',
  participatingInPT: 'Are you participating in physical therapy?',
  physicalTherapyLocation: 'Physical therapy location:',
  additionalQuestionsOrConcerns:
    'What additional questions or concerns do you have about your condition?',
  sdmText: 'SDM note:',
  scheduledFollowUp:
    'Do you have a follow-up visit scheduled with your surgeon after your surgery?',
  questionsAboutSurgery:
    'Do you have any questions about what to expect before and after surgery?',
  procedure: 'Procedure type:',
  providerName: 'Provider Name:',
  providerGroup: 'Provider group:',
  providerGroupText: 'Provider group other:',
  preferredProvider: 'Resurgens preferred provider?',
  providerGroupLocation: 'Provider group location',
  scheduled: 'Appt. scheduled?',
  agreeYouUnderstandCondition: 'Patient understands conditions?',
  agreeYouUnderstandTreatmentOptions: 'Patient understands treatment options?',
  referringProvider: 'Referring provider:',
  referralDate: 'Date of Referral',
  referralType: 'Referral Type',
  referredFor: 'Referred for:',
  medicalHistory: 'Medical History:',
  additionalConditionPain:
    'Regarding your additional condition. On a scale of 0 to 10, what is your current pain level?',
  additionalConditionFunction:
    'Regarding your additional condition. On a scale of 0 to 10, how would you rate your ability to do your normal daily activities?',
  surgerySite: 'Surgery Site:',
  dischargePosition: 'Discharge Disposition:',
  sdmPatientUnderstandsCondition:
    'Was SDM completed and does the patient understand benefits and risks of pathway choice?',
  sdmClinicalBelievesPatientUnderstandsCondition: `Do you feel like this patient understands condition and treatment options?`,
  bodyRegionToNote: 'Body region to note: ',
}

export const questionOrder: string[] = [
  'sdmText',
  'completedAppointment',
  'reasonForMissingAppointment',
  'detailsOfMissedAppointment',
  'appointmentRescheduled',
  'completingPhysicalTherapyExercises',
  'reasonForNotCompletingPTExercises',
  'painDuringPTExercise',
  'painLevelDecreased',
  'newSymptoms',
  'outcomeOfVisit',
  'outcomeNote',
  'isPainControlled',
  'typeOfInjection',
  'improvementFromInjection',
  'accessToMedicineToControlPain',
  'procedure',
  'surgerySite',
  'dischargePosition',
  'participatingInPT',
  'physicalTherapyLocation',
  'scheduledFollowUp',
  'questionsAboutSurgery',
  'additionalQuestionsOrConcerns',
  'referringProvider',
  'referralDate',
  'referralType',
  'providerName',
  'providerGroup',
  'providerGroupText',
  'preferredProvider',
  'providerGroupLocation',
  'appointmentDate',
  'surgeryDate',
  'scheduled',
  'referredFor',
  'bodyRegionToNote',
  'medicalHistory',
  'sdmPatientUnderstandsCondition',
  'sdmClinicalBelievesPatientUnderstandsCondition',
  'agreeYouUnderstandCondition',
  'agreeYouUnderstandTreatmentOptions',
  'additionalConditionPain',
  'additionalConditionFunction',
]

export const questionsWithDateVaules: string[] = [
  'appointmentDate',
  'surgeryDate',
  'referralDate',
]
