import * as yup from 'yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import {
  FormLayoutWithCancelSave,
  FormControlledNoteField,
} from 'src/features/shared/presentation/components/form-fields'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'

import { Grid } from '@mui/material'
import {
  NoteFormProps,
  useCreatePatientOrthoChartCheckNote,
} from 'src/features/notes/presentation'
import { mapToCreatePatientOrthoChartCheckNoteArgs } from 'src/features/notes/adapters'

import {
  OrthoChartCheckAdditionalQuestionsForm,
  OrthoChartCheckQuestionsFormValues,
} from 'src/features/notes/presentation/components/additional-questions'
import { useEffect } from 'react'
import { useNotesStore } from 'src/features/shared/infrastructure'
import { DevTool } from '@hookform/devtools'

export type OrthoChartCheckNoteFormFields =
  OrthoChartCheckQuestionsFormValues & {
    note: string
    communication?: string
  }

type OrthoChartCheckNoteFormProps = NoteFormProps

export const OrthoChartCheckNoteForm = ({
  patient,
  setShowLoader,
}: OrthoChartCheckNoteFormProps) => {
  const { createPatientOrthoChartCheckNoteAsync } =
    useCreatePatientOrthoChartCheckNote()
  const { noteText } = useNotesStore()

  const createOrthoChartCheckNoteFormSchema: yup.Schema<OrthoChartCheckNoteFormFields> =
    yup.object().shape({
      note: yup.string().required('Note is required'),
      completedAppointment: yup.string().required('This field is required'),
      reasonForMissingAppointment: yup.string().when('completedAppointment', {
        is: (completedAppointment: string) => completedAppointment === 'No',
        then: (schema) => schema.required('This field is required'),
      }),
      detailsOfMissedAppointment: yup.string().when('completedAppointment', {
        is: (completedAppointment: string) => completedAppointment === 'No',
        then: (schema) => schema.required('This field is required'),
      }),
      appointmentRescheduled: yup.string().when('completedAppointment', {
        is: (completedAppointment: string) => completedAppointment === 'No',
        then: (schema) => schema.required('This field is required'),
      }),
      outcomeOfVisit: yup.string().when('completedAppointment', {
        is: (completedAppointment: string) => completedAppointment === 'Yes',
        then: (schema) => schema.required('This field is required'),
      }),
      typeOfInjection: yup.string().when('outcomeOfVisit', {
        is: (outcomeOfVisit: string) => outcomeOfVisit === 'Injections',
        then: (schema) => schema.required('This field is required'),
      }),
      typeOfInjectionText: yup.string().when('typeOfInjection', {
        is: (typeOfInjection: string) => typeOfInjection === 'Other',
        then: (schema) => schema.required('This field is required'),
      }),
      outcomeNote: yup.string(),
      appointmentDate: yup.date().nullable(),
      surgeryDate: yup.date().nullable(),
    })

  const formMethods = useForm<OrthoChartCheckNoteFormFields>({
    resolver: yupResolver(createOrthoChartCheckNoteFormSchema),
    defaultValues: {
      note: noteText,
      completedAppointment: '',
      reasonForMissingAppointment: '',
      detailsOfMissedAppointment: '',
      appointmentRescheduled: '',
      outcomeOfVisit: '',
      typeOfInjection: '',
      typeOfInjectionText: '',
      outcomeNote: '',
      appointmentDate: null,
      surgeryDate: null,
    },
  })

  useEffect(() => {
    const subscription = formMethods.watch((value, { name, type }) => {
      if (
        name === 'outcomeOfVisit' &&
        type === 'change' &&
        value['outcomeNote']
      ) {
        formMethods.reset((formValues) => ({
          ...formValues,
          outcomeNote: '',
        }))
      }

      if (
        name === 'outcomeOfVisit' &&
        type === 'change' &&
        value['typeOfInjection']
      ) {
        formMethods.reset((formValues) => ({
          ...formValues,
          typeOfInjection: '',
        }))
      }

      if (
        name === 'typeOfInjection' &&
        type === 'change' &&
        value['typeOfInjectionText']
      ) {
        formMethods.reset((formValues) => ({
          ...formValues,
          typeOfInjectionText: '',
        }))
      }

      if (
        name === 'completedAppointment' &&
        type === 'change' &&
        (value['reasonForMissingAppointment'] ||
          value['detailsOfMissedAppointment'] ||
          value['appointmentRescheduled'])
      ) {
        formMethods.reset((formValues) => ({
          ...formValues,
          reasonForMissingAppointment: '',
          detailsOfMissedAppointment: '',
          appointmentRescheduled: '',
        }))
      }
    })

    return () => subscription.unsubscribe()
  }, [formMethods])

  const submitHandler: SubmitHandler<OrthoChartCheckNoteFormFields> = async (
    data
  ) => {
    setShowLoader?.(true)

    try {
      const createPatientOrthoChartCheckNoteArgs =
        mapToCreatePatientOrthoChartCheckNoteArgs(patient, data)

      await createPatientOrthoChartCheckNoteAsync(
        createPatientOrthoChartCheckNoteArgs,
        {
          onSuccess: () => {
            toast.success('Patient Note successfully created!')
          },
          onSettled: () => {
            setShowLoader?.(false)
          },
        }
      )
    } catch (error) {
      toast.error('Failed creating patient note!')
      toast.error('Patient Status update failed!')
    }
  }

  return (
    <FormLayoutWithCancelSave
      onSubmit={submitHandler}
      formMethods={formMethods}
      renderCommunicationField={false}
    >
      <Grid item container sm={12} pb={2}>
        <FormControlledNoteField />
      </Grid>

      <OrthoChartCheckAdditionalQuestionsForm />
      <DevTool control={formMethods.control} placement={'top-left'} />
    </FormLayoutWithCancelSave>
  )
}
