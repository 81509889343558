import { ErrorMessage } from '@hookform/error-message'
import { FormControl, FormHelperText, TextField } from '@mui/material'
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form'

export const ReferringProviderField = <T extends FieldValues>() => {
  const name = 'referringProvider' as FieldPath<T>
  const { register } = useFormContext()

  return (
    <Controller
      name={name}
      {...register}
      render={({ field }) => (
        <FormControl sx={{ width: '100%' }}>
          <TextField
            size={'small'}
            id={'referring-provider'}
            label={'Referring Provider'}
            value={field.value}
            onChange={(event) => {
              field.onChange(event.target.value)
            }}
          />
          <ErrorMessage
            name={name}
            render={({ message }) => (
              <FormHelperText sx={{ m: 0 }} error={true}>
                {message}
              </FormHelperText>
            )}
          />
        </FormControl>
      )}
    />
  )
}
