import * as yup from 'yup'
import { DevTool } from '@hookform/devtools'
import {
  Alert,
  AlertTitle,
  Backdrop,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  TextField,
  Typography,
} from '@mui/material'
import { FC, useEffect, useState } from 'react'
import {
  SubmitHandler,
  FormProvider,
  Controller,
  UseFormReturn,
} from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import InputMask from 'react-input-mask'
import ReactRouterPrompt from 'react-router-prompt'

import OpenInNewIcon from '@mui/icons-material/OpenInNew'

import {
  BackdropLoader,
  ControlledDatePicker,
  LanguageFieldsSection,
  ReferralSourceSelect,
  PayorSelect,
  WarningModal,
} from 'src/features/shared/presentation'
import {
  addressRegex,
  cityRegex,
  cleanAddressFieldValue,
  cleanCityFieldValue,
  cleanNameFieldValue,
  cleanPhoneFieldValue,
  cleanStateFieldValue,
  cleanZipFieldValue,
  getMaskedPhone,
  nameRegex,
  phoneRegex,
  stateRegex,
  zipRegex,
} from 'src/features/shared/utils'
import {
  mapToCreatePatientManuallyArgs,
  mapToDuplicatePatientWarningResultListItem,
  mapToSearchDuplicatePatientParams,
} from 'src/features/patients/adapters'
import {
  useCreatePatientManually,
  useSearchDuplicatePatientsQuery,
} from 'src/features/patients/presentation'
import { useQueuePatientsStore } from 'src/features/shared/infrastructure'

export type DuplicatePatientWarningResultListItem = {
  label: string
  id: string
}

type DuplicatePatientWarningProps = {
  open: boolean
  patientItems: DuplicatePatientWarningResultListItem[]
  onContinueClick: () => void
}

export const DuplicatePatientWarning: FC<DuplicatePatientWarningProps> = ({
  open,
  onContinueClick,
  patientItems,
}) => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <Alert
        severity={'warning'}
        sx={{
          '& .MuiAlert-message': {
            pr: '16px',
          },
        }}
      >
        <AlertTitle>{'You may be creating a duplicate record...'}</AlertTitle>
        <Typography>
          {
            'Before creating a new record, please confirm that the record you are looking for is not listed below:'
          }
        </Typography>
        <ul>
          {patientItems.map((item, index) => (
            <li key={index}>
              <Link to={`/patients/${item.id}`} target={'_blank'}>
                {item.label} <OpenInNewIcon sx={{ fontSize: '14px' }} />
              </Link>
            </li>
          ))}
        </ul>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={onContinueClick}
            color={'warning'}
            variant={'contained'}
          >
            {'Continue'}
          </Button>
        </Box>
      </Alert>
    </Backdrop>
  )
}

type CreatePatientManuallyFormWarningModalProps = {
  open: boolean
  onConfirm: () => void
  onCancel: () => void
  message?: string
  title?: string
}
export const CreatePatientManuallyFormWarningModal: FC<
  CreatePatientManuallyFormWarningModalProps
> = ({ open, onConfirm, onCancel, message, title }) => {
  const defaultTitle = 'Are you sure?'
  const defaultMessage =
    'You have a manual entry in progress, Leaving this page will result in unsaved entries being lost.'
  return (
    <WarningModal
      title={title || defaultTitle}
      message={message || defaultMessage}
      open={open}
      onConfirm={onConfirm}
      onCancel={onCancel}
      confirmButtonText={'Yes'}
      cancelButtonText={'Cancel'}
    />
  )
}

export type CreatePatientManuallyFormFields = {
  firstName: string
  middleName?: string
  lastName: string
  dob: Date
  phone1: string
  phone2?: string
  email?: string
  addressLine1: string
  addressLine2?: string
  city: string
  state: string
  zip: string
  language?: string
  otherLanguage?: string
  primaryCarePhysician?: string
  pcpPhone?: string
  pcpFax?: string
  pcpGroupName?: string
  pcpAddressLine1?: string
  pcpAddressLine2?: string
  pcpCity?: string
  pcpState?: string
  pcpZip?: string
  referralSource: string
  payor?: string
  otherPayor?: string
  planId?: string
  dnsp: boolean
}

const isOtherPayorRequired = (payor?: string) => payor === 'Other'
const isOtherLanguageRequired = (language?: string) => language === 'Other'

export const createPatientFormSchema: yup.Schema<CreatePatientManuallyFormFields> =
  yup.object().shape({
    firstName: yup
      .string()
      .matches(nameRegex, 'Invalid format')
      .required('Required'),
    middleName: yup.string().matches(nameRegex, 'Invalid format'),
    lastName: yup
      .string()
      .matches(nameRegex, 'Invalid format')
      .required('Required'),
    dob: yup.date().typeError('Invalid Date').required('Required'),
    phone1: yup
      .string()
      .required('Required')
      .test('phone1', 'Invalid format', (value) => {
        if (value) {
          return phoneRegex.test(value)
        }
        return true
      }),
    phone2: yup.string().test('phone2', 'Invalid format', (value) => {
      if (value) {
        return phoneRegex.test(value)
      }
      return true
    }),
    email: yup.string().email(),
    addressLine1: yup
      .string()
      .matches(addressRegex, 'Invalid format')
      .required('Required'),
    addressLine2: yup.string().matches(addressRegex, 'Invalid format'),
    city: yup
      .string()
      .matches(cityRegex, 'Invalid format')
      .required('Required'),
    state: yup
      .string()
      .matches(stateRegex, 'Invalid format')
      .required('Required'),
    zip: yup.string().matches(zipRegex, 'Invalid format').required('Required'),
    language: yup.string(),
    otherLanguage: yup.string().when('language', {
      is: isOtherLanguageRequired,
      then: (schema) => schema.required('Required'),
    }),
    primaryCarePhysician: yup.string().matches(nameRegex, 'Invalid format'),
    pcpPhone: yup.string().test('pcpPhone', 'Invalid format', (value) => {
      if (value) {
        return phoneRegex.test(value)
      }
      return true
    }),
    pcpFax: yup.string().test('pcpFax', 'Invalid format', (value) => {
      if (value) {
        return phoneRegex.test(value)
      }
      return true
    }),
    pcpGroupName: yup.string(),
    pcpAddressLine1: yup.string().matches(addressRegex, 'Invalid format'),
    pcpAddressLine2: yup.string().matches(addressRegex, 'Invalid format'),
    pcpCity: yup.string().matches(cityRegex, 'Invalid format'),
    pcpState: yup.string().matches(stateRegex, 'Invalid format'),
    pcpZip: yup.string().matches(zipRegex, 'Invalid format'),
    referralSource: yup.string().required('Required'),
    payor: yup.string(),
    otherPayor: yup.string().when('payor', {
      is: isOtherPayorRequired,
      then: (schema) => schema.required('Required'),
    }),
    planId: yup.string(),
    dnsp: yup.boolean().required('Required'),
  })

export const createPatientFormDefaultValues = {
  firstName: '',
  middleName: '',
  lastName: '',
  dob: undefined,
  phone1: '',
  phone2: '',
  email: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zip: '',
  language: '',
  otherLanguage: '',
  primaryCarePhysician: '',
  pcpPhone: '',
  pcpFax: '',
  pcpGroupName: '',
  pcpAddressLine1: '',
  pcpAddressLine2: '',
  pcpCity: '',
  pcpState: '',
  pcpZip: '',
  referralSource: '',
  payor: '',
  planId: '',
  dnsp: false,
}

type CreatePatientManuallyFormProps = {
  formMethods: UseFormReturn<CreatePatientManuallyFormFields>
}

export const CreatePatientManuallyForm: FC<CreatePatientManuallyFormProps> = ({
  formMethods,
}) => {
  const navigate = useNavigate()
  const { setOpenCreatePatientModal, setCreatePatientModalSelectedTab } =
    useQueuePatientsStore()
  const { createPatientManually } = useCreatePatientManually()
  const [showCancelWarningModal, setShowCancelWarningModal] = useState(false)
  const [showResetWarningModal, setShowResetWarningModal] = useState(false)
  const [showDuplicatePatientWarning, setShowDuplicatePatientWarning] =
    useState(true)
  const [showLoader, setShowLoader] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [createdPatientId, setCreatedPatientId] = useState('')
  const firstNameWatcher = formMethods.watch('firstName')
  const lastNameWatcher = formMethods.watch('lastName')
  const dobWatcher = formMethods.watch('dob')
  const phone1Watcher = formMethods.watch('phone1')
  const phone2Watcher = formMethods.watch('phone2')
  const payorWatcher = formMethods.watch('payor')
  const languageWatcher = formMethods.watch('language')

  const { duplicatePatientsResults } = useSearchDuplicatePatientsQuery({
    ...mapToSearchDuplicatePatientParams({
      firstName: firstNameWatcher,
      lastName: lastNameWatcher,
      dob: dobWatcher,
      phone1: phone1Watcher,
      phone2: phone2Watcher,
    }),
  })

  const submitHandler: SubmitHandler<CreatePatientManuallyFormFields> = (
    data
  ) => {
    setShowLoader(true)
    const createPatientManuallyArgs = mapToCreatePatientManuallyArgs(data)

    createPatientManually(createPatientManuallyArgs, {
      onSuccess: (data) => {
        toast.success(`Patient succesfully created!`)
        formMethods.reset()
        setSubmitted(true)
        setCreatedPatientId(data.patientId)
      },
      onError: (error) => {
        toast.error(`Patient creation failed! ${error.message}`)
      },
      onSettled: () => {
        setShowLoader(false)
      },
    })
  }

  const handleResetClick = () => {
    setShowResetWarningModal(true)
    setShowCancelWarningModal(false)
  }

  const handleCancelClick = () => {
    if (formMethods.formState.isDirty) {
      setShowCancelWarningModal(true)
      setShowResetWarningModal(false)
      return
    }
    setOpenCreatePatientModal(false)
    setCreatePatientModalSelectedTab('eligibility')
  }

  const handleCancelWarningModalConfirm = () => {
    setOpenCreatePatientModal(false)
    setCreatePatientModalSelectedTab('eligibility')
  }
  const handleCancelWarningModalCancel = () => {
    setShowCancelWarningModal(false)
  }

  const handleResetWarningModalConfirm = () => {
    formMethods.reset()
    setShowResetWarningModal(false)
  }

  const handleResetWarningModalCancel = () => {
    setShowResetWarningModal(false)
  }

  useEffect(() => {
    if (submitted && createdPatientId) {
      setOpenCreatePatientModal(false)
      setCreatePatientModalSelectedTab('eligibility')
      navigate(`/patients/${createdPatientId}`)
    }
  }, [
    createdPatientId,
    navigate,
    setCreatePatientModalSelectedTab,
    setOpenCreatePatientModal,
    submitted,
  ])

  useEffect(() => {
    if (duplicatePatientsResults?.length) {
      setShowDuplicatePatientWarning(true)
    }
  }, [duplicatePatientsResults])

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(submitHandler)}>
        <Box
          sx={{
            pl: { xs: '64px' },
            pr: { xs: '32px' },
            py: { xs: '32px' },
            display: 'flex',
            flexDirection: 'column',
            rowGap: '32px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              columnGap: '32px',
              '& > *': {
                flexBasis: { xs: '220px' },
              },
            }}
          >
            <TextField
              label={'First Name'}
              variant={'outlined'}
              size={'small'}
              error={!!formMethods.formState.errors['firstName']}
              helperText={
                formMethods.formState.errors['firstName']
                  ? (formMethods.formState.errors['firstName']
                      ?.message as string)
                  : undefined
              }
              {...formMethods.register('firstName', {
                onChange: (e) => {
                  const value = cleanNameFieldValue(e.target.value)
                  formMethods.setValue('firstName', value)
                },
              })}
              required
            />
            <TextField
              label={'Middle Name'}
              variant={'outlined'}
              size={'small'}
              error={!!formMethods.formState.errors['middleName']}
              helperText={
                formMethods.formState.errors['middleName']
                  ? (formMethods.formState.errors['middleName']
                      ?.message as string)
                  : undefined
              }
              {...formMethods.register('middleName', {
                onChange: (e) => {
                  const value = cleanNameFieldValue(e.target.value)
                  formMethods.setValue('middleName', value)
                },
              })}
            />
            <TextField
              label={'Last Name'}
              variant={'outlined'}
              size={'small'}
              error={!!formMethods.formState.errors['lastName']}
              helperText={
                formMethods.formState.errors['lastName']
                  ? (formMethods.formState.errors['lastName']
                      ?.message as string)
                  : undefined
              }
              {...formMethods.register('lastName', {
                onChange: (e) => {
                  const value = cleanNameFieldValue(e.target.value)
                  formMethods.setValue('lastName', value)
                },
              })}
              required
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              columnGap: '32px',
            }}
          >
            <ControlledDatePicker
              name="dob"
              datePickerProps={{
                label: 'DOB',
                slotProps: {
                  textField: {
                    required: true,
                    sx: {
                      flex: { xs: '0 1 170px' },
                    },
                  },
                },
              }}
            />
            <Controller
              name={'phone1'}
              control={formMethods.control}
              render={({ field, fieldState }) => (
                <Box
                  sx={{
                    flex: { xs: '0 1 150px' },
                  }}
                >
                  <InputMask
                    mask="(999) 999-9999"
                    value={getMaskedPhone(field.value)}
                    onBlur={field.onBlur}
                    onChange={(e) => {
                      const value = cleanPhoneFieldValue(e.target.value)
                      field.onChange(value)
                    }}
                  >
                    <TextField
                      error={!!fieldState.error}
                      helperText={
                        fieldState.error ? fieldState.error.message : ''
                      }
                      label={'Phone 1'}
                      size={'small'}
                      required
                    />
                  </InputMask>
                </Box>
              )}
            />
            <Controller
              name={'phone2'}
              control={formMethods.control}
              render={({ field, fieldState }) => (
                <Box
                  sx={{
                    flex: { xs: '0 1 150px' },
                  }}
                >
                  <InputMask
                    mask="(999) 999-9999"
                    value={getMaskedPhone(field.value)}
                    onBlur={field.onBlur}
                    onChange={(e) => {
                      const value = cleanPhoneFieldValue(e.target.value)
                      field.onChange(value)
                    }}
                  >
                    <TextField
                      error={!!fieldState.error}
                      helperText={
                        fieldState.error ? fieldState.error.message : ''
                      }
                      label={'Phone 2'}
                      size={'small'}
                    />
                  </InputMask>
                </Box>
              )}
            />
            <TextField
              label={'Email'}
              variant={'outlined'}
              size={'small'}
              error={!!formMethods.formState.errors['email']}
              helperText={
                formMethods.formState.errors['email']
                  ? (formMethods.formState.errors['email']?.message as string)
                  : undefined
              }
              {...formMethods.register('email')}
              sx={{
                flex: { xs: '0 1 220px' },
              }}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '16px',
                flex: { xs: '0 1 465px' },
              }}
            >
              <TextField
                label={'Address'}
                variant={'outlined'}
                size={'small'}
                error={!!formMethods.formState.errors['addressLine1']}
                helperText={
                  formMethods.formState.errors['addressLine1']
                    ? (formMethods.formState.errors['addressLine1']
                        ?.message as string)
                    : undefined
                }
                {...formMethods.register('addressLine1', {
                  onChange: (e) => {
                    const value = cleanAddressFieldValue(e.target.value)
                    formMethods.setValue('addressLine1', value)
                  },
                })}
                required
                fullWidth
              />
              <TextField
                label={'Address'}
                variant={'outlined'}
                size={'small'}
                fullWidth
                error={!!formMethods.formState.errors['addressLine2']}
                helperText={
                  formMethods.formState.errors['addressLine2']
                    ? (formMethods.formState.errors['addressLine2']
                        ?.message as string)
                    : undefined
                }
                {...formMethods.register('addressLine2', {
                  onChange: (e) => {
                    const value = cleanAddressFieldValue(e.target.value)
                    formMethods.setValue('addressLine2', value)
                  },
                })}
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <TextField
                  label={'City'}
                  variant={'outlined'}
                  size={'small'}
                  sx={{
                    flex: { xs: '0 1 188px' },
                  }}
                  error={!!formMethods.formState.errors['city']}
                  helperText={
                    formMethods.formState.errors['city']
                      ? (formMethods.formState.errors['city']
                          ?.message as string)
                      : undefined
                  }
                  {...formMethods.register('city', {
                    onChange: (e) => {
                      const value = cleanCityFieldValue(e.target.value)
                      formMethods.setValue('city', value)
                    },
                  })}
                  required
                />
                <TextField
                  label={'State'}
                  variant={'outlined'}
                  size={'small'}
                  sx={{
                    flex: { xs: '0 1 80px' },
                  }}
                  error={!!formMethods.formState.errors['state']}
                  helperText={
                    formMethods.formState.errors['state']
                      ? (formMethods.formState.errors['state']
                          ?.message as string)
                      : undefined
                  }
                  {...formMethods.register('state', {
                    onChange: (e) => {
                      const value = cleanStateFieldValue(e.target.value)
                      formMethods.setValue('state', value)
                    },
                  })}
                  required
                />
                <TextField
                  label={'Zip'}
                  variant={'outlined'}
                  size={'small'}
                  sx={{
                    flex: { xs: '0 1 120px' },
                  }}
                  error={!!formMethods.formState.errors['zip']}
                  helperText={
                    formMethods.formState.errors['zip']
                      ? (formMethods.formState.errors['zip']?.message as string)
                      : undefined
                  }
                  {...formMethods.register('zip', {
                    onChange: (e) => {
                      const value = cleanZipFieldValue(e.target.value)
                      formMethods.setValue('zip', value)
                    },
                  })}
                  required
                />
              </Box>
            </Box>
          </Box>

          <LanguageFieldsSection
            otherLanguageIsRequired={isOtherLanguageRequired(languageWatcher)}
          />

          <Divider textAlign="left" sx={{ color: 'gray' }}>
            PCP Info
          </Divider>

          <Box
            sx={{
              display: 'flex',
              columnGap: '32px',
              '& > *': {
                flexBasis: { xs: '220px' },
              },
            }}
          >
            <TextField
              label={'PCP Name'}
              variant={'outlined'}
              size={'small'}
              error={!!formMethods.formState.errors['primaryCarePhysician']}
              helperText={
                formMethods.formState.errors['primaryCarePhysician']
                  ? (formMethods.formState.errors['primaryCarePhysician']
                      ?.message as string)
                  : undefined
              }
              {...formMethods.register('primaryCarePhysician', {
                onChange: (e) => {
                  const value = cleanNameFieldValue(e.target.value)
                  formMethods.setValue('primaryCarePhysician', value)
                },
              })}
            />
            <TextField
              label={'PCP Group Name'}
              variant={'outlined'}
              size={'small'}
              error={!!formMethods.formState.errors['pcpGroupName']}
              helperText={
                formMethods.formState.errors['pcpGroupName']
                  ? (formMethods.formState.errors['pcpGroupName']
                      ?.message as string)
                  : undefined
              }
              {...formMethods.register('pcpGroupName')}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              columnGap: '32px',
              '& > *': {
                flexBasis: { xs: '220px' },
              },
            }}
          >
            <Controller
              name={'pcpPhone'}
              control={formMethods.control}
              render={({ field, fieldState }) => (
                <InputMask
                  mask="(999) 999-9999"
                  value={getMaskedPhone(field.value)}
                  onBlur={field.onBlur}
                  onChange={(e) => {
                    const value = cleanPhoneFieldValue(e.target.value)
                    field.onChange(value)
                  }}
                >
                  <TextField
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ''
                    }
                    label={'PCP Phone'}
                    size={'small'}
                  />
                </InputMask>
              )}
            />
            <Controller
              name={'pcpFax'}
              control={formMethods.control}
              render={({ field, fieldState }) => (
                <InputMask
                  mask="(999) 999-9999"
                  value={field.value}
                  onBlur={field.onBlur}
                  onChange={(e) => {
                    const value = cleanPhoneFieldValue(e.target.value)
                    field.onChange(value)
                  }}
                >
                  <TextField
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message : ''
                    }
                    label={'PCP Fax'}
                    size={'small'}
                  />
                </InputMask>
              )}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '16px',
                flex: { xs: '0 1 465px' },
              }}
            >
              <TextField
                label={'PCP Address'}
                variant={'outlined'}
                size={'small'}
                fullWidth
                error={!!formMethods.formState.errors['pcpAddressLine1']}
                helperText={
                  formMethods.formState.errors['pcpAddressLine1']
                    ? (formMethods.formState.errors['pcpAddressLine1']
                        ?.message as string)
                    : undefined
                }
                {...formMethods.register('pcpAddressLine1', {
                  onChange: (e) => {
                    const value = cleanAddressFieldValue(e.target.value)
                    formMethods.setValue('pcpAddressLine1', value)
                  },
                })}
              />
              <TextField
                label={'PCP Address'}
                variant={'outlined'}
                size={'small'}
                fullWidth
                error={!!formMethods.formState.errors['pcpAddressLine2']}
                helperText={
                  formMethods.formState.errors['pcpAddressLine2']
                    ? (formMethods.formState.errors['pcpAddressLine2']
                        ?.message as string)
                    : undefined
                }
                {...formMethods.register('pcpAddressLine2', {
                  onChange: (e) => {
                    const value = cleanAddressFieldValue(e.target.value)
                    formMethods.setValue('pcpAddressLine2', value)
                  },
                })}
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <TextField
                  label={'PCP City'}
                  variant={'outlined'}
                  size={'small'}
                  sx={{
                    flex: { xs: '0 1 188px' },
                  }}
                  error={!!formMethods.formState.errors['pcpCity']}
                  helperText={
                    formMethods.formState.errors['pcpCity']
                      ? (formMethods.formState.errors['pcpCity']
                          ?.message as string)
                      : undefined
                  }
                  {...formMethods.register('pcpCity', {
                    onChange: (e) => {
                      const value = cleanCityFieldValue(e.target.value)
                      formMethods.setValue('pcpCity', value)
                    },
                  })}
                />
                <TextField
                  label={'PCP State'}
                  variant={'outlined'}
                  size={'small'}
                  sx={{
                    flex: { xs: '0 1 100px' },
                  }}
                  error={!!formMethods.formState.errors['pcpState']}
                  helperText={
                    formMethods.formState.errors['pcpState']
                      ? (formMethods.formState.errors['pcpState']
                          ?.message as string)
                      : undefined
                  }
                  {...formMethods.register('pcpState', {
                    onChange: (e) => {
                      const value = cleanStateFieldValue(e.target.value)
                      formMethods.setValue('pcpState', value)
                    },
                  })}
                />
                <TextField
                  label={'PCP Zip'}
                  variant={'outlined'}
                  size={'small'}
                  sx={{
                    flex: { xs: '0 1 120px' },
                  }}
                  error={!!formMethods.formState.errors['pcpZip']}
                  helperText={
                    formMethods.formState.errors['pcpZip']
                      ? (formMethods.formState.errors['pcpZip']
                          ?.message as string)
                      : undefined
                  }
                  {...formMethods.register('pcpZip', {
                    onChange: (e) => {
                      const value = cleanZipFieldValue(e.target.value)
                      formMethods.setValue('pcpZip', value)
                    },
                  })}
                />
              </Box>
            </Box>
          </Box>

          <Divider sx={{ color: 'gray' }} />

          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '16px',
                flex: { xs: '0 1 320px' },
              }}
            >
              <ReferralSourceSelect />
              <PayorSelect
                otherPayorIsRequired={isOtherPayorRequired(payorWatcher)}
              />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  columnGap: '16px',
                }}
              >
                <TextField
                  label={'Plan ID'}
                  variant={'outlined'}
                  size={'small'}
                  sx={{
                    flex: { xs: '1 1 151px' },
                  }}
                  error={!!formMethods.formState.errors['planId']}
                  helperText={
                    formMethods.formState.errors['planId']
                      ? (formMethods.formState.errors['planId']
                          ?.message as string)
                      : undefined
                  }
                  {...formMethods.register('planId')}
                />
                <Controller
                  name={'dnsp'}
                  control={formMethods.control}
                  render={({ field, fieldState }) => (
                    <Box>
                      <FormControlLabel
                        componentsProps={{
                          typography: {
                            variant: 'body1',
                          },
                        }}
                        control={
                          <Checkbox
                            {...field}
                            color={'primary'}
                            checked={field.value}
                          />
                        }
                        label={'DNSP'}
                      />
                      <FormHelperText
                        variant={'outlined'}
                        error={!!fieldState.error}
                      >
                        {fieldState.error?.message}
                      </FormHelperText>
                    </Box>
                  )}
                />
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              columnGap: '16px',
            }}
          >
            <Button
              variant={'outlined'}
              color={'secondary'}
              onClick={handleResetClick}
            >
              RESET
            </Button>
            <Box
              sx={{
                display: 'flex',
                columnGap: '16px',
              }}
            >
              <Button color="secondary" onClick={handleCancelClick}>
                CANCEL
              </Button>
              <Button type="submit" variant={'contained'} color={'secondary'}>
                SAVE
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
      <ReactRouterPrompt when={formMethods.formState.isDirty && !submitted}>
        {({ isActive, onConfirm, onCancel }) => (
          <CreatePatientManuallyFormWarningModal
            open={isActive}
            onConfirm={onConfirm}
            onCancel={onCancel}
            title={'Are you sure you want to leave?'}
          />
        )}
      </ReactRouterPrompt>
      <CreatePatientManuallyFormWarningModal
        open={showCancelWarningModal}
        onConfirm={handleCancelWarningModalConfirm}
        onCancel={handleCancelWarningModalCancel}
        message={
          'You have a manual entry in progress, closing this modal may result in unsaved entry changes being lost.'
        }
      />
      <CreatePatientManuallyFormWarningModal
        open={showResetWarningModal}
        onConfirm={handleResetWarningModalConfirm}
        onCancel={handleResetWarningModalCancel}
        message={
          'Resetting this form will clear all fields, are you sure you want to continue?'
        }
      />
      <DuplicatePatientWarning
        open={!!duplicatePatientsResults.length && showDuplicatePatientWarning}
        patientItems={duplicatePatientsResults.map(
          mapToDuplicatePatientWarningResultListItem
        )}
        onContinueClick={() => setShowDuplicatePatientWarning(false)}
      />
      <BackdropLoader open={showLoader} />
      <DevTool control={formMethods.control} placement={'bottom-left'} />
    </FormProvider>
  )
}
