import { CreateTaskServiceResponse } from 'src/features/tasks/infrastructure'

export const createTaskServiceResponseMock: CreateTaskServiceResponse = {
  // id: '777420eb-2ae8-4a83-9477-39cded7ef901',
  patientId: 'fccd8fd5-c51b-44c2-a45e-b113e98af2f5',
  assignedPathwayId: null,
  assignedUserId: '39861246-8196-4e6c-b7c3-1274846b55f0',
  createdAt: '2023-06-21T02:09:31.126Z',
  lastUpdatedAt: '2023-06-21T02:09:31.126Z',
  status: 'new',
  urgent: true,
  dueDate: '2023-06-20T00:00:00.000Z',
  title: 'TEST',
  description: 'test',
  // createdBy: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
  source: null,
  linkedEventId: null,
  isDeleted: false,
  completedAt: null,
  name: '',
  assignedTCMember: '',
  eventId: '',
  // pathwayId: null,
  // comments: [
  //   {
  //     id: 'd3853ebb-6d8b-40b3-ad89-f809be67d6f5',
  //     eventId: '777420eb-2ae8-4a83-9477-39cded7ef901',
  //     comment: null,
  //     createdAt: '2023-06-21T02:09:31.205Z',
  //     author: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
  //   },
  // ],
}
