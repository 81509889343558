import { FieldPath, FieldValues } from 'react-hook-form'
import { yesNo, CustomSelectField } from 'src/features/shared/presentation'
import { filterNoneValueOptions } from 'src/features/notes/adapters'
import { useAuth } from 'src/features/shared/infrastructure'

export const SDMPatientUnderstandsField = <T extends FieldValues>() => {
  const { myCompassUser } = useAuth()
  const name = 'sdmClinicalBelievesPatientUnderstandsCondition' as FieldPath<T>

  return (
    <CustomSelectField
      name={name}
      id="sdm-clinical-believes-patient-understands-condition"
      label={`${myCompassUser?.firstName}, do you feel like this patient understands condition and treatment options?`}
      items={filterNoneValueOptions(yesNo)}
      selectStyle={{ width: '88px' }}
      labelStyle={{ fontWeight: 'bold', pb: 1 }}
    />
  )
}
