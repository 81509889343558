import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Task } from 'src/features/tasks/domain'
import { RootState } from 'src/features/shared/infrastructure'

export type TasksSidebarState = {
  open: boolean
  selectedTab: 'upcoming' | 'today' | 'done'
  openAddTaskFormModal: boolean
  openEditTaskFormModal: boolean
  selectedTaskForEdit: Task | null
  showLoader: boolean
}

const initialState: TasksSidebarState = {
  open: false,
  selectedTab: 'today',
  openAddTaskFormModal: false,
  openEditTaskFormModal: false,
  selectedTaskForEdit: null,
  showLoader: false,
}

const tasksSidebarSlice = createSlice({
  name: 'tasksSidebar',
  initialState,
  reducers: {
    setOpenAction(state, action: PayloadAction<boolean>) {
      state.open = action.payload
    },
    setSelectedTabAction(
      state,
      action: PayloadAction<TasksSidebarState['selectedTab']>
    ) {
      state.selectedTab = action.payload
    },
    setOpenAddTaskFormModalAction(state, action: PayloadAction<boolean>) {
      state.openAddTaskFormModal = action.payload
    },
    setOpenEditTaskFormModalAction(state, action: PayloadAction<boolean>) {
      state.openEditTaskFormModal = action.payload
      if (action.payload === false) state.selectedTaskForEdit = null
    },
    setSelectedTaskForEditAction(state, action: PayloadAction<Task | null>) {
      state.selectedTaskForEdit = action.payload
    },
    setShowTaskLoaderAction(state, action: PayloadAction<boolean>) {
      state.showLoader = action.payload
    },
  },
})

export const {
  setOpenAction,
  setSelectedTabAction,
  setOpenAddTaskFormModalAction,
  setOpenEditTaskFormModalAction,
  setSelectedTaskForEditAction,
  setShowTaskLoaderAction,
} = tasksSidebarSlice.actions

export const tasksSidebarOpenSelector = (state: RootState) =>
  state.tasksSidebar.open

export const tasksSidebarSelectedTabSelector = (state: RootState) =>
  state.tasksSidebar.selectedTab

export const tasksSidebarOpenAddTaskFormModalSelector = (state: RootState) =>
  state.tasksSidebar.openAddTaskFormModal

export const tasksSidebarOpenEditTaskFormModalSelector = (state: RootState) =>
  state.tasksSidebar.openEditTaskFormModal

export const tasksSidebarSelectedTaskForEditSelector = (state: RootState) =>
  state.tasksSidebar.selectedTaskForEdit

export const tasksSidebarShowLoaderSelector = (state: RootState) =>
  state.tasksSidebar.showLoader

export default tasksSidebarSlice.reducer
