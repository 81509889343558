import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

import { usePatientsUseCases } from 'src/features/patients/patients.service-locator'
import {
  GetPatientPainAndFunctionError,
  GetPatientPainAndFunctionReturns,
} from 'src/features/patients/domain'
import { usePatientId } from 'src/features/shared/presentation'

export const GET_PATIENT_PAIN_AND_FUNCTION_QUERY_KEY =
  'getPatientPainAndFunction'

type UseGetPatientPainAndFunctionQueryProps = {
  patientId?: string
  throwGetPatientIdError?: boolean
}

export const useGetPatientPainAndFunctionQuery = ({
  patientId,
  throwGetPatientIdError = true,
}: UseGetPatientPainAndFunctionQueryProps = {}) => {
  const patientsUseCases = usePatientsUseCases()

  const _patientId = usePatientId({ patientId, throwGetPatientIdError })

  const { data, isLoading, isError } = useQuery<
    GetPatientPainAndFunctionReturns | null,
    GetPatientPainAndFunctionError
  >([GET_PATIENT_PAIN_AND_FUNCTION_QUERY_KEY, _patientId], () => {
    return _patientId
      ? patientsUseCases.getPatientPainAndFunction(_patientId)
      : null
  })
  const hasPainHistory = useMemo(
    () => (data ? data.painHistory.length > 0 : false),
    [data]
  )

  const hasFunctionHistory = useMemo(
    () => (data ? data.functionHistory.length > 0 : false),
    [data]
  )

  return {
    patientPainAndFunction: data,
    hasPainHistory,
    hasFunctionHistory,
    getPatientPainAndFunctionIsLoading: isLoading,
    getPatientPainAndFunctionIsError: isError,
  }
}
