import { Amplify } from 'aws-amplify'
import { Quill } from 'react-quill'
import { LicenseInfo } from '@mui/x-license-pro'

import { store } from './features/shared/infrastructure'
import { saveMSKFormInLocalStorage } from './features/shared/infrastructure'
import { worker } from 'src/__mocks__/backend-mock-service-worker'
import { ReactQuillExternalLink, amplifyConfig } from './config'
import * as settings from './config/settings'

export default () => {
  // Mock Service Worker
  if (settings.MOCK_BACKEND) {
    worker.start()
  }

  // Amplify
  Amplify.configure(amplifyConfig)

  // Redux
  store.subscribe(() => {
    if (store.getState().mskForm.mskFormPatientId) {
      saveMSKFormInLocalStorage(store.getState().mskForm)
    }
  })

  // React Quill
  Quill.register(ReactQuillExternalLink)

  // MUI
  LicenseInfo.setLicenseKey(settings.MUIX_LICENSE_KEY)
}
