import { GetPatientPainAndFunctionServiceResponse } from 'src/features/patients/infrastructure'

export const getPatientPainAndFunctionServiceResponseMock: GetPatientPainAndFunctionServiceResponse =
  {
    min: 0,
    max: 10,
    pain: [
      {
        pain: 5,
        createdAt: '2023-06-10T01:14:43.541Z',
      },
      {
        pain: 8,
        createdAt: '2023-06-13T17:31:07.886Z',
      },
    ],
    function: [
      {
        function: 3,
        createdAt: '2023-06-10T01:14:43.541Z',
      },
      {
        function: 7,
        createdAt: '2023-06-13T17:31:07.886Z',
      },
    ],
  }
