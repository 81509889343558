import { ErrorMessage } from '@hookform/error-message'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form'
import { providerGroups } from 'src/features/shared/presentation'

export const ProviderGroupField = <T extends FieldValues>() => {
  const name = 'providerGroup' as FieldPath<T>
  const { register } = useFormContext()

  return (
    <Controller
      name={name}
      {...register}
      render={({ field }) => {
        return (
          <FormControl size={'small'} sx={{ width: '100%' }}>
            <InputLabel id={'provider-group-label'}>Provider Group</InputLabel>
            <Select
              labelId={'provider-group-label'}
              id={'provider-group'}
              label={'Provider Group'}
              value={field.value}
              onChange={(event) => {
                field.onChange(event.target.value)
              }}
            >
              {providerGroups.map((providerGroup) => (
                <MenuItem key={providerGroup.id} value={providerGroup.label}>
                  {providerGroup.label}
                </MenuItem>
              ))}
            </Select>
            <ErrorMessage
              name={name}
              render={({ message }) => (
                <FormHelperText sx={{ m: 0 }} error={true}>
                  {message}
                </FormHelperText>
              )}
            />
          </FormControl>
        )
      }}
    />
  )
}
