import { ErrorMessage } from '@hookform/error-message'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form'
import { procedureTypes } from 'src/features/shared/presentation'

export const ProcedureTypeField = <T extends FieldValues>() => {
  const name = 'procedure' as FieldPath<T>
  const { register } = useFormContext()

  return (
    <Controller
      name={name}
      {...register}
      render={({ field }) => (
        <FormControl size={'small'} sx={{ width: '100%' }}>
          <InputLabel id={'procedure-type-label'}>Procedure Type</InputLabel>
          <Select
            labelId={'procedure-type-label'}
            id={'procedure-type'}
            label={'Procedure Type'}
            value={field.value}
            onChange={(event) => {
              field.onChange(event.target.value)
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                  marginLeft: 75,
                },
              },
            }}
          >
            {procedureTypes.map((procedureType) => (
              <MenuItem key={procedureType.id} value={procedureType.id}>
                {procedureType.label}
              </MenuItem>
            ))}
          </Select>
          <ErrorMessage
            name={name}
            render={({ message }) => (
              <FormHelperText sx={{ m: 0 }} error={true}>
                {message}
              </FormHelperText>
            )}
          />
        </FormControl>
      )}
    />
  )
}
