export type AgreeType =
  | 'Strongly agree'
  | 'Agree'
  | 'Neutral'
  | 'Disagree'
  | 'Strongly disagree'

export type AgreeConstant = {
  id: AgreeType
  label: AgreeType
}

export const agreeType: AgreeConstant[] = [
  { id: 'Strongly agree', label: 'Strongly agree' },
  { id: 'Agree', label: 'Agree' },
  { id: 'Neutral', label: 'Neutral' },
  { id: 'Disagree', label: 'Disagree' },
  { id: 'Strongly disagree', label: 'Strongly disagree' },
]

export type YesNoType = 'Yes' | 'No' | 'N/A'

export type YesNoConstant = {
  id: YesNoType
  label: YesNoType
}

export const yesNo: YesNoConstant[] = [
  { id: 'N/A', label: 'N/A' },
  { id: 'Yes', label: 'Yes' },
  { id: 'No', label: 'No' },
]

export type ScheduledType =
  | YesNoType
  | 'Pending Partner Confirmation'
  | 'Warm Transferred'
  | 'Provided List of Options'

export type ScheduledConstant = {
  id: ScheduledType
  label: ScheduledType
}
export const scheduledTypes: ScheduledConstant[] = [
  { id: 'Yes', label: 'Yes' },
  { id: 'Pending Partner Confirmation', label: 'Pending Partner Confirmation' },
  { id: 'Warm Transferred', label: 'Warm Transferred' },
  { id: 'Provided List of Options', label: 'Provided List of Options' },
  { id: 'No', label: 'No' },
  { id: 'N/A', label: 'N/A' },
]

export type SurgerySiteType = 'Unknown' | 'ASC' | 'HOPD' | 'IP'

export type SurgerySiteConstant = {
  id: SurgerySiteType
  label: SurgerySiteType
}

export const surgerySites: SurgerySiteConstant[] = [
  { id: 'Unknown', label: 'Unknown' },
  { id: 'ASC', label: 'ASC' },
  { id: 'HOPD', label: 'HOPD' },
  { id: 'IP', label: 'IP' },
]
export type DischargePositionType =
  | 'Unknown'
  | 'Home'
  | 'Home with HH'
  | 'SNF'
  | 'IRF'

export type DischargePositionConstant = {
  id: DischargePositionType
  label: DischargePositionType
}

export const dischargePositions: DischargePositionConstant[] = [
  { id: 'Unknown', label: 'Unknown' },
  { id: 'Home', label: 'Home' },
  { id: 'Home with HH', label: 'Home with HH' },
  { id: 'SNF', label: 'SNF' },
  { id: 'IRF', label: 'IRF' },
]
export type ImprovementType =
  | 'Significant Improvement'
  | 'Some Improvement'
  | 'No Improvement'
  | 'Increased Pain'

export type ImprovementConstant = {
  id: ImprovementType
  label: ImprovementType
}

export const injectionImprovements: ImprovementConstant[] = [
  { id: 'Significant Improvement', label: 'Significant Improvement' },
  { id: 'Some Improvement', label: 'Some Improvement' },
  { id: 'No Improvement', label: 'No Improvement' },
  { id: 'Increased Pain', label: 'Increased Pain' },
]
export type InjectionTypeType =
  | 'In-Office Cortisone Injection'
  | 'Hyaluronic Acid Injection'
  | 'ESI - ASC Injection'
  | 'Other'

export type InjectionTypeConstant = {
  id: InjectionTypeType
  label: InjectionTypeType
}

export const injectionTypes: InjectionTypeConstant[] = [
  {
    id: 'In-Office Cortisone Injection',
    label: 'In-Office Cortisone Injection',
  },
  { id: 'Hyaluronic Acid Injection', label: 'Hyaluronic Acid Injection' },
  { id: 'ESI - ASC Injection', label: 'ESI - ASC Injection' },
  { id: 'Other', label: 'Other' },
]

export type PTLocationType =
  | 'Outpatient PT'
  | 'In-home PT'
  | 'Digital PT'
  | 'Acute Rehab'
  | 'SNF'

export type PTLocationConstant = {
  id: PTLocationType
  label: PTLocationType
}

export const ptLocations: PTLocationConstant[] = [
  { id: 'Outpatient PT', label: 'Outpatient PT' },
  { id: 'In-home PT', label: 'In-home PT' },
  { id: 'Digital PT', label: 'Digital PT' },
  { id: 'Acute Rehab', label: 'Acute Rehab' },
  { id: 'SNF', label: 'SNF' },
]
export type VisitOutcomeType =
  | 'None'
  | 'Continued Follow Up Care'
  | 'Finished Care'
  | 'Pathway Change - Injection'
  | 'Pathway Change - PT'
  | 'Pathway Change - Surgery'

export type VisitOutcomeConstant = {
  id: VisitOutcomeType
  label: VisitOutcomeType
}

export const visitOutcomes: VisitOutcomeConstant[] = [
  { id: 'Continued Follow Up Care', label: 'Continued Follow Up Care' },
  { id: 'Finished Care', label: 'Finished Care' },
  { id: 'Pathway Change - Injection', label: 'Pathway Change - Injection' },
  { id: 'Pathway Change - PT', label: 'Pathway Change - PT' },
  { id: 'Pathway Change - Surgery', label: 'Pathway Change - Surgery' },
]

export type LevelScoreType =
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | 'N/A'

export type LevelScoreConstant = {
  id: LevelScoreType
  label: LevelScoreType
}

export const levelScores: LevelScoreConstant[] = [
  { id: '0', label: '0' },
  { id: '1', label: '1' },
  { id: '2', label: '2' },
  { id: '3', label: '3' },
  { id: '4', label: '4' },
  { id: '5', label: '5' },
  { id: '6', label: '6' },
  { id: '7', label: '7' },
  { id: '8', label: '8' },
  { id: '9', label: '9' },
  { id: '10', label: '10' },
]
export type ProviderGroupType =
  | 'Resurgens'
  | 'Benchmark'
  | 'Luna'
  | 'ATI'
  | 'Select PT'
  | 'PT Solutions'
  | 'Emory'
  | 'Decatur Hand and PT'
  | 'Other'

export type ProviderGroupConstant = {
  id: ProviderGroupType
  label: ProviderGroupType
}

export const providerGroups: ProviderGroupConstant[] = [
  { id: 'Resurgens', label: 'Resurgens' },
  { id: 'Benchmark', label: 'Benchmark' },
  { id: 'Luna', label: 'Luna' },
  { id: 'ATI', label: 'ATI' },
  { id: 'Select PT', label: 'Select PT' },
  { id: 'PT Solutions', label: 'PT Solutions' },
  { id: 'Emory', label: 'Emory' },
  { id: 'Decatur Hand and PT', label: 'Decatur Hand and PT' },
  { id: 'Other', label: 'Other' },
]

export type SpokeWithType =
  | 'N/A'
  | 'Patient'
  | 'Caregiver'
  | 'PCP'
  | 'Ortho'
  | 'PT'
  | 'Other'

export type SpokeWithConstant = {
  id: SpokeWithType
  label: SpokeWithType
}

export const spokeWithOptions: SpokeWithConstant[] = [
  { id: 'N/A', label: 'N/A' },
  { id: 'Patient', label: 'Patient' },
  { id: 'Caregiver', label: 'Caregiver' },
  { id: 'PCP', label: 'PCP' },
  { id: 'Ortho', label: 'Ortho' },
  { id: 'PT', label: 'PT' },
  { id: 'Other', label: 'Other' },
]

export type ReferralTypeType = 'Other' | 'Ortho' | 'PT' | 'Both'

export type ReferralTypeConstant = {
  id: ReferralTypeType
  label: ReferralTypeType
}

export const referralTypes: ReferralTypeConstant[] = [
  { id: 'Ortho', label: 'Ortho' },
  { id: 'PT', label: 'PT' },
  { id: 'Both', label: 'Both' },
  { id: 'Other', label: 'Other' },
]

export type MissedReasonType =
  | 'Unknown'
  | 'No Show'
  | 'Cancelled'
  | 'Rescheduled'
  | 'Other'

export type MissedReasonConstant = {
  id: MissedReasonType
  label: MissedReasonType
}

export const missedReasons: MissedReasonConstant[] = [
  { id: 'Unknown', label: 'Unknown' },
  { id: 'No Show', label: 'No Show' },
  { id: 'Cancelled', label: 'Cancelled' },
  { id: 'Rescheduled', label: 'Rescheduled' },
  { id: 'Other', label: 'Other' },
]

export type MissedHomePTReasonType =
  | 'Pain'
  | 'I don’t know how'
  | 'Takes too much time'
  | 'Difficult to perform'
  | 'Just have not started'
  | 'New health concern'
  | 'New Plan of Care'

export type MissedHomePTReasonConstant = {
  id: MissedHomePTReasonType
  label: MissedHomePTReasonType
}

export const missedHomePTReasons: MissedHomePTReasonConstant[] = [
  { id: 'Pain', label: 'Pain' },
  { id: 'I don’t know how', label: 'I don’t know how' },
  { id: 'Takes too much time', label: 'Takes too much time' },
  { id: 'Difficult to perform', label: 'Difficult to perform' },
  { id: 'Just have not started', label: 'Just have not started' },
  { id: 'New health concern', label: 'New health concern' },
  { id: 'New Plan of Care', label: 'New Plan of Care' },
]

export type ProcedureTypeType =
  | 'Spine - Fusion'
  | 'Spine - Injection'
  | 'Spine - Laminectomy / Discectomy / Disc Replacement'
  | 'Knee - UKA'
  | 'Knee - TKA'
  | 'Knee - Arthroscopy'
  | 'Hip - Hip Arthroscopy'
  | 'Hip - THA'
  | 'Hand - Carpel Tunnel Release'
  | 'Hand - Trigger Finger Release'
  | 'Hand - Wrist Arthroscopy'
  | 'Foot - TAA'
  | 'Shoulder - Shoulder Arthroscopy'
  | 'Shoulder - TSA'
  | 'Shoulder - Rotator Cuff Repair'
  | 'Elbow - Elbow Arthroscopy'
  | 'Other'

export type ProcedureTypeConstant = {
  id: ProcedureTypeType
  label: ProcedureTypeType
}

export const procedureTypes: ProcedureTypeConstant[] = [
  { id: 'Spine - Fusion', label: 'Spine - Fusion' },
  { id: 'Spine - Injection', label: 'Spine - Injection' },
  {
    id: 'Spine - Laminectomy / Discectomy / Disc Replacement',
    label: 'Spine - Laminectomy / Discectomy / Disc Replacement',
  },
  { id: 'Knee - UKA', label: 'Knee - UKA' },
  { id: 'Knee - TKA', label: 'Knee - TKA' },
  { id: 'Knee - Arthroscopy', label: 'Knee - Arthroscopy' },
  { id: 'Hip - Hip Arthroscopy', label: 'Hip - Hip Arthroscopy' },
  { id: 'Hip - THA', label: 'Hip - THA' },
  { id: 'Hand - Carpel Tunnel Release', label: 'Hand - Carpel Tunnel Release' },
  {
    id: 'Hand - Trigger Finger Release',
    label: 'Hand - Trigger Finger Release',
  },
  { id: 'Hand - Wrist Arthroscopy', label: 'Hand - Wrist Arthroscopy' },
  { id: 'Foot - TAA', label: 'Foot - TAA' },
  {
    id: 'Shoulder - Shoulder Arthroscopy',
    label: 'Shoulder - Shoulder Arthroscopy',
  },
  { id: 'Shoulder - TSA', label: 'Shoulder - TSA' },
  {
    id: 'Shoulder - Rotator Cuff Repair',
    label: 'Shoulder - Rotator Cuff Repair',
  },
  { id: 'Elbow - Elbow Arthroscopy', label: 'Elbow - Elbow Arthroscopy' },
  { id: 'Other', label: 'Other' },
]

export type AdditionalQuestionsFormValues = {
  surgerySite?: string
  dischargePosition?: string
  providerName?: string
  providerGroup?: string
  providerGroupText?: string
  preferredProvider?: string
  providerGroupLocation?: string
  scheduled?: string
  agreeYouUnderstandCondition?: string
  agreeYouUnderstandTreatmentOptions?: string
  painLevelDecreased?: string
  newSymptoms?: string
  completedAppointment?: string
  reasonForMissingAppointment?: string
  detailsOfMissedAppointment?: string
  appointmentRescheduled?: string
  completingPhysicalTherapyExercises?: string
  reasonForNotCompletingPTExercises?: string
  painDuringPTExercise?: string
  outcomeOfVisit?: string
  outcomeNote?: string
  improvementFromInjection?: string
  typeOfInjection?: string
  typeOfInjectionText?: string
  appointmentDate?: Date | null
  surgeryDate?: Date | null
  isPainControlled?: string
  accessToMedicineToControlPain?: string
  participatingInPT?: string
  physicalTherapyLocation?: string
  additionalQuestionsOrConcerns?: string
  scheduledFollowUp?: string
  questionsAboutSurgery?: string
  procedure?: string
  sdmText?: string
  referringProvider?: string
  sdmPatientUnderstandsCondition?: boolean | string
  sdmClinicalBelievesPatientUnderstandsCondition?: string
  additionalConditionPain?: string | number
  additionalConditionFunction?: string | number
  outsideSource?: boolean
}
