import {
  Box,
  FormControl,
  FormControlProps,
  FormHelperText,
  FormHelperTextProps,
  FormLabel,
  FormLabelProps,
  InputLabel,
  SxProps,
  Theme,
} from '@mui/material'
import { FC } from 'react'

import { WithChildren } from 'src/features/shared/types'

type VanillaFormFieldContainerProps = {
  label?: string
  helperText?: string
  id?: string
  error?: boolean
  labelComponent?: 'formLabel' | 'inputLabel'
  formControlProps?: Omit<FormControlProps, 'sx'>
  formControlSx?: SxProps<Theme>
  formLabelProps?: Omit<FormLabelProps, 'sx'>
  formLabelSx?: SxProps<Theme>
  inputLabelProps?: Omit<FormLabelProps, 'sx'>
  inputLabelSx?: SxProps<Theme>
  formHelperTextProps?: Omit<FormHelperTextProps, 'sx'>
  formHelperTextSx?: SxProps<Theme>
}

export const VanillaFormFieldContainer: FC<
  WithChildren<VanillaFormFieldContainerProps>
> = ({
  id,
  label,
  helperText,
  error,
  children,
  labelComponent = 'formLabel',
  formControlProps = {},
  formControlSx = {},
  formLabelProps = {},
  formLabelSx = {},
  inputLabelProps = {},
  inputLabelSx = {},
  formHelperTextProps = {},
  formHelperTextSx = {},
}) => {
  const formLabelId = id ? `${id}-label` : undefined
  return (
    <FormControl
      error={error}
      {...formControlProps}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '16px',
        ...formControlSx,
      }}
    >
      {label ? (
        labelComponent === 'formLabel' ? (
          <FormLabel
            id={formLabelId}
            {...formLabelProps}
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '25px',
              color: 'common.black',
              ...formLabelSx,
            }}
          >
            {label}
          </FormLabel>
        ) : (
          <InputLabel {...inputLabelProps} sx={{ ...inputLabelSx }}>
            {label}
          </InputLabel>
        )
      ) : null}
      <Box>
        {children}
        {helperText ? (
          <FormHelperText
            error={error}
            {...formHelperTextProps}
            sx={{ marginTop: '4px', ...formHelperTextSx }}
          >
            {helperText}
          </FormHelperText>
        ) : null}
      </Box>
    </FormControl>
  )
}
