import { FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import {
  FieldValues,
  FieldPath,
  Controller,
  useFormContext,
} from 'react-hook-form'

export const CommunicationField = <T extends FieldValues>() => {
  const name = 'communication' as FieldPath<T>
  const { register } = useFormContext()

  return (
    <Controller
      name={name}
      {...register}
      render={({ field }) => (
        <FormControl>
          <RadioGroup row value={field.value} onChange={field.onChange}>
            <FormControlLabel
              value={'inbound'}
              control={<Radio />}
              label={'Inbound'}
            />
            <FormControlLabel
              value={'outbound'}
              control={<Radio />}
              label={'Outbound'}
            />
          </RadioGroup>
        </FormControl>
      )}
    />
  )
}
