import {
  ReferralSourceObject,
  StageObject,
  PayorObject,
} from 'src/features/shared/constants'
import {
  MultiSelectOption,
  TCReactSelectOption,
} from 'src/features/shared/presentation'

type SelectObject = ReferralSourceObject | StageObject | PayorObject

type MapToMultiSelectOption = (constant: SelectObject) => MultiSelectOption

const mapToMultiSelectOption: MapToMultiSelectOption = (constant) => {
  return {
    label: constant.label,
    value: constant.id,
    key: constant.id,
  }
}

export const mapReferralSourceToMultiSelectOption = mapToMultiSelectOption

export const mapStageToMultiSelectOption = mapToMultiSelectOption

export const mapPayorToMultiSelectOption = mapToMultiSelectOption

export function mapToTCReactSelectOption(from: string): TCReactSelectOption
export function mapToTCReactSelectOption(
  from: SelectObject
): TCReactSelectOption
export function mapToTCReactSelectOption(from: any): TCReactSelectOption {
  if (typeof from === 'string') return { label: from, value: from }

  if (typeof from === 'object') return { label: from.label, value: from.id }

  return { label: from.label, value: from.value }
}
