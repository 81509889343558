import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'
import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { DevTool } from '@hookform/devtools'

import {
  FormLayoutWithCancelSave,
  FormControlledNoteField,
  ReferralSourceField,
  StatusField,
  PainLevelField,
  FunctionLevelField,
} from 'src/features/shared/presentation/components/form-fields'

import { getPatientHasStage } from 'src/features/shared/utils'
import { useStatusDefinitions } from 'src/features/shared/presentation'
import {
  NoteFormProps,
  useCreatePatientCustomNote,
} from 'src/features/notes/presentation'
import { mapToCreatePatientCustomNoteArgs } from 'src/features/notes/adapters'
import { useUpdatePatientStatus } from 'src/features/patients/presentation'
import { mapToPatientUpdateStatus } from 'src/features/patients/adapters'
import { useNotesStore } from 'src/features/shared/infrastructure'

export type CreatePatientCustomNoteFormFields = {
  note: string
  status?: string
  referralSource?: string
  painLevel?: string
  functionLevel?: string
  communication: string
}

type CustomNoteFormProps = NoteFormProps

export const CustomNoteForm = ({
  patient,
  setShowLoader,
}: CustomNoteFormProps) => {
  const patientHasStage = getPatientHasStage(patient)
  const { createPatientCustomNoteAsync } = useCreatePatientCustomNote()
  const { updatePatientStatusAsync } = useUpdatePatientStatus()

  const { getStatusInitialValue, getAllStatusDefinitions } =
    useStatusDefinitions(patient)
  const { noteText } = useNotesStore()

  const createCustomNoteFormSchema: yup.Schema<CreatePatientCustomNoteFormFields> =
    yup.object().shape(
      {
        note: yup.string().required('note is required'),
        status: yup
          .string()
          .when('$patientHasStage', ([patientHasStage], schema) =>
            patientHasStage ? schema.required('status is required') : schema
          ),
        referralSource: yup.string(),
        painLevel: yup.string().when('functionLevel', {
          is: (functionLevel: string) => functionLevel,
          then: (schema) => schema.required('Pain is required'),
        }),
        functionLevel: yup.string().when('painLevel', {
          is: (painLevel: string) => painLevel,
          then: (schema) => schema.required('Function is required'),
        }),
        communication: yup.string().required(),
      },
      [['painLevel', 'functionLevel']]
    )

  const formMethods = useForm<CreatePatientCustomNoteFormFields>({
    resolver: yupResolver(createCustomNoteFormSchema),
    context: { patientHasStage },
    defaultValues: {
      note: noteText,
      status: getStatusInitialValue(),
      referralSource: patient.referralSource || '',
      painLevel: '',
      functionLevel: '',
      communication: 'outbound',
    },
  })
  const watchReferralSource = formMethods.watch().referralSource

  const submitHandler: SubmitHandler<
    CreatePatientCustomNoteFormFields
  > = async (data) => {
    setShowLoader?.(true)

    try {
      const createPatientCustomNoteArgs = mapToCreatePatientCustomNoteArgs(
        patient,
        data
      )

      if (
        patient.stageAndStatus &&
        patient.stageAndStatus.stage &&
        data.status
      ) {
        const createPatientUpdateStatusArgs = mapToPatientUpdateStatus(
          patient,
          data.status
        )
        await updatePatientStatusAsync(createPatientUpdateStatusArgs)
        await createPatientCustomNoteAsync(createPatientCustomNoteArgs)

        toast.success('Patient Note successfully created!')
        toast.success('Patient Status successfully updated!')
      } else {
        await createPatientCustomNoteAsync(createPatientCustomNoteArgs)
        toast.success('Patient Note successfully created!')
      }
    } catch (error) {
      toast.error('Failed creating patient note!')
      toast.error('Patient Status update failed!')
    }

    setShowLoader?.(false)
  }

  return (
    <FormLayoutWithCancelSave
      onSubmit={(data) => submitHandler(data)}
      formMethods={formMethods}
    >
      <Grid container rowSpacing={2} pb={4}>
        <Grid item sm={12} pb={2}>
          <FormControlledNoteField />
        </Grid>
        <Grid item container columnSpacing={2} sm={12}>
          <Grid item sm={4}>
            <StatusField
              statusDefinitions={getAllStatusDefinitions(watchReferralSource)}
            />
          </Grid>
          <Grid item container columnSpacing={2} sm={6}>
            <Grid item sm={3}>
              <PainLevelField />
            </Grid>
            <Grid item sm={3.5}>
              <FunctionLevelField />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container columnSpacing={2}>
          <Grid item sm={4.5}>
            <ReferralSourceField />
          </Grid>
        </Grid>
      </Grid>
      <DevTool control={formMethods.control} placement={'top-left'} />
    </FormLayoutWithCancelSave>
  )
}
