import { rest } from 'msw'

import { GetPatientServiceResponse } from 'src/features/patients/infrastructure'

import * as settings from 'src/config/settings'
import {
  getPatientWithLockedPathwayServiceResponseMock,
  getPatientWithoutLockedPathwayServiceResponseMockEmpty,
} from 'src/__mocks__/responses/get-patient-service-response'

const url = `${settings.COMPASS_API_BASE_URL}/patients/:patientId`

export const getPatientServiceResponseHandler =
  rest.get<GetPatientServiceResponse>(url, async (req, res, ctx) => {
    const { patientId } = req.params

    const mock =
      patientId === 'locked'
        ? getPatientWithLockedPathwayServiceResponseMock
        : getPatientWithoutLockedPathwayServiceResponseMockEmpty

    return res(
      ctx.status(200),
      ctx.json({
        ...mock,
        patientId: patientId,
      })
    )
  })
