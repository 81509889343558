import {
  FC,
  PropsWithChildren,
  SyntheticEvent,
  Fragment,
  useState,
  useEffect,
} from 'react'
import {
  Box,
  Stack,
  Tab,
  Tabs,
  Typography,
  IconButton,
  Button,
} from '@mui/material'
import { styled } from '@mui/material/styles'

import AddIcon from '@mui/icons-material/Add'
import RefreshIcon from '@mui/icons-material/Refresh'

import {
  TasksSidebarState,
  useTasksSidebarStore,
} from 'src/features/shared/infrastructure'
import {
  FullSectionLoader,
  RefreshButton,
} from 'src/features/shared/presentation'
import { useGetPatientQuery } from 'src/features/patients/presentation'
import {
  useRefreshTasks,
  TasksSidebarToggle,
  TaskComponent,
  TaskAddFormModal,
  TaskEditFormModal,
  useTodayTasksByUserIdInfiniteQuery,
  useOverdueTasksByUserIdInfiniteQuery,
  useUrgentTasksByUserIdInfiniteQuery,
  useDoneTasksByUserIdInfiniteQuery,
  useUpcomingTasksByUserIdInfiniteQuery,
} from 'src/features/tasks/presentation'
import { getRotateIconSx } from 'src/features/shared/utils'
import { DoneTask, Task } from 'src/features/tasks/domain'
import {
  mapToGetDoneTaskGroupedByDate,
  mapToGetUpcomingTaskGroupedByDate,
} from 'src/features/tasks/adapters'

export type TaskGroupedByDate = Record<string, Task[]>
export type DoneTaskGroupedByDate = Record<string, DoneTask[]>

export const TASKS_SIDEBAR_DESKTOP_WIDTH = 400
export const TASKS_SIDEBAR_HEADER_HEIGHT = '100px'
export const TASKS_SIDEBAR_TABS_HEIGHT = '48px'

const AlertAccordionHeader = styled(Box)(() => ({
  width: '100%',
  padding: '12px 10px 4px 10px',
  backgroundColor: '#D32F2F12',
  color: '#C62828',
}))

const AccordionHeader = styled(Box)(() => ({
  width: '100%',
  padding: '12px 10px 4px 10px',
  backgroundColor: 'rgba(0, 0, 0, 0.08)',
}))

const NoTasksMessage: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography color="rgba(0, 0, 0, 0.38)">{children}</Typography>
    </Box>
  )
}

const TaskSectionContainer: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box
      sx={{
        width: '100%',
        marginTop: '40px',
        height: `calc(100vh - 40px - ${TASKS_SIDEBAR_HEADER_HEIGHT} - ${TASKS_SIDEBAR_TABS_HEIGHT})`,
        overflowY: 'scroll',
      }}
    >
      {children}
    </Box>
  )
}

type LoadMoreTasksButtonProps = {
  hasNextPage: boolean
  isFetchingNextPage: boolean
  text: string
  onClick: () => void
}

const LoadMoreTasksButton: FC<LoadMoreTasksButtonProps> = ({
  hasNextPage,
  isFetchingNextPage,
  text,
  onClick,
}) => {
  return hasNextPage || isFetchingNextPage ? (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Button onClick={onClick} disabled={isFetchingNextPage}>
        {isFetchingNextPage ? (
          <RefreshIcon
            sx={{
              ...getRotateIconSx(true),
              color: 'primary.main',
            }}
          />
        ) : (
          text
        )}
      </Button>
    </Box>
  ) : null
}

export const TasksSidebarTodayTasks: FC = () => {
  const {
    todayTasksData,
    todayTasksIsLoading,
    todayTasksIsFetchingNextPage,
    fetchNextPageTodayTasks,
    hasNextPageTodayTasks,
  } = useTodayTasksByUserIdInfiniteQuery()
  const {
    urgentTasksData,
    urgentTasksIsLoading,
    urgentTasksIsFetchingNextPage,
    fetchNextPageUrgentTasks,
    hasNextPageUrgentTasks,
  } = useUrgentTasksByUserIdInfiniteQuery()
  const {
    overdueTasksData,
    overdueTasksIsLoading,
    overdueTasksIsFetchingNextPage,
    fetchNextPageOverdueTasks,
    hasNextPageOverdueTasks,
  } = useOverdueTasksByUserIdInfiniteQuery()

  const isLoading =
    todayTasksIsLoading || urgentTasksIsLoading || overdueTasksIsLoading

  const hasTodayTasks =
    todayTasksData?.pages.length && todayTasksData.pages[0].tasks.length
  const hasUrgentTasks =
    urgentTasksData?.pages.length && urgentTasksData.pages[0].tasks.length
  const hasOverdueTasks =
    overdueTasksData?.pages.length && overdueTasksData.pages[0].tasks.length

  const thereIsNoTasks = !hasTodayTasks && !hasUrgentTasks && !hasOverdueTasks

  return (
    <TaskSectionContainer>
      {isLoading ? (
        <FullSectionLoader />
      ) : (
        <Box>
          {hasUrgentTasks ? (
            <Box>
              <AlertAccordionHeader>Urgent</AlertAccordionHeader>
              <Stack>
                {urgentTasksData.pages.map((page, i) => (
                  <Fragment key={i}>
                    {page.tasks.map((task) => (
                      <TaskComponent key={task.id} task={task} />
                    ))}
                  </Fragment>
                ))}
              </Stack>
              <LoadMoreTasksButton
                hasNextPage={!!hasNextPageUrgentTasks}
                isFetchingNextPage={urgentTasksIsFetchingNextPage}
                text="Load More Urgent Tasks"
                onClick={() => fetchNextPageUrgentTasks()}
              />
            </Box>
          ) : null}

          {hasOverdueTasks ? (
            <Box>
              <AlertAccordionHeader>Overdue</AlertAccordionHeader>
              <Stack>
                {overdueTasksData.pages.map((page, i) => (
                  <Fragment key={i}>
                    {page.tasks.map((task) => (
                      <TaskComponent key={task.id} task={task} />
                    ))}
                  </Fragment>
                ))}
              </Stack>
              <LoadMoreTasksButton
                hasNextPage={!!hasNextPageOverdueTasks}
                isFetchingNextPage={overdueTasksIsFetchingNextPage}
                text="Load More Overdue Tasks"
                onClick={() => fetchNextPageOverdueTasks()}
              />
            </Box>
          ) : null}

          {hasTodayTasks ? (
            <Box>
              <AccordionHeader>
                {todayTasksData.pages[0].todayDate}
              </AccordionHeader>
              <Stack>
                {todayTasksData.pages.map((page, i) => (
                  <Fragment key={i}>
                    {page.tasks.map((task) => (
                      <TaskComponent key={task.id} task={task} />
                    ))}
                  </Fragment>
                ))}
              </Stack>
              <LoadMoreTasksButton
                hasNextPage={!!hasNextPageTodayTasks}
                isFetchingNextPage={todayTasksIsFetchingNextPage}
                text="Load More Today Tasks"
                onClick={() => fetchNextPageTodayTasks()}
              />
            </Box>
          ) : null}
          {thereIsNoTasks ? (
            <NoTasksMessage>No tasks for today</NoTasksMessage>
          ) : null}
        </Box>
      )}
    </TaskSectionContainer>
  )
}

export const TasksSidebarDoneTasks: FC = () => {
  const {
    doneTasksData,
    doneTasksIsLoading,
    doneTasksIsFetchingNextPage,
    fetchNextPageDoneTasks,
    hasNextPageDoneTasks,
  } = useDoneTasksByUserIdInfiniteQuery()
  const [groupedTasks, setGroupedTasks] = useState<DoneTaskGroupedByDate>({})

  const hasDoneTasks = Object.keys(groupedTasks).length

  useEffect(() => {
    if (doneTasksData) {
      const groupedTasks = mapToGetDoneTaskGroupedByDate(doneTasksData)
      setGroupedTasks(groupedTasks)
    }
  }, [doneTasksData])
  return (
    <TaskSectionContainer>
      {doneTasksIsLoading ? (
        <FullSectionLoader />
      ) : (
        <Box>
          {hasDoneTasks ? (
            Object.keys(groupedTasks).map((completedDate) => (
              <Box key={completedDate}>
                <AccordionHeader>{completedDate}</AccordionHeader>
                <Stack>
                  {groupedTasks[completedDate].map((task) => (
                    <TaskComponent key={task.id} task={task} edit={false} />
                  ))}
                </Stack>
              </Box>
            ))
          ) : (
            <NoTasksMessage>No tasks done</NoTasksMessage>
          )}
          <LoadMoreTasksButton
            hasNextPage={!!hasNextPageDoneTasks}
            isFetchingNextPage={doneTasksIsFetchingNextPage}
            text="Load More Done Tasks"
            onClick={() => fetchNextPageDoneTasks()}
          />
        </Box>
      )}
    </TaskSectionContainer>
  )
}

export const TasksSidebarUpcomingTasks: FC = () => {
  const {
    upcomingTasksData,
    upcomingTasksIsLoading,
    upcomingTasksIsFetchingNextPage,
    fetchNextPageUpcomingTasks,
    hasNextPageUpcomingTasks,
  } = useUpcomingTasksByUserIdInfiniteQuery()
  const [groupedTasks, setGroupedTasks] = useState<TaskGroupedByDate>({})

  const hasUpcomingTasks = Object.keys(groupedTasks).length

  useEffect(() => {
    if (upcomingTasksData) {
      const groupedTasks = mapToGetUpcomingTaskGroupedByDate(upcomingTasksData)
      setGroupedTasks(groupedTasks)
    }
  }, [upcomingTasksData])
  return (
    <TaskSectionContainer>
      {upcomingTasksIsLoading ? (
        <FullSectionLoader />
      ) : (
        <Box>
          {hasUpcomingTasks ? (
            Object.keys(groupedTasks).map((completedDate) => (
              <Box key={completedDate}>
                <AccordionHeader>{completedDate}</AccordionHeader>
                <Stack>
                  {groupedTasks[completedDate].map((task) => (
                    <TaskComponent key={task.id} task={task} edit={false} />
                  ))}
                </Stack>
              </Box>
            ))
          ) : (
            <NoTasksMessage>No upcoming tasks</NoTasksMessage>
          )}
          <LoadMoreTasksButton
            hasNextPage={!!hasNextPageUpcomingTasks}
            isFetchingNextPage={upcomingTasksIsFetchingNextPage}
            text="Load More Upcoming Tasks"
            onClick={() => fetchNextPageUpcomingTasks()}
          />
        </Box>
      )}
    </TaskSectionContainer>
  )
}

export const TasksSidebar: FC = () => {
  const { patient } = useGetPatientQuery({ throwGetPatientIdError: false })
  const { open, selectedTab, setSelectedTab, setOpenAddTaskFormModal } =
    useTasksSidebarStore()
  const { refreshTasks, isRefreshingTasks } = useRefreshTasks()

  const handleRefreshTasksClick = () => {
    if (!isRefreshingTasks) {
      refreshTasks()
    }
  }

  const handleChange = (
    event: SyntheticEvent,
    newSelectedTab: TasksSidebarState['selectedTab']
  ) => {
    setSelectedTab(newSelectedTab)
  }

  const handleAddTaskClick = () => {
    setOpenAddTaskFormModal(true)
  }

  return (
    <Box
      sx={{
        backgroundColor: 'common.white',
        border: '1px solid #ccc',
        height: '100vh',
        position: 'fixed',
        right: 0,
        top: 0,
        transform: {
          xs: open
            ? 'translateX(0)'
            : `translateX(calc(${TASKS_SIDEBAR_DESKTOP_WIDTH}px - 11px))`,
        },
        transitionDuration: '0.5s',
        transitionProperty: 'transform',
        width: { xs: TASKS_SIDEBAR_DESKTOP_WIDTH },
        zIndex: 1,
      }}
    >
      <TasksSidebarToggle />
      <Box
        sx={{
          width: '100%',
          height: '100px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '40px',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            component={'h3'}
            sx={{ fontSize: '21px', textAlign: 'center' }}
          >
            Tasks
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            {patient ? (
              <IconButton onClick={handleAddTaskClick}>
                <AddIcon />
              </IconButton>
            ) : null}

            <Box sx={{ position: 'relative' }}>
              <RefreshButton
                onClick={handleRefreshTasksClick}
                isRefreshing={isRefreshingTasks}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={{ width: '100%' }}>
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          centered
          sx={{ height: TASKS_SIDEBAR_TABS_HEIGHT }}
        >
          <Tab label="UPCOMING" value={'upcoming'} />
          <Tab label="TODAY" value={'today'} />
          <Tab label="DONE" value={'done'} />
        </Tabs>
        {selectedTab === 'upcoming' ? <TasksSidebarUpcomingTasks /> : null}
        {selectedTab === 'today' ? <TasksSidebarTodayTasks /> : null}
        {selectedTab === 'done' ? <TasksSidebarDoneTasks /> : null}
      </Box>
      <TaskEditFormModal />
      {patient ? <TaskAddFormModal /> : null}
    </Box>
  )
}
