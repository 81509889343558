import {
  UseMutateAsyncFunction,
  UseMutateFunction,
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import {
  CreatePatientReferralUploadNoteReturns,
  CreatePatientReferralUploadNoteArgs,
  CreatePatientReferralUploadNoteError,
} from 'src/features/notes/domain'
import {
  GET_PATIENT_QUERY_KEY,
  GET_PATIENT_PAIN_AND_FUNCTION_QUERY_KEY,
} from 'src/features/patients/presentation'
import { GET_PATIENT_NOTES_QUERY_KEY } from 'src/features/notes/presentation'
import { useNotesUseCases } from 'src/features/notes/notes.service-locator'

type UseCreatePatientReferralUploadNoteProps = Omit<
  UseMutationOptions<
    CreatePatientReferralUploadNoteReturns,
    CreatePatientReferralUploadNoteError,
    CreatePatientReferralUploadNoteArgs,
    unknown
  >,
  'mutationFn'
>

type UseCreatePatientReferralUploadNote = (
  props?: UseCreatePatientReferralUploadNoteProps
) => {
  createPatientReferralUploadNote: UseMutateFunction<
    CreatePatientReferralUploadNoteReturns,
    CreatePatientReferralUploadNoteError,
    CreatePatientReferralUploadNoteArgs,
    unknown
  >
  createPatientReferralUploadNoteAsync: UseMutateAsyncFunction<
    CreatePatientReferralUploadNoteReturns,
    CreatePatientReferralUploadNoteError,
    CreatePatientReferralUploadNoteArgs,
    unknown
  >
  createPatientReferralUploadNoteIsLoading: boolean
  createPatientReferralUploadNoteIsError: boolean
  createPatientReferralUploadNoteStatus: string
}

export const useCreatePatientReferralUploadNote: UseCreatePatientReferralUploadNote =
  (props = {}) => {
    const queryClient = useQueryClient()
    const notesUseCases = useNotesUseCases()

    const { onSuccess } = props

    const { mutate, mutateAsync, isLoading, isError, status } = useMutation<
      CreatePatientReferralUploadNoteReturns,
      CreatePatientReferralUploadNoteError,
      CreatePatientReferralUploadNoteArgs,
      unknown
    >({
      mutationFn: (args: CreatePatientReferralUploadNoteArgs) => {
        return notesUseCases.createPatientReferralUploadNote(args)
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({
          queryKey: [GET_PATIENT_QUERY_KEY, variables.patientId],
        })
        queryClient.invalidateQueries({
          queryKey: [GET_PATIENT_NOTES_QUERY_KEY, variables.patientId],
        })
        queryClient.invalidateQueries({
          queryKey: [
            GET_PATIENT_PAIN_AND_FUNCTION_QUERY_KEY,
            variables.patientId,
          ],
        })
        onSuccess?.(data, variables, context)
      },
    })
    return {
      createPatientReferralUploadNote: mutate,
      createPatientReferralUploadNoteAsync: mutateAsync,
      createPatientReferralUploadNoteIsLoading: isLoading,
      createPatientReferralUploadNoteIsError: isError,
      createPatientReferralUploadNoteStatus: status,
    }
  }
