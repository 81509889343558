import { differenceInMinutes, isValid as isValidDate } from 'date-fns'

import {
  CreatePatientArgs,
  CreatePatientError,
  CreatePatientManuallyArgs,
  CreatePatientManuallyError,
  CreatePatientManuallyReturns,
  CreatePatientReturns,
  SearchDuplicatePatientParams,
  SearchDuplicatePatientsError,
  SearchDuplicatePatientsReturns,
  FunctionHistoryItem,
  GetPatientError,
  GetPatientPainAndFunctionError,
  GetPatientPainAndFunctionReturns,
  GetPatientStatusDefinitionsError,
  GetPatientStatusDefinitionsReturns,
  PainHistoryItem,
  Patient,
  PatientAddress,
  PatientContact,
  PatientView,
  SearchPatientParams,
  SearchPatientsError,
  SearchPatientsReturns,
  UpdatePatientArgs,
  UpdatePatientError,
  UpdatePatientPayload,
  UpdatePatientReturns,
  UpdatePatientStatusArgs,
  UpdatePatientStatusError,
  UpdatePatientStatusReturns,
  SearchDuplicatePatientResult,
} from 'src/features/patients/domain'
import { EligiblePatient } from 'src/features/eligibility/domain'
import {
  CreatePatientManuallyServiceErrorResponse,
  CreatePatientManuallyServicePayload,
  CreatePatientManuallyServiceResponse,
  CreatePatientServiceErrorResponse,
  CreatePatientServicePayload,
  CreatePatientServiceResponse,
  GetPatientPainAndFunctionServiceErrorResponse,
  GetPatientPainAndFunctionServiceResponse,
  GetPatientServiceErrorResponse,
  GetPatientStatusDefinitionsServiceErrorResponse,
  GetPatientStatusDefinitionsServiceResponse,
  SFunctionHistoryItem,
  SPainHistoryItem,
  SPatient,
  SPatientAddress,
  SPatientContact,
  SPatientView,
  SearchPatientsServiceErrorResponse,
  SearchPatientsServiceParams,
  SearchPatientsServiceResponse,
  UpdatePatientServiceArgs,
  UpdatePatientServiceErrorResponse,
  UpdatePatientServicePayload,
  UpdatePatientServiceResponse,
  UpdatePatientStatusServiceArgs,
  UpdatePatientStatusServiceErrorResponse,
  UpdatePatientStatusServiceResponse,
  SSearchDuplicatePatientsServiceParams,
  SSearchDuplicatePatientsServiceErrorResponse,
  SSearchDuplicatePatientsServiceResponse,
} from 'src/features/patients/infrastructure'
import {
  PatientHeaderData,
  PatientHeaderEditFormFields,
} from 'src/features/patients/presentation'
import {
  formatDate,
  cleanPhoneFieldValue,
  getPatientMaskedPhones,
  getPatientCurrentContacts,
  getFormLanguage,
  getPayorFormValue,
  phoneRegex,
} from 'src/features/shared/utils'
import {
  CreatePatientManuallyFormFields,
  DuplicatePatientWarningResultListItem,
} from 'src/features/queues/presentation'
import { MAIN_LANGUAGES, PAYORS } from 'src/features/shared/constants'

// --------------
// GET PATIENT /
// -------------

type MapToPatientView = (sPatient: SPatientView) => PatientView

export const mapToPatientView: MapToPatientView = (sPatientView) => {
  const viewedBy = {
    name: sPatientView.name,
    id: sPatientView.viewedBy,
    email: sPatientView.email,
  }

  return {
    ...sPatientView,
    viewedBy,
  }
}

type GetPatientViewersHistory = (
  sPatient: SPatient
) => Patient['patientViewersHistory']

const getPatientViewersHistory: GetPatientViewersHistory = (sPatient) => {
  if (sPatient.patientViewersHistory) {
    const patientViewersHistory =
      sPatient.patientViewersHistory.map(mapToPatientView)

    // order by viewedAt descending
    return patientViewersHistory.sort((a, b) => {
      return new Date(b.viewedAt).getTime() - new Date(a.viewedAt).getTime()
    })
  }
  return null
}

type GetPatientRecentViewersHistory = (
  patientViewersHistory: Patient['patientViewersHistory']
) => Patient['patientRecentViewersHistory']

const getPatientRecentViewersHistory: GetPatientRecentViewersHistory = (
  patientViewersHistory
) => {
  if (patientViewersHistory) {
    // filter by viewedAt within the last 30 minutes
    return patientViewersHistory.filter((view) => {
      const thirtyMinutesAgo = new Date()
      thirtyMinutesAgo.setMinutes(thirtyMinutesAgo.getMinutes() - 30)
      return differenceInMinutes(new Date(view.viewedAt), thirtyMinutesAgo) < 30
    })
  }
  return null
}

type GetPatientIsBusy = (
  patientViewersHistory: Patient['patientViewersHistory']
) => Patient['isBusy']

const getPatientIsBusy: GetPatientIsBusy = (patientViewersHistory) => {
  if (patientViewersHistory) {
    const thirtyMinutesAgo = new Date()
    thirtyMinutesAgo.setMinutes(thirtyMinutesAgo.getMinutes() - 30)
    for (const view of patientViewersHistory) {
      if (differenceInMinutes(new Date(view.viewedAt), thirtyMinutesAgo) < 30) {
        return true
      }
    }
  }
  return false
}

type MapToPatient = (sPatient: SPatient) => Patient

export const mapToPatient: MapToPatient = (sPatient) => {
  const patientViewersHistory = getPatientViewersHistory(sPatient)
  const patientRecentViewersHistory = getPatientRecentViewersHistory(
    patientViewersHistory
  )

  return {
    ...sPatient,
    patientViewersHistory: patientViewersHistory,
    patientRecentViewersHistory: patientRecentViewersHistory,
    isBusy: getPatientIsBusy(patientViewersHistory),
    dob: formatDate(sPatient.dob, true),
  }
}

type MapToGetPatientReturns = (response: SPatient) => Patient

export const mapToGetPatientReturns: MapToGetPatientReturns = (sPatient) =>
  mapToPatient(sPatient)

type MapToGetPatientError = (
  error: GetPatientServiceErrorResponse
) => GetPatientError

export const mapToGetPatientError: MapToGetPatientError = (error) => {
  return {
    message: error.message,
  }
}

// -----------------
// SEARCH PATIENTS /
// ----------------

type MapToSearchPatientsServiceParams = (
  params: SearchPatientParams
) => SearchPatientsServiceParams

export const mapToSearchPatientsServiceParams: MapToSearchPatientsServiceParams =
  (params) => ({ type: params.type, q: params.query })

type MapToSearchPatientsReturns = (
  response: SearchPatientsServiceResponse
) => SearchPatientsReturns

export const mapToSearchPatientsReturns: MapToSearchPatientsReturns = (
  response
) => ({ ...response })

type MapToSearchPatientsError = (
  error: SearchPatientsServiceErrorResponse
) => SearchPatientsError

export const mapToSearchPatientsError: MapToSearchPatientsError = (error) => ({
  ...error,
})

// ---------------------------
// SEARCH DUPLICATE PATIENTS /
// --------------------------

type MapToSearchDuplicatePatientsServiceParams = (
  params: SearchDuplicatePatientParams
) => SSearchDuplicatePatientsServiceParams

export const mapToSearchDuplicatePatientsServiceParams: MapToSearchDuplicatePatientsServiceParams =
  (params) => ({
    phone: params.phone,
    firstName: params.firstName,
    lastName: params.lastName,
    dob: params.dob,
  })

type MapToSearchDuplicatePatientsReturns = (
  response: SSearchDuplicatePatientsServiceResponse
) => SearchDuplicatePatientsReturns

export const mapToSearchDuplicatePatientsReturns: MapToSearchDuplicatePatientsReturns =
  (response) => [...response]

type MapToSearchDuplicatePatientsError = (
  error: SSearchDuplicatePatientsServiceErrorResponse
) => SearchDuplicatePatientsError

export const mapToSearchDuplicatePatientsError: MapToSearchDuplicatePatientsError =
  (error) => ({
    ...error,
  })

// ----------------
// UPDATE PATIENT /
// ---------------

type MapToSPatientContact = (contact: PatientContact) => SPatientContact

export const mapToSPatientContact: MapToSPatientContact = (contact) => ({
  ...contact,
})

type MapToSPatientAddress = (address: PatientAddress) => SPatientAddress

export const mapToSPatientAddress: MapToSPatientAddress = ({
  id,
  ...address
}) => {
  return {
    ...address,
    addressId: id,
  }
}

type MapToUpdatePatientServicePayload = (
  payload: UpdatePatientPayload
) => UpdatePatientServicePayload

export const mapToUpdatePatientServicePayload: MapToUpdatePatientServicePayload =
  (payload) => {
    return {
      ...payload,
      address: payload.address
        ? mapToSPatientAddress(payload.address)
        : undefined,
      contacts: payload.contacts
        ? payload.contacts.map(mapToSPatientContact)
        : undefined,
    }
  }

type MapToUpdatePatientServiceArgs = (
  args: UpdatePatientArgs
) => UpdatePatientServiceArgs

export const mapToUpdatePatientServiceArgs: MapToUpdatePatientServiceArgs = (
  args
) => {
  return {
    patientId: args.patientId,
    payload: mapToUpdatePatientServicePayload(args.payload),
  }
}

type MapToUpdatePatientReturns = (
  response: UpdatePatientServiceResponse
) => UpdatePatientReturns

export const mapToUpdatePatientReturns: MapToUpdatePatientReturns = (
  response
) => ({ ...response })

type MapToUpdatePatientError = (
  response: UpdatePatientServiceErrorResponse
) => UpdatePatientError

export const mapToUpdatePatientError: MapToUpdatePatientError = (error) => ({
  ...error,
})

// -----------------------
// UPDATE PATIENT STATUS /
// ----------------------

type MapToUpdatePatientStatusServiceArgs = (
  args: UpdatePatientStatusArgs
) => UpdatePatientStatusServiceArgs

export const mapToUpdatePatientStatusServiceArgs: MapToUpdatePatientStatusServiceArgs =
  (args) => {
    return { ...args }
  }

type MapToUpdatePatientStatusReturns = (
  response: UpdatePatientStatusServiceResponse
) => UpdatePatientStatusReturns

export const mapToUpdatePatientStatusReturns: MapToUpdatePatientStatusReturns =
  (response) => ({ ...response })

type MapToUpdatePatientStatusError = (
  response: UpdatePatientStatusServiceErrorResponse
) => UpdatePatientStatusError

export const mapToUpdatePatientStatusError: MapToUpdatePatientStatusError = (
  error
) => ({ ...error })

type MapToUpdatePatientPayload = (
  patient: Patient,
  formValues: PatientHeaderEditFormFields
) => UpdatePatientPayload

export const mapToUpdatePatientPayload: MapToUpdatePatientPayload = (
  patient,
  formValues
) => {
  const updatePatientPayload: UpdatePatientPayload = {}

  if (formValues.firstName)
    updatePatientPayload.firstName = formValues.firstName

  if (formValues.middleName)
    updatePatientPayload.middleName = formValues.middleName

  if (formValues.lastName) updatePatientPayload.lastName = formValues.lastName

  if (formValues.dob) updatePatientPayload.dob = formatDate(formValues.dob)

  if (formValues.referralSource)
    updatePatientPayload.referralSource = formValues.referralSource

  const address: UpdatePatientPayload['address'] = {}

  if (formValues.addressLine1) address.line1 = formValues.addressLine1

  if (formValues.addressLine2) address.line2 = formValues.addressLine2

  if (formValues.city) address.city = formValues.city

  if (formValues.state) address.state = formValues.state

  if (formValues.zip) address.zip = formValues.zip

  if (patient.addressId) address.id = patient.addressId

  if (Object.keys(address).length > 0) {
    updatePatientPayload.address = address
  }

  const currentContacts = getPatientCurrentContacts(patient)

  const contacts: UpdatePatientPayload['contacts'] = []

  if (formValues.phone1) {
    const phone1: PatientContact = {
      patientId: patient.patientId,
      phone: cleanPhoneFieldValue(formValues.phone1),
    }
    if (currentContacts.phone1?.id) {
      phone1.id = currentContacts.phone1.id
    }
    contacts.push(phone1)
  }

  if (formValues.phone2) {
    const phone2: PatientContact = {
      patientId: patient.patientId,
      phone: cleanPhoneFieldValue(formValues.phone2),
    }
    if (currentContacts.phone2?.id) {
      phone2.id = currentContacts.phone2.id
    }
    contacts.push(phone2)
  }

  if (formValues.email) {
    const email: PatientContact = {
      patientId: patient.patientId,
      email: formValues.email,
    }
    if (currentContacts.email?.id) {
      email.id = currentContacts.email.id
    }
    contacts.push(email)
  }

  if (contacts) updatePatientPayload.contacts = contacts

  updatePatientPayload.language = getFormLanguage(
    formValues.language,
    formValues.otherLanguage
  )

  updatePatientPayload.medicalProfile = {}
  if (patient.medicalProfile) {
    updatePatientPayload.medicalProfile.id = patient.medicalProfile.id
    updatePatientPayload.medicalProfile.payor = patient.medicalProfile.payor
  }
  updatePatientPayload.medicalProfile.primaryCarePhysician =
    formValues.primaryCarePhysician || ''
  updatePatientPayload.medicalProfile.addressLine1 =
    formValues.pcpAddressLine1 || ''
  updatePatientPayload.medicalProfile.addressLine2 =
    formValues.pcpAddressLine2 || ''
  updatePatientPayload.medicalProfile.city = formValues.pcpCity || ''
  updatePatientPayload.medicalProfile.state = formValues.pcpState || ''
  updatePatientPayload.medicalProfile.zip = formValues.pcpZip || ''
  updatePatientPayload.medicalProfile.fax = formValues.pcpFax
    ? cleanPhoneFieldValue(formValues.pcpFax)
    : ''
  updatePatientPayload.medicalProfile.groupName = formValues.pcpGroupName || ''
  updatePatientPayload.medicalProfile.phone = formValues.pcpPhone
    ? cleanPhoneFieldValue(formValues.pcpPhone)
    : ''

  if (formValues.payor) {
    updatePatientPayload.medicalProfile.payor = getPayorFormValue(
      formValues.payor,
      formValues.otherPayor
    )
  }

  return updatePatientPayload
}

// ---------------
// CREATE PATIENT /
// ---------------

type MapToCreatePatientReturns = (
  response: CreatePatientServiceResponse
) => CreatePatientReturns

export const mapToCreatePatientReturns: MapToCreatePatientReturns = (
  response
) => {
  return {
    ...response,
  }
}

type MapToCreatePatientError = (
  error: CreatePatientServiceErrorResponse
) => CreatePatientError

export const mapToCreatePatientError: MapToCreatePatientError = (error) => {
  return {
    message: error.message,
  }
}

type MapArgsToCreatePatientServicePayload = (
  args: CreatePatientArgs
) => CreatePatientServicePayload

export const mapArgsToCreatePatientServicePayload: MapArgsToCreatePatientServicePayload =
  (args) => {
    return {
      ...args,
    }
  }

type MapPatientToCreatePatientArgs = (
  patient: EligiblePatient
) => CreatePatientArgs

export const mapPatientToCreatePatientArgs: MapPatientToCreatePatientArgs = (
  patient
) => {
  const contacts: CreatePatientArgs['contacts'] = []

  if (patient?.primaryPhone) {
    contacts.push({
      phone: patient.primaryPhone,
    })
  }

  if (patient?.secondaryPhone) {
    contacts.push({
      phone: patient.secondaryPhone,
    })
  }

  if (patient?.email) {
    contacts.push({
      email: patient.email,
    })
  }

  const address: CreatePatientArgs['address'] = {}

  if (patient?.addressLine1) {
    address.line1 = patient.addressLine1
  }

  if (patient?.addressLine2) {
    address.line2 = patient.addressLine2
  }

  if (patient?.city) {
    address.city = patient.city
  }

  if (patient?.state) {
    address.state = patient.state
  }

  if (patient?.zip) {
    address.zip = patient.zip
  }

  const createPatientArgs: CreatePatientArgs = {
    firstName: patient?.firstName || '',
    lastName: patient?.lastName || '',
    mbi: patient?.mbi || '',
    doNotCall: patient?.doNotCall || false,
    dob: patient?.dob || '',
  }

  if (patient?.middleName) {
    createPatientArgs.middleName = patient.middleName
  }

  if (Object.keys(address).length) {
    createPatientArgs.address = address
  }

  if (contacts.length) {
    createPatientArgs.contacts = contacts
  }

  return createPatientArgs
}

// --------------------------
// CREATE PATIENT MANUALLY /
// ------------------------

type MapToCreatePatientManuallyReturns = (
  response: CreatePatientManuallyServiceResponse
) => CreatePatientManuallyReturns

export const mapToCreatePatientManuallyReturns: MapToCreatePatientManuallyReturns =
  (response) => {
    return {
      ...response,
    }
  }

type MapToCreatePatientManuallyError = (
  error: CreatePatientManuallyServiceErrorResponse
) => CreatePatientManuallyError

export const mapToCreatePatientManuallyError: MapToCreatePatientManuallyError =
  (error) => {
    return {
      message: error.message,
    }
  }

type MapArgsToCreatePatientManuallyServicePayload = (
  args: CreatePatientManuallyArgs
) => CreatePatientManuallyServicePayload

export const mapArgsToCreatePatientManuallyServicePayload: MapArgsToCreatePatientManuallyServicePayload =
  (args) => {
    return {
      ...args,
    }
  }

type MapToCreatePatientManuallyArgs = (
  formValues: CreatePatientManuallyFormFields
) => CreatePatientManuallyArgs

export const mapToCreatePatientManuallyArgs: MapToCreatePatientManuallyArgs = (
  formValues
) => {
  const contacts: CreatePatientManuallyArgs['contacts'] = [
    {
      phone: formValues.phone1,
    },
  ]

  if (formValues.email) {
    contacts[0].email = formValues.email
  }

  const args: CreatePatientManuallyArgs = {
    firstName: formValues.firstName,
    lastName: formValues.lastName,
    dob: formatDate(formValues.dob),
    contacts,
    address: {
      line1: formValues.addressLine1,
      city: formValues.city,
      state: formValues.state,
      zip: formValues.zip,
    },
    referralSource: formValues.referralSource,
    dualCoverage: formValues.dnsp,
  }

  if (formValues.middleName) {
    args.middleName = formValues.middleName
  }

  if (formValues.phone2) {
    contacts.push({ phone: formValues.phone2 })
  }

  if (formValues.addressLine2 && args.address) {
    args.address.line2 = formValues.addressLine2
  }

  args.language = getFormLanguage(formValues.language, formValues.otherLanguage)

  const medicalProfile: CreatePatientManuallyArgs['medicalProfile'] = {}

  if (formValues.payor) {
    medicalProfile.payor = getPayorFormValue(
      formValues.payor,
      formValues.otherPayor
    )
  }

  if (formValues.planId) {
    medicalProfile.planId = formValues.planId
  }

  if (formValues.primaryCarePhysician) {
    medicalProfile.primaryCarePhysician = formValues.primaryCarePhysician
  }

  if (formValues.pcpGroupName) {
    medicalProfile.groupName = formValues.pcpGroupName
  }

  if (formValues.pcpPhone) {
    medicalProfile.phone = formValues.pcpPhone
  }

  if (formValues.pcpFax) {
    medicalProfile.fax = formValues.pcpFax
  }

  if (formValues.pcpAddressLine1) {
    medicalProfile.addressLine1 = formValues.pcpAddressLine1
  }

  if (formValues.pcpAddressLine2) {
    medicalProfile.addressLine2 = formValues.pcpAddressLine2
  }

  if (formValues.pcpCity) {
    medicalProfile.city = formValues.pcpCity
  }

  if (formValues.pcpState) {
    medicalProfile.state = formValues.pcpState
  }

  if (formValues.pcpZip) {
    medicalProfile.zip = formValues.pcpZip
  }

  if (Object.keys(medicalProfile).length) {
    args.medicalProfile = medicalProfile
  }

  return args
}

// --------------------------------
// GET PATIENT STATUS DEFINITIONS /
// -------------------------------

type MapToGetPatientStatusDefinitionsReturns = (
  response: GetPatientStatusDefinitionsServiceResponse
) => GetPatientStatusDefinitionsReturns

export const mapToGetPatientStatusDefinitionsReturns: MapToGetPatientStatusDefinitionsReturns =
  (response) => {
    return response
  }

type MapToGetPatientStatusDefinitionsError = (
  error: GetPatientStatusDefinitionsServiceErrorResponse
) => GetPatientStatusDefinitionsError

export const mapToGetPatientStatusDefinitionsError: MapToGetPatientStatusDefinitionsError =
  (error) => {
    return {
      message: error.message,
    }
  }

// ---------------------------------
// GET PATIENT PAINT AND FUNCTIONS /
// --------------------------------

type MapToPainHistoryItem = (
  sPainHistoryItem: SPainHistoryItem
) => PainHistoryItem

export const mapToPainHistoryItem: MapToPainHistoryItem = (
  sPainHistoryItem
) => {
  const createdAt = new Date(sPainHistoryItem.createdAt)
  return {
    painLevel: sPainHistoryItem.pain,
    createdAt: createdAt,
    date: formatDate(createdAt),
  }
}

type MapToFunctionHistoryItem = (
  sFunctionHistoryItem: SFunctionHistoryItem
) => FunctionHistoryItem

export const mapToFunctionHistoryItem: MapToFunctionHistoryItem = (
  sFunctionHistoryItem
) => {
  const createdAt = new Date(sFunctionHistoryItem.createdAt)
  return {
    functionLevel: sFunctionHistoryItem.function,
    createdAt: createdAt,
    date: formatDate(createdAt),
  }
}

type MapToGetPatientPainAndFunctionReturns = (
  response: GetPatientPainAndFunctionServiceResponse
) => GetPatientPainAndFunctionReturns

export const mapToGetPatientPainAndFunctionReturns: MapToGetPatientPainAndFunctionReturns =
  (response) => {
    const painHistory = response.pain.map(mapToPainHistoryItem)

    const functionHistory = response.function.map(mapToFunctionHistoryItem)

    // sort by createdAt ascending
    painHistory.sort((a, b) => {
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    })
    functionHistory.sort((a, b) => {
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    })

    return {
      painHistory: painHistory,
      functionHistory: functionHistory,
    }
  }

type MapToGetPatientPainAndFunctionError = (
  error: GetPatientPainAndFunctionServiceErrorResponse
) => GetPatientPainAndFunctionError

export const mapToGetPatientPainAndFunctionError: MapToGetPatientPainAndFunctionError =
  (error) => {
    return {
      message: error.message,
    }
  }

// ----------------------
// PRESENTATION MAPPERS /
// --------------------

type MapToPatientHeaderData = (patient: Patient) => PatientHeaderData

export const mapToPatientHeaderData: MapToPatientHeaderData = (patient) => {
  const nameArray: string[] = []
  const validNameAttrs: (keyof Patient)[] = [
    'firstName',
    'middleName',
    'lastName',
  ]
  validNameAttrs.forEach((key: keyof Patient) => {
    if (patient[key] && typeof patient[key] === 'string')
      nameArray.push(patient[key] as string)
  })

  const { phone1, phone2 } = getPatientMaskedPhones(patient.contacts)

  const getStageAndStatus = () => {
    let result = ''
    if (patient.stageAndStatus?.stage) {
      result += patient.stageAndStatus.stage
    }
    if (patient.stageAndStatus?.status) {
      result += ` | ${patient.stageAndStatus.status}`
    }
    return result
  }

  const notEnglishSpeaker = patient.language
    ? patient.language !== 'English'
    : false

  const hasPCPInfo =
    !!patient.medicalProfile?.primaryCarePhysician ||
    !!patient.medicalProfile?.groupName ||
    !!patient.medicalProfile?.phone ||
    !!patient.medicalProfile?.fax

  const hasPayorInfo =
    !!patient.medicalProfile?.payor || !!patient.medicalProfile?.planId

  return {
    ...patient,
    hasPhone: !!phone1 || !!phone2,
    referralSource: patient.referralSource || undefined,
    id: patient.patientId,
    name: nameArray.join(' '),
    stageAndStatus: getStageAndStatus(),
    phone1,
    phone2,
    notEnglishSpeaker,
    hasPCPInfo,
    hasPayorInfo,
  }
}

type MapToPatientUpdateStatus = (
  patient: Patient,
  status: string
) => UpdatePatientStatusArgs

export const mapToPatientUpdateStatus: MapToPatientUpdateStatus = (
  patient,
  status
) => {
  const updatePatientStatusArgs: UpdatePatientStatusArgs = {
    patientId: patient.patientId,
    payload: {
      status,
      assignedPathwayId: patient?.assignedPathwayId ?? undefined,
    },
  }

  return updatePatientStatusArgs
}

type MapToPatientHeaderEditFormFields = (
  patient: Patient
) => PatientHeaderEditFormFields

export const mapToPatientHeaderEditFormFields: MapToPatientHeaderEditFormFields =
  (patient) => {
    const { phone1, phone2 } = getPatientMaskedPhones(patient.contacts)

    const email = patient.contacts
      ? patient.contacts.find((contact) => contact.email)?.email || ''
      : ''

    let language = ''
    let otherLanguage = ''
    if (patient.language && MAIN_LANGUAGES.includes(patient.language)) {
      language = patient.language
    } else if (patient.language && !MAIN_LANGUAGES.includes(patient.language)) {
      language = 'Other'
      otherLanguage = patient.language
    }

    const knownPayors: string[] = PAYORS.map((payor) => payor.id).filter(
      (payor) => payor !== 'Other'
    )

    const getPayor = () => {
      if (patient.medicalProfile?.payor) {
        if (knownPayors.includes(patient.medicalProfile.payor)) {
          return patient.medicalProfile.payor
        } else {
          return 'Other'
        }
      }
      return ''
    }

    const getOtherPayor = () => {
      if (
        patient.medicalProfile?.payor &&
        !knownPayors.includes(patient.medicalProfile.payor)
      ) {
        return patient.medicalProfile.payor
      }
      return ''
    }

    return {
      phone1,
      phone2,
      email,
      language,
      otherLanguage,
      firstName: patient.firstName || '',
      middleName: patient.middleName || '',
      lastName: patient.lastName || '',
      dob: patient.dob ? new Date(patient.dob) : new Date(),
      addressLine1: patient.addressLine1 || '',
      addressLine2: patient.addressLine2 || '',
      city: patient.city || '',
      state: patient.state || '',
      zip: patient.zip || '',
      referralSource: patient.referralSource || '',
      primaryCarePhysician: patient.medicalProfile?.primaryCarePhysician || '',
      pcpPhone: patient.medicalProfile?.phone || '',
      pcpFax: patient.medicalProfile?.fax || '',
      pcpGroupName: patient.medicalProfile?.groupName || '',
      pcpAddressLine1: patient.medicalProfile?.addressLine1 || '',
      pcpAddressLine2: patient.medicalProfile?.addressLine2 || '',
      pcpCity: patient.medicalProfile?.city || '',
      pcpState: patient.medicalProfile?.state || '',
      pcpZip: patient.medicalProfile?.zip || '',
      payor: getPayor(),
      otherPayor: getOtherPayor(),
    }
  }

type MapToSearchDuplicatePatientParams = (
  fields: Pick<
    CreatePatientManuallyFormFields,
    'firstName' | 'lastName' | 'dob' | 'phone1' | 'phone2'
  >
) => SearchDuplicatePatientParams

export const mapToSearchDuplicatePatientParams: MapToSearchDuplicatePatientParams =
  (fields) => {
    const params: SearchDuplicatePatientParams = {
      firstName: fields.firstName,
      lastName: fields.lastName,
    }
    if (isValidDate(fields.dob)) {
      params.dob = formatDate(fields.dob)
    }
    if (phoneRegex.test(fields.phone1)) {
      params.phone = fields.phone1
    } else if (fields.phone2 && phoneRegex.test(fields.phone2)) {
      params.phone = fields.phone2
    }
    return params
  }

type MapToDuplicatePatientWarningResultListItem = (
  patient: SearchDuplicatePatientResult
) => DuplicatePatientWarningResultListItem

export const mapToDuplicatePatientWarningResultListItem: MapToDuplicatePatientWarningResultListItem =
  (patient) => ({
    label: `${patient.firstName} ${patient.lastName}, ${patient.dob}`,
    id: patient.patientId,
  })
