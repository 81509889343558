import { useQuery } from '@tanstack/react-query'

import {
  GetPatientIntakeFlowsReturns,
  GetPatientIntakeFlowsError,
} from 'src/features/msk/domain'
import { useMSKUseCases } from 'src/features/msk/msk.service-locator'
import { usePatientId } from 'src/features/shared/presentation'

export const GET_PATIENT_INTAKE_FLOWS_QUERY_KEY = 'getPatientIntakeFlows'

type UseGetPatientIntakeFlowsQueryProps = {
  patientId?: string
  throwGetPatientIdError?: boolean
}

export const useGetPatientIntakeFlowsQuery = ({
  patientId,
  throwGetPatientIdError = true,
}: UseGetPatientIntakeFlowsQueryProps = {}) => {
  const mskUseCases = useMSKUseCases()

  const _patientId = usePatientId({ patientId, throwGetPatientIdError })

  const { data, isLoading, isError } = useQuery<
    GetPatientIntakeFlowsReturns | null,
    GetPatientIntakeFlowsError
  >([GET_PATIENT_INTAKE_FLOWS_QUERY_KEY, _patientId], () => {
    return _patientId ? mskUseCases.getPatientIntakeFlows(_patientId) : null
  })
  return {
    intakeFlows:
      data && data.intakeFlows && data.intakeFlows.length
        ? data.intakeFlows
        : [],
    previouslyTriedActions: data ? data.previouslyTriedActions : [],
    actionsOpenToTry: data ? data.actionsOpenToTry : [],
    questionsAndActions: data ? data.questionsAndActions : null,
    enablingTherapies: data ? data.enablingTherapies : [],
    getPatientIntakeFlowsIsLoading: isLoading,
    getPatientIntakeFlowsIsError: isError,
  }
}
