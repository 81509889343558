import { FC } from 'react'
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from '@mui/material'

import {
  CreatePatientNoteForm,
  PatientNotesList,
} from 'src/features/notes/presentation'
import { mapToPatientNoteTypes } from 'src/features/notes/adapters'
import { NoteType } from 'src/features/notes/domain'
import { Patient } from 'src/features/patients/domain'
import { useNotesStore } from 'src/features/shared/infrastructure/store/hooks/use-notes.store'
import { useRolesAndPermissions } from 'src/features/shared/presentation'

type PatientNotesSectionProps = {
  patient: Patient
  onIntakeAssessmentClick: () => void
}

export const PatientNotesSection: FC<PatientNotesSectionProps> = ({
  patient,
  onIntakeAssessmentClick,
}) => {
  const { hasPermissions } = useRolesAndPermissions()
  const { selectedNoteType, setSelectedNoteType } = useNotesStore()

  const disabledIntakeAssessmentButton = !hasPermissions(['intake:create'])

  const getNoteTypes = () => {
    return mapToPatientNoteTypes(patient)
  }

  const handleSetSelectedNoteType = (event: SelectChangeEvent<NoteType>) => {
    const noteTypeValue = event.target.value as NoteType
    setSelectedNoteType(noteTypeValue)
  }

  return (
    <Box
      sx={{
        paddingTop: '16px',
        paddingBottom: '32px',
      }}
    >
      <Box
        sx={{
          padding: '0 16px 16px 16px',
          display: 'flex',
          width: 'full',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant={'h6'}>Notes</Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
          }}
        >
          <FormControl size={'small'} sx={{ width: 227 }}>
            <InputLabel id={'notes-type-form-label'}>Type of Note</InputLabel>
            <Select
              labelId={'notes-type-form-label'}
              id={'note-type-form'}
              label={'Type of note'}
              value={selectedNoteType}
              onChange={handleSetSelectedNoteType}
            >
              <MenuItem value={''}>None</MenuItem>
              {getNoteTypes().map((noteType) => (
                <MenuItem key={noteType} value={noteType}>
                  {noteType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Tooltip
            title={
              disabledIntakeAssessmentButton
                ? 'Only clinical users can create intake assessments'
                : ''
            }
            placement="top"
            arrow
          >
            <Box
              component={'span'}
              sx={{
                cursor: disabledIntakeAssessmentButton
                  ? 'not-allowed'
                  : 'pointer',
              }}
            >
              <Button
                variant="text"
                color="secondary"
                size="medium"
                sx={{
                  textTransform: 'none',
                  textDecoration: 'underline',
                  '&.Mui-disabled': {
                    cursor: 'not-allowed',
                  },
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
                onClick={onIntakeAssessmentClick}
                disabled={disabledIntakeAssessmentButton}
                data-testid="open_intake_assessment_modal_btn"
              >
                Intake Assessment
              </Button>
            </Box>
          </Tooltip>
        </Box>
      </Box>
      <Box>
        {selectedNoteType ? <CreatePatientNoteForm patient={patient} /> : null}
      </Box>
      <Box>
        <PatientNotesList />
      </Box>
    </Box>
  )
}
