import { ReferralSource, Stage, Payor } from 'src/features/shared/types'

export type ReferralSourceObject = {
  id: ReferralSource
  label: ReferralSource
}

export const REFERRAL_SOURCES: ReferralSourceObject[] = [
  { id: 'UM - Mail', label: 'UM - Mail' },
  { id: 'UM - Email Marketing', label: 'UM - Email Marketing' },
  { id: 'PCP - One Medical', label: 'PCP - One Medical' },
  { id: 'PCP - Other', label: 'PCP - Other' },
  {
    id: 'Orthopedic Surgeon - Resurgens',
    label: 'Orthopedic Surgeon - Resurgens',
  },
  { id: 'Orthopedic Surgeon - Other', label: 'Orthopedic Surgeon - Other' },
  { id: 'Plan - Humana', label: 'Plan - Humana' },
  { id: 'PCP - Ineligible Referral', label: 'PCP - Ineligible Referral' },
]

export type StageObject = {
  id: Stage
  label: Stage
}

export const STAGES: StageObject[] = [
  { id: 'Do Not Contact', label: 'Do Not Contact' },
  { id: 'Pending Contact', label: 'Pending Contact' },
  { id: 'Attempted', label: 'Attempted' },
  { id: 'Engaged', label: 'Engaged' },
  { id: 'Maintenance', label: 'Maintenance' },
  { id: 'Completed', label: 'Completed' },
  { id: 'Closed', label: 'Closed' },
  { id: 'Pathway Rec. Only', label: 'Pathway Rec. Only' },
]

export type PayorObject = {
  id: Payor
  label: Payor
}

export const PAYORS: PayorObject[] = [
  { id: 'Aetna', label: 'Aetna' },
  { id: 'Blue Cross', label: 'Blue Cross' },
  { id: 'Cigna', label: 'Cigna' },
  { id: 'Humana', label: 'Humana' },
  { id: 'Medicare', label: 'Medicare' },
  { id: 'United', label: 'United' },
  { id: 'WellCare', label: 'WellCare' },
  { id: 'Other', label: 'Other' },
]

export type RecommendedActionPathwayType = Record<string, string[]>

export const RECOMMENDED_ACTION_PATHWAYS_TYPES: RecommendedActionPathwayType = {
  '002a': ['Surgeon', 'APP (PA or NP)'],
  '003a': ['McKenzie'],
  '004a': ['Physiatry'],
  '005a': ['Surgeon', 'Physiatry'],
  '006a': ['Self-Care'],
  '008a': ['Traditional'],
  '009a': ['Surgeon', 'APP (PA or NP)'],
  '010a': ['CHT'],
}

export type Language = string

type Languages = Language[]

export const MAIN_LANGUAGES: Languages = [
  'English',
  'Spanish',
  'Korean',
  'Chinese - Mandarin',
  'Chinese - Cantonese',
  'French',
  'German',
  'ASL',
]

export const OTHER_LANGUAGES: Languages = [
  'Abkhazian',
  'Afar',
  'Afrikaans',
  'Akan',
  'Albanian',
  'Amharic',
  'Arabic',
  'Aragonese',
  'Armenian',
  'Assamese',
  'Avaric',
  'Avestan',
  'Aymara',
  'Azerbaijani',
  'Bambara',
  'Bashkir',
  'Basque',
  'Belarusian',
  'Bengali',
  'Bihari languages',
  'Bislama',
  'Bokm\u00e5l, Norwegian; Norwegian Bokm\u00e5l',
  'Bosnian',
  'Breton',
  'Bulgarian',
  'Burmese',
  'Catalan; Valencian',
  'Central Khmer',
  'Chamorro',
  'Chechen',
  'Chichewa; Chewa; Nyanja',
  'Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic',
  'Chuvash',
  'Cornish',
  'Corsican',
  'Cree',
  'Croatian',
  'Czech',
  'Danish',
  'Divehi; Dhivehi; Maldivian',
  'Dutch; Flemish',
  'Dzongkha',
  'Esperanto',
  'Estonian',
  'Ewe',
  'Faroese',
  'Fijian',
  'Finnish',
  'Fulah',
  'Gaelic; Scottish Gaelic',
  'Galician',
  'Ganda',
  'Georgian',
  'German',
  'Greek, Modern (1453-)',
  'Guarani',
  'Gujarati',
  'Haitian; Haitian Creole',
  'Hausa',
  'Hebrew',
  'Herero',
  'Hindi',
  'Hiri Motu',
  'Hungarian',
  'Icelandic',
  'Ido',
  'Igbo',
  'Indonesian',
  'Interlingua (International Auxiliary Language Association)',
  'Interlingue; Occidental',
  'Inuktitut',
  'Inupiaq',
  'Irish',
  'Italian',
  'Japanese',
  'Javanese',
  'Kalaallisut; Greenlandic',
  'Kannada',
  'Kanuri',
  'Kashmiri',
  'Kazakh',
  'Kikuyu; Gikuyu',
  'Kinyarwanda',
  'Kirghiz; Kyrgyz',
  'Komi',
  'Kongo',
  'Kuanyama; Kwanyama',
  'Kurdish',
  'Lao',
  'Latin',
  'Latvian',
  'Limburgan; Limburger; Limburgish',
  'Lingala',
  'Lithuanian',
  'Luba-Katanga',
  'Luxembourgish; Letzeburgesch',
  'Macedonian',
  'Malagasy',
  'Malay',
  'Malayalam',
  'Maltese',
  'Manx',
  'Maori',
  'Marathi',
  'Marshallese',
  'Mongolian',
  'Nauru',
  'Navajo; Navaho',
  'Ndebele, North; North Ndebele',
  'Ndebele, South; South Ndebele',
  'Ndonga',
  'Nepali',
  'Northern Sami',
  'Norwegian Nynorsk; Nynorsk, Norwegian',
  'Norwegian',
  'Occitan (post 1500)',
  'Ojibwa',
  'Oriya',
  'Oromo',
  'Ossetian; Ossetic',
  'Pali',
  'Panjabi; Punjabi',
  'Persian',
  'Polish',
  'Portuguese',
  'Pushto; Pashto',
  'Quechua',
  'Romanian; Moldavian; Moldovan',
  'Romansh',
  'Rundi',
  'Russian',
  'Samoan',
  'Sango',
  'Sanskrit',
  'Sardinian',
  'Serbian',
  'Shona',
  'Sichuan Yi; Nuosu',
  'Sindhi',
  'Sinhala; Sinhalese',
  'Slovak',
  'Slovenian',
  'Somali',
  'Sotho, Southern',
  'Sundanese',
  'Swahili',
  'Swati',
  'Swedish',
  'Tagalog',
  'Tahitian',
  'Tajik',
  'Tamil',
  'Tatar',
  'Telugu',
  'Thai',
  'Tibetan',
  'Tigrinya',
  'Tonga (Tonga Islands)',
  'Tsonga',
  'Tswana',
  'Turkish',
  'Turkmen',
  'Twi',
  'Uighur; Uyghur',
  'Ukrainian',
  'Urdu',
  'Uzbek',
  'Venda',
  'Vietnamese',
  'Volap\u00fck',
  'Walloon',
  'Welsh',
  'Western Frisian',
  'Wolof',
  'Xhosa',
  'Yiddish',
  'Yoruba',
  'Zhuang; Chuang',
  'Zulu',
]
