import { ErrorMessage } from '@hookform/error-message'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material'
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form'

type StatusFieldProps = {
  statusDefinitions: string[]
}

export const StatusField = <T extends FieldValues>({
  statusDefinitions,
}: StatusFieldProps) => {
  const name = 'status' as FieldPath<T>
  const { register } = useFormContext()

  return (
    <Controller
      name={name}
      {...register}
      render={({ field }) => (
        <FormControl size={'small'} sx={{ width: '100%' }}>
          <InputLabel id={'status-label'}>Status</InputLabel>
          <Select
            labelId={'status-label'}
            id={'status'}
            label={'Status'}
            value={field.value}
            onChange={(event) => {
              field.onChange(event.target.value)
            }}
          >
            {statusDefinitions.map((statusOption) => (
              <MenuItem key={statusOption} value={statusOption}>
                {statusOption}
              </MenuItem>
            ))}
          </Select>
          <ErrorMessage
            name="status"
            render={({ message }) => (
              <FormHelperText sx={{ mx: 0 }} error={true}>
                {message}
              </FormHelperText>
            )}
          />
        </FormControl>
      )}
    />
  )
}
