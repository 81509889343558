import {
  UseMutateAsyncFunction,
  UseMutateFunction,
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import {
  CreatePatientPreOpTouchpointNoteReturns,
  CreatePatientPreOpTouchpointNoteArgs,
  CreatePatientPreOpTouchpointNoteError,
} from 'src/features/notes/domain'
import {
  GET_PATIENT_QUERY_KEY,
  GET_PATIENT_PAIN_AND_FUNCTION_QUERY_KEY,
} from 'src/features/patients/presentation'
import { GET_PATIENT_NOTES_QUERY_KEY } from 'src/features/notes/presentation'
import { useNotesUseCases } from 'src/features/notes/notes.service-locator'

type UseCreatePatientPreOpTouchpointNoteProps = Omit<
  UseMutationOptions<
    CreatePatientPreOpTouchpointNoteReturns,
    CreatePatientPreOpTouchpointNoteError,
    CreatePatientPreOpTouchpointNoteArgs,
    unknown
  >,
  'mutationFn'
>

type UseCreatePatientPreOpTouchpointNote = (
  props?: UseCreatePatientPreOpTouchpointNoteProps
) => {
  createPatientPreOpTouchpointNote: UseMutateFunction<
    CreatePatientPreOpTouchpointNoteReturns,
    CreatePatientPreOpTouchpointNoteError,
    CreatePatientPreOpTouchpointNoteArgs,
    unknown
  >
  createPatientPreOpTouchpointNoteAsync: UseMutateAsyncFunction<
    CreatePatientPreOpTouchpointNoteReturns,
    CreatePatientPreOpTouchpointNoteError,
    CreatePatientPreOpTouchpointNoteArgs,
    unknown
  >
  createPatientPreOpTouchpointNoteIsLoading: boolean
  createPatientPreOpTouchpointNoteIsError: boolean
  createPatientPreOpTouchpointNoteStatus: string
}

export const useCreatePatientPreOpTouchpointNote: UseCreatePatientPreOpTouchpointNote =
  (props = {}) => {
    const queryClient = useQueryClient()
    const notesUseCases = useNotesUseCases()

    const { onSuccess } = props

    const { mutate, mutateAsync, isLoading, isError, status } = useMutation<
      CreatePatientPreOpTouchpointNoteReturns,
      CreatePatientPreOpTouchpointNoteError,
      CreatePatientPreOpTouchpointNoteArgs,
      unknown
    >({
      mutationFn: (args: CreatePatientPreOpTouchpointNoteArgs) => {
        return notesUseCases.createPatientPreOpTouchpointNote(args)
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({
          queryKey: [GET_PATIENT_QUERY_KEY, variables.patientId],
        })
        queryClient.invalidateQueries({
          queryKey: [GET_PATIENT_NOTES_QUERY_KEY, variables.patientId],
        })
        queryClient.invalidateQueries({
          queryKey: [
            GET_PATIENT_PAIN_AND_FUNCTION_QUERY_KEY,
            variables.patientId,
          ],
        })
        onSuccess?.(data, variables, context)
      },
    })
    return {
      createPatientPreOpTouchpointNote: mutate,
      createPatientPreOpTouchpointNoteAsync: mutateAsync,
      createPatientPreOpTouchpointNoteIsLoading: isLoading,
      createPatientPreOpTouchpointNoteIsError: isError,
      createPatientPreOpTouchpointNoteStatus: status,
    }
  }
