import {
  Box,
  InputAdornment,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import { ChangeEvent, FC, useEffect } from 'react'

import {
  MSKFormState,
  useMSKFormStore,
} from 'src/features/shared/infrastructure'
import {
  MSKFormPagination,
  MSKFormStepContainer,
} from 'src/features/msk/presentation'
import { LoadMSKFormReturns } from 'src/features/msk/domain'
import { mapBodyPartToMultiSelectOption } from 'src/features/msk/adapters'
import {
  MultiSelect,
  MultiSelectOption,
} from 'src/features/shared/presentation'

type MSKFormStepOneProps = {
  loadedMSKForm: LoadMSKFormReturns
}

export const MSKFormStepOne: FC<MSKFormStepOneProps> = ({ loadedMSKForm }) => {
  const bodyPartsOptions = loadedMSKForm.botheredBodyParts
  const {
    setModalTitle,
    errors,
    addNewErrors,
    deleteError,
    setStep,
    selectedBotheredBodyParts,
    setSelectedBotheredBodyParts,
    profileHeightFt,
    setProfileHeightFt,
    profileHeightIn,
    setProfileHeightIn,
    profileWeight,
    setProfileWeight,
    setIntakeAssessments,
  } = useMSKFormStore({ loadedMSKForm })

  const getMultiSelectFormControlSx = () => {
    return {
      m: 0,
      width: '100%',
    }
  }

  const handleBotheredBodyPartsChange = (
    event: SelectChangeEvent<MultiSelectOption['value'][]>
  ) => {
    const {
      target: { value },
    } = event
    // On autofill we get a stringified value.
    const selectedValueList =
      typeof value === 'string' ? value.split(',') : value
    setSelectedBotheredBodyParts(
      bodyPartsOptions.filter((bodyPartOption) =>
        selectedValueList.includes(bodyPartOption.id)
      )
    )
    if (selectedValueList.length) {
      deleteError('selectedBotheredBodyParts')
    }
  }

  const handleProfileHeightFtChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const regex = /^[1-7]$/
    const value = event.target.value
    if (value === '' || regex.test(value)) {
      setProfileHeightFt(value)
      if (value) {
        deleteError('profileHeightFt')
      }
    }
  }

  const handleProfileHeightInChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const regex = /^[0-9]{1,2}$/
    const value = event.target.value
    if (value === '' || regex.test(value)) {
      setProfileHeightIn(value)
      const numValue = parseInt(value, 10)
      if (numValue >= 0 && numValue <= 11) {
        deleteError('profileHeightIn')
      } else {
        addNewErrors({
          profileHeightIn: 'Inches must be between 0 and 11.',
        })
      }
    }
  }

  const handleProfileWeightChange = (event: ChangeEvent<HTMLInputElement>) => {
    const regex = /^[0-9]{1,3}(\.)?(\d{1,3})?$/
    const value = event.target.value
    if (value === '' || regex.test(value)) {
      setProfileWeight(value)
      if (value) {
        deleteError('profileWeight')
      }
    }
  }

  const isFormValid = () => {
    const newErrors: MSKFormState['errors'] = {}

    if (!selectedBotheredBodyParts.length) {
      newErrors.selectedBotheredBodyParts = 'Bothered Body Parts is required.'
    }

    if (!profileHeightFt) {
      newErrors.profileHeightFt = 'Height Ft is required.'
    }

    if (!profileHeightIn) {
      newErrors.profileHeightIn = 'Height In is required.'
    }

    if (!profileWeight) {
      newErrors.profileWeight = 'Weight is required.'
    }

    if (Object.keys(newErrors).length) {
      addNewErrors(newErrors)
      return false
    }

    return true
  }

  const handleNext = () => {
    if (isFormValid()) {
      setIntakeAssessments()
      setStep(2)
    }
  }

  useEffect(() => {
    setModalTitle('Intake Assessment')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <MSKFormStepContainer>
      <Box
        id="msk_form_step_one"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '32px',
        }}
      >
        <Box
          sx={{
            width: '380px',
          }}
        >
          <MultiSelect
            displayOptionType="checkbox"
            id="msk_form_bothered_body_parts"
            data-testid="msk_form_bothered_body_parts"
            label="What part of the body is bothering you?"
            options={bodyPartsOptions.map(mapBodyPartToMultiSelectOption)}
            selectedOptions={selectedBotheredBodyParts.map(
              (selectedBodyBotheredPart) => selectedBodyBotheredPart.id
            )}
            handleChange={handleBotheredBodyPartsChange}
            formControlSx={getMultiSelectFormControlSx()}
            helperText={
              errors.selectedBotheredBodyParts
                ? errors.selectedBotheredBodyParts
                : undefined
            }
            error={!!errors.selectedBotheredBodyParts}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '16px',
          }}
        >
          <Box sx={{ display: 'flex', maxWidth: '266px', columnGap: '16px' }}>
            <TextField
              data-testid={'msk_form_profile_height_ft_text_field'}
              name={'profileHeightFt'}
              label={'Height'}
              value={profileHeightFt}
              onChange={handleProfileHeightFtChange}
              error={!!errors.profileHeightFt}
              helperText={errors.profileHeightFt ? errors.profileHeightFt : ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position={'end'}>ft</InputAdornment>
                ),
              }}
            />
            <TextField
              data-testid={'msk_form_profile_height_in_text_field'}
              name={'profileHeightIn'}
              label={'Height'}
              value={profileHeightIn}
              onChange={handleProfileHeightInChange}
              error={!!errors.profileHeightIn}
              helperText={errors.profileHeightIn ? errors.profileHeightIn : ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position={'end'}>in</InputAdornment>
                ),
              }}
            />
          </Box>
          <Box sx={{ maxWidth: '125px' }}>
            <TextField
              data-testid={'msk_form_profile_weight_text_field'}
              name={'profileWeight'}
              label={'Weight'}
              value={profileWeight}
              onChange={handleProfileWeightChange}
              error={!!errors.profileWeight}
              helperText={errors.profileWeight ? errors.profileWeight : ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position={'end'}>lbs</InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      </Box>
      <MSKFormPagination onNext={handleNext} showPrevious={false} />
    </MSKFormStepContainer>
  )
}
