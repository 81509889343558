import { FC } from 'react'
import { SxProps, Theme, useTheme } from '@mui/material/styles'
import {
  Box,
  Chip,
  Checkbox,
  ListItemText,
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
  FormHelperText,
} from '@mui/material'
import { Key } from 'react'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export type MultiSelectOption = {
  key: Key
  value: string
  label: string
}

type MultiSelectProps = {
  id: string
  label: string
  options: MultiSelectOption[]
  selectedOptions: string[]
  handleChange: (event: SelectChangeEvent<string[]>) => void
  displayValueType?: 'normal' | 'chip'
  displayOptionType?: 'normal' | 'checkbox'
  helperText?: string
  error?: boolean
  formControlSx?: SxProps<Theme>
  'data-testid'?: string
}

export const MultiSelect: FC<MultiSelectProps> = ({
  id,
  label,
  options,
  selectedOptions,
  handleChange,
  formControlSx,
  helperText,
  error,
  'data-testid': dataTestId = 'multi_select',
  displayValueType = 'normal',
  displayOptionType = 'normal',
}) => {
  const theme = useTheme()

  const getOptionSx = (option: MultiSelectOption) => {
    return {
      fontWeight: !selectedOptions.includes(option.value)
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
    }
  }

  const getDisplayValueLabel = (value: string) => {
    const option = options.find((option) => option.value === value)
    return option ? option.label : ''
  }

  return (
    <FormControl
      sx={{
        width: '100%',
        ...formControlSx,
      }}
      error={error}
    >
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <Select
        data-testid={dataTestId}
        labelId={`${id}-label`}
        id={`${id}`}
        multiple
        value={selectedOptions}
        onChange={handleChange}
        input={<OutlinedInput id={`${id}-select`} label={label} />}
        renderValue={(selected) => {
          if (displayValueType === 'normal') {
            return selected.map(getDisplayValueLabel).join(', ')
          }

          if (displayValueType === 'chip') {
            return (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip key={value} label={getDisplayValueLabel(value)} />
                ))}
              </Box>
            )
          }
        }}
        MenuProps={MenuProps}
      >
        {options.map((option) => {
          if (displayOptionType === 'normal') {
            return (
              <MenuItem
                key={option.key}
                value={option.value}
                sx={{ ...getOptionSx(option) }}
              >
                {option.label}
              </MenuItem>
            )
          }

          if (displayOptionType === 'checkbox') {
            return (
              <MenuItem
                key={option.key}
                value={option.value}
                sx={{ ...getOptionSx(option) }}
              >
                <Checkbox checked={selectedOptions.includes(option.value)} />
                <ListItemText primary={option.label} />
              </MenuItem>
            )
          }
        })}
      </Select>
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  )
}
