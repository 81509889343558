import {
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  SxProps,
  Theme,
  Box,
} from '@mui/material'
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form'

type CustomSelectFieldProps<T extends FieldValues> = {
  name?: FieldPath<T>
  label?: string
  id: string
  items?: { id: string; label: string }[]
  defaultItem?: string
  selectStyle?: SxProps<Theme>
  labelStyle?: SxProps<Theme>
  helperText?: string
}

export const CustomSelectField = <T extends FieldValues>({
  name,
  label,
  id,
  items,
  defaultItem,
  selectStyle,
  labelStyle,
  helperText,
}: CustomSelectFieldProps<T>) => {
  const customName = name as FieldPath<T>
  const { register } = useFormContext()

  return (
    <Controller
      name={customName}
      {...register}
      render={({ field, fieldState }) => (
        <Box>
          {label ? (
            <InputLabel id={`${id}-label`} sx={labelStyle}>
              {label}
            </InputLabel>
          ) : null}
          <Select
            size="small"
            labelId={label && `${id}-label`}
            id={id}
            value={field.value}
            sx={selectStyle}
            displayEmpty
            onChange={(event) => {
              field.onChange(event.target.value)
            }}
          >
            {defaultItem ? <MenuItem value={''}>{defaultItem}</MenuItem> : null}
            {items?.map((item) => (
              <MenuItem key={item.id} value={item.label}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
          {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
          {fieldState.error ? (
            <FormHelperText error={!!fieldState.error}>
              {fieldState.error ? fieldState.error.message : ''}
            </FormHelperText>
          ) : null}
        </Box>
      )}
    />
  )
}
