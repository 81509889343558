import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  MSKQuestion,
  MSKAdditionalQuestionsResultDisplay,
} from 'src/features/msk/domain'
import { getCurrentIntakeAssessment } from 'src/features/shared/utils'
import {
  MSKFormState,
  MSKFormStateFieldName,
  MSKFormStateIntakeAssessment,
  SetMSKFormStateIntakeAssessmentAnswerPayloadAction,
  MSKFormStateAdditionalQuestionsFieldOptionsScore,
  MSKFormStateSelectedAction,
} from 'src/features/shared/infrastructure'
import { loadMSKFormStateFromLocalStorage } from 'src/features/shared/infrastructure'

const additionalQuestionsOptionsScore: MSKFormStateAdditionalQuestionsFieldOptionsScore =
  {
    v1: {
      'Not At All': 0,
      'Several Days': 1,
      'More than Half of Days': 2,
      'Nearly Every Day': 3,
    },
  }

const getPhScore = (
  phQ1: MSKFormState['additionalQuestions']['phQ1'],
  phQ2: MSKFormState['additionalQuestions']['phQ2']
) => {
  if (phQ1 === 'Select an option' && phQ2 === 'Select an option') {
    return null
  }

  let result = 0
  if (phQ1 !== 'Select an option') {
    result += additionalQuestionsOptionsScore.v1[phQ1]
  }
  if (phQ2 !== 'Select an option') {
    result += additionalQuestionsOptionsScore.v1[phQ2]
  }
  return result
}

const getPhAssessment = (
  phScore: MSKFormState['additionalQuestions']['phScore']
): MSKAdditionalQuestionsResultDisplay['v1'] => {
  if (phScore === null) {
    return 'N/A'
  }

  if (phScore < 3) {
    return 'Negative'
  }

  if (phScore >= 3) {
    return 'Positive'
  }

  return 'N/A'
}

const getGad2Score = (
  gad2Q1: MSKFormState['additionalQuestions']['gad2Q1'],
  gad2Q2: MSKFormState['additionalQuestions']['gad2Q2']
) => {
  if (gad2Q1 === 'Select an option' && gad2Q2 === 'Select an option') {
    return null
  }

  let result = 0
  if (gad2Q1 !== 'Select an option') {
    result += additionalQuestionsOptionsScore.v1[gad2Q1]
  }
  if (gad2Q2 !== 'Select an option') {
    result += additionalQuestionsOptionsScore.v1[gad2Q2]
  }
  return result
}

const getGad2Assessment = (
  gad2Score: MSKFormState['additionalQuestions']['gad2Score']
): MSKAdditionalQuestionsResultDisplay['v1'] => {
  if (gad2Score === null) {
    return 'N/A'
  }

  if (gad2Score < 3) {
    return 'Negative'
  }

  if (gad2Score >= 3) {
    return 'Positive'
  }

  return 'N/A'
}

const getBhAction1 = (
  getPhAssessment: MSKFormState['additionalQuestions']['phAssessment'],
  getGad2Assessment: MSKFormState['additionalQuestions']['gad2Assessment']
): MSKFormState['additionalQuestions']['bhAction1'] => {
  if (
    [getPhAssessment, getGad2Assessment].some(
      (assessment) => assessment === 'Positive'
    )
  ) {
    return 'Y'
  }

  return 'N'
}

const getFallScreenResult = (
  fallScreenQ1: MSKFormState['additionalQuestions']['fallScreenQ1'],
  fallScreenQ2: MSKFormState['additionalQuestions']['fallScreenQ2']
): MSKFormState['additionalQuestions']['fallScreenResult'] => {
  if (
    fallScreenQ1 === 'Select an option' &&
    fallScreenQ2 === 'Select an option'
  ) {
    return 'N/A'
  }

  if ([fallScreenQ1, fallScreenQ2].some((assessment) => assessment === 'Y')) {
    return 'Positive'
  }

  return 'Negative'
}

const initialState: MSKFormState = {
  mskFormPatientId: '',
  modalTitle: 'Intake Assessment',
  step: 1,
  page: 1,
  errors: {},
  selectedBotheredBodyParts: [],
  profileHeightFt: '',
  profileHeightIn: '',
  profileWeight: '',
  intakeAssessments: [],
  currentCondition: null,
  showStepThreeFullSummary: false,
  showStepTwoFullSummary: false,
  showFullSummary: false,
  showHardstopWarning: false,
  currentIntakeAssessmentIdx: 0,
  currentPathQuestion: null,
  currentPathQuestionAnswer: null,
  pathQuestionsNext: null,
  isCurrentPathQuestionValidated: false,
  additionalQuestions: {
    phQ1: 'Select an option',
    phQ2: 'Select an option',
    phScore: null,
    phAssessment: 'N/A',
    gad2Q1: 'Select an option',
    gad2Q2: 'Select an option',
    gad2Score: null,
    gad2Assessment: 'N/A',
    bhAction1: 'N',
    bhAction2: 'Select an option',
    fallScreenQ1: 'Select an option',
    fallScreenQ2: 'Select an option',
    fallScreenResult: 'N/A',
    tabletComputerOrPhone: 'Select an option',
    barriersToBeAwareOf: 'Select an option',
    barriersToBeAwareOfText: '',
    additionalPhone: '',
    peopleAllowedToReachOutToWho: '',
    peopleAllowedToReachOutToPhone: '',
    pcpName: '',
    pcpPhone: '',
    pcpFax: '',
    pcpGroupName: '',
    pcpAddressLine1: '',
    pcpAddressLine2: '',
    pcpCity: '',
    pcpState: '',
    pcpZip: '',
    confirmed: false,
  },
}

const mskFormSlice = createSlice({
  name: 'mskForm',
  initialState: initialState,
  reducers: {
    resetMSKFormAction(
      state,
      action: PayloadAction<MSKFormState['mskFormPatientId']>
    ) {
      return { ...initialState, mskFormPatientId: action.payload }
    },
    initMSKFormAction(
      state,
      action: PayloadAction<MSKFormState['mskFormPatientId']>
    ) {
      const mskFormPatientId = action.payload
      return (
        loadMSKFormStateFromLocalStorage(mskFormPatientId) || {
          ...initialState,
          mskFormPatientId,
        }
      )
    },
    setMSKFormPatientIdAction(state, action: PayloadAction<string>) {
      state.mskFormPatientId = action.payload
    },
    setMSKFormModalTitleAction(state, action: PayloadAction<string>) {
      state.modalTitle = action.payload
    },
    setMSKFormStepAction(state, action: PayloadAction<MSKFormState['step']>) {
      state.step = action.payload
    },
    setMSKFormPageAction(state, action: PayloadAction<MSKFormState['page']>) {
      state.page = action.payload
    },
    setMSKFormErrorsAction(
      state,
      action: PayloadAction<MSKFormState['errors']>
    ) {
      state.errors = action.payload
    },
    deleteMSKFormErrorsAction(
      state,
      action: PayloadAction<MSKFormStateFieldName | MSKFormStateFieldName[]>
    ) {
      if (Array.isArray(action.payload)) {
        action.payload.forEach((error) => delete state.errors[error])
        return
      }
      delete state.errors[action.payload]
    },
    addNewMSKFormErrorsAction(
      state,
      action: PayloadAction<MSKFormState['errors']>
    ) {
      state.errors = { ...state.errors, ...action.payload }
    },
    setMSKFormSelectedBotheredBodyPartsAction(
      state,
      action: PayloadAction<MSKFormState['selectedBotheredBodyParts']>
    ) {
      state.selectedBotheredBodyParts = action.payload
    },
    setMSKFormProfileHeightFtAction(
      state,
      action: PayloadAction<MSKFormState['profileHeightFt']>
    ) {
      state.profileHeightFt = action.payload
    },
    setMSKFormProfileHeightInAction(
      state,
      action: PayloadAction<MSKFormState['profileHeightIn']>
    ) {
      state.profileHeightIn = action.payload
    },
    setMSKFormProfileWeightAction(
      state,
      action: PayloadAction<MSKFormState['profileWeight']>
    ) {
      state.profileWeight = action.payload
    },
    addMSKFormIntakeAssessmentAction(
      state,
      action: PayloadAction<MSKFormStateIntakeAssessment>
    ) {
      state.intakeAssessments.push(action.payload)
    },
    deleteMSKFormIntakeAssessmentAction(
      state,
      action: PayloadAction<MSKFormStateIntakeAssessment>
    ) {
      state.intakeAssessments = state.intakeAssessments.filter(
        (intakeAssessment) =>
          intakeAssessment.botheredBodyPart.id !==
          action.payload.botheredBodyPart.id
      )
    },
    setMSKFormCurrentConditionAction(
      state,
      action: PayloadAction<MSKFormState['currentCondition']>
    ) {
      state.currentCondition = action.payload
    },
    setMSKFormIntakeAssessmentAction(
      state,
      action: PayloadAction<MSKFormStateIntakeAssessment>
    ) {
      state.intakeAssessments = state.intakeAssessments.map(
        (intakeAssessment) =>
          intakeAssessment.botheredBodyPart.id ===
          action.payload.botheredBodyPart.id
            ? action.payload
            : intakeAssessment
      )
    },
    addPreviouslyTriedActionAction(
      state,
      action: PayloadAction<MSKFormStateSelectedAction>
    ) {
      // -------------------------------
      // Get current intake assessment |
      // -------------------------------
      const currentIntakeAssessment = getCurrentIntakeAssessment(
        state.intakeAssessments,
        state.currentCondition
      )

      // -----------------------------
      // Check if action is included |
      // -----------------------------
      const isSelectedActionIncluded =
        currentIntakeAssessment.previouslyTriedActions.findIndex(
          (_action) => _action.id === action.payload.id
        ) !== -1

      // ---------------------------
      // Add action if not included |
      // ---------------------------
      if (!isSelectedActionIncluded) {
        currentIntakeAssessment.previouslyTriedActions.push(action.payload)
      }
    },
    deletePreviouslyTriedActionAction(
      state,
      action: PayloadAction<MSKFormStateSelectedAction>
    ) {
      // -------------------------------
      // Get current intake assessment |
      // -------------------------------
      const currentIntakeAssessment = getCurrentIntakeAssessment(
        state.intakeAssessments,
        state.currentCondition
      )

      // -----------------------------
      // Check if action is included |
      // -----------------------------
      const isSelectedActionIncluded =
        currentIntakeAssessment.previouslyTriedActions.findIndex(
          (_action) => _action.id === action.payload.id
        ) !== -1

      // --------------------------
      // Delete action if included |
      // --------------------------
      if (isSelectedActionIncluded) {
        currentIntakeAssessment.previouslyTriedActions =
          currentIntakeAssessment.previouslyTriedActions.filter(
            (_action) => _action.id !== action.payload.id
          )
      }

      // ----------------------------------------------------------------
      // If deleted action is opioids unset currentlyUsingOpioids value |
      // ----------------------------------------------------------------
      if (isSelectedActionIncluded && action.payload.id === 'opioids') {
        delete currentIntakeAssessment.currentlyUsingOpioids
      }
    },
    setCurrentlyUsingOpioidsAction(
      state,
      action: PayloadAction<
        MSKFormStateIntakeAssessment['currentlyUsingOpioids']
      >
    ) {
      // -------------------------------
      // Get current intake assessment |
      // -------------------------------
      const currentIntakeAssessment = getCurrentIntakeAssessment(
        state.intakeAssessments,
        state.currentCondition
      )

      // ----------------------------------
      // Set currently using opioids value |
      // ----------------------------------

      currentIntakeAssessment.currentlyUsingOpioids = action.payload
    },
    setOtherPreviouslyTriedActionTextAction(
      state,
      action: PayloadAction<
        MSKFormStateIntakeAssessment['otherPreviouslyTriedActionText']
      >
    ) {
      // -------------------------------
      // Get current intake assessment |
      // -------------------------------
      const currentIntakeAssessment = getCurrentIntakeAssessment(
        state.intakeAssessments,
        state.currentCondition
      )

      // ----------------------------------
      // Set currently using opioids value |
      // ----------------------------------

      currentIntakeAssessment.otherPreviouslyTriedActionText = action.payload
    },
    addOpenToTryActionAction(
      state,
      action: PayloadAction<MSKFormStateSelectedAction>
    ) {
      // -------------------------------
      // Get current intake assessment |
      // -------------------------------
      const currentIntakeAssessment = getCurrentIntakeAssessment(
        state.intakeAssessments,
        state.currentCondition
      )

      // -----------------------------
      // Check if action is included |
      // -----------------------------
      const isSelectedActionIncluded =
        currentIntakeAssessment.actionsOpenToTry.findIndex(
          (_action) => _action.id === action.payload.id
        ) !== -1

      // ---------------------------
      // Add action if not included |
      // ---------------------------
      if (!isSelectedActionIncluded) {
        currentIntakeAssessment.actionsOpenToTry.push(action.payload)
      }
    },
    deleteOpenToTryActionAction(
      state,
      action: PayloadAction<MSKFormStateSelectedAction>
    ) {
      // -------------------------------
      // Get current intake assessment |
      // -------------------------------
      const currentIntakeAssessment = getCurrentIntakeAssessment(
        state.intakeAssessments,
        state.currentCondition
      )

      // -----------------------------
      // Check if action is included |
      // -----------------------------
      const isSelectedActionIncluded =
        currentIntakeAssessment.actionsOpenToTry.findIndex(
          (_action) => _action.id === action.payload.id
        ) !== -1

      // --------------------------
      // Delete action if included |
      // --------------------------
      if (isSelectedActionIncluded) {
        currentIntakeAssessment.actionsOpenToTry =
          currentIntakeAssessment.actionsOpenToTry.filter(
            (_action) => _action.id !== action.payload.id
          )
      }
    },
    setCurrentPainLevelAction(
      state,
      action: PayloadAction<MSKFormStateIntakeAssessment['currentPainLevel']>
    ) {
      // -------------------------------
      // Get current intake assessment |
      // -------------------------------
      const currentIntakeAssessment = getCurrentIntakeAssessment(
        state.intakeAssessments,
        state.currentCondition
      )

      // -----------------
      // Set pain level  |
      // -----------------
      currentIntakeAssessment.currentPainLevel = action.payload
    },
    setCurrentFunctionLevelAction(
      state,
      action: PayloadAction<
        MSKFormStateIntakeAssessment['currentFunctionLevel']
      >
    ) {
      // -------------------------------
      // Get current intake assessment |
      // -------------------------------
      const currentIntakeAssessment = getCurrentIntakeAssessment(
        state.intakeAssessments,
        state.currentCondition
      )

      // ---------------------
      // Set function level |
      // ---------------------
      currentIntakeAssessment.currentFunctionLevel = action.payload
    },
    setBodyRegionToNoteAction(
      state,
      action: PayloadAction<MSKFormStateIntakeAssessment['bodyRegionToNote']>
    ) {
      // -------------------------------
      // Get current intake assessment |
      // -------------------------------
      const currentIntakeAssessment = getCurrentIntakeAssessment(
        state.intakeAssessments,
        state.currentCondition
      )

      // ------------------------
      // Set body region to note |
      // ------------------------
      currentIntakeAssessment.bodyRegionToNote = action.payload
    },
    setMSKFormIntakeAssessmentAnswerAction(
      state,
      action: SetMSKFormStateIntakeAssessmentAnswerPayloadAction
    ) {
      // -------------------------------
      // Get current intake assessment |
      // -------------------------------
      const currentIntakeAssessment = getCurrentIntakeAssessment(
        state.intakeAssessments,
        state.currentCondition
      )
      // ------------
      // Set answer |
      // ------------

      const { question, answer } = action.payload

      currentIntakeAssessment.answers[question.id] = answer

      // ----------------------------------
      // Set question history and answers |
      // ---------------------------------
      const currentQuestionIdx =
        currentIntakeAssessment.questionHistory.findIndex(
          (questionId) => questionId === question.id
        )

      if (currentQuestionIdx === -1) {
        // If question is not in history, add it
        currentIntakeAssessment.questionHistory.push(question.id)
      } else {
        // Questions until current question
        const newQuestionHistory =
          currentIntakeAssessment.questionHistory.slice(
            0,
            currentQuestionIdx + 1
          )
        // Next questions after current question
        const invalidQuestions = currentIntakeAssessment.questionHistory.slice(
          currentQuestionIdx + 1
        )

        // If question is in history, remove all the next questions in history
        currentIntakeAssessment.questionHistory = newQuestionHistory

        // If question is in history, remove all the next questions answers
        invalidQuestions.forEach((questionId) => {
          delete currentIntakeAssessment.answers[questionId]
        })
      }

      // ------------------------
      // Set recommended action |
      // -----------------------
      currentIntakeAssessment.recommendedAction = null
    },
    deleteMSKFormIntakeAssessmentAnswerAction(
      state,
      action: PayloadAction<MSKQuestion>
    ) {
      // -------------------------------
      // Get current intake assessment |
      // -------------------------------
      const currentIntakeAssessment = getCurrentIntakeAssessment(
        state.intakeAssessments,
        state.currentCondition
      )

      const { id: questionId } = action.payload

      // ----------------------------------
      // Set question history and answers |
      // ---------------------------------
      const currentQuestionIdx =
        currentIntakeAssessment.questionHistory.findIndex(
          (_questionId) => _questionId === questionId
        )

      // Questions until current question
      const newQuestionHistory = currentIntakeAssessment.questionHistory.slice(
        0,
        currentQuestionIdx + 1
      )
      // Next questions after current question
      const invalidQuestions = currentIntakeAssessment.questionHistory.slice(
        currentQuestionIdx + 1
      )

      // If question is in history, remove all the next questions in history
      currentIntakeAssessment.questionHistory = newQuestionHistory

      // If question is in history, remove all the next questions answers
      invalidQuestions.forEach((_questionId) => {
        delete currentIntakeAssessment.answers[_questionId]
      })

      // -----------------------------------
      // Delete question answer and history |
      // -----------------------------------
      delete currentIntakeAssessment.answers[questionId]
      currentIntakeAssessment.questionHistory =
        currentIntakeAssessment.questionHistory.filter(
          (_questionId) => _questionId !== questionId
        )

      // --------------------------------
      // Set recommended action to null |
      // --------------------------------
      currentIntakeAssessment.recommendedAction = null
    },
    setMSKFormIntakeAssessmentRecommendedActionAction(
      state,
      action: PayloadAction<MSKFormStateIntakeAssessment['recommendedAction']>
    ) {
      // -------------------------------
      // Get current intake assessment |
      // -------------------------------
      const currentIntakeAssessment = getCurrentIntakeAssessment(
        state.intakeAssessments,
        state.currentCondition
      )

      // -----------------------
      // Set recommended action |
      // -----------------------
      currentIntakeAssessment.recommendedAction = action.payload
    },
    setMSKFormIntakeAssessmentConfirmedAction(
      state,
      action: PayloadAction<MSKFormStateIntakeAssessment['confirmed']>
    ) {
      // -------------------------------
      // Get current intake assessment |
      // -------------------------------
      const currentIntakeAssessment = getCurrentIntakeAssessment(
        state.intakeAssessments,
        state.currentCondition
      )

      // -----------------------
      // Set recommended action |
      // -----------------------
      currentIntakeAssessment.confirmed = action.payload
    },
    setMSKFormIntakeAssessmentHardstopAction(
      state,
      action: PayloadAction<MSKFormStateIntakeAssessment['hardstop']>
    ) {
      // -------------------------------
      // Get current intake assessment |
      // -------------------------------
      const currentIntakeAssessment = getCurrentIntakeAssessment(
        state.intakeAssessments,
        state.currentCondition
      )

      // -----------------------
      // Set recommended action |
      // -----------------------
      currentIntakeAssessment.hardstop = action.payload
    },
    setMSKFormShowFullSummaryAction(
      state,
      action: PayloadAction<MSKFormState['showFullSummary']>
    ) {
      state.showFullSummary = action.payload
    },
    setMSKFormShowStepTwoFullSummaryAction(
      state,
      action: PayloadAction<MSKFormState['showStepTwoFullSummary']>
    ) {
      state.showStepTwoFullSummary = action.payload
    },
    setMSKFormShowStepThreeFullSummaryAction(
      state,
      action: PayloadAction<MSKFormState['showStepThreeFullSummary']>
    ) {
      state.showStepThreeFullSummary = action.payload
    },
    setMSKFormShowHardstopWarningAction(
      state,
      action: PayloadAction<MSKFormState['showHardstopWarning']>
    ) {
      state.showHardstopWarning = action.payload
    },
    setMSKFormCurrentIntakeAssessmentIdxAction(
      state,
      action: PayloadAction<MSKFormState['currentIntakeAssessmentIdx']>
    ) {
      state.currentIntakeAssessmentIdx = action.payload
    },
    setMSKFormNextIntakeAssessmentIdxAction(state) {
      state.currentIntakeAssessmentIdx = state.currentIntakeAssessmentIdx + 1
    },
    setMSKFormPreviousIntakeAssessmentIdxAction(state) {
      state.currentIntakeAssessmentIdx = state.currentIntakeAssessmentIdx - 1
    },
    setMSKFormCurrentPathQuestionAction(
      state,
      action: PayloadAction<MSKFormState['currentPathQuestion']>
    ) {
      state.currentPathQuestion = action.payload
    },
    setMSKFormCurrentPathQuestionAnswerAction(
      state,
      action: PayloadAction<MSKFormState['currentPathQuestionAnswer']>
    ) {
      state.currentPathQuestionAnswer = action.payload
    },
    setMSKFormPathQuestionsNextAction(
      state,
      action: PayloadAction<MSKFormState['pathQuestionsNext']>
    ) {
      state.pathQuestionsNext = action.payload
    },
    setMSKFormIsCurrentPathQuestionValidatedAction(
      state,
      action: PayloadAction<MSKFormState['isCurrentPathQuestionValidated']>
    ) {
      state.isCurrentPathQuestionValidated = action.payload
    },
    setMSKFormAdditionalQuestionsAction(
      state,
      action: PayloadAction<Partial<MSKFormState['additionalQuestions']>>
    ) {
      state.additionalQuestions = {
        ...state.additionalQuestions,
        ...action.payload,
      }

      state.additionalQuestions.phScore = getPhScore(
        state.additionalQuestions.phQ1,
        state.additionalQuestions.phQ2
      )
      state.additionalQuestions.phAssessment = getPhAssessment(
        state.additionalQuestions.phScore
      )

      state.additionalQuestions.gad2Score = getGad2Score(
        state.additionalQuestions.gad2Q1,
        state.additionalQuestions.gad2Q2
      )
      state.additionalQuestions.gad2Assessment = getGad2Assessment(
        state.additionalQuestions.gad2Score
      )

      state.additionalQuestions.bhAction1 = getBhAction1(
        state.additionalQuestions.phAssessment,
        state.additionalQuestions.gad2Assessment
      )

      state.additionalQuestions.fallScreenResult = getFallScreenResult(
        state.additionalQuestions.fallScreenQ1,
        state.additionalQuestions.fallScreenQ2
      )
    },
  },
})

export const {
  addMSKFormIntakeAssessmentAction,
  addNewMSKFormErrorsAction,
  addOpenToTryActionAction,
  addPreviouslyTriedActionAction,
  deleteMSKFormErrorsAction,
  deleteMSKFormIntakeAssessmentAction,
  deleteMSKFormIntakeAssessmentAnswerAction,
  deleteOpenToTryActionAction,
  deletePreviouslyTriedActionAction,
  initMSKFormAction,
  resetMSKFormAction,
  setBodyRegionToNoteAction,
  setCurrentFunctionLevelAction,
  setCurrentPainLevelAction,
  setCurrentlyUsingOpioidsAction,
  setMSKFormAdditionalQuestionsAction,
  setMSKFormCurrentConditionAction,
  setMSKFormCurrentIntakeAssessmentIdxAction,
  setMSKFormCurrentPathQuestionAction,
  setMSKFormCurrentPathQuestionAnswerAction,
  setMSKFormErrorsAction,
  setMSKFormIntakeAssessmentAction,
  setMSKFormIntakeAssessmentAnswerAction,
  setMSKFormIntakeAssessmentConfirmedAction,
  setMSKFormIntakeAssessmentHardstopAction,
  setMSKFormIntakeAssessmentRecommendedActionAction,
  setMSKFormIsCurrentPathQuestionValidatedAction,
  setMSKFormModalTitleAction,
  setMSKFormNextIntakeAssessmentIdxAction,
  setMSKFormPageAction,
  setMSKFormPathQuestionsNextAction,
  setMSKFormPatientIdAction,
  setMSKFormPreviousIntakeAssessmentIdxAction,
  setMSKFormProfileHeightFtAction,
  setMSKFormProfileHeightInAction,
  setMSKFormProfileWeightAction,
  setMSKFormSelectedBotheredBodyPartsAction,
  setMSKFormShowFullSummaryAction,
  setMSKFormShowHardstopWarningAction,
  setMSKFormShowStepThreeFullSummaryAction,
  setMSKFormShowStepTwoFullSummaryAction,
  setMSKFormStepAction,
  setOtherPreviouslyTriedActionTextAction,
} = mskFormSlice.actions

export default mskFormSlice.reducer
