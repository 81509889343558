import axios from 'axios'

import { PatientsUseCases } from 'src/features/patients/domain'
import {
  usePatientsService,
  useSearchPatientsService,
  useSearchDuplicatePatientsService,
} from 'src/features/patients/infrastructure'
import {
  mapToGetPatientReturns,
  mapToSearchPatientsServiceParams,
  mapToSearchPatientsReturns,
  mapToUpdatePatientServiceArgs,
  mapToUpdatePatientReturns,
  mapToUpdatePatientError,
  mapArgsToCreatePatientServicePayload,
  mapToCreatePatientReturns,
  mapToCreatePatientError,
  mapToGetPatientStatusDefinitionsReturns,
  mapToUpdatePatientStatusServiceArgs,
  mapToUpdatePatientStatusReturns,
  mapToUpdatePatientStatusError,
  mapToGetPatientPainAndFunctionReturns,
  mapToGetPatientPainAndFunctionError,
  mapToGetPatientError,
  mapToSearchPatientsError,
  mapToGetPatientStatusDefinitionsError,
  mapArgsToCreatePatientManuallyServicePayload,
  mapToCreatePatientManuallyError,
  mapToCreatePatientManuallyReturns,
  mapToSearchDuplicatePatientsServiceParams,
  mapToSearchDuplicatePatientsReturns,
  mapToSearchDuplicatePatientsError,
} from 'src/features/patients/adapters'
import { useAxiosErrorHandling } from 'src/features/shared/infrastructure'

export const usePatientsServiceAdapter = () => {
  const patientsService = usePatientsService()
  const searchPatientsService = useSearchPatientsService()
  const searchDuplicatePatientsService = useSearchDuplicatePatientsService()
  const { handleAxiosError } = useAxiosErrorHandling()

  const getPatient: PatientsUseCases['getPatient'] = async (patientId) => {
    try {
      const response = await patientsService.getPatient(patientId)

      return mapToGetPatientReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToGetPatientError)
      }
      throw error
    }
  }

  const getPatientPainAndFunction: PatientsUseCases['getPatientPainAndFunction'] =
    async (patientId) => {
      try {
        const response = await patientsService.getPatientPainAndFunction(
          patientId
        )

        return mapToGetPatientPainAndFunctionReturns(response.data)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          handleAxiosError(error, mapToGetPatientPainAndFunctionError)
        }
        throw error
      }
    }

  const updatePatient: PatientsUseCases['updatePatient'] = async (args) => {
    try {
      const serviceArgs = mapToUpdatePatientServiceArgs(args)

      const response = await patientsService.updatePatient(serviceArgs)

      return mapToUpdatePatientReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToUpdatePatientError)
      }
      throw error
    }
  }

  const updatePatientStatus: PatientsUseCases['updatePatientStatus'] = async (
    args
  ) => {
    try {
      const serviceArgs = mapToUpdatePatientStatusServiceArgs(args)

      const response = await patientsService.updatePatientStatus(serviceArgs)

      return mapToUpdatePatientStatusReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToUpdatePatientStatusError)
      }
      throw error
    }
  }

  const createPatient: PatientsUseCases['createPatient'] = async (args) => {
    try {
      const servicePayload = mapArgsToCreatePatientServicePayload(args)

      const response = await patientsService.createPatient(servicePayload)

      return mapToCreatePatientReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToCreatePatientError)
      }
      throw error
    }
  }

  const createPatientManually: PatientsUseCases['createPatientManually'] =
    async (args) => {
      try {
        const servicePayload =
          mapArgsToCreatePatientManuallyServicePayload(args)

        const response = await patientsService.createPatientManually(
          servicePayload
        )

        return mapToCreatePatientManuallyReturns(response.data)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          handleAxiosError(error, mapToCreatePatientManuallyError)
        }
        throw error
      }
    }

  const searchPatients: PatientsUseCases['searchPatients'] = async (params) => {
    try {
      const serviceParams = mapToSearchPatientsServiceParams(params)

      const response = await searchPatientsService.SearchPatients(serviceParams)

      return mapToSearchPatientsReturns(response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleAxiosError(error, mapToSearchPatientsError)
      }
      throw error
    }
  }
  const searchDuplicatePatients: PatientsUseCases['searchDuplicatePatients'] =
    async (params) => {
      try {
        const searchDuplicatePatientsParams =
          mapToSearchDuplicatePatientsServiceParams(params)

        const response =
          await searchDuplicatePatientsService.duplicateUserSearch(
            searchDuplicatePatientsParams
          )

        return mapToSearchDuplicatePatientsReturns(response.data)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          handleAxiosError(error, mapToSearchDuplicatePatientsError)
        }
        throw error
      }
    }

  const getPatientStatusDefinitions: PatientsUseCases['getPatientStatusDefinitions'] =
    async () => {
      try {
        const response = await patientsService.getPatientStatusDefinitions()

        return mapToGetPatientStatusDefinitionsReturns(response.data)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          handleAxiosError(error, mapToGetPatientStatusDefinitionsError)
        }
        throw error
      }
    }

  return {
    getPatient,
    updatePatient,
    updatePatientStatus,
    searchPatients,
    searchDuplicatePatients,
    createPatient,
    createPatientManually,
    getPatientStatusDefinitions,
    getPatientPainAndFunction,
  }
}
