import { Box, Divider, Stack, Typography } from '@mui/material'
import { FieldPath, FieldValues } from 'react-hook-form'
import { filterNoneValueOptions } from 'src/features/notes/adapters'
import {
  AdditionalQuestionsFormValues,
  CustomSelectField,
  CustomTextField,
  levelScores,
  yesNo,
} from 'src/features/shared/presentation'

export type PreOpTouchpointQuestionsFormValues = Pick<
  AdditionalQuestionsFormValues,
  | 'scheduledFollowUp'
  | 'additionalQuestionsOrConcerns'
  | 'questionsAboutSurgery'
  | 'sdmPatientUnderstandsCondition'
  | 'sdmClinicalBelievesPatientUnderstandsCondition'
  | 'procedure'
  | 'sdmText'
>

export const PreOpTouchpointAdditionalQuestionsForm = <
  T extends FieldValues
>() => {
  const painLevel = 'painLevel' as FieldPath<T>
  const functionLevel = 'functionLevel' as FieldPath<T>

  const followscheduledFollowUppVisit = 'scheduledFollowUp' as FieldPath<T>
  const questionsAboutSurgery = 'questionsAboutSurgery' as FieldPath<T>
  const additionalQuestionsOrConcerns =
    'additionalQuestionsOrConcerns' as FieldPath<T>

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          width: 170,
          whiteSpace: 'nowrap',
          flexDirection: 'column',
        }}
      >
        <Divider />
        <Typography pb={2} color={'grey'}>
          Assessment Questions
        </Typography>
      </Box>
      <Stack direction={'column'} rowGap={1}>
        <CustomSelectField
          name={painLevel}
          id="pain-level"
          label={'On a scale of 0 to 10, what is your current pain level?'}
          items={filterNoneValueOptions(levelScores)}
          defaultItem="Add Score"
          selectStyle={{ width: 'max-width' }}
          labelStyle={{ fontWeight: 'bold', pb: 1 }}
        />
        <CustomSelectField
          name={functionLevel}
          id="function-level"
          label={
            'On a scale of 0 to 10, how would you rate your ability to do your normal daily activities, where 0 is completely unable, and 10 is able to perform as normal?'
          }
          items={filterNoneValueOptions(levelScores)}
          defaultItem="Add Score"
          selectStyle={{ width: 'max-width' }}
          labelStyle={{
            fontWeight: 'bold',
            pb: 1,
            whiteSpace: 'pre-wrap',
            width: '90%',
            height: 'min-height',
          }}
        />

        <CustomSelectField
          name={followscheduledFollowUppVisit}
          id="scheduled-follow-up"
          label="Do you have a follow-up visit scheduled with your surgeon after your surgery?"
          items={filterNoneValueOptions(yesNo)}
          labelStyle={{ fontWeight: 'bold', pb: 1 }}
          selectStyle={{ width: '88px' }}
        />

        <CustomTextField
          name={questionsAboutSurgery}
          id="questions-about-surgery"
          label={
            'Do you have any questions about what to expect before and after surgery?'
          }
          labelStyle={{ fontWeight: 'bold', pb: 1 }}
          inputStyle={{ width: '100%' }}
        />

        <CustomTextField
          name={additionalQuestionsOrConcerns}
          id="additional-questions-or-concerns"
          label={
            'What additional questions or concerns do you have about your condition?'
          }
          labelStyle={{ fontWeight: 'bold', pb: 1 }}
          inputStyle={{ width: '100%' }}
        />
      </Stack>
    </>
  )
}
