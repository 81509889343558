import axios from 'axios'
import { useCallback } from 'react'

import { useAuth } from 'src/features/shared/infrastructure'
import * as settings from 'src/config/settings'

export const useCompassApiClient = () => {
  const { getTokens } = useAuth()

  const getCompassApiClient = useCallback(async () => {
    const { idToken } = await getTokens()
    return axios.create({
      headers: {
        'x-api-key': settings.COMPASS_API_KEY,
        Authorization: `Bearer ${idToken}`,
      },
    })
  }, [getTokens])

  return {
    getCompassApiClient,
  }
}
