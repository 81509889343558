import {
  useMSKFormServiceAdapter,
  usePatientIntakeFlowsServiceAdapter,
} from 'src/features/msk/adapters'
import { UseMSKUseCases } from 'src/features/msk/domain'

type MakeUseMSKUseCases = () => UseMSKUseCases

const makeUseMSKUseCases: MakeUseMSKUseCases = () => () => {
  const mskFormServiceAdapter = useMSKFormServiceAdapter()
  const patientIntakeFlowsServiceAdapter = usePatientIntakeFlowsServiceAdapter()

  return {
    loadMSKForm: mskFormServiceAdapter.loadMSKForm,
    processMSKForm: mskFormServiceAdapter.processMSKForm,
    getPatientIntakeFlows:
      patientIntakeFlowsServiceAdapter.getPatientIntakeFlows,
    updateIntake: patientIntakeFlowsServiceAdapter.updateIntake,
    createEnablingTherapy:
      patientIntakeFlowsServiceAdapter.createEnablingTherapy,
  }
}

export const useMSKUseCases: UseMSKUseCases = makeUseMSKUseCases()
