import { Box, Checkbox, FormControlLabel, FormHelperText } from '@mui/material'
import { ErrorMessage } from '@hookform/error-message'
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form'
import ReactQuill from 'react-quill'

import { reactQuillDefaultModules } from 'src/config'
import { cleanReactQuillText } from 'src/features/shared/utils'

export const SDMField = <T extends FieldValues>() => {
  const name = 'sdm' as FieldPath<T>
  const sdmTextName = 'sdmText' as FieldPath<T>
  const { register } = useFormContext()

  return (
    <Controller
      name={name}
      {...register}
      render={({ field }) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            width: '100%',
          }}
        >
          <FormControlLabel
            componentsProps={{
              typography: {
                variant: 'body1',
              },
            }}
            control={
              <Checkbox {...field} color={'primary'} checked={field.value} />
            }
            label={'SDM'}
          />
          {!!field.value && (
            <Box width={'100%'}>
              <Controller
                name={sdmTextName}
                {...register}
                render={({ field }) => (
                  <ReactQuill
                    theme="snow"
                    modules={reactQuillDefaultModules}
                    value={field.value}
                    onBlur={field.onBlur}
                    onChange={(value) => {
                      const cleanValue = cleanReactQuillText(value)
                      field.onChange(cleanValue)
                    }}
                  />
                )}
              />
              <ErrorMessage
                name={sdmTextName}
                render={({ message }) => (
                  <FormHelperText error={true}>{message}</FormHelperText>
                )}
              />
            </Box>
          )}
        </Box>
      )}
    />
  )
}
