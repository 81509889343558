import { ErrorMessage } from '@hookform/error-message'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material'
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form'
import { scheduledTypes } from 'src/features/shared/presentation'

export const ScheduledField = <T extends FieldValues>() => {
  const name = 'scheduled' as FieldPath<T>
  const { register } = useFormContext()

  return (
    <Controller
      name={name}
      {...register}
      render={({ field }) => (
        <FormControl size="small" sx={{ width: '100%' }}>
          <InputLabel id="scheduled-label">Scheduled</InputLabel>
          <Select
            labelId="scheduled-label"
            id="scheduled"
            label="Scheduled"
            value={field.value}
            onChange={(event) => {
              field.onChange(event.target.value)
            }}
          >
            {scheduledTypes.map((value) => (
              <MenuItem key={value.id} value={value.label}>
                {value.label}
              </MenuItem>
            ))}
          </Select>
          <ErrorMessage
            name={name}
            render={({ message }) => (
              <FormHelperText sx={{ m: 0 }} error={true}>
                {message}
              </FormHelperText>
            )}
          />
        </FormControl>
      )}
    />
  )
}
