import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form'
import { levelScores } from 'src/features/shared/presentation'

export const PTPainLevelField = <T extends FieldValues>() => {
  const name = 'painDuringPTExercise' as FieldPath<T>
  const { register } = useFormContext()

  return (
    <Controller
      name={name}
      {...register}
      render={({ field }) => (
        <FormControl size={'small'} sx={{ width: '432px' }}>
          <InputLabel id={'pt-pain-level-label'}>
            What is your pain level while performing exercises?
          </InputLabel>
          <Select
            labelId={'pt-pain-level-label'}
            id={'pt-pain-level'}
            label={'What is your pain level while performing exercises?'}
            value={field.value}
            onChange={(event) => {
              field.onChange(event.target.value)
            }}
          >
            <MenuItem value={''}>Add Score</MenuItem>
            {levelScores.map((painLevelOption) => (
              <MenuItem key={painLevelOption.id} value={painLevelOption.label}>
                {painLevelOption.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  )
}
