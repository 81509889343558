import { ErrorMessage } from '@hookform/error-message'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form'
import { filterNoneValueOptions } from 'src/features/notes/adapters'
import { useNotesStore } from 'src/features/shared/infrastructure'
import { missedReasons } from 'src/features/shared/presentation'

export const MissedAppointmentReasonField = <T extends FieldValues>() => {
  const name = 'reasonForMissingAppointment' as FieldPath<T>
  const { register } = useFormContext()

  const { selectedNoteType } = useNotesStore()
  const isChartCheck = selectedNoteType === 'Ortho Chart Check'
  const filteredMissedReasons = isChartCheck
    ? missedReasons
    : filterNoneValueOptions(missedReasons)

  return (
    <Controller
      name={name}
      {...register}
      render={({ field }) => (
        <FormControl size={'small'} sx={{ width: '340px' }}>
          <InputLabel id={'reason-for-missing-appointment-label'}>
            What was the reason for missing?
          </InputLabel>
          <Select
            labelId={'reason-for-missing-appointment-label'}
            id={'reasonForMissingAppointment'}
            label={'What was the reason for missing?'}
            value={field.value}
            onChange={(event) => {
              field.onChange(event.target.value)
            }}
          >
            {filteredMissedReasons.map((value) => (
              <MenuItem key={value.id} value={value.label}>
                {value.label}
              </MenuItem>
            ))}
          </Select>
          <ErrorMessage
            name={name}
            render={({ message }) => (
              <FormHelperText sx={{ m: 0 }} error={true}>
                {message}
              </FormHelperText>
            )}
          />
        </FormControl>
      )}
    />
  )
}
