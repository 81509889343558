import {
  UseMutateAsyncFunction,
  UseMutateFunction,
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import {
  CreatePatientPTTouchpointNoteReturns,
  CreatePatientPTTouchpointNoteArgs,
  CreatePatientPTTouchpointNoteError,
} from 'src/features/notes/domain'
import {
  GET_PATIENT_QUERY_KEY,
  GET_PATIENT_PAIN_AND_FUNCTION_QUERY_KEY,
} from 'src/features/patients/presentation'
import { GET_PATIENT_NOTES_QUERY_KEY } from 'src/features/notes/presentation'
import { useNotesUseCases } from 'src/features/notes/notes.service-locator'

type UseCreatePatientPTTouchpointNoteProps = Omit<
  UseMutationOptions<
    CreatePatientPTTouchpointNoteReturns,
    CreatePatientPTTouchpointNoteError,
    CreatePatientPTTouchpointNoteArgs,
    unknown
  >,
  'mutationFn'
>

type UseCreatePatientPTTouchpointNote = (
  props?: UseCreatePatientPTTouchpointNoteProps
) => {
  createPatientPTTouchpointNote: UseMutateFunction<
    CreatePatientPTTouchpointNoteReturns,
    CreatePatientPTTouchpointNoteError,
    CreatePatientPTTouchpointNoteArgs,
    unknown
  >
  createPatientPTTouchpointNoteAsync: UseMutateAsyncFunction<
    CreatePatientPTTouchpointNoteReturns,
    CreatePatientPTTouchpointNoteError,
    CreatePatientPTTouchpointNoteArgs,
    unknown
  >
  createPatientPTTouchpointNoteIsLoading: boolean
  createPatientPTTouchpointNoteIsError: boolean
  createPatientPTTouchpointNoteStatus: string
}

export const useCreatePatientPTTouchpointNote: UseCreatePatientPTTouchpointNote =
  (props = {}) => {
    const queryClient = useQueryClient()
    const notesUseCases = useNotesUseCases()

    const { onSuccess } = props

    const { mutate, mutateAsync, isLoading, isError, status } = useMutation<
      CreatePatientPTTouchpointNoteReturns,
      CreatePatientPTTouchpointNoteError,
      CreatePatientPTTouchpointNoteArgs,
      unknown
    >({
      mutationFn: (args: CreatePatientPTTouchpointNoteArgs) => {
        return notesUseCases.createPatientPTTouchpointNote(args)
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({
          queryKey: [GET_PATIENT_QUERY_KEY, variables.patientId],
        })
        queryClient.invalidateQueries({
          queryKey: [GET_PATIENT_NOTES_QUERY_KEY, variables.patientId],
        })
        queryClient.invalidateQueries({
          queryKey: [
            GET_PATIENT_PAIN_AND_FUNCTION_QUERY_KEY,
            variables.patientId,
          ],
        })
        onSuccess?.(data, variables, context)
      },
    })
    return {
      createPatientPTTouchpointNote: mutate,
      createPatientPTTouchpointNoteAsync: mutateAsync,
      createPatientPTTouchpointNoteIsLoading: isLoading,
      createPatientPTTouchpointNoteIsError: isError,
      createPatientPTTouchpointNoteStatus: status,
    }
  }
