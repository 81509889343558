import { useInfiniteQuery } from '@tanstack/react-query'

import { useTasksUseCases } from 'src/features/tasks/tasks.service-locator'
import * as settings from 'src/config/settings'

export const GET_UPCOMING_TASKS_BY_USER_ID_INFINITE_QUERY_KEY =
  'getUpcomingTasksByUserIdInfinite'

export const useUpcomingTasksByUserIdInfiniteQuery = () => {
  const tasksUseCases = useTasksUseCases()

  const {
    data,
    refetch,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isError,
    isFetching,
    isFetchingNextPage,
    isRefetching,
  } = useInfiniteQuery({
    queryKey: [GET_UPCOMING_TASKS_BY_USER_ID_INFINITE_QUERY_KEY],
    queryFn: ({ pageParam = undefined }) => {
      return tasksUseCases.getUpcomingTasksByUserId({
        params: {
          limit: settings.UPCOMING_TASKS_PER_LOAD,
          lastTaskId: pageParam?.lastTaskId,
          lastTaskDate: pageParam?.lastTaskDate,
        },
      })
    },
    getNextPageParam: (lastPage) => {
      if (!lastPage.lastTaskId || !lastPage.lastTaskDate) return undefined
      return {
        lastTaskId: lastPage.lastTaskId,
        lastTaskDate: lastPage.lastTaskDate,
      }
    },
  })
  return {
    upcomingTasksData: data,
    refetchUpcomingTasks: refetch,
    fetchNextPageUpcomingTasks: fetchNextPage,
    hasNextPageUpcomingTasks: hasNextPage,
    upcomingTasksIsLoading: isLoading,
    upcomingTasksIsError: isError,
    upcomingTasksIsFetching: isFetching,
    upcomingTasksIsFetchingNextPage: isFetchingNextPage,
    upcomingTasksIsRefetching: isRefetching,
  }
}
