import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'
import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import {
  FormLayoutWithCancelSave,
  FormControlledNoteField,
  ReferralSourceField,
  SpokeWithField,
  StatusField,
} from 'src/features/shared/presentation/components/form-fields'
import { getPatientHasStage } from 'src/features/shared/utils'
import { useStatusDefinitions } from 'src/features/shared/presentation'
import {
  NoteFormProps,
  useCreatePatientOutreachNote,
} from 'src/features/notes/presentation'
import { mapToCreatePatientOutreachNoteArgs } from 'src/features/notes/adapters'
import { useNotesStore } from 'src/features/shared/infrastructure'
import { useUpdatePatientStatus } from 'src/features/patients/presentation'
import { DevTool } from '@hookform/devtools'
import { mapToPatientUpdateStatus } from 'src/features/patients/adapters'

export type OutreachNoteFormFields = {
  note: string
  status: string
  referralSource?: string
  communication: string
  spokeWith?: string
}

type OutreachNoteFormProps = NoteFormProps

export const OutreachNoteForm = ({
  patient,
  setShowLoader,
}: OutreachNoteFormProps) => {
  const patientHasStage = getPatientHasStage(patient)

  const { getAllStatusDefinitions } = useStatusDefinitions(patient)
  const { createPatientOutreachNoteAsync } = useCreatePatientOutreachNote()
  const { updatePatientStatusAsync } = useUpdatePatientStatus()
  const { noteText } = useNotesStore()

  const createOutreachNoteFormSchema: yup.Schema<OutreachNoteFormFields> = yup
    .object()
    .shape({
      note: yup.string().required('Note is required'),
      status: yup.string().required('Status is required'),
      referralSource: yup.string().required('ReferralSource is required'),
      spokeWith: yup.string().required('This field is required'),
      communication: yup.string().required(),
    })

  const formMethods = useForm<OutreachNoteFormFields>({
    resolver: yupResolver(createOutreachNoteFormSchema),
    context: { patientHasStage },
    defaultValues: {
      note: noteText,
      status: '',
      referralSource: patient.referralSource || '',
      spokeWith: '',
      communication: 'outbound',
    },
  })
  const watchReferralSource = formMethods.watch('referralSource')

  const submitHandler: SubmitHandler<OutreachNoteFormFields> = async (data) => {
    setShowLoader?.(true)

    try {
      const createPatientOutreachNoteArgs = mapToCreatePatientOutreachNoteArgs(
        patient,
        data
      )

      if (
        patient.stageAndStatus &&
        patient.stageAndStatus.stage &&
        data.status
      ) {
        const createPatientUpdateStatusArgs = mapToPatientUpdateStatus(
          patient,
          data.status
        )
        await updatePatientStatusAsync(createPatientUpdateStatusArgs)

        await createPatientOutreachNoteAsync(createPatientOutreachNoteArgs)

        toast.success('Patient Note successfully created!')
        toast.success('Patient Status successfully updated!')
      } else {
        await createPatientOutreachNoteAsync(createPatientOutreachNoteArgs)
        toast.success('Patient Note successfully created!')
      }
    } catch (error) {
      toast.error('Failed creating patient note!')
      toast.error('Patient Status update failed!')
    }

    setShowLoader?.(false)
  }

  return (
    <FormLayoutWithCancelSave
      onSubmit={submitHandler}
      formMethods={formMethods}
    >
      <Grid container rowSpacing={2}>
        <Grid item sm={12} pb={2}>
          <FormControlledNoteField />
        </Grid>
        <Grid item container columnSpacing={2} sm={12}>
          <Grid item sm={4}>
            <StatusField
              statusDefinitions={getAllStatusDefinitions(watchReferralSource)}
            />
          </Grid>
        </Grid>
        <Grid item container columnGap={2} sm={12}>
          <Grid item sm={2}>
            <SpokeWithField />
          </Grid>
          <Grid item sm={3}>
            <ReferralSourceField />
          </Grid>
        </Grid>
      </Grid>
      <DevTool control={formMethods.control} placement={'top-left'} />
    </FormLayoutWithCancelSave>
  )
}
