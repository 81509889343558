import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, Stack } from '@mui/material'
import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import {
  FormLayoutWithCancelSave,
  FormControlledNoteField,
  SDMField,
  SpokeWithField,
  StatusField,
  SDMCompletedField,
  SDMPatientUnderstandsField,
} from 'src/features/shared/presentation/components/form-fields'
import { getPatientHasStage } from 'src/features/shared/utils'
import { useStatusDefinitions } from 'src/features/shared/presentation'
import {
  NoteFormProps,
  useCreatePatientPTTouchpointNote,
} from 'src/features/notes/presentation'
import {
  PTTouchpointAdditionalQuestionsForm,
  PTTouchpointQuestionsFormValues,
} from 'src/features/notes/presentation/components/additional-questions'
import { mapToCreatePatientPTTouchpointNoteArgs } from 'src/features/notes/adapters'
import { useUpdatePatientStatus } from 'src/features/patients/presentation'
import { useEffect } from 'react'
import { useNotesStore } from 'src/features/shared/infrastructure'
import { DevTool } from '@hookform/devtools'
import { mapToPatientUpdateStatus } from 'src/features/patients/adapters'

export type PTTouchpointNoteFormFields = PTTouchpointQuestionsFormValues & {
  note: string
  status: string
  spokeWith?: string
  painLevel?: string
  functionLevel?: string
  sdm: boolean
  communication: string
}

type PTTouchpointNoteFormProps = NoteFormProps

export const PTTouchpointNoteForm = ({
  patient,
  setShowLoader,
}: PTTouchpointNoteFormProps) => {
  const patientHasStage = getPatientHasStage(patient)

  const { getAllStatusDefinitions } = useStatusDefinitions(patient)
  const { createPatientPTTouchpointNoteAsync } =
    useCreatePatientPTTouchpointNote()
  const { updatePatientStatusAsync } = useUpdatePatientStatus()
  const { noteText } = useNotesStore()

  const createPTTouchpointNoteFormSchema: yup.Schema<PTTouchpointNoteFormFields> =
    yup.object().shape(
      {
        note: yup.string().required('note is required'),
        sdm: yup.boolean().required(),
        spokeWith: yup.string().required('This field is required'),
        painLevel: yup.string().when('functionLevel', {
          is: (functionLevel: string) => functionLevel,
          then: (schema) => schema.required('Pain is required'),
        }),
        functionLevel: yup.string().when('painLevel', {
          is: (painLevel: string) => painLevel,
          then: (schema) => schema.required('Function is required'),
        }),
        communication: yup.string().required(),
        status: yup.string().required('This field is required'),
        completedAppointment: yup.string(),
        reasonForMissingAppointment: yup.string().when('completedAppointment', {
          is: (completed: string) => completed === 'No',
          then: (schema) => schema.required('This field is required'),
        }),
        detailsOfMissedAppointment: yup.string().when('completedAppointment', {
          is: (completed: string) => completed === 'No',
          then: (schema) => schema.required('This field is required'),
        }),
        appointmentRescheduled: yup.string().when('completedAppointment', {
          is: (completed: string) => completed === 'No',
          then: (schema) => schema.required('This field is required'),
        }),
        completingPhysicalTherapyExercises: yup.string(),
        reasonForNotCompletingPTExercises: yup
          .string()
          .when('completingPhysicalTherapyExercises', {
            is: (completed: string) => completed === 'No',
            then: (schema) => schema.required('This field is required'),
          }),
        painDuringPTExercise: yup
          .string()
          .when('reasonForNotCompletingPTExercises', {
            is: (reason: string) => reason === 'Pain',
            then: (schema) => schema.required('This field is required'),
          }),
        additionalQuestionsOrConcerns: yup.string(),
        additionalConditionPain: yup.string(),
        additionalConditionFunction: yup.string(),
        sdmText: yup.string().when('sdm', {
          is: (checked: string) => !!checked,
          then: (schema) => schema.required('This field is required'),
        }),
        sdmPatientUnderstandsCondition: yup.string().when('sdm', {
          is: (checked: string) => !!checked,
          then: (schema) => schema.required('This field is required'),
        }),
        sdmClinicalBelievesPatientUnderstandsCondition: yup
          .string()
          .when('sdm', {
            is: (checked: string) => !!checked,
            then: (schema) => schema.required('This field is required'),
          }),
      },
      [['painLevel', 'functionLevel']]
    )

  const formMethods = useForm<PTTouchpointNoteFormFields>({
    resolver: yupResolver(createPTTouchpointNoteFormSchema),
    context: { patientHasStage },
    defaultValues: {
      note: noteText,
      sdm: false,
      spokeWith: '',
      painLevel: '',
      functionLevel: '',
      communication: 'outbound',
      status: '',
      additionalQuestionsOrConcerns: '',
      completedAppointment: '',
      reasonForMissingAppointment: '',
      detailsOfMissedAppointment: '',
      appointmentRescheduled: '',
      completingPhysicalTherapyExercises: '',
      reasonForNotCompletingPTExercises: '',
      painDuringPTExercise: '',
      sdmText: '',
      additionalConditionPain: '',
      additionalConditionFunction: '',
      sdmPatientUnderstandsCondition: '',
      sdmClinicalBelievesPatientUnderstandsCondition: '',
    },
  })

  useEffect(() => {
    const subscription = formMethods.watch((value, { name, type }) => {
      if (
        name === 'completingPhysicalTherapyExercises' &&
        type === 'change' &&
        value['reasonForNotCompletingPTExercises']
      ) {
        formMethods.reset((formValues) => ({
          ...formValues,
          reasonForNotCompletingPTExercises: '',
        }))
      }

      if (
        name === 'completedAppointment' &&
        type === 'change' &&
        (value['reasonForMissingAppointment'] ||
          value['detailsOfMissedAppointment'] ||
          value['appointmentRescheduled'])
      ) {
        formMethods.reset((formValues) => ({
          ...formValues,
          reasonForMissingAppointment: '',
          detailsOfMissedAppointment: '',
          appointmentRescheduled: '',
        }))
      }

      if (
        name === 'sdm' &&
        type === 'change' &&
        (value['sdmPatientUnderstandsCondition'] ||
          value['sdmClinicalBelievesPatientUnderstandsCondition'])
      ) {
        formMethods.reset((formValues) => ({
          ...formValues,
          sdmPatientUnderstandsCondition: '',
          sdmClinicalBelievesPatientUnderstandsCondition: '',
        }))
      }
    })

    return () => subscription.unsubscribe()
  }, [formMethods])

  const submitHandler: SubmitHandler<PTTouchpointNoteFormFields> = async (
    data
  ) => {
    setShowLoader?.(true)

    try {
      const createPatientPTTouchpointNoteArgs =
        mapToCreatePatientPTTouchpointNoteArgs(patient, data)

      if (
        patient.stageAndStatus &&
        patient.stageAndStatus.stage &&
        data.status
      ) {
        const createPatientUpdateStatusArgs = mapToPatientUpdateStatus(
          patient,
          data.status
        )
        await updatePatientStatusAsync(createPatientUpdateStatusArgs)
        await createPatientPTTouchpointNoteAsync(
          createPatientPTTouchpointNoteArgs
        )

        toast.success('Patient Note successfully created!')
        toast.success('Patient Status successfully updated!')
      } else {
        await createPatientPTTouchpointNoteAsync(
          createPatientPTTouchpointNoteArgs
        )
        toast.success('Patient Note successfully created!')
      }
    } catch (error) {
      toast.error('Failed creating patient note!')
      toast.error('Patient Status update failed!')
    }

    setShowLoader?.(false)
  }

  return (
    <FormLayoutWithCancelSave
      onSubmit={submitHandler}
      formMethods={formMethods}
    >
      <Grid container rowSpacing={2} pb={2}>
        <Grid item sm={12} pb={2}>
          <FormControlledNoteField />
        </Grid>
        <Grid item container columnSpacing={2} sm={12}>
          <Grid item sm={3}>
            <StatusField statusDefinitions={getAllStatusDefinitions()} />
          </Grid>
          <Grid item sm={2}>
            <SpokeWithField />
          </Grid>
        </Grid>
        <Grid item container columnGap={2} sm={10}>
          <Box pt={1} width="100%">
            <SDMField />
          </Box>
          {formMethods.watch('sdm') ? (
            <Stack direction={'column'} rowGap={1} ml={10} py={2}>
              <SDMCompletedField />
              <SDMPatientUnderstandsField />
            </Stack>
          ) : null}
        </Grid>
      </Grid>

      <PTTouchpointAdditionalQuestionsForm />
      <DevTool control={formMethods.control} placement={'top-left'} />
    </FormLayoutWithCancelSave>
  )
}
