import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form'
import { missedHomePTReasons } from 'src/features/shared/presentation'
import { filterNoneValueOptions } from 'src/features/notes/adapters'

export const MissedPTReasonField = <T extends FieldValues>() => {
  const name = 'reasonForNotCompletingPTExercises' as FieldPath<T>
  const { register } = useFormContext()

  return (
    <Controller
      name={name}
      {...register}
      render={({ field }) => (
        <FormControl size={'small'} sx={{ width: '340px' }}>
          <InputLabel id={'reason-for-not-completing-pt-exercises-label'}>
            If No, why not?
          </InputLabel>
          <Select
            labelId={'reason-for-not-completing-pt-exercises-label'}
            id={'reasonForNotCompletingPTExercises'}
            label={'If No, why not?'}
            value={field.value}
            onChange={(event) => {
              field.onChange(event.target.value)
            }}
          >
            {filterNoneValueOptions(missedHomePTReasons).map((value) => (
              <MenuItem key={value.id} value={value.label}>
                {value.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  )
}
