import { FormControl, TextField } from '@mui/material'
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form'

export const ProviderNameField = <T extends FieldValues>() => {
  const name = 'providerName' as FieldPath<T>
  const { register } = useFormContext()

  return (
    <Controller
      name={name}
      {...register}
      render={({ field }) => (
        <FormControl sx={{ width: '100%' }}>
          <TextField
            size={'small'}
            id={'provider-name'}
            label={'Provider Name'}
            value={field.value}
            onChange={(event) => {
              field.onChange(event.target.value)
            }}
          />
        </FormControl>
      )}
    />
  )
}
