import styled from '@emotion/styled'
import {
  Checkbox,
  Accordion,
  AccordionSummary,
  Stack,
  Typography,
  AccordionDetails,
  Box,
  Chip,
  IconButton,
  Slide,
} from '@mui/material'
import { ChangeEvent, FC, MouseEventHandler, useState } from 'react'
import { toast } from 'react-toastify'

import EditIcon from '@mui/icons-material/Edit'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { Task } from 'src/features/tasks/domain'
import { useTasksSidebarStore } from 'src/features/shared/infrastructure'
import { LinkChip, DangerChip } from 'src/features/shared/presentation'
import { useUpdateTask, TaskComments } from 'src/features/tasks/presentation'

const TaskCheckbox = styled(Checkbox)(() => ({
  padding: '0px',
}))

type TaskComponentProps = {
  task: Task
  edit?: boolean
}

export const TaskComponent: FC<TaskComponentProps> = ({
  task,
  edit = true,
}) => {
  const { updateTask } = useUpdateTask()
  const [show, setShow] = useState(true)
  const [completedIsChecked, setCompletedIsChecked] = useState(true)
  const [pendingIsChecked, setPendingIsChecked] = useState(false)
  const { setSelectedTaskForEdit, setOpenEditTaskFormModal } =
    useTasksSidebarStore()

  const handleChecboxChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    updateTask(
      { taskId: task.id, payload: { status: checked ? 'complete' : 'new' } },
      {
        onSuccess: () => {
          if (checked) {
            toast.success(`Task ${task.title} marked as done!`)
          } else {
            toast.success(`Task ${task.title} marked as pending!`)
          }
          setShow(false)
        },
        onError: () => {
          if (checked) {
            toast.error(`Error marking task ${task.title} as done!`)
            setPendingIsChecked(false)
          } else {
            toast.error(`Error marking task ${task.title} as pending!`)
            setCompletedIsChecked(true)
          }
        },
      }
    )
  }

  const handleCheckboxClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation()
    if (task.status === 'complete') {
      setCompletedIsChecked(!completedIsChecked)
    } else {
      setPendingIsChecked(!pendingIsChecked)
    }
  }

  const handleEditTaskClick: MouseEventHandler<HTMLButtonElement> = () => {
    setSelectedTaskForEdit(task)
    setOpenEditTaskFormModal(true)
  }

  return (
    <Slide
      direction={`${task.status === 'complete' ? 'right' : 'left'}`}
      in={show}
      timeout={500}
      appear={false}
      unmountOnExit
    >
      <Accordion
        disableGutters
        sx={{
          boxShadow: 'none',
          borderBottom: '1px solid #EFEFF0',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${task.id}-content`}
          id={`${task.id}-header`}
          sx={{ display: 'flex', alignItems: 'center', padding: '0px 11px' }}
        >
          <Stack direction={'row'} gap={'8px'}>
            <TaskCheckbox
              checked={
                task.status === 'complete'
                  ? completedIsChecked
                  : pendingIsChecked
              }
              onChange={handleChecboxChange}
              onClick={handleCheckboxClick}
            />
            <Typography>{task.title}</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '8px 43px 16px' }}>
          <Stack gap={'32px'}>
            <Box>
              <Typography color="rgba(0, 0, 0, 0.6)">
                {task.description}
              </Typography>
            </Box>
            <TaskComments task={task} />
            <Stack
              direction={'column'}
              gap={'8px'}
              justifyContent={'flex-start'}
            >
              <Stack direction={'row'} gap={'8px'}>
                <LinkChip
                  linkProps={{ to: `/patients/${task.patientId}` }}
                  chipProps={{
                    label: task.patientName,
                    variant: 'outlined',
                    clickable: true,
                  }}
                />
                <Chip label={task.dueDate} variant="outlined" />
              </Stack>
              <Stack direction={'row'} gap={'8px'}>
                <Chip label={task.assignedTCMemberName} variant="outlined" />
                {task.urgent ? <DangerChip label="Urgent" /> : null}
              </Stack>
            </Stack>
          </Stack>
          {edit ? (
            <IconButton
              sx={{
                position: 'absolute',
                bottom: '0',
                right: '0',
              }}
              onClick={handleEditTaskClick}
            >
              <EditIcon />
            </IconButton>
          ) : null}
        </AccordionDetails>
      </Accordion>
    </Slide>
  )
}
