import {
  FC,
  MouseEventHandler,
  ButtonHTMLAttributes,
  ClassAttributes,
} from 'react'
import { styled } from '@mui/material'

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos'

import { useTasksSidebarStore } from 'src/features/shared/infrastructure'

const TasksSidebarToggleButton = styled('button')(({ theme }) => ({
  backgroundColor: theme.palette.magenta['300'],
  border: 'none',
  borderRadius: '50%',
  color: theme.palette.common.white,
  cursor: 'pointer',
  height: 40,
  position: 'absolute',
  width: 40,
  '&:hover': {
    backgroundColor: theme.palette.magenta['400'],
  },
}))

type TasksSidebarToggleProps = JSX.IntrinsicAttributes &
  ClassAttributes<HTMLButtonElement> &
  ButtonHTMLAttributes<HTMLButtonElement>

export const TasksSidebarToggle: FC<TasksSidebarToggleProps> = ({
  onClick,
  ...props
}) => {
  const { open, setOpen } = useTasksSidebarStore()

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    onClick?.(event)
    setOpen(!open)
  }
  return (
    <TasksSidebarToggleButton
      sx={{ top: '50%', left: -30, zIndex: 1 }}
      onClick={handleClick}
      {...props}
    >
      {open ? <ArrowForwardIos /> : <ArrowBackIosIcon />}
    </TasksSidebarToggleButton>
  )
}
