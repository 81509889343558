import { ErrorMessage } from '@hookform/error-message'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form'
import { surgerySites } from 'src/features/shared/presentation'

export const SurgerySiteField = <T extends FieldValues>() => {
  const name = 'surgerySite' as FieldPath<T>
  const { register } = useFormContext()

  return (
    <Controller
      name={name}
      {...register}
      render={({ field }) => (
        <FormControl size={'small'} sx={{ width: '100%' }}>
          <InputLabel id={'surgery-site-label'}>Surgery Site</InputLabel>
          <Select
            labelId={'surgery-site-label'}
            id={'surgery-site'}
            label={'Surgery Type'}
            value={field.value}
            onChange={(event) => {
              field.onChange(event.target.value)
            }}
          >
            {surgerySites.map((site) => (
              <MenuItem key={site.id} value={site.label}>
                {site.label}
              </MenuItem>
            ))}
          </Select>
          <ErrorMessage
            name={name}
            render={({ message }) => (
              <FormHelperText sx={{ m: 0 }} error={true}>
                {message}
              </FormHelperText>
            )}
          />
        </FormControl>
      )}
    />
  )
}
