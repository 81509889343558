import { Box, FormHelperText } from '@mui/material'
import { ErrorMessage } from '@hookform/error-message'
import { useRef } from 'react'
import {
  Controller,
  FieldPath,
  FieldValues,
  useController,
  useFormContext,
} from 'react-hook-form'
import ReactQuill from 'react-quill'
import ReactRouterPrompt from 'react-router-prompt'

import { isInViewport } from 'src/features/shared/utils'
import { WarningModal } from 'src/features/shared/presentation/components/modals'
import { NoteField } from 'src/features/shared/presentation'

type NoteFieldProps<T extends FieldValues> = {
  name?: FieldPath<T>
}

export const FormControlledNoteField = <T extends FieldValues>({
  name = 'note' as FieldPath<T>,
}: NoteFieldProps<T>) => {
  const noteEditorEl = useRef<ReactQuill>(null)
  const noteEditorContainer = useRef<HTMLDivElement>(null)

  const { register } = useFormContext()
  const { fieldState } = useController({ name })

  return (
    <Box ref={noteEditorContainer} width={'100%'}>
      <Controller
        name={name}
        {...register}
        render={({ field }) => (
          <NoteField onChange={field.onChange} onBlur={field.onBlur} />
        )}
      />
      <ErrorMessage
        name={name}
        render={({ message }) => (
          <FormHelperText error={true}>{message}</FormHelperText>
        )}
      />
      <ReactRouterPrompt when={fieldState.isDirty}>
        {({ isActive, onConfirm, onCancel }) => (
          <WarningModal
            title={'Are you sure you want to leave?'}
            message={
              'Leaving this page will result in unsaved notes being lost.'
            }
            open={isActive}
            onConfirm={onConfirm}
            onCancel={() => {
              onCancel()
              setTimeout(() => {
                if (
                  noteEditorEl.current &&
                  !isInViewport(noteEditorContainer?.current)
                ) {
                  noteEditorContainer?.current?.scrollIntoView()
                }
              }, 0)
            }}
            confirmButtonText={'Yes'}
            cancelButtonText={'Cancel'}
          />
        )}
      </ReactRouterPrompt>
    </Box>
  )
}
