import { GetQueuePatientsServiceResponse } from 'src/features/queues/infrastructure'

export const getQueuePatientsServiceResponseMock: GetQueuePatientsServiceResponse =
  {
    patients: [
      {
        attempts: 0,
        name: 'Emmaline Drinkale',
        patientId: 'fd4c4f07-0b62-4417-ac1c-c1a9a7fe0dc6',
        dateOfBirth: '08/21/2026',
        eligible: true,
        busy: false,
        doNotCall: false,
        referralSource: 'UM - Mail',
        lastContact: '2023-05-26T12:49:22.338Z',
        lastToContact: 'David Grandados',
        notes: {
          count: 64,
          lastContact: '2023-05-26T12:49:22.338179+00:00',
          author: 'David Grandados',
        },
        assignedPathwayId: null,
        stage: 'Engaged',
        status: 'On Pathway',
        patientViewersHistory: null,
        count: '224',
      },
      {
        attempts: 4,
        name: 'Marguerite Lortzing',
        patientId: 'fccd8fd5-c51b-44c2-a45e-b113e98af2f5',
        dateOfBirth: '06/18/2048',
        eligible: true,
        busy: false,
        doNotCall: false,
        referralSource: 'UM - Mail',
        lastContact: null,
        lastToContact: null,
        notes: null,
        assignedPathwayId: null,
        stage: 'Pending Contact',
        status: 'Not Attempted',
        patientViewersHistory: null,
        count: '224',
      },
      {
        attempts: 1,
        name: 'Calley Forster',
        patientId: 'fc11ae7d-4721-4ddc-810e-3399984d6454',
        dateOfBirth: '01/02/2035',
        eligible: true,
        busy: false,
        doNotCall: false,
        referralSource: 'UM - Email Marketing',
        lastContact: null,
        lastToContact: null,
        notes: null,
        assignedPathwayId: null,
        stage: 'Pending Contact',
        status: 'Not Attempted',
        patientViewersHistory: null,
        count: '224',
      },
      {
        attempts: 0,
        name: 'Veronica Limb',
        patientId: 'fbe9e140-4825-45b1-8c14-8d96f88d76cc',
        dateOfBirth: '09/23/2045',
        eligible: true,
        busy: false,
        doNotCall: false,
        referralSource: 'UM - Mail',
        lastContact: '2023-04-21T21:02:13.262Z',
        lastToContact: 'Hector Rivera',
        notes: {
          count: 23,
          lastContact: '2023-04-21T21:02:13.262054+00:00',
          author: 'Hector Rivera',
        },
        assignedPathwayId: 'c0dfbc12-6c09-4756-b87b-274ce286fb1d',
        stage: null,
        status: null,
        patientViewersHistory: null,
        count: '224',
      },
      {
        attempts: 0,
        name: 'Jennifer Lipson',
        patientId: 'fb3479bc-b59a-4c45-9cd0-742bcb508820',
        dateOfBirth: '11/09/2043',
        eligible: true,
        busy: false,
        doNotCall: false,
        referralSource: 'PCP - Iora',
        lastContact: null,
        lastToContact: null,
        notes: null,
        assignedPathwayId: '1c9361dc-b0a2-49ea-b502-9c091ccccdbc',
        stage: 'Engaged',
        status: 'On Pathway',
        patientViewersHistory: null,
        count: '224',
      },
      {
        attempts: 0,
        name: 'Cindra Scryne',
        patientId: 'fad81b75-6072-434e-bad0-afd4d514becf',
        dateOfBirth: '12/24/2022',
        eligible: true,
        busy: true,
        doNotCall: false,
        referralSource: 'PCP - Iora',
        lastContact: '2023-06-19T03:21:55.543Z',
        lastToContact: 'Thomas Roberts',
        notes: {
          count: 38,
          lastContact: '2023-06-19T03:21:55.543219+00:00',
          author: 'Thomas Roberts',
        },
        assignedPathwayId: '26284a43-1153-4e7e-935e-2e4ffc7b375a',
        stage: 'Engaged',
        status: 'On Pathway',
        patientViewersHistory: [
          {
            id: 'deaee45e-a1e0-4bf1-bb45-dd3f7f6d6569',
            viewedBy: 'c6ba367d-e893-4ac6-883f-8732d03f9950',
            viewedAt: '2023-06-19T03:21:56.026142+00:00',
            name: 'Thomas Roberts',
            email: 'troberts@tailorcare.com',
          },
        ],
        count: '224',
      },
      {
        attempts: 0,
        name: 'Elianora Kinghorn',
        patientId: 'fa9c4a6b-25dc-4b4a-8317-3c87e98989e6',
        dateOfBirth: '12/19/2024',
        eligible: true,
        busy: false,
        doNotCall: false,
        referralSource: 'PCP - Other',
        lastContact: '2023-06-09T20:05:20.293Z',
        lastToContact: 'Hector Rivera',
        notes: {
          count: 5,
          lastContact: '2023-06-09T20:05:20.293186+00:00',
          author: 'Hector Rivera',
        },
        assignedPathwayId: '30654a50-4bc9-44c0-a1c7-50a37fe582ea',
        stage: 'Engaged',
        status: 'On Pathway',
        patientViewersHistory: null,
        count: '224',
      },
      {
        attempts: 0,
        name: 'Shaine Siemon',
        patientId: 'f73c29d3-baca-47cf-8215-f9e90d7d1ae8',
        dateOfBirth: '05/08/2025',
        eligible: true,
        busy: false,
        doNotCall: false,
        referralSource: 'UM - Mail',
        lastContact: null,
        lastToContact: null,
        notes: null,
        assignedPathwayId: null,
        stage: 'Pending Contact',
        status: 'Not Attempted',
        patientViewersHistory: null,
        count: '224',
      },
      {
        attempts: 0,
        name: 'Tabatha Moorman',
        patientId: 'f6d92ce1-9cce-439c-9d73-a2c16cdf3b5a',
        dateOfBirth: '12/06/2023',
        eligible: true,
        busy: false,
        doNotCall: false,
        referralSource: 'UM - Mail',
        lastContact: null,
        lastToContact: null,
        notes: null,
        assignedPathwayId: 'cc509e58-1805-4a57-a53a-bb92faf1fecc',
        stage: 'Engaged',
        status: 'On Pathway',
        patientViewersHistory: null,
        count: '224',
      },
      {
        attempts: 0,
        name: 'Sullivan Cominello',
        patientId: 'f5eb8703-6fe2-4291-b6cc-8f7cdfc4e762',
        dateOfBirth: '10/07/2038',
        eligible: true,
        busy: false,
        doNotCall: false,
        referralSource: 'UM - Email Marketing',
        lastContact: '2023-03-27T19:17:45.751Z',
        lastToContact: 'Gareth Mohamed',
        notes: {
          count: 1,
          lastContact: '2023-03-27T19:17:45.751256+00:00',
          author: 'Gareth Mohamed',
        },
        assignedPathwayId: null,
        stage: 'Engaged',
        status: 'On Pathway',
        patientViewersHistory: null,
        count: '224',
      },
      {
        attempts: 0,
        name: 'd d',
        patientId: 'f3e31c10-6ca3-4151-86f0-d939c36a97f8',
        dateOfBirth: '03/17/1967',
        eligible: true,
        busy: false,
        doNotCall: false,
        referralSource: 'Plan - Humana',
        lastContact: '2023-04-12T18:08:10.368Z',
        lastToContact: 'Gareth Mohamed',
        notes: {
          count: 3,
          lastContact: '2023-04-12T18:08:10.368751+00:00',
          author: 'Gareth Mohamed',
        },
        assignedPathwayId: null,
        stage: 'Attempted',
        status: 'No Answer',
        patientViewersHistory: null,
        count: '224',
      },
      {
        attempts: 0,
        name: 'Marion Whitters',
        patientId: 'f39fa8cb-7c73-4402-a35a-d907b9f3560e',
        dateOfBirth: '10/07/2036',
        eligible: true,
        busy: false,
        doNotCall: false,
        referralSource: 'Plan - Humana',
        lastContact: '2023-06-16T16:53:14.348Z',
        lastToContact: 'Harrison Kunkel',
        notes: {
          count: 7,
          lastContact: '2023-06-16T16:53:14.34861+00:00',
          author: 'Harrison Kunkel',
        },
        assignedPathwayId: '079fff9c-78e0-4f4e-919a-9e3e6e2933ee',
        stage: 'Engaged',
        status: 'On Pathway',
        patientViewersHistory: null,
        count: '224',
      },
      {
        attempts: 0,
        name: 'Tobiah Seakin',
        patientId: 'f2fc4381-7c69-4f64-a4b5-3d68a6e72b4e',
        dateOfBirth: '01/03/2046',
        eligible: true,
        busy: false,
        doNotCall: false,
        referralSource: 'Plan - Humana',
        lastContact: null,
        lastToContact: null,
        notes: null,
        assignedPathwayId: '8532b9fb-b345-40e7-97a8-22ff00be7240',
        stage: null,
        status: null,
        patientViewersHistory: null,
        count: '224',
      },
      {
        attempts: 0,
        name: 'Kelsy Awin',
        patientId: 'eebffa97-65fa-40ec-848d-f4f808d622fc',
        dateOfBirth: '08/05/2020',
        eligible: true,
        busy: false,
        doNotCall: false,
        referralSource: 'UM - Email Marketing',
        lastContact: null,
        lastToContact: null,
        notes: null,
        assignedPathwayId: null,
        stage: 'Pending Contact',
        status: 'Not Attempted',
        patientViewersHistory: null,
        count: '224',
      },
      {
        attempts: 0,
        name: 'Bobbe Clipston',
        patientId: 'ee8b077e-698b-4087-805b-8eb00b62d74f',
        dateOfBirth: '02/13/2041',
        eligible: true,
        busy: false,
        doNotCall: false,
        referralSource: 'PCP - Other',
        lastContact: '2023-06-15T17:19:27.959Z',
        lastToContact: 'Gareth Mohamed',
        notes: {
          count: 4,
          lastContact: '2023-06-15T17:19:27.959863+00:00',
          author: 'Gareth Mohamed',
        },
        assignedPathwayId: '4efe9be0-89f4-46b3-9296-84785bc65f72',
        stage: 'Engaged',
        status: 'On Pathway',
        patientViewersHistory: null,
        count: '224',
      },
      {
        attempts: 0,
        name: 'Antonino Watmen',
        patientId: 'ec6f16bd-2ccd-4dd9-beb6-a91ad3b3fac9',
        dateOfBirth: '1/7/50',
        eligible: true,
        busy: false,
        doNotCall: false,
        referralSource: 'UM - Mail',
        lastContact: null,
        lastToContact: null,
        notes: null,
        assignedPathwayId: null,
        stage: 'Pending Contact',
        status: 'Not Attempted',
        patientViewersHistory: null,
        count: '224',
      },
      {
        attempts: 0,
        name: 'Edan Sumshon',
        patientId: 'ebd0e553-526d-4726-8c81-dd8ec48e3cb9',
        dateOfBirth: '09/13/2040',
        eligible: true,
        busy: false,
        doNotCall: false,
        referralSource: 'PCP - Iora',
        lastContact: '2023-06-15T17:55:19.040Z',
        lastToContact: 'Gareth Mohamed',
        notes: {
          count: 2,
          lastContact: '2023-06-15T17:55:19.040625+00:00',
          author: 'Gareth Mohamed',
        },
        assignedPathwayId: '02b9fce7-78a3-4fe2-a073-52c4196f4363',
        stage: 'Engaged',
        status: 'On Pathway',
        patientViewersHistory: null,
        count: '224',
      },
      {
        attempts: 0,
        name: 'Elita McChesney',
        patientId: 'e99b3234-9baa-445a-97c9-f0ee65ad64cf',
        dateOfBirth: '12/10/2047',
        eligible: true,
        busy: false,
        doNotCall: false,
        referralSource: 'UM - Mail',
        lastContact: null,
        lastToContact: null,
        notes: null,
        assignedPathwayId: null,
        stage: 'Pending Contact',
        status: 'Not Attempted',
        patientViewersHistory: null,
        count: '224',
      },
      {
        attempts: 1,
        name: 'Mandie Billings',
        patientId: 'e97643f0-87d3-436f-a996-0e1d418fd4fa',
        dateOfBirth: '08/18/2032',
        eligible: true,
        busy: false,
        doNotCall: false,
        referralSource: 'PCP - Iora',
        lastContact: null,
        lastToContact: null,
        notes: null,
        assignedPathwayId: '84c43a87-d00f-4ff0-9126-43816c226629',
        stage: 'Engaged',
        status: 'On Pathway',
        patientViewersHistory: null,
        count: '224',
      },
      {
        attempts: 0,
        name: 'Leoliney Cuthill',
        patientId: 'e7e6fdca-6846-4ee2-826c-b46eaff7de4c',
        dateOfBirth: '04/22/2022',
        eligible: true,
        busy: false,
        doNotCall: false,
        referralSource: 'UM - Mail',
        lastContact: '2023-03-01T19:42:00.423Z',
        lastToContact: 'David Grandados',
        notes: {
          count: 18,
          lastContact: '2023-03-01T19:42:00.423697+00:00',
          author: 'David Grandados',
        },
        assignedPathwayId: null,
        stage: 'Pending Contact',
        status: 'Not Attempted',
        patientViewersHistory: null,
        count: '224',
      },
      {
        attempts: 0,
        name: 'Charlton De Luna',
        patientId: 'e738d661-0c38-4039-8a3b-df2599cfca40',
        dateOfBirth: '07/09/2032',
        eligible: true,
        busy: false,
        doNotCall: false,
        referralSource: 'Plan - Humana',
        lastContact: '2023-03-10T14:23:28.305Z',
        lastToContact: 'Hector Rivera',
        notes: {
          count: 2,
          lastContact: '2023-03-10T14:23:28.305568+00:00',
          author: 'Hector Rivera',
        },
        assignedPathwayId: null,
        stage: 'Pending Contact',
        status: 'Not Attempted',
        patientViewersHistory: null,
        count: '224',
      },
      {
        attempts: 2,
        name: 'Byrann Mayer',
        patientId: 'e4057186-7936-4e11-9078-2c5392a7f9df',
        dateOfBirth: '01/19/2039',
        eligible: true,
        busy: false,
        doNotCall: false,
        referralSource: 'PCP - Other',
        lastContact: null,
        lastToContact: null,
        notes: null,
        assignedPathwayId: '1edd4976-890c-478c-b6a1-c4ee961231b1',
        stage: 'Engaged',
        status: 'On Pathway',
        patientViewersHistory: null,
        count: '224',
      },
      {
        attempts: 3,
        name: 'Gayla Conaghy',
        patientId: 'e3d56499-e7b7-4ac4-bae3-9549191e0915',
        dateOfBirth: '03/30/2042',
        eligible: true,
        busy: false,
        doNotCall: false,
        referralSource: 'PCP - Other',
        lastContact: null,
        lastToContact: null,
        notes: null,
        assignedPathwayId: 'ddb719b5-ba19-4e51-890e-3c76d325344b',
        stage: 'Engaged',
        status: 'On Pathway',
        patientViewersHistory: null,
        count: '224',
      },
    ],
    count: 224,
  }
