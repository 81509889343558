import { ErrorMessage } from '@hookform/error-message'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form'
import { levelScores } from 'src/features/shared/presentation'

export const PainLevelField = <T extends FieldValues>() => {
  const name = 'painLevel' as FieldPath<T>
  const { register } = useFormContext()

  return (
    <Controller
      name={name}
      {...register}
      render={({ field }) => (
        <FormControl size={'small'} sx={{ width: '100%' }}>
          <InputLabel id={'painLevel-label'}>Pain</InputLabel>
          <Select
            labelId={'painLevel-label'}
            id={'painLevel'}
            label={'Pain'}
            value={field.value}
            onChange={(event) => {
              field.onChange(event.target.value)
            }}
          >
            <MenuItem value={''}>Add Score</MenuItem>
            {levelScores.map((painLevelOption) => (
              <MenuItem key={painLevelOption.id} value={painLevelOption.label}>
                {painLevelOption.label}
              </MenuItem>
            ))}
          </Select>
          <ErrorMessage
            name={name}
            render={({ message }) => (
              <FormHelperText sx={{ mx: 0, whiteSpace: 'nowrap' }} error={true}>
                {message}
              </FormHelperText>
            )}
          />
        </FormControl>
      )}
    />
  )
}
