import { FC, useEffect, useState } from 'react'
import { Box, Paper, Typography } from '@mui/material'

import {
  BackdropLoader,
  FullSectionLoader,
  useGetNavigatorsQuery,
  usePatientId,
  TCTextDivider,
} from 'src/features/shared/presentation'
import {
  useGetPatientQuery,
  useGetPatientPainAndFunctionQuery,
  PatientPageHeaderView,
  PatientPageHeaderEditForm,
  PatientPainFunctionGraphsSection,
} from 'src/features/patients/presentation'
import { useGetPathwaysQuery } from 'src/features/pathways/presentation'
import {
  mapToPatientHeaderEditFormFields,
  mapToPatientHeaderData,
} from 'src/features/patients/adapters'
import {
  MSKFormModal,
  PatientIntakeFlow,
  useGetPatientIntakeFlowsQuery,
  useLoadMSKFormQuery,
} from 'src/features/msk/presentation'
import {
  useMSKFormStore,
  usePatientPageStore,
} from 'src/features/shared/infrastructure'
import { PatientPathwayForm } from 'src/features/pathways/presentation/components'
import { PatientNotesSection } from 'src/features/notes/presentation'
import { ChildrenInProps } from 'src/features/shared/types'
import { IntakeFlow } from 'src/features/msk/domain'
import { mapToPatientIntakeFlowsWithCreatedInfo } from 'src/features/msk/adapters'

export type PatientIntakeFlowWithCreatedInfo = IntakeFlow & {
  createdInfo: string
}

const getPatientPageSectionFlex = () => {
  return {
    xs: '0 1 940px',
  }
}

const PatientSectionWrapper: FC<ChildrenInProps> = ({ children }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      {children}
    </Box>
  )
}

const PatientPagePaperSection: FC<ChildrenInProps> = ({ children }) => {
  return (
    <PatientSectionWrapper>
      <Paper
        sx={{
          flex: getPatientPageSectionFlex(),
        }}
      >
        {children}
      </Paper>
    </PatientSectionWrapper>
  )
}

const PatientPageBoxSection: FC<ChildrenInProps> = ({ children }) => {
  return (
    <PatientSectionWrapper>
      <Box
        sx={{
          flex: getPatientPageSectionFlex(),
        }}
      >
        {children}
      </Box>
    </PatientSectionWrapper>
  )
}

export const PatientPage: FC = () => {
  const patientId = usePatientId()
  const [showEditForm, setShowEditForm] = useState(false)
  const { openMSKFormModal, setOpenMSKFormModal } = usePatientPageStore()
  const { patient, getPatientIsLoading } = useGetPatientQuery()
  const { navigators, getNavigatorsIsLoading } = useGetNavigatorsQuery()
  const { getPathwaysIsLoading } = useGetPathwaysQuery()
  const { loadedMSKForm, mskFormIsLoading } = useLoadMSKFormQuery()
  const { mskFormPatientId, initMSKForm, resetMSKForm } = useMSKFormStore()
  const { intakeFlows, questionsAndActions, getPatientIntakeFlowsIsLoading } =
    useGetPatientIntakeFlowsQuery()
  const {
    patientPainAndFunction,
    getPatientPainAndFunctionIsLoading,
    hasFunctionHistory,
    hasPainHistory,
  } = useGetPatientPainAndFunctionQuery()

  const showGraphSection =
    getPatientPainAndFunctionIsLoading || (hasPainHistory && hasFunctionHistory)

  const pathwayFormSectionIsLoading =
    getNavigatorsIsLoading ||
    getPathwaysIsLoading ||
    getPatientIntakeFlowsIsLoading

  const intakeFlowsSectionIsLoading =
    getNavigatorsIsLoading ||
    getPatientIntakeFlowsIsLoading ||
    getPatientIsLoading

  const showBackDropLoader =
    getPatientIsLoading || (openMSKFormModal && mskFormIsLoading)

  const handleEditTaskClick = () => setShowEditForm(true)

  const handleEditFormCancelClick = () => setShowEditForm(false)

  const handleEditFormSave = () => setShowEditForm(false)

  const handleIntakeAssessmentClick = () => {
    if (patientId !== mskFormPatientId) {
      initMSKForm(patientId || '')
    }
    setOpenMSKFormModal(true)
  }

  useEffect(() => {
    resetMSKForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box
      id={'patient-page'}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '56px',
        alignItems: 'center',
      }}
    >
      {patient ? (
        <>
          {/* Patient Page Header */}
          <PatientPagePaperSection>
            {showEditForm ? (
              <PatientPageHeaderEditForm
                patient={patient}
                initialValues={mapToPatientHeaderEditFormFields(patient)}
                onCancelClick={handleEditFormCancelClick}
                onSaveSuccess={handleEditFormSave}
              />
            ) : (
              <PatientPageHeaderView
                patient={mapToPatientHeaderData(patient)}
                onEditClick={handleEditTaskClick}
              />
            )}
          </PatientPagePaperSection>

          {/* Patient Page Pathway Form */}
          <PatientPageBoxSection>
            {pathwayFormSectionIsLoading ? (
              <FullSectionLoader />
            ) : (
              <PatientPathwayForm patient={patient} />
            )}
          </PatientPageBoxSection>

          {/* Patient Page Pain Function Graphs */}
          {showGraphSection ? (
            <PatientPagePaperSection>
              {getPatientPainAndFunctionIsLoading ? (
                <FullSectionLoader />
              ) : null}
              {patientPainAndFunction ? (
                <PatientPainFunctionGraphsSection
                  patientPainAndFunction={patientPainAndFunction}
                />
              ) : null}
            </PatientPagePaperSection>
          ) : null}

          {/* Patient Page Intake Results */}
          {intakeFlowsSectionIsLoading ? (
            <FullSectionLoader />
          ) : intakeFlows.length && questionsAndActions && patient ? (
            <PatientPagePaperSection>
              <Box
                sx={{
                  pl: '24px',
                  py: '16px',
                }}
              >
                <Box
                  sx={{
                    paddingBottom: '16px',
                    mb: '16px',
                  }}
                >
                  <Typography
                    fontWeight={500}
                    fontSize={20}
                    color={'rgba(0, 0, 0, 0.87)'}
                  >
                    Intake Results
                  </Typography>
                </Box>
                <Box
                  sx={{
                    pr: '24px',
                    maxHeight: '600px',
                    overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: '64px',
                  }}
                >
                  {mapToPatientIntakeFlowsWithCreatedInfo(
                    intakeFlows,
                    navigators
                  ).map((intakeFlow, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '16px',
                      }}
                    >
                      <TCTextDivider>
                        <Typography
                          fontWeight={400}
                          fontSize={'16px'}
                          color={'gray'}
                          component={'span'}
                        >
                          {intakeFlow.createdInfo}
                        </Typography>
                      </TCTextDivider>
                      <PatientIntakeFlow
                        patient={patient}
                        intakeFlow={intakeFlow}
                        questionsAndActions={questionsAndActions}
                      />
                    </Box>
                  ))}
                </Box>
              </Box>
            </PatientPagePaperSection>
          ) : null}

          {/* Patient Page Notes */}
          <PatientPagePaperSection>
            <PatientNotesSection
              patient={patient}
              onIntakeAssessmentClick={handleIntakeAssessmentClick}
            />
          </PatientPagePaperSection>

          {loadedMSKForm ? (
            <MSKFormModal patient={patient} loadedMSKForm={loadedMSKForm} />
          ) : null}
        </>
      ) : null}
      <BackdropLoader open={showBackDropLoader} />
    </Box>
  )
}
