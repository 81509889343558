import {
  UseMutateFunction,
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import {
  CreatePathwayReturns,
  CreatePathwayArgs,
  CreatePathwayError,
} from 'src/features/pathways/domain'
import { usePathwaysUseCases } from 'src/features/pathways/pathways.service-locator'
import { GET_PATIENT_QUERY_KEY } from 'src/features/patients/presentation'

type UseCreatePathwayProps = Omit<
  UseMutationOptions<
    CreatePathwayReturns,
    CreatePathwayError,
    CreatePathwayArgs,
    unknown
  >,
  'mutationFn'
>

type UseCreatePathway = (props?: UseCreatePathwayProps) => {
  createPathway: UseMutateFunction<
    CreatePathwayReturns,
    CreatePathwayError,
    CreatePathwayArgs,
    unknown
  >
  createPathwayIsLoading: boolean
  createPathwayIsError: boolean
  createPathwayStatus: string
}

export const useCreatePathway: UseCreatePathway = (props = {}) => {
  const queryClient = useQueryClient()
  const pathwaysUseCases = usePathwaysUseCases()

  const { onSuccess } = props

  const { mutate, isLoading, isError, status } = useMutation<
    CreatePathwayReturns,
    CreatePathwayError,
    CreatePathwayArgs,
    unknown
  >({
    mutationFn: (args: CreatePathwayArgs) => {
      return pathwaysUseCases.createPathway(args)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [GET_PATIENT_QUERY_KEY, variables.patientId],
      })
      onSuccess?.(data, variables, context)
    },
  })
  return {
    createPathway: mutate,
    createPathwayIsLoading: isLoading,
    createPathwayIsError: isError,
    createPathwayStatus: status,
  }
}
