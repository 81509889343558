import { Pathway } from 'src/features/pathways/domain'
import { Patient } from 'src/features/patients/domain'
import { QueuePatient } from 'src/features/queues/domain'

export const patientHasLockedPathway = (patient: Patient) =>
  !!patient.assignedPathwayId

export const queuePatientHasLockedPathway = (patient: QueuePatient) =>
  !!patient.assignedPathwayId

export const getPatientHasStage = (patient: Patient) =>
  patient.stageAndStatus && patient.stageAndStatus.stage ? true : false

export const getPatientIsEngaged = (
  patient: Patient,
  pathway?: 'self-care' | 'pt' | 'ortho'
) => {
  if (pathway) {
    return (
      patient.stageAndStatus?.stage.toLowerCase() == 'engaged' &&
      patient.pathway?.toLowerCase() == pathway
    )
  }
  return patient.stageAndStatus?.stage.toLowerCase() == 'engaged'
}

export const pathwayDateFieldIsRequired = (track: Pathway['code']) => {
  return [
    'PT01P',
    'OR05P',
    'OR04P',
    'OR10P',
    'OR08P',
    'OR09P',
    'OR11P',
  ].includes(track)
}
