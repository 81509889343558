import { UseTasksUseCases } from 'src/features/tasks/domain'
import { useTasksServiceAdapter } from 'src/features/tasks/adapters'

type MakeUseTasksUseCases = () => UseTasksUseCases

const makeUseTasksUseCases: MakeUseTasksUseCases = () => () => {
  const eligibilityServiceAdapter = useTasksServiceAdapter()

  return {
    createTask: eligibilityServiceAdapter.createTask,
    getDoneTasksByUserId: eligibilityServiceAdapter.getDoneTasksByUserId,
    getTodayTasksByUserId: eligibilityServiceAdapter.getTodayTasksByUserId,
    getUrgentTasksByUserId: eligibilityServiceAdapter.getUrgentTasksByUserId,
    getOverdueTasksByUserId: eligibilityServiceAdapter.getOverdueTasksByUserId,
    getUpcomingTasksByUserId:
      eligibilityServiceAdapter.getUpcomingTasksByUserId,
    updateTask: eligibilityServiceAdapter.updateTask,
  }
}

export const useTasksUseCases: UseTasksUseCases = makeUseTasksUseCases()
