import { GetNavigatorsServiceResponse } from 'src/features/shared/infrastructure'

export const getNavigatorsServiceResponseMock: GetNavigatorsServiceResponse = {
  navigators: [
    {
      id: 'bcb88bda-8cfa-4a98-af77-32f36097190c',
      email: 'racary@gmail.com',
      description: null,
      active: true,
      createdAt: null,
      lastUpdatedAt: null,
      name: 'Ryan Cary',
      role: null,
      type: 'clinical',
      totalassignedpathways: '1',
    },
    {
      id: '738bf894-abb3-4867-9352-beadd95fa827',
      email: 'snolan@tailorcare.com',
      description: 'pt specialist',
      active: true,
      createdAt: null,
      lastUpdatedAt: null,
      name: 'Sam Nolan',
      role: null,
      type: 'clinical',
      totalassignedpathways: '1',
    },
    {
      id: 'aec09f3c-992b-4a9e-b38c-cb119efd7032',
      email: 'tsparks@tailorcare.com',
      description: 'RN',
      active: true,
      createdAt: null,
      lastUpdatedAt: null,
      name: 'Terry Sparks',
      role: null,
      type: 'clinical',
      totalassignedpathways: '0',
    },
    {
      id: '4abd8d06-8d5a-4c6c-a164-3ace11535bae',
      email: 'cjenkins@tailorcare.com',
      description: 'pt specialist',
      active: true,
      createdAt: null,
      lastUpdatedAt: null,
      name: 'Cindy Jenkins',
      role: null,
      type: 'clinical',
      totalassignedpathways: '0',
    },
    {
      id: '39861246-8196-4e6c-b7c3-1274846b55f0',
      email: 'testuser@tailorcare.com',
      description: null,
      active: true,
      createdAt: null,
      lastUpdatedAt: null,
      name: 'Test User',
      role: null,
      type: 'non-clinical',
      totalassignedpathways: '1',
    },
    {
      id: '6a07096e-4cca-491a-b548-9f3b9d38d6c2',
      email: 'null',
      description: null,
      active: true,
      createdAt: null,
      lastUpdatedAt: null,
      name: 'test user',
      role: null,
      type: 'non-clinical',
      totalassignedpathways: '0',
    },
    {
      id: 'aba1a8b8-f27b-40e8-b2d4-ab8bcccef5f4',
      email: 'null',
      description: 'sytem user for automated events',
      active: true,
      createdAt: null,
      lastUpdatedAt: null,
      name: 'System User',
      role: null,
      type: 'non-clinical',
      totalassignedpathways: '0',
    },
    {
      id: '3a9c703b-5e5f-480c-9e66-67edd6ded559',
      email: 'tturner@tailorcare.com',
      description: 'Secure Works pen tester',
      active: true,
      createdAt: '2023-03-02T17:22:04.248Z',
      lastUpdatedAt: '2023-03-02T17:22:04.248Z',
      name: 'Tyrone Turner',
      role: null,
      type: 'clinical',
      totalassignedpathways: '0',
    },
    {
      id: '7a89b889-8d9a-40f1-b96e-5940bdb5942a',
      email: 'rcary@tailorcare.com',
      description: 'Engineering',
      active: true,
      createdAt: '2023-01-20T19:30:20.946Z',
      lastUpdatedAt: '2023-01-20T19:30:20.946Z',
      name: 'Ryan Cary',
      role: null,
      type: 'clinical',
      totalassignedpathways: '7',
    },
    {
      id: '131e3197-927f-4b01-934d-5996de12f7a3',
      email: 'hrivera@tailorcare.com',
      description: 'Director Engineering',
      active: true,
      createdAt: null,
      lastUpdatedAt: null,
      name: 'Hector Rivera',
      role: null,
      type: 'non-clinical',
      totalassignedpathways: '20',
    },
    {
      id: '5aef0dd5-1eb1-492f-928d-2e37c82facfb',
      email: 'dgranados@tailorcare.com',
      description: 'Engineering',
      active: true,
      createdAt: null,
      lastUpdatedAt: null,
      name: 'David Grandados',
      role: null,
      type: 'non-clinical',
      totalassignedpathways: '4',
    },
    {
      id: 'b4e60812-0698-49a3-8b3f-1c429461c970',
      email: 'gmohamed@tailorcare.com',
      description: 'Product',
      active: true,
      createdAt: null,
      lastUpdatedAt: null,
      name: 'Gareth Mohamed',
      role: null,
      type: 'non-clinical',
      totalassignedpathways: '21',
    },
    {
      id: '973cd9e0-cc16-4c3b-8d07-bbd47e66251d',
      email: 'ebergman@tailorcare.com',
      description: 'Clin-Ops',
      active: true,
      createdAt: '2023-03-02T17:22:04.248Z',
      lastUpdatedAt: '2023-03-02T17:22:04.248Z',
      name: 'Elissa Bergman',
      role: null,
      type: 'clinical',
      totalassignedpathways: '0',
    },
    {
      id: '3577a656-8d95-4ead-b022-5b5e99f005e2',
      email: 'akaiser@tailorcare.com',
      description: 'Clin-Ops',
      active: true,
      createdAt: '2023-03-02T17:22:04.248Z',
      lastUpdatedAt: '2023-03-02T17:22:04.248Z',
      name: 'Abi Kaiser',
      role: null,
      type: 'clinical',
      totalassignedpathways: '0',
    },
    {
      id: '7bcd773f-43b7-41cb-9572-32a98bcf2b7c',
      email: 'emastagni@tailorcare.com',
      description: 'Head of Clin-Ops',
      active: true,
      createdAt: '2023-03-02T17:22:04.248Z',
      lastUpdatedAt: '2023-03-02T17:22:04.248Z',
      name: 'Erin Mastagni',
      role: null,
      type: 'clinical',
      totalassignedpathways: '0',
    },
    {
      id: '49f6d659-c4ff-4c63-97d8-b556668bfb94',
      email: 'kcoates@tailorcare.com',
      description: 'Chief Medical Officer',
      active: true,
      createdAt: '2023-03-02T17:22:04.248Z',
      lastUpdatedAt: '2023-03-02T17:22:04.248Z',
      name: 'Kevin Coates',
      role: null,
      type: 'clinical',
      totalassignedpathways: '0',
    },
    {
      id: 'd827b00a-64c9-403d-b806-7158a49282d6',
      email: 'jsheedy@tailorcare.com',
      description: 'ClinOps',
      active: true,
      createdAt: '2023-03-02T17:22:04.248Z',
      lastUpdatedAt: '2023-03-02T17:22:04.248Z',
      name: 'Julie Sheedy',
      role: null,
      type: 'clinical',
      totalassignedpathways: '0',
    },
    {
      id: '0362d102-47b2-41a3-b086-d2ffc1681b22',
      email: 'sgriffin@tailorcare.com',
      description: 'PT',
      active: true,
      createdAt: '2023-03-02T17:22:04.248Z',
      lastUpdatedAt: '2023-03-02T17:22:04.248Z',
      name: 'Steven Griffin',
      role: null,
      type: 'clinical',
      totalassignedpathways: '0',
    },
    {
      id: 'c6ba367d-e893-4ac6-883f-8732d03f9950',
      email: 'troberts@tailorcare.com',
      description: 'Engineering',
      active: true,
      createdAt: '2023-04-28T15:41:50.478Z',
      lastUpdatedAt: null,
      name: 'Thomas Roberts',
      role: null,
      type: 'non-clinical',
      totalassignedpathways: '10',
    },
    {
      id: '365a8476-f31c-48ae-8651-2bf6ea5eaf8b',
      email: 'mbalk@tailorcare.com',
      description: 'Data Sciences',
      active: true,
      createdAt: '2023-05-23T13:39:56.328Z',
      lastUpdatedAt: null,
      name: 'Max Balk',
      role: null,
      type: 'non-clinical',
      totalassignedpathways: '0',
    },
    {
      id: 'b4114ee5-e70b-4662-8e09-32574914ec5e',
      email: 'mike@tailorcare.com',
      description: 'Head Of Data Sciences',
      active: true,
      createdAt: '2023-05-23T13:41:12.614Z',
      lastUpdatedAt: null,
      name: 'Mike Krahulec',
      role: null,
      type: 'non-clinical',
      totalassignedpathways: '0',
    },
    {
      id: '7f380f2f-8205-47d0-b9e2-4df9e3499196',
      email: 'jsteele@tailorcare.com',
      description: 'Data Sciences',
      active: true,
      createdAt: '2023-05-23T13:42:18.732Z',
      lastUpdatedAt: null,
      name: 'Jessica Steele',
      role: null,
      type: 'non-clinical',
      totalassignedpathways: '0',
    },
    {
      id: '56e118a7-1e53-46aa-996d-25d8a3f2c85c',
      email: 'rjiang@tailorcare.com',
      description: 'Head of Product',
      active: true,
      createdAt: '2023-05-23T13:44:39.621Z',
      lastUpdatedAt: null,
      name: 'Rachel Jian',
      role: null,
      type: 'clinical',
      totalassignedpathways: '0',
    },
    {
      id: '0b711404-6c8c-4f55-9889-ca7f5ee55422',
      email: 'hkunkel@tailorcare.com',
      description: 'Engineering',
      active: true,
      createdAt: '2023-05-30T13:41:12.614Z',
      lastUpdatedAt: '2023-05-30T13:41:12.614Z',
      name: 'Harrison Kunkel',
      role: null,
      type: 'non-clinical',
      totalassignedpathways: '25',
    },
    {
      id: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      email: 'e2e@tailorcare.com',
      description: 'End to End Automation Account',
      active: true,
      createdAt: '2023-05-30T13:41:12.614Z',
      lastUpdatedAt: '2023-05-30T13:41:12.614Z',
      name: 'E2E Automation ',
      role: null,
      type: 'non-clinical',
      totalassignedpathways: '11',
    },
    {
      id: '7891dfe1-2247-4c28-ba81-77341e331bf6',
      email: 'apool@tailorcare.com',
      description: 'Clin-Ops',
      active: true,
      createdAt: '2023-06-26T18:29:22.785Z',
      lastUpdatedAt: null,
      name: 'AnnaLou Pool',
      role: null,
      type: 'clinical',
      totalassignedpathways: '0',
    },
    {
      id: 'c6889aa2-8810-434a-9693-dd795c030890',
      email: 'esnipes@tailorcare.com',
      description: 'Clin-Ops',
      active: true,
      createdAt: '2023-06-26T18:29:52.304Z',
      lastUpdatedAt: null,
      name: 'Ebonie Snipes',
      role: null,
      type: 'clinical',
      totalassignedpathways: '0',
    },
    {
      id: 'e0e6a627-37db-45d0-89cd-5b58af95c7b9',
      email: 'jgamble@tailorcare.com',
      description: 'Clin-Ops',
      active: true,
      createdAt: '2023-06-26T18:30:17.611Z',
      lastUpdatedAt: null,
      name: 'Joanne Gamble',
      role: null,
      type: 'clinical',
      totalassignedpathways: '0',
    },
    {
      id: 'cd91f350-9cbb-49f7-8afd-256a753988fc',
      email: 'bcorcoran@tailorcare.com',
      description: 'Clin-Ops',
      active: true,
      createdAt: '2023-05-23T13:36:36.280Z',
      lastUpdatedAt: null,
      name: 'Berkeley Corcoran',
      role: null,
      type: 'clinical',
      totalassignedpathways: '0',
    },
    {
      id: '05e52fed-76db-48e9-a553-e0794d01c7bf',
      email: 'tmickey@tailorcare.com',
      description: 'Clin-Ops',
      active: true,
      createdAt: '2023-05-23T13:38:03.238Z',
      lastUpdatedAt: null,
      name: 'Taylor Mickey',
      role: null,
      type: 'clinical',
      totalassignedpathways: '0',
    },
  ],
}
