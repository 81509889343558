import { useCallback } from 'react'

import { useCompassApiClient } from 'src/features/shared/infrastructure'
import {
  LoadMSKFormServiceResponse,
  ProcessMSKFormServicePayload,
  ProcessMSKFormServiceResponse,
} from 'src/features/msk/infrastructure'
import * as settings from 'src/config/settings'

export const useMSKFormService = () => {
  const { getCompassApiClient } = useCompassApiClient()

  const loadMSKForm = useCallback(async () => {
    const compassClient = await getCompassApiClient()
    return await compassClient.get<LoadMSKFormServiceResponse>(
      settings.COMPASS_API_BASE_URL + '/msk/load'
    )
  }, [getCompassApiClient])

  const processMSKForm = useCallback(
    async (payload: ProcessMSKFormServicePayload) => {
      const compassClient = await getCompassApiClient()
      return await compassClient.post<ProcessMSKFormServiceResponse>(
        settings.COMPASS_API_BASE_URL + '/msk/process',
        payload
      )
    },
    [getCompassApiClient]
  )

  return {
    loadMSKForm,
    processMSKForm,
  }
}
