import {
  CreatePatientCustomNoteServiceResponse,
  CreatePatientIntakeNoteServiceResponse,
  CreatePatientOrthoChartCheckNoteServiceResponse,
  CreatePatientOrthoTouchpointNoteServiceResponse,
  CreatePatientOutreachNoteServiceResponse,
  CreatePatientPTTouchpointNoteServiceResponse,
  CreatePatientPostOpTouchpointNoteServiceResponse,
  CreatePatientPreOpTouchpointNoteServiceResponse,
  CreatePatientReferralUploadNoteServiceResponse,
  CreatePatientSelfCareTouchpointNoteServiceResponse,
} from 'src/features/notes/infrastructure'

export const createPatientCustomNoteServiceResponseMock: CreatePatientCustomNoteServiceResponse =
  {
    id: 'b174fa08-5716-4bbf-a9fd-1fdf47645d29',
    patientId: 'd1b855f8-4eea-404b-be15-6db8bd981b38',
    author: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
    createdAt: '2023-07-08T06:37:35.172Z',
    note: '<p>Test Custom</p>',
    // sdm: false,
    // spokeWith: null,
    // type: 'Custom',
    // data: null,
    // communication: 'outbound',
  }

export const createPatientIntakeNoteServiceResponseMock: CreatePatientIntakeNoteServiceResponse =
  {
    id: '8362eb8a-8b11-4f23-b309-84b83d3d93c0',
    patientId: 'd1b855f8-4eea-404b-be15-6db8bd981b38',
    author: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
    createdAt: '2023-07-08T06:39:19.898Z',
    note: '<p>Test Intake</p>',
    // sdm: true,
    // spokeWith: 'PCP',
    // type: 'Intake',
    // data: {
    //   scheduled: 'Yes',
    //   providerName: 'Test',
    //   providerGroup: 'PT Solutions',
    //   providerGroupLocation: 'Test',
    //   sdmText: '<p>TEST</p>',
    //   agreeYouUnderstandCondition: 'Neutral',
    //   agreeYouUnderstandTreatmentOptions: 'Strongly agree',
    // },
    // communication: 'outbound',
  }

export const createPatientOutreachNoteServiceResponseMock: CreatePatientOutreachNoteServiceResponse =
  {
    id: 'e15d2901-5f69-427c-bd61-5bdcfb14088b',
    patientId: 'd1b855f8-4eea-404b-be15-6db8bd981b38',
    author: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
    createdAt: '2023-07-08T06:41:50.647Z',
    note: '<p>Test Outreach</p>',
    // sdm: false,
    // spokeWith: 'Caregiver',
    // type: 'Outreach',
    // data: null,
    // communication: 'outbound',
  }

export const createPatientReferralUploadNoteServiceResponseMock: CreatePatientReferralUploadNoteServiceResponse =
  {
    id: '429dcf53-d210-469c-af6f-0528c73a3ec0',
    patientId: 'd1b855f8-4eea-404b-be15-6db8bd981b38',
    author: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
    createdAt: '2023-07-08T06:43:11.075Z',
    note: '<p>Test Referral Upload</p>',
    // sdm: false,
    // spokeWith: null,
    // type: 'Referral Upload',
    // data: {
    //   referringProvider: 'TEST',
    //   referralDate: '2023-07-08T06:42:53.338Z',
    //   referralType: 'Ortho',
    //   referredFor:
    //     'Left Hip, Back-Neck, Left Knee, Left Ankle-Foot, Left Elbow',
    //   medicalHistory: 'TEST',
    // },
    // communication: 'outbound',
  }

export const createPatientOrthoTouchpointNoteServiceResponseMock: CreatePatientOrthoTouchpointNoteServiceResponse =
  {
    id: '8c3c6e3a-e1f6-4aee-af1c-1598c67631e5',
    patientId: 'fb3479bc-b59a-4c45-9cd0-742bcb508820',
    author: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
    createdAt: '2023-07-08T06:46:07.179Z',
    note: '<p>Test Ortho Touchpoint</p>',
    // sdm: false,
    // spokeWith: 'PCP',
    // type: 'Ortho Touchpoint',
    // data: {
    //   completedAppointment: 'Yes',
    //   improvementFromInjection: 'Significant Improvement',
    //   typeOfInjection: 'In-Office Cortisone Injection',
    //   outcomeOfVisit: 'Injections',
    //   additionalConditionPain: 7,
    //   additionalConditionFunction: 6,
    // },
    // communication: 'outbound',
  }

export const createPatientOrthoChartCheckNoteServiceResponseMock: CreatePatientOrthoChartCheckNoteServiceResponse =
  {
    id: '61549686-33a4-416d-9b7b-fe2575625953',
    patientId: 'fb3479bc-b59a-4c45-9cd0-742bcb508820',
    author: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
    createdAt: '2023-07-08T06:48:10.615Z',
    note: '<p>Test Ortho Chart Check</p>',
    // sdm: false,
    // spokeWith: null,
    // type: 'Ortho Chart Check',
    // data: {
    //   completedAppointment: 'No',
    //   reasonForMissingAppointment: 'No Show',
    //   detailsOfMissedAppointment: 'TEST',
    //   appointmentRescheduled: 'Yes',
    //   outcomeOfVisit: 'Injections',
    //   typeOfInjection: 'N/A',
    //   appointmentDate: '2023-07-02T05:00:00.000Z',
    //   surgeryDate: '2023-07-28T05:00:00.000Z',
    // },
    // communication: 'outbound',
  }

export const createPatientPreOpTouchpointNoteServiceResponseMock: CreatePatientPreOpTouchpointNoteServiceResponse =
  {
    id: '7e5567f9-54e3-4796-8558-b0198cac59c3',
    patientId: 'fb3479bc-b59a-4c45-9cd0-742bcb508820',
    author: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
    createdAt: '2023-07-08T06:49:41.656Z',
    note: '<p>Test Pre-Op Touchpoint</p>',
    // sdm: false,
    // spokeWith: 'Caregiver',
    // type: 'Pre-Op Touchpoint',
    // data: {
    //   additionalQuestionsOrConcerns: 'NONE',
    //   questionsAboutSurgery: 'YES',
    //   procedure: 'Other Hip',
    // },
    // communication: 'outbound',
  }

export const createPatientPostOpTouchpointNoteServiceResponseMock: CreatePatientPostOpTouchpointNoteServiceResponse =
  {
    id: '7e5567f9-54e3-4796-8558-b0198cac59c3',
    patientId: 'fb3479bc-b59a-4c45-9cd0-742bcb508820',
    author: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
    createdAt: '2023-07-08T06:49:41.656Z',
    note: '<p>Test Post-Op Touchpoint</p>',
  }

export const createPatientSelfCareTouchpointNoteServiceResponseMock: CreatePatientSelfCareTouchpointNoteServiceResponse =
  {
    id: '7e5567f9-54e3-4796-8558-b0198cac59c3',
    patientId: 'fb3479bc-b59a-4c45-9cd0-742bcb508820',
    author: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
    createdAt: '2023-07-08T06:49:41.656Z',
    note: '<p>Test Self-Care Touchpoint</p>',
  }

export const createPatientPTTouchpointNoteServiceResponseMock: CreatePatientPTTouchpointNoteServiceResponse =
  {
    id: '7e5567f9-54e3-4796-8558-b0198cac59c3',
    patientId: 'fb3479bc-b59a-4c45-9cd0-742bcb508820',
    author: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
    createdAt: '2023-07-08T06:49:41.656Z',
    note: '<p>Test PT Touchpoint</p>',
  }
