import * as yup from 'yup'
import { DevTool } from '@hookform/devtools'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  IconButton,
  Box,
  Select,
  MenuItem,
  Typography,
  Button,
  Tooltip,
  SelectChangeEvent,
} from '@mui/material'
import {
  FC,
  useState,
  useCallback,
  useMemo,
  MouseEventHandler,
  useEffect,
} from 'react'
import {
  useForm,
  SubmitHandler,
  FormProvider,
  Controller,
  ControllerRenderProps,
} from 'react-hook-form'
import { toast } from 'react-toastify'

import LockIcon from '@mui/icons-material/Lock'

import {
  getOutlineInputNotAllowedCursorStyle,
  patientHasLockedPathway,
  pathwayDateFieldIsRequired,
} from 'src/features/shared/utils'
import { useAuth } from 'src/features/shared/infrastructure'
import {
  mapToCreatePathwayArgs,
  mapToReassignPathwayArgs,
  mapToAllTracks,
  mapToUpdatePathwayPayload,
  mapToTracksByPathway,
  mapToOrthoTrackTypes,
  mapToOrthoTracks,
  orthoTrackSplitter,
} from 'src/features/pathways/adapters'
import {
  useCreatePathway,
  useGetPathwaysQuery,
  useReassignPathway,
  useUpdatePathway,
} from 'src/features/pathways/presentation'
import { useRefreshTasks } from 'src/features/tasks/presentation'
import { Patient } from 'src/features/patients/domain'
import {
  useRolesAndPermissions,
  BackdropLoader,
  WarningModal,
  TCDatePicker,
  useGetNavigatorsQuery,
  VanillaFormFieldContainer,
} from 'src/features/shared/presentation'
import {
  useGetPatientIntakeFlowsQuery,
  useGetPatientLastIntakeFlowQuery,
} from 'src/features/msk/presentation'
import { UpdatePathwayArgs } from 'src/features/pathways/domain'
import { ChildrenInProps, SelectOption } from 'src/features/shared/types'

export const WAIT_TIME_FOR_TASKS_TO_BE_CREATED = 60000
export const WAIT_TIME_FOR_CLOSE_PATHWAY_TOAST = 5000
const LABELS = {
  pathway: 'Pathway',
  trackType: 'Group',
  track: 'Track',
  pathwayType: 'Pathway Type',
  navigator: 'Assign Navigator',
}

const DefaultPatwayFormSelectContainer: FC<ChildrenInProps> = ({
  children,
}) => {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flex: '0 1 210px',
      }}
    >
      {children}
    </Box>
  )
}

const PathwayLockIcon = () => {
  return (
    <IconButton
      disableRipple={true}
      sx={{
        position: 'absolute',
        left: '0',
        cursor: 'not-allowed',
      }}
    >
      <LockIcon />
    </IconButton>
  )
}

export type PatientPathwayFormFields = {
  pathway: string
  trackType?: string
  track: string
  pathwayType: string
  navigatorId: string
  commsTrigger?: Date | undefined | null
}

const patientPathwayFormSchema: yup.Schema<PatientPathwayFormFields> = yup
  .object()
  .shape(
    {
      pathway: yup.string().required('Pathway is required'),
      trackType: yup.string().when('pathway', {
        is: (pathway: string) => pathway === 'ortho',
        then: (schema) => schema.required('Track type is required'),
      }),
      track: yup.string().required('Track is required'),
      pathwayType: yup.string().required('Pathway Type is required'),
      navigatorId: yup.string().required('Navigator is required'),
      commsTrigger: yup.date().when('track', {
        is: (track: string) => pathwayDateFieldIsRequired(track),
        then: (schema) => schema.required('Appt date is required'),
      }),
    },
    [['track', 'commsTrigger']]
  )

type PatientPathwayFormProps = {
  patient: Patient
}

export const PatientPathwayForm: FC<PatientPathwayFormProps> = ({
  patient,
}) => {
  const { navigators } = useGetNavigatorsQuery()
  const { pathways, pathwayTypes } = useGetPathwaysQuery()
  const { intakeFlows } = useGetPatientIntakeFlowsQuery()
  const { hasPermissions } = useRolesAndPermissions()
  const [showPathwayTypeWarning, setShowPathwayTypeWarning] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [isReassign, setIsReassign] = useState(false)
  const [availableTracks, setAvailableTracks] = useState<SelectOption[]>(
    mapToAllTracks(pathways)
  )
  const [availableTypes, setAvailableTypes] = useState<string[]>(
    Object.values(pathwayTypes).flat()
  )

  const [isLocked, setIsLocked] = useState(patientHasLockedPathway(patient))

  const { myCompassUser } = useAuth()
  const { createPathway } = useCreatePathway()
  const { reassignPathway } = useReassignPathway()
  const { updatePathway } = useUpdatePathway()
  const { refreshTasks } = useRefreshTasks()
  const { lastIntakeFlow } = useGetPatientLastIntakeFlowQuery()

  const availableTracksTypes = useMemo(
    () => mapToOrthoTrackTypes(pathways),
    [pathways]
  )

  const getWarningModalTitle = () => {
    if (lastIntakeFlow && lastIntakeFlow.recommendedActions.length) {
      const recommendedActions = lastIntakeFlow.recommendedActions.join(', ')
      const recommendedActionsOr =
        lastIntakeFlow.recommendedActions.join(' or ')
      return `Recommended for ${recommendedActions}. If not scheduling with ${recommendedActionsOr}, please note why.`
    }
    return 'If not scheduling with recommended action, please note why.'
  }

  const getCurrentPathway = () => {
    return pathways.find((pathway) => pathway.code === patient.pathwaycode)
  }

  const getInitialPathway = () => {
    return getCurrentPathway()?.pathway || ''
  }

  const getInitialTrackType = () => {
    const currentPathway = getCurrentPathway()
    if (currentPathway && currentPathway.pathway === 'Ortho') {
      return orthoTrackSplitter(currentPathway.track).trackType
    }
    return ''
  }
  const getInitialTrack = () => {
    return getCurrentPathway()?.code || ''
  }

  const getInitialType = () => {
    return patient.pathwayType || ''
  }

  const getInitialLockDate = useCallback(() => {
    if (patient.lockDate) {
      return new Date(patient.lockDate)
    }
    return new Date()
  }, [patient.lockDate])

  const getInitialNavigatorId = () => {
    const currentNavigator = navigators?.find(
      (navigator) => navigator.id === myCompassUser?.id
    )

    return patient.assignedUserId || currentNavigator?.id || ''
  }

  const getPathwayOptions = () => {
    return Array.from(
      new Set(
        pathways
          ?.filter((pathway) => pathway.track && pathway.type)
          .map((pathway) => pathway.pathway)
      )
    )
  }

  const formMethods = useForm<PatientPathwayFormFields>({
    resolver: yupResolver(patientPathwayFormSchema),
    mode: 'onBlur',
    defaultValues: {
      pathway: getInitialPathway(),
      trackType: getInitialTrackType(),
      track: getInitialTrack(),
      pathwayType: getInitialType(),
      navigatorId: getInitialNavigatorId(),
      commsTrigger: getInitialLockDate(),
    },
  })

  const pathwayWatcher = formMethods.watch('pathway')
  const trackTypeWatcher = formMethods.watch('trackType')
  const trackWatcher = formMethods.watch('track')

  const orthoPathwayIsSelected = useMemo(
    () => pathwayWatcher === 'Ortho',
    [pathwayWatcher]
  )

  const trackTypeIsNotRequired = !!pathwayWatcher && !orthoPathwayIsSelected

  const trackTypeIsInvalid = orthoPathwayIsSelected && !trackTypeWatcher

  const fieldHasErrorMessage = (field: keyof PatientPathwayFormFields) => {
    return (
      !!formMethods.formState.errors[field] &&
      !!formMethods.formState.errors[field]?.message
    )
  }

  const lockButtonIsDisabled =
    !hasPermissions('pathway:edit') || !intakeFlows?.length

  const inputsAreDisabled = lockButtonIsDisabled

  const pathwayInputIsDisabled = inputsAreDisabled || isLocked

  const trackTypeInputIsDisabled =
    inputsAreDisabled || isLocked || !pathwayWatcher || trackTypeIsNotRequired

  const trackInputIsDisabled =
    inputsAreDisabled || isLocked || !pathwayWatcher || trackTypeIsInvalid

  const pathwayTypeInputIsDisabled =
    inputsAreDisabled || isLocked || !pathwayWatcher

  const navigatorInputIsDisabled = inputsAreDisabled || isLocked

  const commsTriggerInputIsDisabled = inputsAreDisabled || isLocked

  const showCancelButton = patientHasLockedPathway(patient) && !isLocked

  const submitButtonText = useMemo(() => {
    return patientHasLockedPathway(patient) && isReassign
      ? 'REASSIGN'
      : patientHasLockedPathway(patient)
      ? 'SAVE'
      : 'LOCK'
  }, [isReassign, patient])

  const getAvailableTracks = useCallback(
    (pathway: string) => mapToTracksByPathway(pathways, pathway),
    [pathways]
  )

  const getOrthoAvailableTracks = useCallback(
    (trackType: string) => mapToOrthoTracks(pathways, trackType),
    [pathways]
  )

  const getAvailableTypes = useCallback(
    (pathway: string) => {
      const pathwayType = pathway.toLowerCase() as keyof typeof pathwayTypes
      return pathwayTypes[pathwayType]
    },
    [pathwayTypes]
  )

  const getSelectedPathwayByFormValues = useCallback(
    (values: PatientPathwayFormFields) => {
      return pathways?.find((pathwayItem) => pathwayItem.code === values.track)
    },
    [pathways]
  )

  const getTrackHelperText = () => {
    if (fieldHasErrorMessage('track')) {
      return formMethods.formState.errors['track']?.message
    }
    if (!pathwayWatcher) {
      return 'Select a pathway first'
    }
    if (trackTypeIsInvalid) {
      return 'Select a track type first'
    }
    return ''
  }

  const getTrackTypeHelperText = () => {
    if (fieldHasErrorMessage('trackType')) {
      return formMethods.formState.errors['trackType']?.message
    }
    if (!pathwayWatcher) {
      return 'Select a pathway first'
    }
    if (trackTypeIsNotRequired) {
      return `Track type is not required for selected pathway ${pathwayWatcher}`
    }
    return ''
  }

  const getPathwayTypeHelperText = () => {
    if (
      formMethods.formState.errors['pathwayType'] &&
      formMethods.formState.errors['pathwayType'].message
    ) {
      return formMethods.formState.errors['pathwayType'].message
    }
    if (!pathwayWatcher) {
      return 'Select a pathway first'
    }
    return ''
  }

  const getInputLockedStyles = () => {
    return {
      paddingLeft: isLocked ? '32px' : '14px',
    }
  }

  const getFormControlStyles = () => {
    return {
      width: '100%',
    }
  }

  const getSelectInputStyles = () => {
    return {
      width: '100%',
      background: 'white',
      '& .MuiSelect-select': {
        ...getInputLockedStyles(),
      },
    }
  }

  const getLockButtonDisabledTitle = () => {
    if (!lockButtonIsDisabled) {
      return ''
    }
    if (!hasPermissions('pathway:edit')) {
      return 'Only clinical users can make this change'
    }
    if (!intakeFlows?.length) {
      return 'You must complete an Intake Assessment for this record before locking a Pathway'
    }
    return ''
  }

  const getApptDateLabel = useCallback(() => {
    switch (pathwayWatcher) {
      case 'PT':
        if (trackWatcher === 'PT01P') {
          return `Date of first ${pathwayWatcher} appt.`
        }
        return 'Pathway Lock'

      case 'Ortho':
        if (['OR04P', 'OR05P'].includes(trackWatcher)) {
          return `Date of first ${pathwayWatcher} appt.`
        }
        if (['OR06P', 'OR07P'].includes(trackWatcher)) {
          return 'Pathway lock'
        }
        return 'Surgical Date'

      case 'Self-Care':
        return 'Pathway lock'
    }
  }, [pathwayWatcher, trackWatcher])

  const getPathwayTypeChangeHandler =
    (field: ControllerRenderProps<PatientPathwayFormFields, 'pathwayType'>) =>
    (event: SelectChangeEvent) => {
      const value = event.target.value

      if (
        lastIntakeFlow &&
        lastIntakeFlow.recommendedActions.length &&
        !lastIntakeFlow.recommendedActions.includes(value)
      ) {
        setShowPathwayTypeWarning(true)
      }

      field.onChange(value)
    }

  const handleCancelClick = () => {
    const pathway = getCurrentPathway()?.pathway
    if (pathway) {
      setAvailableTypes(getAvailableTypes(pathway))
      if (pathway === 'Ortho') {
        setAvailableTracks(getOrthoAvailableTracks(getInitialTrackType()))
      } else {
        setAvailableTracks(getAvailableTracks(pathway))
      }
    }
    formMethods.reset({
      pathway: pathway || '',
      track: getInitialTrack(),
      trackType: getInitialTrackType(),
      pathwayType: getInitialType(),
      navigatorId: getInitialNavigatorId(),
      commsTrigger: getInitialLockDate(),
    })
    setIsReassign(false)
    setIsLocked(true)
  }

  const handleUnlockClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault()
    const pathway = getCurrentPathway()?.pathway
    if (pathway) {
      setAvailableTypes(getAvailableTypes(pathway))
      if (pathway === 'Ortho') {
        setAvailableTracks(getOrthoAvailableTracks(getInitialTrackType()))
      } else {
        setAvailableTracks(getAvailableTracks(pathway))
      }
    } else {
      setAvailableTracks([])
      setAvailableTypes([])
    }
    setIsLocked(false)
  }

  const handleConfirmPathwayType = () => {
    setShowPathwayTypeWarning(false)
  }

  const submitHandler: SubmitHandler<PatientPathwayFormFields> = (values) => {
    setShowLoader(true)

    const selectedPathway = getSelectedPathwayByFormValues(values)

    if (!selectedPathway) {
      toast.error('Unable to find selected pathway')
      formMethods.reset()
      setShowLoader(false)
      return
    }

    if (patient.assignedPathwayId && isReassign) {
      // If the patient has locked pathway and the user is reassigning the pathway

      const reassignPathwayArgs = mapToReassignPathwayArgs(
        selectedPathway,
        patient.patientId,
        patient.assignedPathwayId,
        values
      )

      reassignPathway(reassignPathwayArgs, {
        onSuccess: () => {
          toast.success(
            'Pathway reassigned successfully! Upcoming Tasks should appear for the assigned navigator shortly.',
            {
              autoClose: WAIT_TIME_FOR_CLOSE_PATHWAY_TOAST,
            }
          )
          setIsLocked(true)
        },
        onError: () => {
          toast.error('Unable to reassign pathway')
        },
        onSettled: () => {
          setShowLoader(false)
        },
      })
    } else if (patient.assignedPathwayId) {
      // If the patient has locked pathway and the user is updating the pathway

      const updatePathwayArgs: UpdatePathwayArgs = {
        pathwayId: patient.assignedPathwayId,
        payload: mapToUpdatePathwayPayload(
          patient.patientId,
          selectedPathway,
          values
        ),
      }

      updatePathway(updatePathwayArgs, {
        onSuccess: () => {
          toast.success(
            'Pathway updated successfully! Upcoming Tasks should appear for the assigned navigator shortly.',
            {
              autoClose: WAIT_TIME_FOR_CLOSE_PATHWAY_TOAST,
            }
          )
          setIsLocked(true)
        },
        onError: () => {
          toast.error('Unable to update pathway')
        },
        onSettled: () => {
          setShowLoader(false)
        },
      })
    } else {
      // If the patient does not have a locked pathway

      const createPathwayArgs = mapToCreatePathwayArgs(
        selectedPathway,
        patient,
        values
      )

      createPathway(createPathwayArgs, {
        onSuccess: () => {
          toast.success(
            'Pathway assigned successfully! Upcoming Tasks should appear for the assigned navigator shortly.',
            {
              autoClose: WAIT_TIME_FOR_CLOSE_PATHWAY_TOAST,
            }
          )
          setIsLocked(true)
        },
        onError: () => {
          toast.error('Unable to assign pathway')
        },
        onSettled: () => {
          setShowLoader(false)
        },
      })
    }

    setTimeout(() => {
      refreshTasks()
    }, WAIT_TIME_FOR_TASKS_TO_BE_CREATED)
  }

  useEffect(() => {
    const subscription = formMethods.watch((value, { name, type }) => {
      if (type === 'change') {
        switch (name) {
          case 'pathway':
            if (value['pathway']) {
              formMethods.reset((formValues) => ({
                ...formValues,
                trackType: '',
                track: '',
                pathwayType: '',
              }))

              if (value['pathway'] !== 'Ortho') {
                setAvailableTracks(getAvailableTracks(value['pathway']))
              }
              setAvailableTypes(getAvailableTypes(value['pathway']))

              if (
                patientHasLockedPathway(patient) &&
                value['pathway'] !== patient.pathway
              ) {
                setIsReassign(true)
                formMethods.reset((formValues) => ({
                  ...formValues,
                  commsTrigger: null,
                }))
              }
            }
            break
          case 'trackType':
            if (value['trackType'] && value['pathway'] === 'Ortho') {
              formMethods.reset((formValues) => ({
                ...formValues,
                track: '',
              }))
              setAvailableTracks(getOrthoAvailableTracks(value['trackType']))
            }
            break
          case 'track':
            if (value['track']) {
              if (
                patientHasLockedPathway(patient) &&
                value['track'] !== patient.track
              ) {
                setIsReassign(true)
                formMethods.reset((formValues) => ({
                  ...formValues,
                  commsTrigger: new Date(),
                }))
              } else {
                formMethods.reset((formValues) => ({
                  ...formValues,
                  commsTrigger: getInitialLockDate(),
                }))
              }
            }
            break
          default:
            break
        }
      }
    })

    return () => subscription.unsubscribe()
  }, [
    formMethods,
    getInitialLockDate,
    getAvailableTracks,
    getAvailableTypes,
    patient,
    getOrthoAvailableTracks,
  ])

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(submitHandler)}>
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '24px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
          >
            <Controller
              name="pathway"
              control={formMethods.control}
              render={({ field, fieldState }) => (
                <DefaultPatwayFormSelectContainer>
                  <VanillaFormFieldContainer
                    label={LABELS.pathway}
                    labelComponent={'inputLabel'}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    inputLabelProps={{ required: true }}
                    formControlProps={{
                      size: 'small',
                      disabled: pathwayInputIsDisabled,
                    }}
                    formControlSx={{
                      ...getFormControlStyles(),
                      ...getOutlineInputNotAllowedCursorStyle(
                        pathwayInputIsDisabled
                      ),
                    }}
                  >
                    <Select
                      required={true}
                      label={LABELS.pathway}
                      value={field.value}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      sx={{
                        ...getSelectInputStyles(),
                      }}
                    >
                      {getPathwayOptions().map((pathway) => (
                        <MenuItem key={pathway} value={pathway}>
                          {pathway}
                        </MenuItem>
                      ))}
                    </Select>
                  </VanillaFormFieldContainer>
                  {isLocked ? <PathwayLockIcon /> : null}
                </DefaultPatwayFormSelectContainer>
              )}
            />
            {orthoPathwayIsSelected ? (
              <Controller
                name="trackType"
                control={formMethods.control}
                render={({ field, fieldState }) => (
                  <DefaultPatwayFormSelectContainer>
                    <VanillaFormFieldContainer
                      label={LABELS.trackType}
                      labelComponent={'inputLabel'}
                      error={!!fieldState.error}
                      helperText={getTrackTypeHelperText()}
                      inputLabelProps={{ required: true }}
                      formControlProps={{
                        size: 'small',
                        disabled: trackTypeInputIsDisabled,
                      }}
                      formControlSx={{
                        ...getFormControlStyles(),
                        ...getOutlineInputNotAllowedCursorStyle(
                          trackTypeInputIsDisabled
                        ),
                      }}
                    >
                      <Select
                        required={true}
                        label={LABELS.trackType}
                        value={field.value}
                        onBlur={field.onBlur}
                        onChange={field.onChange}
                        sx={{
                          ...getSelectInputStyles(),
                        }}
                      >
                        {availableTracksTypes.map((trackType) => (
                          <MenuItem key={trackType} value={trackType}>
                            {trackType}
                          </MenuItem>
                        ))}
                      </Select>
                    </VanillaFormFieldContainer>
                    {isLocked ? <PathwayLockIcon /> : null}
                  </DefaultPatwayFormSelectContainer>
                )}
              />
            ) : null}
            <Controller
              name="track"
              control={formMethods.control}
              render={({ field, fieldState }) => (
                <DefaultPatwayFormSelectContainer>
                  <VanillaFormFieldContainer
                    label={LABELS.track}
                    labelComponent={'inputLabel'}
                    error={!!fieldState.error}
                    helperText={getTrackHelperText()}
                    inputLabelProps={{ required: true }}
                    formControlProps={{
                      size: 'small',
                      disabled: trackInputIsDisabled,
                    }}
                    formControlSx={{
                      ...getFormControlStyles(),
                      ...getOutlineInputNotAllowedCursorStyle(
                        trackInputIsDisabled
                      ),
                    }}
                  >
                    <Select
                      required={true}
                      label={LABELS.track}
                      value={field.value}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      sx={{
                        ...getSelectInputStyles(),
                      }}
                    >
                      {availableTracks.map((track) => (
                        <MenuItem key={track.key} value={track.value}>
                          {track.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </VanillaFormFieldContainer>
                  {isLocked ? <PathwayLockIcon /> : null}
                </DefaultPatwayFormSelectContainer>
              )}
            />
            <Controller
              name="pathwayType"
              control={formMethods.control}
              render={({ field, fieldState }) => (
                <DefaultPatwayFormSelectContainer>
                  <VanillaFormFieldContainer
                    label={LABELS.pathwayType}
                    labelComponent={'inputLabel'}
                    error={!!fieldState.error}
                    helperText={getPathwayTypeHelperText()}
                    inputLabelProps={{ required: true }}
                    formControlProps={{
                      size: 'small',
                      disabled: pathwayTypeInputIsDisabled,
                    }}
                    formControlSx={{
                      ...getFormControlStyles(),
                      ...getOutlineInputNotAllowedCursorStyle(
                        pathwayTypeInputIsDisabled
                      ),
                    }}
                  >
                    <Select
                      required={true}
                      label={LABELS.pathwayType}
                      value={field.value}
                      onBlur={field.onBlur}
                      onChange={getPathwayTypeChangeHandler(field)}
                      sx={{
                        ...getSelectInputStyles(),
                      }}
                    >
                      {availableTypes.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </VanillaFormFieldContainer>
                  {isLocked ? <PathwayLockIcon /> : null}
                </DefaultPatwayFormSelectContainer>
              )}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
          >
            <Controller
              name="navigatorId"
              control={formMethods.control}
              render={({ field, fieldState }) => (
                <DefaultPatwayFormSelectContainer>
                  <VanillaFormFieldContainer
                    label={LABELS.navigator}
                    labelComponent={'inputLabel'}
                    error={!!fieldState.error}
                    helperText={getPathwayTypeHelperText()}
                    inputLabelProps={{ required: true }}
                    formControlProps={{
                      size: 'small',
                      disabled: navigatorInputIsDisabled,
                    }}
                    formControlSx={{
                      ...getFormControlStyles(),
                      ...getOutlineInputNotAllowedCursorStyle(
                        navigatorInputIsDisabled
                      ),
                    }}
                  >
                    <Select
                      required={true}
                      label={LABELS.navigator}
                      value={field.value}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      sx={{
                        ...getSelectInputStyles(),
                        '& .totalPathwayCount': {
                          display: 'none',
                        },
                      }}
                    >
                      {navigators.map((navigator) => (
                        <MenuItem
                          key={navigator.id}
                          value={navigator.id}
                          sx={{
                            borderBottom: '1px solid rgba(0, 0, 0, 0.18) ',
                            '&:last-child': { borderBottom: 'none' },
                          }}
                        >
                          <Box>
                            <Typography>{navigator.name} </Typography>
                            <Typography
                              variant={'caption'}
                              color={'gray'}
                              className={'totalPathwayCount'}
                            >
                              Assigned Pathways:{' '}
                              {navigator.totalAssignedPathways}
                            </Typography>
                          </Box>
                        </MenuItem>
                      ))}
                    </Select>
                  </VanillaFormFieldContainer>
                  {isLocked ? <PathwayLockIcon /> : null}
                </DefaultPatwayFormSelectContainer>
              )}
            />
            {pathwayDateFieldIsRequired(trackWatcher) ? (
              <Box sx={{ flexBasis: '210px' }}>
                <Controller
                  name="commsTrigger"
                  control={formMethods.control}
                  render={({ field, fieldState }) => (
                    <Box sx={{ position: 'relative' }}>
                      <TCDatePicker
                        label={getApptDateLabel()}
                        value={field.value ? field.value : null}
                        disabled={commsTriggerInputIsDisabled}
                        onChange={(newValue) => {
                          field.onChange(newValue)
                        }}
                        slotProps={{
                          textField: {
                            disabled: commsTriggerInputIsDisabled,
                            size: 'small',
                            error: !!fieldState.error,
                            helperText: fieldState.error ? 'Invalid date' : '',
                            sx: {
                              '& .MuiInputBase-root': {
                                backgroundColor: 'white',
                              },
                              '& .MuiInputBase-root.Mui-disabled': {
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                  borderColor: 'rgba(0, 0, 0, 0.23)',
                                },
                              },
                              '& .MuiInputBase-input': {
                                ...getInputLockedStyles(),
                              },
                              '& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled':
                                {
                                  cursor: 'not-allowed',
                                },
                            },
                          },
                        }}
                      />

                      {isLocked ? <PathwayLockIcon /> : null}
                    </Box>
                  )}
                />
              </Box>
            ) : null}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                columnGap: '8px',
                flexBasis: '210px',
              }}
            >
              {showCancelButton ? (
                <Button
                  variant={'outlined'}
                  color={'secondary'}
                  onClick={handleCancelClick}
                >
                  CANCEL
                </Button>
              ) : null}
              <Tooltip title={getLockButtonDisabledTitle()} arrow>
                <Box>
                  {isLocked ? (
                    <Button
                      type="button"
                      color="secondary"
                      variant="outlined"
                      onClick={handleUnlockClick}
                      disabled={lockButtonIsDisabled}
                    >
                      UNLOCK
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      color="secondary"
                      variant="contained"
                      disabled={lockButtonIsDisabled}
                    >
                      {submitButtonText}
                    </Button>
                  )}
                </Box>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </form>

      <WarningModal
        open={showPathwayTypeWarning}
        onConfirm={handleConfirmPathwayType}
        title={getWarningModalTitle()}
        confirmButtonText={'OK'}
        confirmButtonVariant={'contained'}
        textAlignment={'center'}
        actionsAlignment={'center'}
      />

      <BackdropLoader open={showLoader} />
      <DevTool control={formMethods.control} />
    </FormProvider>
  )
}
