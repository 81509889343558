import { useQuery } from '@tanstack/react-query'

import {
  GetQueuePatientsParams,
  GetQueuePatientsFilters,
  GetQueuePatientsReturns,
  GetQueuePatientsError,
} from 'src/features/queues/domain'
import { useQueuePatientsQueryParams } from 'src/features/queues/presentation'
import { useQueuesUseCases } from 'src/features/queues/queues.service-locator'

export const GET_QUEUE_PATIENTS_QUERY_KEY = 'getQueuePatients'
export const DEFAULT_QUEUE_PATIENTS_LIMIT_PARAM = '25'

export const useGetQueuePatientsQuery = () => {
  const {
    getLimit,
    getPage,
    getAssignedToMe,
    getQ,
    getReferralSource,
    getStage,
    getPayor,
    getAssignedTo,
    getLastToContact,
    getAttemptsQtyRange,
    getDateOfLastAttemptRange,
  } = useQueuePatientsQueryParams()
  const queuesUseCases = useQueuesUseCases()

  const queuePatientsParams: GetQueuePatientsParams = {
    limit: getLimit() || DEFAULT_QUEUE_PATIENTS_LIMIT_PARAM,
    page: getPage() || '1',
    q: getQ(),
    assignedToMe: getAssignedToMe(),
  }

  const queuePatientsFilters: GetQueuePatientsFilters = {
    referralSource: getReferralSource(),
    stage: getStage(),
    payor: getPayor(),
    assignedTo: getAssignedTo(),
    lastToContact: getLastToContact(),
    attemptsQtyRange: getAttemptsQtyRange(),
    dateOfLastContactRange: getDateOfLastAttemptRange(),
  }

  const { data, isLoading, isError, refetch, isRefetching } = useQuery<
    GetQueuePatientsReturns,
    GetQueuePatientsError
  >(
    [
      GET_QUEUE_PATIENTS_QUERY_KEY,
      { ...queuePatientsParams, ...queuePatientsFilters },
    ],
    () => {
      return queuesUseCases.getQueuePatients({
        params: queuePatientsParams,
        filters: queuePatientsFilters,
      })
    },
    {
      staleTime: 1000 * 60, // 1 minute
    }
  )

  return {
    totalCount: data ? data.count : 0,
    queuePatients: data ? data.patients : [],
    getQueuePatientsIsLoading: isLoading,
    getQueuePatientsIsError: isError,
    getQueuePatientsIsRefetching: isRefetching,
    refetchQueuePatients: refetch,
  }
}
