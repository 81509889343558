import { ErrorMessage } from '@hookform/error-message'
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { useEffect, useState } from 'react'
import {
  Control,
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form'
import { bodyRegionsToNoteMultiSelectOptions } from 'src/features/msk/presentation'

type BodyRegionFieldProps<T extends FieldValues> = {
  control: Control<T>
}

export const BodyRegionField = <T extends FieldValues>({
  control,
}: BodyRegionFieldProps<T>) => {
  const name = 'bodyRegionToNote' as FieldPath<T>

  const [selectedBodyRegions, setSelectedBodyRegions] = useState<string[]>([])
  const { setValue, getValues } = useFormContext()

  const handleChange = (
    event: SelectChangeEvent<typeof selectedBodyRegions>
  ) => {
    const value = event.target.value
    setSelectedBodyRegions(value as string[])
  }

  useEffect(() => {
    setValue('bodyRegionToNote', selectedBodyRegions.join(', '))
  }, [selectedBodyRegions, name, setValue, getValues])

  return (
    <Controller
      name={name}
      control={control}
      render={() => (
        <FormControl size={'small'} sx={{ width: '100%' }}>
          <InputLabel id={'body-region-to-note-label'}>
            Any specific body region to note?
          </InputLabel>
          <Select
            labelId={'body-region-to-note-label'}
            id={'body-region-to-note'}
            label={'Any specific body region to note?'}
            multiple
            value={selectedBodyRegions}
            onChange={handleChange}
            displayEmpty
            renderValue={(selected) => selected.join(', ')}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                },
              },
            }}
          >
            {bodyRegionsToNoteMultiSelectOptions.map((bodyRegion) => (
              <MenuItem key={bodyRegion.id} value={bodyRegion.display}>
                <Checkbox
                  checked={selectedBodyRegions.includes(bodyRegion.display)}
                />
                <ListItemText primary={bodyRegion.display} />
              </MenuItem>
            ))}
          </Select>
          <ErrorMessage
            name={name}
            render={({ message }) => (
              <FormHelperText sx={{ m: 0 }} error={true}>
                {message}
              </FormHelperText>
            )}
          />
        </FormControl>
      )}
    />
  )
}
