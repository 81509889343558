import {
  GetTodayTasksByUserIdServiceResponse,
  GetDoneTasksByUserIdServiceResponse,
  GetUpcomingTasksByUserIdServiceResponse,
  GetUrgentTasksByUserIdServiceResponse,
  GetOverdueTasksByUserIdServiceResponse,
} from 'src/features/tasks/infrastructure/'

export const getTodayTasksByUserIdServiceResponseMock: GetTodayTasksByUserIdServiceResponse =
  {
    // urgent: [
    //   {
    //     eventId: '48e4ba74-11c9-48d0-8212-b26edba9343a',
    //     comments: [
    //       {
    //         eventCommentId: 'be7a484a-3da7-4c7d-a601-e7778ed6e46a',
    //         comment: 'testing',
    //         createdAt: '2023-06-06T17:29:04.608781+00:00',
    //         name: 'E2E Automation ',
    //       },
    //     ],
    //     name: 'David Granados',
    //     patientId: 'ca472ffd-ba1d-4375-b792-4dd1ad8fbe36',
    //     status: null,
    //     urgent: true,
    //     dueDate: '2023-06-06T00:00:00.000Z',
    //     title: 'Call Reminder',
    //     description: 'Call Reminder',
    //     assignedTCMember: 'E2E Automation ',
    //     completedAt: null,
    //     assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
    //   },
    // ],
    // overdue: [],
    today: [],
  }

export const getDoneTasksByUserIdServiceResponseMock: GetDoneTasksByUserIdServiceResponse =
  {
    done: [
      {
        eventId: '1168ec74-cd96-4412-92de-d12c0121e313',
        comments: [
          {
            eventCommentId: '8c23cf6a-1501-4a1a-9a1d-444e6a29c852',
            comment: 'null',
            createdAt: '2023-06-08T23:29:36.274348+00:00',
            name: 'E2E Automation ',
          },
        ],
        name: 'Elianora Kinghorn',
        patientId: 'fa9c4a6b-25dc-4b4a-8317-3c87e98989e6',
        status: 'complete',
        urgent: false,
        dueDate: '2023-06-08T00:00:00.000Z',
        title: 'test',
        description: 'test',
        assignedTCMember: 'E2E Automation ',
        completedAt: '2023-06-14T00:00:00.000Z',
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      },
      {
        eventId: '306098cd-8ae0-4068-a65b-b2b53138f2f4',
        comments: [
          {
            eventCommentId: 'f177c0fd-0c9c-4de9-a705-9c3cb5c92953',
            comment: 'null',
            createdAt: '2023-06-09T00:04:48.986804+00:00',
            name: 'E2E Automation ',
          },
        ],
        name: 'Elianora Kinghorn',
        patientId: 'fa9c4a6b-25dc-4b4a-8317-3c87e98989e6',
        status: 'complete',
        urgent: true,
        dueDate: '2023-06-08T00:00:00.000Z',
        title: 'test 2',
        description: 'null',
        assignedTCMember: 'E2E Automation ',
        completedAt: '2023-06-14T00:00:00.000Z',
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      },
      {
        eventId: '48e4ba74-11c9-48d0-8212-b26edba9343a',
        comments: [
          {
            eventCommentId: 'be7a484a-3da7-4c7d-a601-e7778ed6e46a',
            comment: 'testing',
            createdAt: '2023-06-06T17:29:04.608781+00:00',
            name: 'E2E Automation ',
          },
        ],
        name: 'NathaliaA Kinvan',
        patientId: 'ca472ffd-ba1d-4375-b792-4dd1ad8fbe36',
        status: 'complete',
        urgent: true,
        dueDate: '2023-06-06T00:00:00.000Z',
        title: 'Call Reminder',
        description: 'Call Reminder',
        assignedTCMember: 'E2E Automation ',
        completedAt: '2023-06-14T00:00:00.000Z',
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      },
    ],
  }

export const getUpcomingTasksByUserIdServiceResponseMock: GetUpcomingTasksByUserIdServiceResponse =
  {
    upcoming: [
      {
        eventId: 'ffb39a8a-91e4-488b-9fd1-b43c5d542f5b',
        comments: null,
        name: 'Zorina Hassen',
        patientId: '2fabae5d-5cc1-40fc-b8ce-32d73fcb31ec',
        status: 'new',
        urgent: false,
        dueDate: '2023-07-20T00:00:00.000Z',
        title: 'Call Reminder',
        description: 'Call Reminder',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      },
      {
        eventId: 'e616fbbc-127a-462a-b3f1-f22a9b52bc30',
        comments: null,
        name: 'Che Boakes',
        patientId: '49c4a3af-c849-427c-b0f6-b1f8adf3fa4d',
        status: 'new',
        urgent: false,
        dueDate: '2023-07-24T00:00:00.000Z',
        title: 'Call Reminder',
        description: 'Call Reminder',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      },
      {
        eventId: '013fb16d-2058-4851-a6e2-8394dcea50ea',
        comments: null,
        name: 'Joete Drewett',
        patientId: 'faeed079-e4ce-42da-bbd7-c61c3414b4f8',
        status: 'new',
        urgent: false,
        dueDate: '2023-07-25T00:00:00.000Z',
        title: 'Call Reminder',
        description: 'Call Reminder',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      },
      {
        eventId: '30929b61-a76c-47fa-9c87-e2da22c4bf73',
        comments: null,
        name: 'Darell Botger',
        patientId: '7df329df-5cca-45cd-8c46-93a1ab07655d',
        status: 'new',
        urgent: false,
        dueDate: '2023-07-28T00:00:00.000Z',
        title: 'Call Reminder',
        description: 'Call Reminder',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      },
      {
        eventId: '4311ef79-c14c-4146-905a-ff620cb412ef',
        comments: null,
        name: 'Tally Addeycott',
        patientId: '221a23ec-cc6e-42ee-8663-614a1f9d8170',
        status: 'new',
        urgent: false,
        dueDate: '2023-07-31T00:00:00.000Z',
        title: 'Call Reminder',
        description: 'Call Reminder',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      },
      {
        eventId: '5e05eabf-cd79-4560-b447-5e965a4af181',
        comments: null,
        name: 'Zorina Hassen',
        patientId: '2fabae5d-5cc1-40fc-b8ce-32d73fcb31ec',
        status: 'new',
        urgent: false,
        dueDate: '2023-07-31T00:00:00.000Z',
        title: 'Call Reminder',
        description: 'Call Reminder',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      },
      {
        eventId: 'b30dc1d0-64d9-4ceb-8721-13bff90e0da5',
        comments: null,
        name: 'Jennifer Lipson',
        patientId: 'fb3479bc-b59a-4c45-9cd0-742bcb508820',
        status: 'new',
        urgent: false,
        dueDate: '2023-07-31T00:00:00.000Z',
        title: 'Call Reminder',
        description: 'Call Reminder',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      },
      {
        eventId: '85b9fd79-b3fa-4aa3-895b-526ccd67b94f',
        comments: null,
        name: 'Allissa Spavon',
        patientId: 'f934bf6d-4e92-4b83-aacc-95d5f8ef9503',
        status: 'new',
        urgent: false,
        dueDate: '2023-08-07T00:00:00.000Z',
        title:
          'Chart Check, If care completed, call the patient and complete Ortho Touchpoint Call.',
        description:
          'Chart Check, If care completed, call the patient and complete Ortho Touchpoint Call.',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      },
      {
        eventId: 'aed8835e-892b-40ed-a28e-05abac13688a',
        comments: null,
        name: 'Carlotta Elrick',
        patientId: 'f531b908-241e-4bc3-912e-f6acc8cd18a4',
        status: 'new',
        urgent: false,
        dueDate: '2023-08-07T00:00:00.000Z',
        title:
          'Chart Check, If care completed, call the patient and complete Ortho Touchpoint Call.',
        description:
          'Chart Check, If care completed, call the patient and complete Ortho Touchpoint Call.',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      },
      {
        eventId: 'f682005b-f9f9-4048-935f-d46c6bb05130',
        comments: null,
        name: 'Joete Drewett',
        patientId: 'faeed079-e4ce-42da-bbd7-c61c3414b4f8',
        status: 'new',
        urgent: false,
        dueDate: '2023-08-10T00:00:00.000Z',
        title: 'Call Reminder',
        description: 'Call Reminder',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      },
    ],
    lastTaskId: 'f682005b-f9f9-4048-935f-d46c6bb05130',
    // lastTaskDate: '2023-08-10T00:00:00.000Z',
  }

export const getUrgentTasksByUserIdServiceResponseMock: GetUrgentTasksByUserIdServiceResponse =
  {
    urgent: [
      {
        eventId: '0026e847-5950-4fe8-a1a8-b56876509012',
        comments: null,
        name: 'Joete Drewett',
        patientId: 'faeed079-e4ce-42da-bbd7-c61c3414b4f8',
        status: 'new',
        urgent: true,
        dueDate: '2023-07-14T12:56:33.762Z',
        title: 'Serious Symptom follow-up',
        description: 'Follow up with patient following Serious Symptom Warning',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      },
      {
        eventId: '07c8da14-2894-4341-a8bc-2d3c3f9cc17d',
        comments: null,
        name: 'Joete Drewett',
        patientId: 'faeed079-e4ce-42da-bbd7-c61c3414b4f8',
        status: 'new',
        urgent: true,
        dueDate: '2023-07-13T14:34:11.233Z',
        title: 'Serious Symptom follow-up',
        description: 'Follow up with patient following Serious Symptom Warning',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      },
      {
        eventId: '0847985e-5a58-4951-9d2f-ee75938e423c',
        comments: null,
        name: 'Joete Drewett',
        patientId: 'faeed079-e4ce-42da-bbd7-c61c3414b4f8',
        status: 'new',
        urgent: true,
        dueDate: '2023-07-13T16:47:42.595Z',
        title: 'Serious Symptom follow-up',
        description: 'Follow up with patient following Serious Symptom Warning',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      },
      {
        eventId: '089fa36d-2f29-4476-97f3-a0c9d62ca47f',
        comments: null,
        name: 'Joete Drewett',
        patientId: 'faeed079-e4ce-42da-bbd7-c61c3414b4f8',
        status: 'new',
        urgent: true,
        dueDate: '2023-07-13T18:30:10.632Z',
        title: 'Serious Symptom follow-up',
        description: 'Follow up with patient following Serious Symptom Warning',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      },
      {
        eventId: '0ce0fe1e-6a3d-4216-b6d8-b38917ba712d',
        comments: null,
        name: 'Joete Drewett',
        patientId: 'faeed079-e4ce-42da-bbd7-c61c3414b4f8',
        status: 'new',
        urgent: true,
        dueDate: '2023-07-13T16:50:00.935Z',
        title: 'Serious Symptom follow-up',
        description: 'Follow up with patient following Serious Symptom Warning',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      },
      {
        eventId: '0de6b319-e2d5-4e64-a934-a4a00d9d4a2f',
        comments: null,
        name: 'Joete Drewett',
        patientId: 'faeed079-e4ce-42da-bbd7-c61c3414b4f8',
        status: 'new',
        urgent: true,
        dueDate: '2023-07-14T13:00:52.861Z',
        title: 'Serious Symptom follow-up',
        description: 'Follow up with patient following Serious Symptom Warning',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      },
      {
        eventId: '1062ce02-f940-4674-8c96-10e345cac9ba',
        comments: null,
        name: 'Joete Drewett',
        patientId: 'faeed079-e4ce-42da-bbd7-c61c3414b4f8',
        status: 'new',
        urgent: true,
        dueDate: '2023-07-13T18:19:05.790Z',
        title: 'Serious Symptom follow-up',
        description: 'Follow up with patient following Serious Symptom Warning',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      },
      {
        eventId: '10a09440-3692-45cb-83bf-7493a4e980b3',
        comments: null,
        name: 'Joete Drewett',
        patientId: 'faeed079-e4ce-42da-bbd7-c61c3414b4f8',
        status: 'new',
        urgent: true,
        dueDate: '2023-07-13T17:04:42.105Z',
        title: 'Serious Symptom follow-up',
        description: 'Follow up with patient following Serious Symptom Warning',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      },
      {
        eventId: '13aee567-8929-4fbc-a8d9-61f9eda69819',
        comments: null,
        name: 'Joete Drewett',
        patientId: 'faeed079-e4ce-42da-bbd7-c61c3414b4f8',
        status: 'new',
        urgent: true,
        dueDate: '2023-07-13T17:11:45.775Z',
        title: 'Serious Symptom follow-up',
        description: 'Follow up with patient following Serious Symptom Warning',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      },
      {
        eventId: '164a69fb-747c-4fe4-86d0-861745b162b5',
        comments: [
          {
            eventCommentId: '6540c0be-afab-427b-ab58-f08582c74818',
            comment: 'TEST',
            createdAt: '2023-06-21T02:04:33.4028+00:00',
            name: 'E2E Automation ',
          },
        ],
        name: 'Trix Bartoloma',
        patientId: '3c2343eb-bc60-45bf-9d48-41d33c1437f7',
        status: 'new',
        urgent: true,
        dueDate: '2023-06-27T00:00:00.000Z',
        title: 'Call Reminder',
        description: 'Call Reminder',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      },
    ],
    lastTaskId: '164a69fb-747c-4fe4-86d0-861745b162b5',
    // lastTaskDate: '2023-06-27T00:00:00.000Z',
  }

export const getOverdueTasksByUserIdServiceResponseMock: GetOverdueTasksByUserIdServiceResponse =
  {
    overdue: [
      {
        eventId: '355da0ae-7d66-4f79-b7a9-62af01dac5c0',
        comments: null,
        name: 'Allissa Spavon',
        patientId: 'f934bf6d-4e92-4b83-aacc-95d5f8ef9503',
        status: 'new',
        urgent: false,
        dueDate: '2023-06-23T00:00:00.000Z',
        title: 'Chart Check',
        description: 'Chart Check',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      },
      {
        eventId: '7420e474-e5b4-4afb-bf68-30fb83c86f0a',
        comments: null,
        name: 'Joete Drewett',
        patientId: 'faeed079-e4ce-42da-bbd7-c61c3414b4f8',
        status: 'new',
        urgent: false,
        dueDate: '2023-06-23T00:00:00.000Z',
        title: 'Chart Check',
        description: 'Chart Check',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      },
      {
        eventId: '57d4ff25-4647-4d5f-8374-965abb43c4ba',
        comments: null,
        name: 'Carlotta Elrick',
        patientId: 'f531b908-241e-4bc3-912e-f6acc8cd18a4',
        status: 'new',
        urgent: false,
        dueDate: '2023-06-26T00:00:00.000Z',
        title: 'Chart Check',
        description: 'Chart Check',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      },
      {
        eventId: 'b5de2bfa-594c-4ff2-aba9-22a6d2788a2d',
        comments: null,
        name: 'Marcia Corless',
        patientId: '2d46ded3-f201-4bc4-a07f-8f50ad4f0135',
        status: 'new',
        urgent: false,
        dueDate: '2023-06-29T00:00:00.000Z',
        title: 'Call Reminder',
        description: 'Call Reminder',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      },
      {
        eventId: '135e2d8f-bef7-4c2c-aa70-2d9f47947b38',
        comments: [
          {
            eventCommentId: '69f544d1-8558-4777-bcfe-4ffbd8bc57cd',
            comment: 'null',
            createdAt: '2023-07-13T18:31:51.836159+00:00',
            name: 'Ryan Cary',
          },
        ],
        name: 'Sour Kid',
        patientId: '00054f1a-d517-44f7-bfcb-e90681c5e17d',
        status: 'new',
        urgent: false,
        dueDate: '2023-07-13T00:00:00.000Z',
        title: '07/13/2023 Task for E2E',
        description: 'E2E testing task',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      },
      {
        eventId: '2d11c0c8-6d47-46af-92ff-14405622f199',
        comments: [
          {
            eventCommentId: 'bb26c9e9-2c32-4b60-ac0a-55706b141f62',
            comment: 'null',
            createdAt: '2023-07-13T18:31:50.907003+00:00',
            name: 'Ryan Cary',
          },
        ],
        name: 'Sour Kid',
        patientId: '00054f1a-d517-44f7-bfcb-e90681c5e17d',
        status: 'new',
        urgent: false,
        dueDate: '2023-07-13T00:00:00.000Z',
        title: '07/13/2023 Task for E2E',
        description: 'E2E testing task',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      },
      {
        eventId: '3c7f5ca4-e95a-4323-9b90-c7557cdcaec6',
        comments: [
          {
            eventCommentId: '7619e678-1993-478f-9db9-6a63ba3bc50f',
            comment: 'null',
            createdAt: '2023-07-13T18:31:57.172339+00:00',
            name: 'Ryan Cary',
          },
        ],
        name: 'Sour Kid',
        patientId: '00054f1a-d517-44f7-bfcb-e90681c5e17d',
        status: 'new',
        urgent: false,
        dueDate: '2023-07-13T00:00:00.000Z',
        title: '07/13/2023 Task for E2E',
        description: 'E2E testing task',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      },
      {
        eventId: '85f170d3-7efb-4a13-9697-567462c89dc9',
        comments: [
          {
            eventCommentId: '9d4542dc-b1d3-41c7-b36f-0cdc08f279f8',
            comment: 'null',
            createdAt: '2023-07-13T18:31:49.915459+00:00',
            name: 'Ryan Cary',
          },
        ],
        name: 'Sour Kid',
        patientId: '00054f1a-d517-44f7-bfcb-e90681c5e17d',
        status: 'new',
        urgent: false,
        dueDate: '2023-07-13T00:00:00.000Z',
        title: '07/13/2023 Task for E2E',
        description: 'E2E testing task',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      },
      {
        eventId: '920e4963-69f9-4ee0-9c2e-256d6699973b',
        comments: [
          {
            eventCommentId: 'e0bd59a3-a485-493c-8cc4-fb6a12dd810a',
            comment: 'null',
            createdAt: '2023-07-13T18:31:57.968125+00:00',
            name: 'Ryan Cary',
          },
        ],
        name: 'Sour Kid',
        patientId: '00054f1a-d517-44f7-bfcb-e90681c5e17d',
        status: 'new',
        urgent: false,
        dueDate: '2023-07-13T00:00:00.000Z',
        title: '07/13/2023 Task for E2E',
        description: 'E2E testing task',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      },
      {
        eventId: '9c405ab3-4a26-4d6d-88c1-b23dc43676ad',
        comments: [
          {
            eventCommentId: '1ad73d02-e94c-423b-a8d5-f1b2ce43d551',
            comment: 'null',
            createdAt: '2023-07-13T18:31:58.667474+00:00',
            name: 'Ryan Cary',
          },
        ],
        name: 'Sour Kid',
        patientId: '00054f1a-d517-44f7-bfcb-e90681c5e17d',
        status: 'new',
        urgent: false,
        dueDate: '2023-07-13T00:00:00.000Z',
        title: '07/13/2023 Task for E2E',
        description: 'E2E testing task',
        assignedTCMember: 'E2E Automation ',
        completedAt: null,
        assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      },
    ],
    lastTaskId: '9c405ab3-4a26-4d6d-88c1-b23dc43676ad',
    // lastTaskDate: '2023-07-13T00:00:00.000Z',
  }
