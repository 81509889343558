import { ErrorMessage } from '@hookform/error-message'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form'
import { filterNoneValueOptions } from 'src/features/notes/adapters'
import { yesNo } from 'src/features/shared/presentation'

export const PreferredProviderField = <T extends FieldValues>() => {
  const name = 'preferredProvider' as FieldPath<T>
  const { register } = useFormContext()

  return (
    <Controller
      name={name}
      {...register}
      render={({ field }) => (
        <FormControl size={'small'} sx={{ width: '100%' }}>
          <InputLabel id={'preferred-provider-label'}>
            Preferred Provider
          </InputLabel>
          <Select
            labelId={'preferred-provider-label'}
            id={'preferred-provider'}
            label={'Preferred Provider'}
            value={field.value}
            onChange={(event) => {
              field.onChange(event.target.value)
            }}
          >
            {filterNoneValueOptions(yesNo).map((value) => (
              <MenuItem key={value.id} value={value.label}>
                {value.label}
              </MenuItem>
            ))}
          </Select>
          <ErrorMessage
            name={name}
            render={({ message }) => (
              <FormHelperText sx={{ m: 0 }} error={true}>
                {message}
              </FormHelperText>
            )}
          />
        </FormControl>
      )}
    />
  )
}
