import {
  Radio,
  RadioGroup,
  FormControlLabel,
  RadioGroupProps,
} from '@mui/material'

import { VanillaFormFieldContainer } from 'src/features/shared/presentation'

type YesNoRadioGroupProps = RadioGroupProps & {
  label: string
  helperText?: string
  error?: boolean
  dataTestIdPreffix?: string
}

export function YesNoRadioGroup({
  label,
  id,
  error,
  helperText,
  dataTestIdPreffix = '',
  ...props
}: YesNoRadioGroupProps) {
  const dataTestIdYes = dataTestIdPreffix
    ? `${dataTestIdPreffix}_yes`
    : undefined
  const dataTestIdNo = dataTestIdPreffix ? `${dataTestIdPreffix}_no` : undefined
  return (
    <VanillaFormFieldContainer
      id={id}
      label={label}
      error={error}
      helperText={helperText}
    >
      <RadioGroup row id={id} {...props}>
        <FormControlLabel
          value={'yes'}
          control={<Radio />}
          label={'Yes'}
          data-testid={dataTestIdYes}
        />
        <FormControlLabel
          value={'no'}
          control={<Radio />}
          label={'No'}
          data-testid={dataTestIdNo}
        />
      </RadioGroup>
    </VanillaFormFieldContainer>
  )
}
