import {
  SelectChangeEvent,
  Box,
  Select,
  MenuItem,
  TextField,
  Divider,
  FormHelperText,
} from '@mui/material'
import { FC } from 'react'

import { LoadMSKFormReturns } from 'src/features/msk/domain'
import {
  useMSKFormStore,
  MSKFormStateAdditionalQuestionsFieldOptions,
} from 'src/features/shared/infrastructure'
import { VanillaFormFieldContainer } from 'src/features/shared/presentation'

import {
  QUESTIONS_LABELS,
  getSelectDefaultSx,
  fieldOptions,
  getTextFieldDefaultSx,
} from './helpers'

type MSKFormStepThreePage1Props = {
  loadedMSKForm: LoadMSKFormReturns
}

export const MSKFormStepThreePage1: FC<MSKFormStepThreePage1Props> = ({
  loadedMSKForm,
}) => {
  const { additionalQuestions, setAdditionalQuestions, errors, deleteError } =
    useMSKFormStore({
      loadedMSKForm,
    })

  const getPhScore = () => {
    if (additionalQuestions.phScore === null) {
      return ''
    }
    return additionalQuestions.phScore
  }

  const getGad2Score = () => {
    if (additionalQuestions.gad2Score === null) {
      return ''
    }
    return additionalQuestions.gad2Score
  }

  const handlePhQ1Change = (event: SelectChangeEvent) => {
    const value = event.target
      .value as MSKFormStateAdditionalQuestionsFieldOptions['v1']
    setAdditionalQuestions({
      phQ1: value,
    })
  }

  const handlePhQ2Change = (event: SelectChangeEvent) => {
    const value = event.target
      .value as MSKFormStateAdditionalQuestionsFieldOptions['v1']
    setAdditionalQuestions({
      phQ2: value,
    })
  }

  const handleGad2Q1Change = (event: SelectChangeEvent) => {
    const value = event.target
      .value as MSKFormStateAdditionalQuestionsFieldOptions['v1']
    setAdditionalQuestions({
      gad2Q1: value,
    })
  }

  const handleGad2Q2Change = (event: SelectChangeEvent) => {
    const value = event.target
      .value as MSKFormStateAdditionalQuestionsFieldOptions['v1']
    setAdditionalQuestions({
      gad2Q2: value,
    })
  }

  const handleBhAction2Change = (event: SelectChangeEvent) => {
    const value = event.target
      .value as MSKFormStateAdditionalQuestionsFieldOptions['v3']
    setAdditionalQuestions({
      bhAction2: value,
    })

    if (value !== 'Select an option') {
      deleteError('bhAction2')
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '32px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '32px',
          }}
        >
          <VanillaFormFieldContainer
            id={'phQ1'}
            label={QUESTIONS_LABELS.phQ1}
            error={!!errors.phQ1}
            helperText={errors.phQ1 ? errors.phQ1 : ''}
          >
            <Select
              id={'phQ1'}
              size="small"
              value={additionalQuestions.phQ1}
              onChange={handlePhQ1Change}
              error={!!errors.phQ1}
              sx={{
                ...getSelectDefaultSx(),
              }}
            >
              {fieldOptions.v1.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.display}
                </MenuItem>
              ))}
            </Select>
          </VanillaFormFieldContainer>

          <VanillaFormFieldContainer
            id={'phQ2'}
            label={QUESTIONS_LABELS.phQ2}
            error={!!errors.phQ2}
            helperText={errors.phQ2 ? errors.phQ2 : ''}
          >
            <Select
              id={'phQ2'}
              size="small"
              value={additionalQuestions.phQ2}
              onChange={handlePhQ2Change}
              error={!!errors.phQ2}
              sx={{
                ...getSelectDefaultSx(),
              }}
            >
              {fieldOptions.v1.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.display}
                </MenuItem>
              ))}
            </Select>
          </VanillaFormFieldContainer>
        </Box>

        <Box
          sx={{
            display: 'flex',
            columnGap: '16px',
            justifyContent: 'flex-end',
          }}
        >
          <TextField
            label={'PHQ2 Score'}
            value={getPhScore()}
            size={'small'}
            disabled={true}
            sx={{
              width: '124px',
            }}
          />
          <TextField
            label={'PHQ2 Assessment'}
            value={additionalQuestions.phAssessment}
            size={'small'}
            disabled={true}
            sx={{
              width: '140px',
            }}
          />
        </Box>
      </Box>

      <Divider />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '32px',
          }}
        >
          <VanillaFormFieldContainer
            id={'gad2Q1'}
            label={QUESTIONS_LABELS.gad2Q1}
            error={!!errors.gad2Q1}
            helperText={errors.gad2Q1 ? errors.gad2Q1 : ''}
          >
            <Select
              id={'gad2Q1'}
              size="small"
              value={additionalQuestions.gad2Q1}
              onChange={handleGad2Q1Change}
              error={!!errors.gad2Q1}
              sx={{
                ...getSelectDefaultSx(),
              }}
            >
              {fieldOptions.v1.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.display}
                </MenuItem>
              ))}
            </Select>
          </VanillaFormFieldContainer>

          <VanillaFormFieldContainer
            id={'gad2Q2'}
            label={QUESTIONS_LABELS.gad2Q2}
            error={!!errors.gad2Q2}
            helperText={errors.gad2Q2 ? errors.gad2Q2 : ''}
          >
            <Select
              id={'gad2Q2'}
              size="small"
              value={additionalQuestions.gad2Q2}
              onChange={handleGad2Q2Change}
              error={!!errors.gad2Q2}
              sx={{
                ...getSelectDefaultSx(),
              }}
            >
              {fieldOptions.v1.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.display}
                </MenuItem>
              ))}
            </Select>
          </VanillaFormFieldContainer>
        </Box>
        <Box
          sx={{
            display: 'flex',
            columnGap: '16px',
            justifyContent: 'flex-end',
          }}
        >
          <TextField
            label={'GAD2 Score'}
            value={getGad2Score()}
            size={'small'}
            disabled={true}
            sx={{
              width: '124px',
            }}
          />
          <TextField
            label={'GAD2 Assessment'}
            value={additionalQuestions.gad2Assessment}
            size={'small'}
            disabled={true}
            sx={{
              width: '140px',
            }}
          />
        </Box>
      </Box>

      <Divider />

      <Box
        sx={{
          display: 'flex',
          columnGap: '16px',
        }}
      >
        <VanillaFormFieldContainer
          id={'bhAction2'}
          label={QUESTIONS_LABELS.bhAction2}
          error={!!errors.bhAction2}
        >
          <Box
            sx={{
              display: 'flex',
              columnGap: '16px',
            }}
          >
            <TextField
              value={additionalQuestions.bhAction1}
              size={'small'}
              disabled={true}
              sx={{
                ...getTextFieldDefaultSx(),
              }}
            />
            {additionalQuestions.bhAction1 === 'Y' ? (
              <Box>
                <Select
                  id={'bhAction2'}
                  size="small"
                  value={additionalQuestions.bhAction2}
                  onChange={handleBhAction2Change}
                  error={!!errors.bhAction2}
                  sx={{
                    ...getSelectDefaultSx(),
                  }}
                >
                  {fieldOptions.v3.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.display}
                    </MenuItem>
                  ))}
                </Select>

                {errors.bhAction2 ? (
                  <FormHelperText error={!!errors.bhAction2}>
                    {errors.bhAction2}
                  </FormHelperText>
                ) : null}
              </Box>
            ) : null}
          </Box>
        </VanillaFormFieldContainer>
      </Box>
    </Box>
  )
}
