import { format } from 'date-fns'

import {
  CreatePatientCustomNoteArgs,
  CreatePatientCustomNoteError,
  CreatePatientCustomNoteReturns,
  CreatePatientIntakeNoteArgs,
  CreatePatientIntakeNoteError,
  CreatePatientIntakeNoteReturns,
  CreatePatientOrthoChartCheckNoteArgs,
  CreatePatientOrthoChartCheckNoteError,
  CreatePatientOrthoChartCheckNoteReturns,
  CreatePatientOrthoTouchpointNoteArgs,
  CreatePatientOrthoTouchpointNoteError,
  CreatePatientOrthoTouchpointNoteReturns,
  CreatePatientOutreachNoteArgs,
  CreatePatientOutreachNoteError,
  CreatePatientOutreachNoteReturns,
  CreatePatientPTTouchpointNoteArgs,
  CreatePatientPTTouchpointNoteError,
  CreatePatientPTTouchpointNoteReturns,
  CreatePatientPostOpTouchpointNoteArgs,
  CreatePatientPostOpTouchpointNoteError,
  CreatePatientPostOpTouchpointNoteReturns,
  CreatePatientPreOpTouchpointNoteArgs,
  CreatePatientPreOpTouchpointNoteError,
  CreatePatientPreOpTouchpointNoteReturns,
  CreatePatientReferralUploadNoteArgs,
  CreatePatientReferralUploadNoteError,
  CreatePatientReferralUploadNoteReturns,
  CreatePatientSelfCareTouchpointNoteArgs,
  CreatePatientSelfCareTouchpointNoteError,
  CreatePatientSelfCareTouchpointNoteReturns,
  GetPatientNotesError,
  GetPatientNotesReturns,
  Note,
  NoteType,
} from 'src/features/notes/domain'
import {
  CreatePatientCustomNoteServiceErrorResponse,
  CreatePatientCustomNoteServicePayload,
  CreatePatientCustomNoteServiceResponse,
  CreatePatientIntakeNoteServiceErrorResponse,
  CreatePatientIntakeNoteServicePayload,
  CreatePatientIntakeNoteServiceResponse,
  CreatePatientOrthoChartCheckNoteServiceErrorResponse,
  CreatePatientOrthoChartCheckNoteServicePayload,
  CreatePatientOrthoChartCheckNoteServiceResponse,
  CreatePatientOrthoTouchpointNoteServiceErrorResponse,
  CreatePatientOrthoTouchpointNoteServicePayload,
  CreatePatientOrthoTouchpointNoteServiceResponse,
  CreatePatientOutreachNoteServiceErrorResponse,
  CreatePatientOutreachNoteServicePayload,
  CreatePatientOutreachNoteServiceResponse,
  CreatePatientPTTouchpointNoteServiceErrorResponse,
  CreatePatientPTTouchpointNoteServicePayload,
  CreatePatientPTTouchpointNoteServiceResponse,
  CreatePatientPostOpTouchpointNoteServiceErrorResponse,
  CreatePatientPostOpTouchpointNoteServicePayload,
  CreatePatientPostOpTouchpointNoteServiceResponse,
  CreatePatientPreOpTouchpointNoteServiceErrorResponse,
  CreatePatientPreOpTouchpointNoteServicePayload,
  CreatePatientPreOpTouchpointNoteServiceResponse,
  CreatePatientReferralUploadNoteServiceErrorResponse,
  CreatePatientReferralUploadNoteServicePayload,
  CreatePatientReferralUploadNoteServiceResponse,
  CreatePatientSelfCareTouchpointNoteServiceErrorResponse,
  CreatePatientSelfCareTouchpointNoteServicePayload,
  CreatePatientSelfCareTouchpointNoteServiceResponse,
  GetPatientNotesServiceErrorResponse,
  GetPatientNotesServiceResponse,
  SNote,
} from 'src/features/notes/infrastructure'
import { Patient } from 'src/features/patients/domain'
import {
  CreatePatientCustomNoteFormFields,
  CreatePatientIntakeNoteFormFields,
  OrthoChartCheckNoteFormFields,
  OrthoTouchpointNoteFormFields,
  OutreachNoteFormFields,
  PTTouchpointNoteFormFields,
  PostOpTouchpointNoteFormFields,
  PreOpTouchpointNoteFormFields,
  ReferralUploadNoteFormFields,
  SelfCareTouchpointNoteFormFields,
} from 'src/features/notes/presentation/components/forms'
import { getPatientIsEngaged } from 'src/features/shared/utils'

// -------------------
// GET PATIENT NOTES /
// -----------------

type MapToNote = (note: SNote) => Note

export const mapToNote: MapToNote = (note) => {
  const createdAt = format(
    new Date(note.createdAt),
    "MM/dd/yy 'at' p"
  ).toLowerCase()

  const header = `Added by ${note.authorName} on ${createdAt}`

  return {
    ...note,
    createdAt,
    header,
  }
}

type MapToGetPatientNotesReturns = (
  response: GetPatientNotesServiceResponse
) => GetPatientNotesReturns

export const mapToGetPatientNotesReturns: MapToGetPatientNotesReturns = (
  response
) => {
  const sortedNotes = response.notes.sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  })

  return {
    notes: sortedNotes.map(mapToNote),
  }
}

type MapToGetPatientNotesError = (
  response: GetPatientNotesServiceErrorResponse
) => GetPatientNotesError

export const mapToGetPatientNotesError: MapToGetPatientNotesError = (
  response
) => {
  return {
    message: response.message,
  }
}

// ---------------------------
// CREATE PATIENT CUSTOM NOTE /
// --------------------------

type MapToCreatePatientCustomNoteArgs = (
  patient: Patient,
  formValues: CreatePatientCustomNoteFormFields
) => CreatePatientCustomNoteArgs

export const mapToCreatePatientCustomNoteArgs: MapToCreatePatientCustomNoteArgs =
  (patient, formValues) => {
    const args: CreatePatientCustomNoteArgs = {
      type: 'Custom',
      note: formValues.note,
      communication: formValues.communication,
      patientId: patient.patientId,
    }

    if (formValues.painLevel && formValues.functionLevel) {
      args['pain'] = Number(formValues.painLevel)
      args['function'] = Number(formValues.functionLevel)
    }

    if (
      patient.stageAndStatus?.stage.toLowerCase() === 'engaged' &&
      patient.assignedPathwayId &&
      formValues.status
    ) {
      args['assignedPathwayId'] = patient.assignedPathwayId
      args['status'] = formValues.status
    }
    return args
  }

type MapToCreatePatientCustomNoteServicePayload = (
  args: CreatePatientCustomNoteArgs
) => CreatePatientCustomNoteServicePayload

export const mapToCreatePatientCustomNoteServicePayload: MapToCreatePatientCustomNoteServicePayload =
  (args) => {
    return {
      ...args,
    }
  }

type MapToCreatePatientCustomNoteReturns = (
  response: CreatePatientCustomNoteServiceResponse
) => CreatePatientCustomNoteReturns

export const mapToCreatePatientCustomNoteReturns: MapToCreatePatientCustomNoteReturns =
  (response) => {
    return {
      ...response,
    }
  }

type MapToCreatePatientCustomNoteError = (
  response: CreatePatientCustomNoteServiceErrorResponse
) => CreatePatientCustomNoteError

export const mapToCreatePatientCustomNoteError: MapToCreatePatientCustomNoteError =
  (response) => {
    return {
      ...response,
    }
  }

// ---------------------------------------
// CREATE PATIENT INTAKE ASSESSMENT NOTE /
// -------------------------------------

type MapToCreatePatientIntakeNoteArgs = (
  patient: Patient,
  formValues: CreatePatientIntakeNoteFormFields
) => CreatePatientIntakeNoteArgs

export const mapToCreatePatientIntakeNoteArgs: MapToCreatePatientIntakeNoteArgs =
  (patient, formValues) => {
    const args: CreatePatientIntakeNoteArgs = {
      type: 'Intake',
      note: formValues.note,
      sdm: formValues.sdm,
      patientId: patient.patientId,
      communication: formValues.communication,
      data: {},
    }

    if (formValues.outsideSource) {
      args.data['outsideSource'] = formValues.outsideSource
    }

    if (formValues.spokeWith) {
      args['spokeWith'] = formValues.spokeWith
    }

    if (formValues.scheduled) {
      args.data['scheduled'] = formValues.scheduled
    }

    if (formValues.providerName) {
      args.data['providerName'] = formValues.providerName
    }

    if (formValues.providerGroup) {
      args.data['providerGroup'] = formValues.providerGroup
    }

    if (formValues.providerGroupText) {
      args.data['providerGroupText'] = formValues.providerGroupText
    }

    if (formValues.providerGroupLocation) {
      args.data['providerGroupLocation'] = formValues.providerGroupLocation
    }

    if (formValues.preferredProvider) {
      args.data['preferredProvider'] = formValues.preferredProvider
    }

    if (formValues.sdmText) {
      args.data['sdmText'] = formValues.sdmText
    }

    if (formValues.sdmText) {
      args.data['sdmText'] = formValues.sdmText
    }
    if (formValues.agreeYouUnderstandCondition) {
      args.data['agreeYouUnderstandCondition'] =
        formValues.agreeYouUnderstandCondition
    }
    if (formValues.agreeYouUnderstandTreatmentOptions) {
      args.data['agreeYouUnderstandTreatmentOptions'] =
        formValues.agreeYouUnderstandTreatmentOptions
    }

    if (formValues.sdmPatientUnderstandsCondition) {
      args.data['sdmPatientUnderstandsCondition'] =
        formValues.sdmPatientUnderstandsCondition
    }
    if (formValues.sdmClinicalBelievesPatientUnderstandsCondition) {
      args.data['sdmClinicalBelievesPatientUnderstandsCondition'] =
        formValues.sdmClinicalBelievesPatientUnderstandsCondition
    }

    if (formValues.painLevel && formValues.functionLevel) {
      args['pain'] = Number(formValues.painLevel)
      args['function'] = Number(formValues.functionLevel)
    }

    if (
      patient.stageAndStatus?.stage.toLowerCase() === 'engaged' &&
      patient.assignedPathwayId &&
      formValues.status
    ) {
      args['assignedPathwayId'] = patient.assignedPathwayId
      args['status'] = formValues.status
    }
    return args
  }

type MapToCreatePatientIntakeNoteServicePayload = (
  args: CreatePatientIntakeNoteArgs
) => CreatePatientIntakeNoteServicePayload

export const mapToCreatePatientIntakeNoteServicePayload: MapToCreatePatientIntakeNoteServicePayload =
  (args) => {
    return {
      ...args,
    }
  }

type MapToCreatePatientIntakeNoteReturns = (
  response: CreatePatientIntakeNoteServiceResponse
) => CreatePatientIntakeNoteReturns

export const mapToCreatePatientIntakeNoteReturns: MapToCreatePatientIntakeNoteReturns =
  (response) => {
    return {
      ...response,
    }
  }

type MapToCreatePatientIntakeNoteError = (
  response: CreatePatientIntakeNoteServiceErrorResponse
) => CreatePatientIntakeNoteError

export const mapToCreatePatientIntakeNoteError: MapToCreatePatientIntakeNoteError =
  (response) => {
    return {
      ...response,
    }
  }

// ---------------------------------------
// CREATE PATIENT ORTHO CHART CHECK ASSESSMENT NOTE /
// -------------------------------------

type MapToCreatePatientOrthoChartCheckNoteArgs = (
  patient: Patient,
  formValues: OrthoChartCheckNoteFormFields
) => CreatePatientOrthoChartCheckNoteArgs

export const mapToCreatePatientOrthoChartCheckNoteArgs: MapToCreatePatientOrthoChartCheckNoteArgs =
  (patient, formValues) => {
    const args: CreatePatientOrthoChartCheckNoteArgs = {
      type: 'Ortho Chart Check',
      note: formValues.note,
      patientId: patient.patientId,
      data: {},
    }

    if (patient.assignedPathwayId) {
      args['assignedPathwayId'] = patient.assignedPathwayId
    }

    // Additional Questions

    if (formValues.completedAppointment) {
      args.data['completedAppointment'] = formValues.completedAppointment
    }

    if (formValues.reasonForMissingAppointment) {
      args.data['reasonForMissingAppointment'] =
        formValues.reasonForMissingAppointment
    }
    if (formValues.detailsOfMissedAppointment) {
      args.data['detailsOfMissedAppointment'] =
        formValues.detailsOfMissedAppointment
    }
    if (formValues.appointmentRescheduled) {
      args.data['appointmentRescheduled'] = formValues.appointmentRescheduled
    }

    if (formValues.outcomeNote) {
      args.data['outcomeNote'] = formValues.outcomeNote
    }
    if (formValues.outcomeOfVisit) {
      args.data['outcomeOfVisit'] = formValues.outcomeOfVisit
    }
    if (formValues.typeOfInjection) {
      args.data['typeOfInjection'] = formValues.typeOfInjection
    }

    if (formValues.typeOfInjectionText) {
      args.data['typeOfInjectionText'] = formValues.typeOfInjectionText
    }

    if (formValues.appointmentDate) {
      args.data['appointmentDate'] = formValues.appointmentDate
    }

    if (formValues.surgeryDate) {
      args.data['surgeryDate'] = formValues.surgeryDate
    }

    return args
  }

type MapToCreatePatientOrthoChartCheckNoteServicePayload = (
  args: CreatePatientOrthoChartCheckNoteArgs
) => CreatePatientOrthoChartCheckNoteServicePayload

export const mapToCreatePatientOrthoChartCheckNoteServicePayload: MapToCreatePatientOrthoChartCheckNoteServicePayload =
  (args) => {
    return {
      ...args,
    }
  }

type MapToCreatePatientOrthoChartCheckNoteReturns = (
  response: CreatePatientOrthoChartCheckNoteServiceResponse
) => CreatePatientOrthoChartCheckNoteReturns

export const mapToCreatePatientOrthoChartCheckNoteReturns: MapToCreatePatientOrthoChartCheckNoteReturns =
  (response) => {
    return {
      ...response,
    }
  }

type MapToCreatePatientOrthoChartCheckNoteError = (
  response: CreatePatientOrthoChartCheckNoteServiceErrorResponse
) => CreatePatientOrthoChartCheckNoteError

export const mapToCreatePatientOrthoChartCheckNoteError: MapToCreatePatientOrthoChartCheckNoteError =
  (response) => {
    return {
      ...response,
    }
  }

// ---------------------------------------
// CREATE PATIENT ORTHO TOUCHPOINT ASSESSMENT NOTE /
// -------------------------------------

type MapToCreatePatientOrthoTouchpointNoteArgs = (
  patient: Patient,
  formValues: OrthoTouchpointNoteFormFields
) => CreatePatientOrthoTouchpointNoteArgs

export const mapToCreatePatientOrthoTouchpointNoteArgs: MapToCreatePatientOrthoTouchpointNoteArgs =
  (patient, formValues) => {
    const args: CreatePatientOrthoTouchpointNoteArgs = {
      type: 'Ortho Touchpoint',
      note: formValues.note,
      patientId: patient.patientId,
      sdm: formValues.sdm,
      communication: formValues.communication,
      data: {},
    }

    if (formValues.spokeWith) {
      args['spokeWith'] = formValues.spokeWith
    }

    if (formValues.painLevel && formValues.functionLevel) {
      args['pain'] = Number(formValues.painLevel)
      args['function'] = Number(formValues.functionLevel)
    }

    // Additional Questions

    if (formValues.completedAppointment) {
      args.data['completedAppointment'] = formValues.completedAppointment
    }

    if (formValues.reasonForMissingAppointment) {
      args.data['reasonForMissingAppointment'] =
        formValues.reasonForMissingAppointment
    }
    if (formValues.detailsOfMissedAppointment) {
      args.data['detailsOfMissedAppointment'] =
        formValues.detailsOfMissedAppointment
    }
    if (formValues.appointmentRescheduled) {
      args.data['appointmentRescheduled'] = formValues.appointmentRescheduled
    }
    if (formValues.improvementFromInjection) {
      args.data['improvementFromInjection'] =
        formValues.improvementFromInjection
    }
    if (formValues.typeOfInjection) {
      args.data['typeOfInjection'] = formValues.typeOfInjection
    }

    if (formValues.outcomeNote) {
      args.data['outcomeNote'] = formValues.outcomeNote
    }
    if (formValues.outcomeOfVisit) {
      args.data['outcomeOfVisit'] = formValues.outcomeOfVisit
    }

    if (formValues.sdmText) {
      args.data['sdmText'] = formValues.sdmText
    }

    if (
      formValues.additionalConditionPain &&
      formValues.additionalConditionFunction
    ) {
      args.data['additionalConditionPain'] = Number(
        formValues.additionalConditionPain
      )
      args.data['additionalConditionFunction'] = Number(
        formValues.additionalConditionFunction
      )
    }

    if (formValues.sdmPatientUnderstandsCondition) {
      args.data['sdmPatientUnderstandsCondition'] =
        formValues.sdmPatientUnderstandsCondition
    }
    if (formValues.sdmClinicalBelievesPatientUnderstandsCondition) {
      args.data['sdmClinicalBelievesPatientUnderstandsCondition'] =
        formValues.sdmClinicalBelievesPatientUnderstandsCondition
    }

    if (
      getPatientIsEngaged(patient) &&
      patient.assignedPathwayId &&
      formValues.status
    ) {
      args['assignedPathwayId'] = patient.assignedPathwayId
      args['status'] = formValues.status
    }

    return args
  }

type MapToCreatePatientOrthoTouchpointNoteServicePayload = (
  args: CreatePatientOrthoTouchpointNoteArgs
) => CreatePatientOrthoTouchpointNoteServicePayload

export const mapToCreatePatientOrthoTouchpointNoteServicePayload: MapToCreatePatientOrthoTouchpointNoteServicePayload =
  (args) => {
    const { ...restArgs } = args
    return {
      ...restArgs,
    }
  }

type MapToCreatePatientOrthoTouchpointNoteReturns = (
  response: CreatePatientOrthoTouchpointNoteServiceResponse
) => CreatePatientOrthoTouchpointNoteReturns

export const mapToCreatePatientOrthoTouchpointNoteReturns: MapToCreatePatientOrthoTouchpointNoteReturns =
  (response) => {
    return {
      ...response,
    }
  }

type MapToCreatePatientOrthoTouchpointNoteError = (
  response: CreatePatientOrthoTouchpointNoteServiceErrorResponse
) => CreatePatientOrthoTouchpointNoteError

export const mapToCreatePatientOrthoTouchpointNoteError: MapToCreatePatientOrthoTouchpointNoteError =
  (response) => {
    return {
      ...response,
    }
  }

// ---------------------------------------
// CREATE PATIENT OUTREACH ASSESSMENT NOTE /
// -------------------------------------

type MapToCreatePatientOutreachNoteArgs = (
  patient: Patient,
  formValues: OutreachNoteFormFields
) => CreatePatientOutreachNoteArgs

export const mapToCreatePatientOutreachNoteArgs: MapToCreatePatientOutreachNoteArgs =
  (patient, formValues) => {
    const args: CreatePatientOutreachNoteArgs = {
      type: 'Outreach',
      note: formValues.note,
      patientId: patient.patientId,
      communication: formValues.communication,
    }

    if (formValues.spokeWith) {
      args['spokeWith'] = formValues.spokeWith
    }

    if (
      getPatientIsEngaged(patient) &&
      patient.assignedPathwayId &&
      formValues.status
    ) {
      args['assignedPathwayId'] = patient.assignedPathwayId
      args['status'] = formValues.status
    }

    return args
  }

type MapToCreatePatientOutreachNoteServicePayload = (
  args: CreatePatientOutreachNoteArgs
) => CreatePatientOutreachNoteServicePayload

export const mapToCreatePatientOutreachNoteServicePayload: MapToCreatePatientOutreachNoteServicePayload =
  (args) => {
    return {
      ...args,
    }
  }

type MapToCreatePatientOutreachNoteReturns = (
  response: CreatePatientOutreachNoteServiceResponse
) => CreatePatientOutreachNoteReturns

export const mapToCreatePatientOutreachNoteReturns: MapToCreatePatientOutreachNoteReturns =
  (response) => {
    return {
      ...response,
    }
  }

type MapToCreatePatientOutreachNoteError = (
  response: CreatePatientOutreachNoteServiceErrorResponse
) => CreatePatientOutreachNoteError

export const mapToCreatePatientOutreachNoteError: MapToCreatePatientOutreachNoteError =
  (response) => {
    return {
      ...response,
    }
  }

// ---------------------------------------
// CREATE PATIENT SELFCARE TOUCHPOINT ASSESSMENT NOTE /
// -------------------------------------

type MapToCreatePatientSelfCareTouchpointNoteArgs = (
  patient: Patient,
  formValues: SelfCareTouchpointNoteFormFields
) => CreatePatientSelfCareTouchpointNoteArgs

export const mapToCreatePatientSelfCareTouchpointNoteArgs: MapToCreatePatientSelfCareTouchpointNoteArgs =
  (patient, formValues) => {
    const args: CreatePatientSelfCareTouchpointNoteArgs = {
      type: 'Self-Care Touchpoint',
      note: formValues.note,
      patientId: patient.patientId,
      sdm: formValues.sdm,
      communication: formValues.communication,
      data: {},
    }

    if (formValues.spokeWith) {
      args['spokeWith'] = formValues.spokeWith
    }

    if (
      getPatientIsEngaged(patient) &&
      patient.assignedPathwayId &&
      formValues.status
    ) {
      args['assignedPathwayId'] = patient.assignedPathwayId
      args['status'] = formValues.status
    }

    if (formValues.painLevel && formValues.functionLevel) {
      args['pain'] = Number(formValues.painLevel)
      args['function'] = Number(formValues.functionLevel)
    }

    // Additional Questions

    if (formValues.painLevelDecreased) {
      args.data['painLevelDecreased'] = formValues.painLevelDecreased
    }

    if (formValues.newSymptoms) {
      args.data['newSymptoms'] = formValues.newSymptoms
    }
    if (formValues.additionalQuestionsOrConcerns) {
      args.data['additionalQuestionsOrConcerns'] =
        formValues.additionalQuestionsOrConcerns
    }
    if (formValues.sdmText) {
      args.data['sdmText'] = formValues.sdmText
    }

    if (
      formValues.additionalConditionPain &&
      formValues.additionalConditionFunction
    ) {
      args.data['additionalConditionPain'] = Number(
        formValues.additionalConditionPain
      )
      args.data['additionalConditionFunction'] = Number(
        formValues.additionalConditionFunction
      )
    }

    if (formValues.sdmPatientUnderstandsCondition) {
      args.data['sdmPatientUnderstandsCondition'] =
        formValues.sdmPatientUnderstandsCondition
    }
    if (formValues.sdmClinicalBelievesPatientUnderstandsCondition) {
      args.data['sdmClinicalBelievesPatientUnderstandsCondition'] =
        formValues.sdmClinicalBelievesPatientUnderstandsCondition
    }

    return args
  }

type MapToCreatePatientSelfCareTouchpointNoteServicePayload = (
  args: CreatePatientSelfCareTouchpointNoteArgs
) => CreatePatientSelfCareTouchpointNoteServicePayload

export const mapToCreatePatientSelfCareTouchpointNoteServicePayload: MapToCreatePatientSelfCareTouchpointNoteServicePayload =
  (args) => {
    return {
      ...args,
    }
  }

type MapToCreatePatientSelfCareTouchpointNoteReturns = (
  response: CreatePatientSelfCareTouchpointNoteServiceResponse
) => CreatePatientSelfCareTouchpointNoteReturns

export const mapToCreatePatientSelfCareTouchpointNoteReturns: MapToCreatePatientSelfCareTouchpointNoteReturns =
  (response) => {
    return {
      ...response,
    }
  }

type MapToCreatePatientSelfCareTouchpointNoteError = (
  response: CreatePatientSelfCareTouchpointNoteServiceErrorResponse
) => CreatePatientSelfCareTouchpointNoteError

export const mapToCreatePatientSelfCareTouchpointNoteError: MapToCreatePatientSelfCareTouchpointNoteError =
  (response) => {
    return {
      ...response,
    }
  }

// ---------------------------------------
// CREATE PATIENT REFERRAL UPLOAD ASSESSMENT NOTE /
// -------------------------------------

type MapToCreatePatientReferralUploadNoteArgs = (
  patient: Patient,
  formValues: ReferralUploadNoteFormFields
) => CreatePatientReferralUploadNoteArgs

export const mapToCreatePatientReferralUploadNoteArgs: MapToCreatePatientReferralUploadNoteArgs =
  (patient, formValues) => {
    const args: CreatePatientReferralUploadNoteArgs = {
      type: 'Referral Upload',
      note: formValues.note,
      patientId: patient.patientId,
      data: {},
    }

    if (formValues.spokeWith) {
      args['spokeWith'] = formValues.spokeWith
    }
    if (formValues.referringProvider) {
      args.data['referringProvider'] = formValues.referringProvider
    }
    if (formValues.referralDate) {
      args.data['referralDate'] = formValues.referralDate
    }
    if (formValues.referralType) {
      args.data['referralType'] = formValues.referralType
    }
    if (formValues.referredFor) {
      args.data['referredFor'] = formValues.referredFor
    }
    if (formValues.bodyRegionToNote) {
      args.data['bodyRegionToNote'] = formValues.bodyRegionToNote
    }
    if (formValues.medicalHistory) {
      args.data['medicalHistory'] = formValues.medicalHistory
    }

    if (getPatientIsEngaged(patient) && patient.assignedPathwayId) {
      args['assignedPathwayId'] = patient.assignedPathwayId
    }

    return args
  }

type MapToCreatePatientReferralUploadNoteServicePayload = (
  args: CreatePatientReferralUploadNoteArgs
) => CreatePatientReferralUploadNoteServicePayload

export const mapToCreatePatientReferralUploadNoteServicePayload: MapToCreatePatientReferralUploadNoteServicePayload =
  (args) => {
    return {
      ...args,
    }
  }

type MapToCreatePatientReferralUploadNoteReturns = (
  response: CreatePatientReferralUploadNoteServiceResponse
) => CreatePatientReferralUploadNoteReturns

export const mapToCreatePatientReferralUploadNoteReturns: MapToCreatePatientReferralUploadNoteReturns =
  (response) => {
    return {
      ...response,
    }
  }

type MapToCreatePatientReferralUploadNoteError = (
  response: CreatePatientReferralUploadNoteServiceErrorResponse
) => CreatePatientReferralUploadNoteError

export const mapToCreatePatientReferralUploadNoteError: MapToCreatePatientReferralUploadNoteError =
  (response) => {
    return {
      ...response,
    }
  }

// ---------------------------------------
// CREATE PATIENT PT TOUCHPOINT ASSESSMENT NOTE /
// -------------------------------------

type MapToCreatePatientPTTouchpointNoteArgs = (
  patient: Patient,
  formValues: PTTouchpointNoteFormFields
) => CreatePatientPTTouchpointNoteArgs

export const mapToCreatePatientPTTouchpointNoteArgs: MapToCreatePatientPTTouchpointNoteArgs =
  (patient, formValues) => {
    const args: CreatePatientPTTouchpointNoteArgs = {
      type: 'PT Touchpoint',
      note: formValues.note,
      patientId: patient.patientId,
      sdm: formValues.sdm,
      communication: formValues.communication,
      data: {},
    }

    if (formValues.spokeWith) {
      args['spokeWith'] = formValues.spokeWith
    }

    if (formValues.painLevel && formValues.functionLevel) {
      args['pain'] = Number(formValues.painLevel)
      args['function'] = Number(formValues.functionLevel)
    }

    // Additional Questions

    if (formValues.completedAppointment) {
      args.data['completedAppointment'] = formValues.completedAppointment
    }
    if (formValues.reasonForMissingAppointment) {
      args.data['reasonForMissingAppointment'] =
        formValues.reasonForMissingAppointment
    }
    if (formValues.detailsOfMissedAppointment) {
      args.data['detailsOfMissedAppointment'] =
        formValues.detailsOfMissedAppointment
    }
    if (formValues.appointmentRescheduled) {
      args.data['appointmentRescheduled'] = formValues.appointmentRescheduled
    }
    if (formValues.completingPhysicalTherapyExercises) {
      args.data['completingPhysicalTherapyExercises'] =
        formValues.completingPhysicalTherapyExercises
    }
    if (formValues.reasonForNotCompletingPTExercises) {
      args.data['reasonForNotCompletingPTExercises'] =
        formValues.reasonForNotCompletingPTExercises
    }
    if (formValues.painDuringPTExercise) {
      args.data['painDuringPTExercise'] = formValues.painDuringPTExercise
    }
    if (formValues.additionalQuestionsOrConcerns) {
      args.data['additionalQuestionsOrConcerns'] =
        formValues.additionalQuestionsOrConcerns
    }
    if (formValues.sdmText) {
      args.data['sdmText'] = formValues.sdmText
    }

    if (
      formValues.additionalConditionPain &&
      formValues.additionalConditionFunction
    ) {
      args.data['additionalConditionPain'] = Number(
        formValues.additionalConditionPain
      )
      args.data['additionalConditionFunction'] = Number(
        formValues.additionalConditionFunction
      )
    }

    if (formValues.sdmPatientUnderstandsCondition) {
      args.data['sdmPatientUnderstandsCondition'] =
        formValues.sdmPatientUnderstandsCondition
    }
    if (formValues.sdmClinicalBelievesPatientUnderstandsCondition) {
      args.data['sdmClinicalBelievesPatientUnderstandsCondition'] =
        formValues.sdmClinicalBelievesPatientUnderstandsCondition
    }

    if (
      getPatientIsEngaged(patient) &&
      patient.assignedPathwayId &&
      formValues.status
    ) {
      args['assignedPathwayId'] = patient.assignedPathwayId
      args['status'] = formValues.status
    }

    return args
  }

type MapToCreatePatientPTTouchpointNoteServicePayload = (
  args: CreatePatientPTTouchpointNoteArgs
) => CreatePatientPTTouchpointNoteServicePayload

export const mapToCreatePatientPTTouchpointNoteServicePayload: MapToCreatePatientPTTouchpointNoteServicePayload =
  (args) => {
    return {
      ...args,
    }
  }

type MapToCreatePatientPTTouchpointNoteReturns = (
  response: CreatePatientPTTouchpointNoteServiceResponse
) => CreatePatientPTTouchpointNoteReturns

export const mapToCreatePatientPTTouchpointNoteReturns: MapToCreatePatientPTTouchpointNoteReturns =
  (response) => {
    return {
      ...response,
    }
  }

type MapToCreatePatientPTTouchpointNoteError = (
  response: CreatePatientPTTouchpointNoteServiceErrorResponse
) => CreatePatientPTTouchpointNoteError

export const mapToCreatePatientPTTouchpointNoteError: MapToCreatePatientPTTouchpointNoteError =
  (response) => {
    return {
      ...response,
    }
  }

// ---------------------------------------
// CREATE PATIENT POST OP TOUCHPOINT ASSESSMENT NOTE /
// -------------------------------------

type MapToCreatePatientPostOpTouchpointNoteArgs = (
  patient: Patient,
  formValues: PostOpTouchpointNoteFormFields
) => CreatePatientPostOpTouchpointNoteArgs

export const mapToCreatePatientPostOpTouchpointNoteArgs: MapToCreatePatientPostOpTouchpointNoteArgs =
  (patient, formValues) => {
    const args: CreatePatientPostOpTouchpointNoteArgs = {
      type: 'Post-Op Touchpoint',
      note: formValues.note,
      patientId: patient.patientId,
      sdm: formValues.sdm,
      communication: formValues.communication,
      data: {},
    }

    if (formValues.spokeWith) {
      args['spokeWith'] = formValues.spokeWith
    }

    if (formValues.painLevel && formValues.functionLevel) {
      args['pain'] = Number(formValues.painLevel)
      args['function'] = Number(formValues.functionLevel)
    }

    // Additional Questions

    if (formValues.isPainControlled) {
      args.data['isPainControlled'] = formValues.isPainControlled
    }

    if (formValues.accessToMedicineToControlPain) {
      args.data['accessToMedicineToControlPain'] =
        formValues.accessToMedicineToControlPain
    }

    if (formValues.participatingInPT) {
      args.data['participatingInPT'] = formValues.participatingInPT
    }

    if (formValues.physicalTherapyLocation) {
      args.data['physicalTherapyLocation'] = formValues.physicalTherapyLocation
    }
    if (formValues.additionalQuestionsOrConcerns) {
      args.data['additionalQuestionsOrConcerns'] =
        formValues.additionalQuestionsOrConcerns
    }
    if (formValues.sdmText) {
      args.data['sdmText'] = formValues.sdmText
    }
    if (formValues.procedure) {
      args.data['procedure'] = formValues.procedure
    }
    if (formValues.surgerySite) {
      args.data['surgerySite'] = formValues.surgerySite
    }
    if (formValues.dischargePosition) {
      args.data['dischargePosition'] = formValues.dischargePosition
    }
    if (formValues.sdmPatientUnderstandsCondition) {
      args.data['sdmPatientUnderstandsCondition'] =
        formValues.sdmPatientUnderstandsCondition
    }
    if (formValues.sdmClinicalBelievesPatientUnderstandsCondition) {
      args.data['sdmClinicalBelievesPatientUnderstandsCondition'] =
        formValues.sdmClinicalBelievesPatientUnderstandsCondition
    }

    if (
      getPatientIsEngaged(patient) &&
      patient.assignedPathwayId &&
      formValues.status
    ) {
      args['assignedPathwayId'] = patient.assignedPathwayId
      args['status'] = formValues.status
    }

    return args
  }

type MapToCreatePatientPostOpTouchpointNoteServicePayload = (
  args: CreatePatientPostOpTouchpointNoteArgs
) => CreatePatientPostOpTouchpointNoteServicePayload

export const mapToCreatePatientPostOpTouchpointNoteServicePayload: MapToCreatePatientPostOpTouchpointNoteServicePayload =
  (args) => {
    return {
      ...args,
    }
  }

type MapToCreatePatientPostOpTouchpointNoteReturns = (
  response: CreatePatientPostOpTouchpointNoteServiceResponse
) => CreatePatientPostOpTouchpointNoteReturns

export const mapToCreatePatientPostOpTouchpointNoteReturns: MapToCreatePatientPostOpTouchpointNoteReturns =
  (response) => {
    return {
      ...response,
    }
  }

type MapToCreatePatientPostOpTouchpointNoteError = (
  response: CreatePatientPostOpTouchpointNoteServiceErrorResponse
) => CreatePatientPostOpTouchpointNoteError

export const mapToCreatePatientPostOpTouchpointNoteError: MapToCreatePatientPostOpTouchpointNoteError =
  (response) => {
    return {
      ...response,
    }
  }

// ---------------------------------------
// CREATE PATIENT PRE OP TOUCHPOINT ASSESSMENT NOTE /
// -------------------------------------

type MapToCreatePatientPreOpTouchpointNoteArgs = (
  patient: Patient,
  formValues: PreOpTouchpointNoteFormFields
) => CreatePatientPreOpTouchpointNoteArgs

export const mapToCreatePatientPreOpTouchpointNoteArgs: MapToCreatePatientPreOpTouchpointNoteArgs =
  (patient, formValues) => {
    const args: CreatePatientPreOpTouchpointNoteArgs = {
      type: 'Pre-Op Touchpoint',
      note: formValues.note,
      patientId: patient.patientId,
      sdm: formValues.sdm,
      communication: formValues.communication,
      data: {},
    }

    if (formValues.spokeWith) {
      args['spokeWith'] = formValues.spokeWith
    }
    if (formValues.painLevel && formValues.functionLevel) {
      args['pain'] = Number(formValues.painLevel)
      args['function'] = Number(formValues.functionLevel)
    }

    // Additional questions
    if (formValues.scheduledFollowUp) {
      args.data['scheduledFollowUp'] = formValues.scheduledFollowUp
    }
    if (formValues.additionalQuestionsOrConcerns) {
      args.data['additionalQuestionsOrConcerns'] =
        formValues.additionalQuestionsOrConcerns
    }
    if (formValues.questionsAboutSurgery) {
      args.data['questionsAboutSurgery'] = formValues.questionsAboutSurgery
    }
    if (formValues.procedure) {
      args.data['procedure'] = formValues.procedure
    }
    if (formValues.sdmText) {
      args.data['sdmText'] = formValues.sdmText
    }

    if (formValues.sdmPatientUnderstandsCondition) {
      args.data['sdmPatientUnderstandsCondition'] =
        formValues.sdmPatientUnderstandsCondition
    }
    if (formValues.sdmClinicalBelievesPatientUnderstandsCondition) {
      args.data['sdmClinicalBelievesPatientUnderstandsCondition'] =
        formValues.sdmClinicalBelievesPatientUnderstandsCondition
    }

    if (
      getPatientIsEngaged(patient) &&
      patient.assignedPathwayId &&
      formValues.status
    ) {
      args['assignedPathwayId'] = patient.assignedPathwayId
      args['status'] = formValues.status
    }

    return args
  }

type MapToCreatePatientPreOpTouchpointNoteServicePayload = (
  args: CreatePatientPreOpTouchpointNoteArgs
) => CreatePatientPreOpTouchpointNoteServicePayload

export const mapToCreatePatientPreOpTouchpointNoteServicePayload: MapToCreatePatientPreOpTouchpointNoteServicePayload =
  (args) => {
    return {
      ...args,
    }
  }

type MapToCreatePatientPreOpTouchpointNoteReturns = (
  response: CreatePatientPreOpTouchpointNoteServiceResponse
) => CreatePatientPreOpTouchpointNoteReturns

export const mapToCreatePatientPreOpTouchpointNoteReturns: MapToCreatePatientPreOpTouchpointNoteReturns =
  (response) => {
    return {
      ...response,
    }
  }

type MapToCreatePatientPreOpTouchpointNoteError = (
  response: CreatePatientPreOpTouchpointNoteServiceErrorResponse
) => CreatePatientPreOpTouchpointNoteError

export const mapToCreatePatientPreOpTouchpointNoteError: MapToCreatePatientPreOpTouchpointNoteError =
  (response) => {
    return {
      ...response,
    }
  }

// ---------------------
// PRESENTATION MAPPERS /
// ---------------------

type MapToPatientNoteTypes = (patient: Patient) => NoteType[]

export const mapToPatientNoteTypes: MapToPatientNoteTypes = (patient) => {
  const isSurgicalTrack = patient.pathwaycode
    ? ['OR08P', 'OR09P'].includes(patient.pathwaycode)
    : false

  const isPostOpTrack = !!patient.track?.toLowerCase().includes('post-op only')

  const validNoteTypes: Record<NoteType, boolean> = {
    '': false,
    Outreach: !getPatientIsEngaged(patient),
    Intake: true,
    'Referral Upload': true,
    'Ortho Chart Check': getPatientIsEngaged(patient, 'ortho'),
    'Ortho Touchpoint': getPatientIsEngaged(patient),
    'Self-Care Touchpoint': getPatientIsEngaged(patient),
    'PT Touchpoint': getPatientIsEngaged(patient),
    'Pre-Op Touchpoint':
      getPatientIsEngaged(patient, 'ortho') && isSurgicalTrack,
    'Post-Op Touchpoint':
      getPatientIsEngaged(patient, 'ortho') &&
      (isSurgicalTrack || isPostOpTrack),
    Custom: true,
  }

  return Object.entries(validNoteTypes)
    .filter(([, value]) => value)
    .map(([key]) => key as NoteType)
}
export const filterNoneValueOptions = (
  values: { id: string; label: string }[]
) => values.filter((val) => !['None', 'N/A', 'Unknown'].includes(val.label))
