import * as yup from 'yup'
import { Box, Checkbox, FormControlLabel, Grid, Stack } from '@mui/material'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import {
  FormLayoutWithCancelSave,
  FormControlledNoteField,
  PreferredProviderField,
  ProviderGroupField,
  ProviderGroupLocationField,
  ProviderGroupOtherField,
  ReferralSourceField,
  SDMField,
  ScheduledField,
  SpokeWithField,
  StatusField,
  ProviderNameField,
  PainLevelField,
  FunctionLevelField,
  SDMCompletedField,
  SDMPatientUnderstandsField,
} from 'src/features/shared/presentation/components/form-fields'

import { yupResolver } from '@hookform/resolvers/yup'
import { getPatientHasStage } from 'src/features/shared/utils'
import { useStatusDefinitions } from 'src/features/shared/presentation'

import { mapToCreatePatientIntakeNoteArgs } from 'src/features/notes/adapters'
import { useUpdatePatientStatus } from 'src/features/patients/presentation'
import { useCreatePatientIntakeNote } from 'src/features/notes/presentation/hooks/use-create-patient-intake-note'
import { mapToPatientUpdateStatus } from 'src/features/patients/adapters'
import { DevTool } from '@hookform/devtools'
import { NoteFormProps } from 'src/features/notes/presentation'
import { useEffect } from 'react'
import {
  IntakeQuestionsFormValues,
  IntakeAdditionalQuestionsForm,
} from 'src/features/notes/presentation/components/additional-questions'
import { useNotesStore } from 'src/features/shared/infrastructure'

export type CreatePatientIntakeNoteFormFields = IntakeQuestionsFormValues & {
  note: string
  status?: string
  referralSource?: string
  painLevel?: string
  functionLevel?: string
  spokeWith?: string
  sdm: boolean
  communication: string
}

type IntakeNoteFormProps = NoteFormProps

export const IntakeNoteForm = ({
  patient,
  setShowLoader,
}: IntakeNoteFormProps) => {
  const patientHasStage = getPatientHasStage(patient)
  const { createPatientIntakeNoteAsync } = useCreatePatientIntakeNote()
  const { updatePatientStatusAsync } = useUpdatePatientStatus()

  const { getAllStatusDefinitions } = useStatusDefinitions(patient)
  const { noteText } = useNotesStore()

  const createIntakeNoteFormSchema: yup.Schema<CreatePatientIntakeNoteFormFields> =
    yup.object().shape(
      {
        note: yup.string().required('Note is required'),
        status: yup.string().required(),
        sdm: yup.boolean().required(),
        referralSource: yup.string().required('ReferralSource is required'),
        painLevel: yup.string().when('functionLevel', {
          is: (functionLevel: string) => functionLevel,
          then: (schema) => schema.required('Pain is required'),
        }),
        functionLevel: yup.string().when('painLevel', {
          is: (painLevel: string) => painLevel,
          then: (schema) => schema.required('Function is required'),
        }),
        spokeWith: yup.string().required('This field is required'),
        scheduled: yup.string().required('This field is required'),
        providerName: yup.string(),
        providerGroup: yup.string().when('scheduled', {
          is: (scheduled: string) =>
            [
              'Yes',
              'Pending Partner Confirmation',
              'Warm Transferred',
            ].includes(scheduled),
          then: (schema) => schema.required('This field is required'),
        }),

        providerGroupText: yup.string().when(['scheduled', 'providerGroup'], {
          is: (scheduled: string, providerGroup: string) =>
            [
              'Yes',
              'Pending Partner Confirmation',
              'Warm Transferred',
            ].includes(scheduled) && providerGroup === 'Other',
          then: (schema) => schema.required('This field is required'),
        }),
        providerGroupLocation: yup.string(),
        preferredProvider: yup.string().when('providerGroup', {
          is: (providerGroup: string) => providerGroup === 'Resurgens',
          then: (schema) => schema.required('This field is required'),
        }),
        communication: yup.string().required(),
        agreeYouUnderstandCondition: yup
          .string()
          .required('This field is required'),
        agreeYouUnderstandTreatmentOptions: yup
          .string()
          .required('This field is required'),
        sdmPatientUnderstandsCondition: yup.string().when('sdm', {
          is: (checked: string) => !!checked,
          then: (schema) => schema.required('This field is required'),
        }),
        sdmClinicalBelievesPatientUnderstandsCondition: yup
          .string()
          .when('sdm', {
            is: (checked: string) => !!checked,
            then: (schema) => schema.required('This field is required'),
          }),
        sdmText: yup.string().when('sdm', {
          is: (checked: string) => !!checked,
          then: (schema) => schema.required('This field is required'),
        }),
        outsideSource: yup.boolean(),
      },
      [['painLevel', 'functionLevel']]
    )

  const formMethods = useForm<CreatePatientIntakeNoteFormFields>({
    resolver: yupResolver(createIntakeNoteFormSchema),
    context: { patientHasStage },
    defaultValues: {
      note: noteText,
      status: '',
      referralSource: patient.referralSource || '',
      painLevel: '',
      functionLevel: '',
      spokeWith: '',
      providerName: '',
      providerGroup: '',
      providerGroupText: '',
      providerGroupLocation: '',
      preferredProvider: '',
      sdm: false,
      communication: 'outbound',
      agreeYouUnderstandCondition: '',
      agreeYouUnderstandTreatmentOptions: '',
      sdmPatientUnderstandsCondition: '',
      sdmClinicalBelievesPatientUnderstandsCondition: '',
      scheduled: '',
      sdmText: '',
      outsideSource: false,
    },
  })

  const watchProviderGroup = formMethods.watch('providerGroup')
  const watchReferralSource = formMethods.watch('referralSource')

  useEffect(() => {
    const subscription = formMethods.watch((value, { name, type }) => {
      if (
        name === 'providerGroup' &&
        type === 'change' &&
        value['preferredProvider']
      ) {
        formMethods.reset((formValues) => ({
          ...formValues,
          preferredProvider: '',
        }))
      }

      if (
        name === 'providerGroup' &&
        type === 'change' &&
        value['providerGroupText']
      ) {
        formMethods.reset((formValues) => ({
          ...formValues,
          providerGroupText: '',
        }))
      }

      if (
        name === 'sdm' &&
        type === 'change' &&
        (value['sdmPatientUnderstandsCondition'] ||
          value['sdmClinicalBelievesPatientUnderstandsCondition'])
      ) {
        formMethods.reset((formValues) => ({
          ...formValues,
          sdmPatientUnderstandsCondition: '',
          sdmClinicalBelievesPatientUnderstandsCondition: '',
        }))
      }
    })

    return () => subscription.unsubscribe()
  }, [formMethods])

  const submitHandler: SubmitHandler<
    CreatePatientIntakeNoteFormFields
  > = async (data) => {
    setShowLoader?.(true)

    try {
      const createPatientIntakeNoteArgs = mapToCreatePatientIntakeNoteArgs(
        patient,
        data
      )

      if (
        patient.stageAndStatus &&
        patient.stageAndStatus.stage &&
        data.status
      ) {
        const createPatientUpdateStatusArgs = mapToPatientUpdateStatus(
          patient,
          data.status
        )
        await updatePatientStatusAsync(createPatientUpdateStatusArgs)
        await createPatientIntakeNoteAsync(createPatientIntakeNoteArgs)

        toast.success('Patient Note successfully created!')
        toast.success('Patient Status successfully updated!')
      } else {
        await createPatientIntakeNoteAsync(createPatientIntakeNoteArgs)
        toast.success('Patient Note successfully created!')
      }
    } catch (error) {
      toast.error('Failed creating patient note!')
      toast.error('Patient Status update failed!')
    }

    setShowLoader?.(false)
  }

  const OutsideSourceField = () => {
    return (
      <Controller
        name="outsideSource"
        control={formMethods.control}
        render={({ field }) => (
          <FormControlLabel
            control={
              <Checkbox {...field} color={'primary'} checked={field.value} />
            }
            label={'Recommended by Garner'}
          />
        )}
      />
    )
  }

  return (
    <FormLayoutWithCancelSave
      onSubmit={submitHandler}
      formMethods={formMethods}
    >
      <Grid container rowSpacing={2} pb={4}>
        <Grid item sm={12} pb={2}>
          <FormControlledNoteField />
        </Grid>
        <Grid item container columnSpacing={2} sm={12}>
          <Grid item sm={4}>
            <StatusField
              statusDefinitions={getAllStatusDefinitions(watchReferralSource)}
            />
          </Grid>
          <Grid item container columnSpacing={2} sm={6}>
            <Grid item sm={3}>
              <PainLevelField />
            </Grid>
            <Grid item sm={3.5}>
              <FunctionLevelField />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item columnSpacing={2}>
          <Grid item sm={2}>
            <SpokeWithField />
          </Grid>
          <Grid item sm={4.5}>
            <ReferralSourceField />
          </Grid>
          <Grid item sm={3}>
            <ScheduledField />
          </Grid>
        </Grid>
        <Grid container item sm={12} columnSpacing={2}>
          <Grid item sm={4}>
            <ProviderNameField />
          </Grid>
          <Grid item sm={4}>
            <ProviderGroupField />
          </Grid>
          {watchProviderGroup === 'Other' ? (
            <Grid item sm={4}>
              <ProviderGroupOtherField />
            </Grid>
          ) : null}
          {watchProviderGroup === 'Resurgens' ? (
            <Grid item sm={3}>
              <PreferredProviderField />
            </Grid>
          ) : null}
        </Grid>
        <Grid container item sm={12} spacing={2}>
          <Grid item sm={8}>
            <ProviderGroupLocationField />
          </Grid>
          <Grid item>
            <OutsideSourceField />
          </Grid>
        </Grid>
        <Grid container item sm={10}>
          <Box pt={1} width="100%">
            <SDMField />
          </Box>
          {formMethods.watch('sdm') ? (
            <Stack direction={'column'} rowGap={1} ml={10} py={2}>
              <SDMCompletedField />
              <SDMPatientUnderstandsField />
            </Stack>
          ) : null}
        </Grid>
      </Grid>

      <IntakeAdditionalQuestionsForm />
      <DevTool control={formMethods.control} placement={'top-left'} />
    </FormLayoutWithCancelSave>
  )
}
