import {
  CreateEnablingTherapyArgs,
  CreateEnablingTherapyError,
  CreateEnablingTherapyPayload,
  CreateEnablingTherapyReturns,
  CreatedEnablingTherapy,
  GetPatientIntakeFlowsError,
  GetPatientIntakeFlowsParams,
  GetPatientIntakeFlowsReturns,
  Intake,
  IntakeFlow,
  IntakeFlowActionOpenToTry,
  IntakeFlowAdditionalQuestions,
  IntakeFlowAnswer,
  IntakeFlowPreviouslyTriedAction,
  IntakeFlowQuestionsAndActions,
  IntakePatientActions,
  MSKAdditionalQuestions,
  MSKAdditionalQuestionsFieldOptions,
  MSKAdditionalQuestionsResultDisplay,
  UpdateIntakeArgs,
  UpdateIntakeError,
  UpdateIntakeReturns,
} from 'src/features/msk/domain'
import {
  CreateEnablingTherapyServiceArgs,
  CreateEnablingTherapyServiceErrorResponse,
  CreateEnablingTherapyServiceResponse,
  GetPatientIntakeFlowsServiceErrorResponse,
  GetPatientIntakeFlowsServiceParams,
  GetPatientIntakeFlowsServiceResponse,
  SCreatedEnablingTherapy,
  SIntake,
  SIntakeFlow,
  SIntakeFlowAnswer,
  SIntakeFlowQuestionsAndActions,
  SIntakePatientActions,
  UpdateIntakeServiceArgs,
  UpdateIntakeServiceErrorResponse,
  UpdateIntakeServiceResponse,
} from 'src/features/msk/infrastructure'
import { mapToMSKQuestionsAndActions } from 'src/features/msk/adapters'
import {
  CreateEnablingTherapyFormFields,
  PatientIntakeFlowAdditionalQuestions,
  PatientIntakeFlowCondition,
  PatientIntakeFlowData,
  QUESTIONS_LABELS,
} from 'src/features/msk/presentation'
import { Navigator } from 'src/features/shared/domain'
import { PatientIntakeFlowWithCreatedInfo } from 'src/features/patients/presentation'
import { RECOMMENDED_ACTION_PATHWAYS_TYPES } from 'src/features/shared/constants'
import { mapToUseCaseDefaultError } from 'src/features/shared/adapters'
import { formatDate } from 'src/features/shared/utils'

type MapToIntakeAssessment = (
  sIntakeAssessment: SIntake['intakeAssessment']
) => Intake['intakeAssessment']

export const mapToIntakeAssessment: MapToIntakeAssessment = (
  sIntakeAssessment
) => {
  const mappedIntakeAssessment: Intake['intakeAssessment'] = {}
  Object.entries(sIntakeAssessment).forEach(([key, value]) => {
    if (key !== 'botheredBodyPart') {
      const answerData = value as SIntakeFlowAnswer
      mappedIntakeAssessment[key] = {
        questionId: answerData.id,
        value: answerData.answer,
      }
    } else {
      mappedIntakeAssessment[key] = value as string
    }
  })

  return mappedIntakeAssessment
}

type MapToIntakePatientActions = (
  sIntakePatientActions: SIntakePatientActions
) => IntakePatientActions

export const mapToIntakePatientActions: MapToIntakePatientActions = (
  sIntakePatientActions
) => {
  let currentlyUsingOpioids = null
  if (sIntakePatientActions.currentlyUsingOpiods === 'yes') {
    currentlyUsingOpioids = true
  } else if (sIntakePatientActions.currentlyUsingOpiods === 'no') {
    currentlyUsingOpioids = false
  }
  return {
    accupuncture: sIntakePatientActions.accupuncture,
    chiropracticCare: sIntakePatientActions.chiropracticCare,
    compression: sIntakePatientActions.compression,
    createdAt: sIntakePatientActions.createdAt,
    elevation: sIntakePatientActions.elevation,
    ice: sIntakePatientActions.ice,
    id: sIntakePatientActions.id,
    injections: sIntakePatientActions.injections,
    intakeId: sIntakePatientActions.intakeId,
    openToTry: sIntakePatientActions.openToTry,
    opioids: sIntakePatientActions.opioids,
    otcMedication: sIntakePatientActions.otcMedication,
    patientId: sIntakePatientActions.patientId,
    physicalTherapy: sIntakePatientActions.physicalTherapy,
    previouslyTried: sIntakePatientActions.previouslyTried,
    rest: sIntakePatientActions.rest,
    surgery: sIntakePatientActions.surgery,
    other: sIntakePatientActions.other,
    currentlyUsingOpioids,
    otherPreviouslyTriedActionText: sIntakePatientActions.otherText,
  }
}

type MapToIntakeFlowQuestionsAndActions = (
  questionsAndActions: SIntakeFlowQuestionsAndActions
) => IntakeFlowQuestionsAndActions

export const mapToIntakeFlowQuestionsAndActions: MapToIntakeFlowQuestionsAndActions =
  (questionsAndActions) => {
    return mapToMSKQuestionsAndActions(questionsAndActions)
  }

type MapToEnablingTherapy = (
  sEnablingTherapy: SCreatedEnablingTherapy
) => CreatedEnablingTherapy

export const mapToCreatedEnablingTherapy: MapToEnablingTherapy = (
  sCreatedEnablingTherapy
) => {
  return {
    ...sCreatedEnablingTherapy,
    createdBy: sCreatedEnablingTherapy.name,
    createdAt: formatDate(sCreatedEnablingTherapy.createdAt, true),
    dateOfFirstAppointment: formatDate(
      sCreatedEnablingTherapy.dateOfFirstAppointment,
      true
    ),
  }
}

type MapToIntake = (sIntake: SIntake) => Intake

export const mapToIntake: MapToIntake = (sIntake) => {
  const bodyRegionToNote: Intake['bodyRegionToNote'] = []
  if (sIntake.bodyRegionToNote) {
    const bodyRegionsToNote = sIntake.bodyRegionToNote
      .split(',')
      .map((bodyRegion) => bodyRegion.trim()) as Intake['bodyRegionToNote']
    bodyRegionToNote.push(...bodyRegionsToNote)
  }
  return {
    ...sIntake,
    enablingTherapies: sIntake.enablingTherapies
      ? sIntake.enablingTherapies.map(mapToCreatedEnablingTherapy)
      : [],
    bodyRegionToNote,
    hardstop: sIntake.hardStop,
    patientActions: sIntake.patientActions
      ? sIntake.patientActions.map(mapToIntakePatientActions)
      : [],
    intakeAssessment: mapToIntakeAssessment(sIntake.intakeAssessment),
  }
}

type MapToIntakeFlow = (sIntakeFlow: SIntakeFlow) => IntakeFlow

export const mapToIntakeFlow: MapToIntakeFlow = (sIntakeFlow) => {
  const intakes = sIntakeFlow.intakes?.map(mapToIntake) || []

  const recommendedActionIds = intakes.map(
    (intake) => intake.recommendedActionId
  )

  const recommendedActions: string[] = recommendedActionIds.reduce<string[]>(
    (prev, curr) => {
      if (curr && RECOMMENDED_ACTION_PATHWAYS_TYPES[curr]) {
        return Array.from(
          new Set([...prev, ...RECOMMENDED_ACTION_PATHWAYS_TYPES[curr]])
        )
      }
      return prev
    },
    []
  )

  return {
    ...sIntakeFlow,
    recommendedActions,
    intakes,
  }
}

type MapToGetPatientIntakeFlowsReturns = (
  response: GetPatientIntakeFlowsServiceResponse
) => GetPatientIntakeFlowsReturns

export const mapToGetPatientIntakeFlowsReturns: MapToGetPatientIntakeFlowsReturns =
  (response) => {
    return {
      intakeFlows: response.intakeFlows?.map(mapToIntakeFlow) || [],
      previouslyTriedActions: response.previouslyTriedActions,
      actionsOpenToTry: response.actionsOpenToTry,
      enablingTherapies: response.enablingTherapies,
      questionsAndActions: mapToIntakeFlowQuestionsAndActions(
        response.questionsAndActions
      ),
    }
  }

type MapToGetPatientIntakeFlowsError = (
  error: GetPatientIntakeFlowsServiceErrorResponse
) => GetPatientIntakeFlowsError

export const mapToGetPatientIntakeFlowsError: MapToGetPatientIntakeFlowsError =
  (error) => {
    return {
      message: error.message,
    }
  }

type MapToGetPatientIntakeFlowsServiceParams = (
  params?: GetPatientIntakeFlowsParams
) => GetPatientIntakeFlowsServiceParams | undefined

export const mapToGetPatientIntakeFlowsServiceParams: MapToGetPatientIntakeFlowsServiceParams =
  (params) => {
    if (!params) return undefined
    return {
      limit: params.limit,
      orderBy: params.orderBy,
      orderDirection: params.orderDirection,
    }
  }

// -------------------------
// CREATE ENABLING THERAPY /
// ------------------------

type MapToCreateEnablingTherapyServiceArgs = (
  args: CreateEnablingTherapyArgs
) => CreateEnablingTherapyServiceArgs

export const mapToCreateEnablingTherapyServiceArgs: MapToCreateEnablingTherapyServiceArgs =
  (args) => {
    return {
      ...args,
    }
  }

type MapToCreateEnablingTherapyReturns = (
  response: CreateEnablingTherapyServiceResponse
) => CreateEnablingTherapyReturns

export const mapToCreateEnablingTherapyReturns: MapToCreateEnablingTherapyReturns =
  (response) => {
    return {
      ...response,
    }
  }

type MapToCreateEnablingTherapyError = (
  error: CreateEnablingTherapyServiceErrorResponse
) => CreateEnablingTherapyError

export const mapToCreateEnablingTherapyError: MapToCreateEnablingTherapyError =
  (error) =>
    mapToUseCaseDefaultError<CreateEnablingTherapyServiceErrorResponse>(error)

type MapToCreateEnablingTherapyPayload = (
  patientId: string,
  assignedPathwayId: string,
  formValues: CreateEnablingTherapyFormFields
) => CreateEnablingTherapyPayload

export const mapToCreateEnablingTherapyPayload: MapToCreateEnablingTherapyPayload =
  (patientId, assignedPathwayId, formValues) => {
    const args: CreateEnablingTherapyPayload = {
      patientId: patientId,
      therapy: formValues.type,
      notes: formValues.therapyNote,
      assignedPathwayId: assignedPathwayId,
    }

    if (formValues.dateOfFirstAppointment) {
      args['dateOfFirstAppointment'] = formatDate(
        formValues.dateOfFirstAppointment
      )
    }

    return args
  }

// ---------------
// UPDATE INTAKE /
// ---------------

type MapToUpdateIntakeServiceArgs = (
  args: UpdateIntakeArgs
) => UpdateIntakeServiceArgs

export const mapToUpdateIntakeServiceArgs: MapToUpdateIntakeServiceArgs = (
  args
) => {
  return {
    ...args,
  }
}

type MapToUpdateIntakeReturns = (
  response: UpdateIntakeServiceResponse
) => UpdateIntakeReturns

export const mapToUpdateIntakeReturns: MapToUpdateIntakeReturns = (
  response
) => ({ ...response })

type MapToUpdateIntakeError = (
  response: UpdateIntakeServiceErrorResponse
) => UpdateIntakeError

export const mapToUpdateIntakeError: MapToUpdateIntakeError = (error) => ({
  ...error,
})

// ----------------------
// PRESENTATION MAPPERS /
// --------------------

type MapToPatientIntakeFlowAdditionalQuestions = (
  additionalQuestions: NonNullable<IntakeFlowAdditionalQuestions>
) => Omit<PatientIntakeFlowAdditionalQuestions, 'createdInfo'>

export const mapToPatientIntakeFlowAdditionalQuestions: MapToPatientIntakeFlowAdditionalQuestions =
  (additionalQuestions) => {
    const getPhScore = () => {
      if (!additionalQuestions.phScore) {
        return 'N/A'
      }
      return `${additionalQuestions.phScore}`
    }

    const getGad2Score = () => {
      if (!additionalQuestions.gad2Score) {
        return 'N/A'
      }
      return `${additionalQuestions.gad2Score}`
    }

    const getAssessment = (
      assessment?: MSKAdditionalQuestionsResultDisplay['v1']
    ) => {
      if (!assessment) {
        return 'N/A'
      }
      return assessment
    }

    const getV1Answer = (answer?: MSKAdditionalQuestionsFieldOptions['v1']) => {
      if (!answer) {
        return 'Not answered'
      }
      return answer
    }

    const getV2Answer = (answer?: MSKAdditionalQuestionsFieldOptions['v2']) => {
      if (!answer) {
        return 'Not answered'
      }
      return answer === 'Y' ? 'Yes' : 'No'
    }

    const getBhAnswer = (
      bhAction1: MSKAdditionalQuestions['bhAction1'],
      bhAction2: MSKAdditionalQuestions['bhAction2']
    ) => {
      if (!bhAction1) {
        return 'N/A'
      }

      let result = bhAction1 === 'Y' ? 'Yes' : 'No'

      if (bhAction2) {
        result += ` / ${bhAction2}`
      }

      return result
    }

    const getBarriersToBeAwareOfAnswer = (
      barriersToBeAwareOf: MSKAdditionalQuestions['barriersToBeAwareOf'],
      barriersToBeAwareOfText: MSKAdditionalQuestions['barriersToBeAwareOfText']
    ) => {
      if (!barriersToBeAwareOf) {
        return 'Not answered'
      }
      if (barriersToBeAwareOf !== 'other') {
        return barriersToBeAwareOf
      }
      return `${
        barriersToBeAwareOf.charAt(0).toUpperCase() +
        barriersToBeAwareOf.slice(1)
      }: ${barriersToBeAwareOfText}`
    }

    const getPeopleAllowedToReachOutToAnswer = (
      peopleAllowedToReachOutTo: MSKAdditionalQuestions['peopleAllowedToReachOutTo']
    ) => {
      if (!peopleAllowedToReachOutTo) {
        return 'Not answered'
      }
      return peopleAllowedToReachOutTo.replaceAll('|', '-')
    }

    return {
      phQ1Question: QUESTIONS_LABELS.phQ1,
      phQ1Answer: getV1Answer(additionalQuestions.phQ1),
      phQ2Question: QUESTIONS_LABELS.phQ2,
      phQ2Answer: getV1Answer(additionalQuestions.phQ2),
      phScore: getPhScore(),
      phAssessment: getAssessment(additionalQuestions.phAssessment),
      gad2Q1Question: QUESTIONS_LABELS.gad2Q1,
      gad2Q1Answer: getV1Answer(additionalQuestions.gad2Q1),
      gad2Q2Question: QUESTIONS_LABELS.gad2Q2,
      gad2Q2Answer: getV1Answer(additionalQuestions.gad2Q2),
      gad2Score: getGad2Score(),
      gad2Assessment: getAssessment(additionalQuestions.gad2Assessment),
      bhAnswer: getBhAnswer(
        additionalQuestions.bhAction1,
        additionalQuestions.bhAction2
      ),
      fallScreenQ1Question: QUESTIONS_LABELS.fallScreenQ1,
      fallScreenQ1Answer: getV2Answer(additionalQuestions.fallScreenQ1),
      fallScreenQ2Question: QUESTIONS_LABELS.fallScreenQ2,
      fallScreenQ2Answer: getV2Answer(additionalQuestions.fallScreenQ2),
      fallScreenResult: getAssessment(additionalQuestions.fallScreenResult),
      bodyRegionToNoteQuestion: QUESTIONS_LABELS.bodyRegionToNote,
      tabletComputerOrPhoneQuestion: QUESTIONS_LABELS.tabletComputerOrPhone,
      tabletComputerOrPhoneAnswer: getV2Answer(
        additionalQuestions.tabletComputerOrPhone
      ),
      barriersToBeAwareOfQuestion: QUESTIONS_LABELS.barriersToBeAwareOf,
      barriersToBeAwareOfAnswer: getBarriersToBeAwareOfAnswer(
        additionalQuestions.barriersToBeAwareOf,
        additionalQuestions.barriersToBeAwareOfText
      ),
      peopleAllowedToReachOutToQuestion:
        QUESTIONS_LABELS.peopleAllowedToReachOutTo,

      peopleAllowedToReachOutToAnswer: getPeopleAllowedToReachOutToAnswer(
        additionalQuestions.peopleAllowedToReachOutTo
      ),
    }
  }

type MapToPatientLastIntakeFlowSectionArgs = {
  intakeFlow: IntakeFlow
  previouslyTriedActions: IntakeFlowPreviouslyTriedAction[]
  actionsOpenToTry: IntakeFlowActionOpenToTry[]
  questionsAndActions: IntakeFlowQuestionsAndActions
  navigators: Navigator[]
}

type MapToPatientIntakeFlowData = (
  args: MapToPatientLastIntakeFlowSectionArgs
) => PatientIntakeFlowData

export const mapToPatientIntakeFlowData: MapToPatientIntakeFlowData = ({
  intakeFlow,
  previouslyTriedActions,
  actionsOpenToTry,
  questionsAndActions,
  navigators,
}) => {
  const createdAt = formatDate(intakeFlow.createdAt)
  let createdInfo = `Added ${createdAt}`

  const conditions: PatientIntakeFlowData['conditions'] =
    intakeFlow.intakes.map((intake) => {
      const createdBy = navigators.find(
        (navigator) => navigator.id === intake.capturedBy
      )?.name
      if (createdBy) {
        createdInfo += ` by ${createdBy}`
      }

      const bodyRegion = intake.botheredBodyPart.toUpperCase()

      const [recommendedCarePathway, recommendedType] =
        intake.recommendedAction.split(':')

      const carePathwayId = intake.recommendedActionId

      let currentlyUsingOpioids: PatientIntakeFlowCondition['currentlyUsingOpioids']
      let otherPreviouslyTriedActionText: PatientIntakeFlowCondition['otherPreviouslyTriedActionText']
      let previouslyTried = '-'
      const patientPreviouslyTriedActions = intake.patientActions.filter(
        (patientActions) => patientActions.previouslyTried
      )
      if (patientPreviouslyTriedActions.length) {
        const previouslyTriedActionsObject = patientPreviouslyTriedActions[0]

        if (previouslyTriedActionsObject.currentlyUsingOpioids === true) {
          currentlyUsingOpioids = 'Yes'
        } else if (
          previouslyTriedActionsObject.currentlyUsingOpioids === false
        ) {
          currentlyUsingOpioids = 'No'
        }

        if (
          previouslyTriedActionsObject.other &&
          previouslyTriedActionsObject.otherPreviouslyTriedActionText
        ) {
          otherPreviouslyTriedActionText =
            previouslyTriedActionsObject.otherPreviouslyTriedActionText
        }

        const previouslyTriedActionsId = Object.entries(
          previouslyTriedActionsObject
        )
          .filter(([, selected]) => selected === true)
          .map(([key]) => key)

        previouslyTried = previouslyTriedActions
          .filter((previouslyTriedAction) =>
            previouslyTriedActionsId.includes(previouslyTriedAction.id)
          )
          .map(({ display }) => display)
          .join(', ')
      }

      let openToTrying = '-'
      const patientOpenToTryingActions = intake.patientActions.filter(
        (patientActions) => patientActions.openToTry
      )
      if (patientOpenToTryingActions.length) {
        const openToTryingActionsObject = patientOpenToTryingActions[0]
        const openToTryingActionsId = Object.entries(openToTryingActionsObject)
          .filter(([, selected]) => selected === true)
          .map(([key]) => key)
        openToTrying = actionsOpenToTry
          .filter((openToTryingAction) =>
            openToTryingActionsId.includes(openToTryingAction.id)
          )
          .map(({ display }) => display)
          .join(', ')
      }

      const painLevel = intake.currentPainLevel
      const functionLevel = intake.currentFunctionLevel

      const answers = Object.entries(intake.intakeAssessment)
        .filter(
          ([key]) =>
            key !== 'botheredBodyPart' && key in questionsAndActions.questions
        )
        .map(([key, value]) => {
          const question = questionsAndActions.questions[key].q
          const answerData = value as IntakeFlowAnswer

          return {
            question,
            value: answerData.value,
          }
        })

      const bodyRegionToNote = intake.bodyRegionToNote.length
        ? intake.bodyRegionToNote.join(', ')
        : 'Not answered'

      return {
        id: intake.id,
        createdInfo,
        bodyRegion,
        bodyRegionToNote,
        recommendedCarePathway,
        recommendedType,
        carePathwayId,
        previouslyTried: previouslyTried || '-',
        openToTrying: openToTrying || '-',
        painLevel,
        functionLevel,
        answers,
        currentlyUsingOpioids,
        otherPreviouslyTriedActionText,
        enablingTherapies: intake.enablingTherapies,
      }
    })

  const patientFlowData: PatientIntakeFlowData = {
    conditions,
  }

  if (intakeFlow.additionalQuestions?.questions) {
    patientFlowData.additionalQuestions = {
      createdInfo,
      ...mapToPatientIntakeFlowAdditionalQuestions(
        intakeFlow.additionalQuestions.questions
      ),
    }
  }

  return patientFlowData
}

type MapToPatientIntakeFlowsWithCreatedInfo = (
  patientIntakeFlows: GetPatientIntakeFlowsReturns['intakeFlows'],
  navigators: Navigator[]
) => PatientIntakeFlowWithCreatedInfo[]

export const mapToPatientIntakeFlowsWithCreatedInfo: MapToPatientIntakeFlowsWithCreatedInfo =
  (patientIntakeFlows, navigators) => {
    if (!patientIntakeFlows.length) {
      return []
    }

    const firstIntakeFlow = patientIntakeFlows[0]

    const createdAt = formatDate(firstIntakeFlow.createdAt)

    let createdInfo = `Added ${createdAt}`

    if (firstIntakeFlow.intakes.length) {
      const firsIntake = firstIntakeFlow.intakes[0]
      const createdBy = navigators.find(
        (navigator) => navigator.id === firsIntake.capturedBy
      )?.name
      if (createdBy) {
        createdInfo += ` by ${createdBy}`
      }
    }

    return patientIntakeFlows.map((intakeFlow) => {
      return {
        createdInfo,
        ...intakeFlow,
      }
    })
  }
