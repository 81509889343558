import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Box,
  TextField,
} from '@mui/material'
import { FC } from 'react'
import { useFormContext, Controller } from 'react-hook-form'

import { mapToTCReactSelectOption } from 'src/features/shared/adapters'
import { PAYORS } from 'src/features/shared/constants'
import { TCReactSelect } from 'src/features/shared/presentation'
import * as settings from 'src/config/settings'

type PayorSelectProps = {
  otherPayorIsRequired?: boolean
}

export const PayorSelect: FC<PayorSelectProps> = ({ otherPayorIsRequired }) => {
  const formMethods = useFormContext()
  const payorWatcher = formMethods.watch('payor')
  if (settings.USE_REACT_SELECT_ALTERNATIVE) {
    return (
      <Controller
        name="payor"
        control={formMethods.control}
        render={({ field }) => (
          <TCReactSelect
            isCreateable
            placeholder={'Plan Name'}
            options={PAYORS.filter((option) => option.id !== 'Other').map(
              mapToTCReactSelectOption
            )}
            value={
              field.value ? mapToTCReactSelectOption(field.value) : undefined
            }
            onChange={(option) => {
              const value = option ? option.value : ''
              field.onChange(value)
            }}
          />
        )}
      />
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        columnGap: '16px',
      }}
    >
      <Controller
        name="payor"
        control={formMethods.control}
        render={({ field, fieldState }) => (
          <FormControl
            size={'small'}
            sx={{
              width: payorWatcher === 'Other' ? '30%' : '100%',
            }}
          >
            <InputLabel
              id={'eligibility-patient-form-referral-source-label'}
              error={!!fieldState.error}
            >
              Plan Name
            </InputLabel>
            <Select
              labelId={'eligibility-patient-form-referral-source-label'}
              id={'eligibility-patient-form-referral-source'}
              label={'Plan Name'}
              value={field.value}
              onChange={(event) => {
                field.onChange(event.target.value)
              }}
              error={!!fieldState.error}
            >
              {PAYORS.map((payor) => (
                <MenuItem key={payor.id} value={payor.id}>
                  {payor.label}
                </MenuItem>
              ))}
            </Select>
            {fieldState.error ? (
              <FormHelperText error={!!fieldState.error}>
                {fieldState.error ? fieldState.error.message : ''}
              </FormHelperText>
            ) : null}
          </FormControl>
        )}
      />
      {payorWatcher === 'Other' ? (
        <TextField
          required={otherPayorIsRequired}
          label={'Other Plan Name'}
          variant={'outlined'}
          size={'small'}
          error={!!formMethods.formState.errors['otherPayor']}
          helperText={
            formMethods.formState.errors['otherPayor']
              ? (formMethods.formState.errors['otherPayor']?.message as string)
              : undefined
          }
          {...formMethods.register('otherPayor')}
          sx={{
            width: '70%',
          }}
        />
      ) : null}
    </Box>
  )
}
