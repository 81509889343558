import { useCallback } from 'react'

import {
  CreatePatientService,
  CreatePatientServiceResponse,
  GetPatientService,
  GetPatientServiceResponse,
  GetPatientPainAndFunctionService,
  GetPatientPainAndFunctionServiceResponse,
  GetPatientStatusDefinitionsService,
  UpdatePatientService,
  UpdatePatientServiceResponse,
  UpdatePatientStatusService,
  UpdatePatientStatusServiceResponse,
  CreatePatientManuallyService,
  CreatePatientManuallyServiceResponse,
} from 'src/features/patients/infrastructure'
import { useCompassApiClient } from 'src/features/shared/infrastructure'
import * as settings from 'src/config/settings'

export const usePatientsService = () => {
  const { getCompassApiClient } = useCompassApiClient()

  const getPatient: GetPatientService = useCallback(
    async (patientId) => {
      const compassClient = await getCompassApiClient()
      return await compassClient.get<GetPatientServiceResponse>(
        settings.COMPASS_API_BASE_URL + `/patients/${patientId}`
      )
    },
    [getCompassApiClient]
  )

  const getPatientPainAndFunction: GetPatientPainAndFunctionService =
    useCallback(
      async (patientId) => {
        const compassClient = await getCompassApiClient()
        return await compassClient.get<GetPatientPainAndFunctionServiceResponse>(
          settings.COMPASS_API_BASE_URL +
            `/patients/${patientId}/painAndFunction`
        )
      },
      [getCompassApiClient]
    )

  const updatePatient: UpdatePatientService = useCallback(
    async ({ patientId, payload }) => {
      const compassClient = await getCompassApiClient()
      return await compassClient.put<UpdatePatientServiceResponse>(
        settings.COMPASS_API_BASE_URL + `/patients/${patientId}`,
        payload
      )
    },
    [getCompassApiClient]
  )

  const updatePatientStatus: UpdatePatientStatusService = useCallback(
    async ({ patientId, payload }) => {
      const compassClient = await getCompassApiClient()
      return await compassClient.post<UpdatePatientStatusServiceResponse>(
        settings.COMPASS_API_BASE_URL + `/patients/${patientId}/status`,
        payload
      )
    },
    [getCompassApiClient]
  )

  const createPatient: CreatePatientService = useCallback(
    async (payload) => {
      const compassClient = await getCompassApiClient()
      return await compassClient.post<CreatePatientServiceResponse>(
        settings.COMPASS_API_BASE_URL + `/patients`,
        payload
      )
    },
    [getCompassApiClient]
  )

  const createPatientManually: CreatePatientManuallyService = useCallback(
    async (payload) => {
      const compassClient = await getCompassApiClient()
      return await compassClient.post<CreatePatientManuallyServiceResponse>(
        settings.COMPASS_API_BASE_URL + `/patients?manualEntry=true`,
        payload
      )
    },
    [getCompassApiClient]
  )

  const getPatientStatusDefinitions: GetPatientStatusDefinitionsService =
    useCallback(async () => {
      const compassClient = await getCompassApiClient()
      return await compassClient.get(
        settings.COMPASS_API_BASE_URL + `/patients/status/definitions`
      )
    }, [getCompassApiClient])

  return {
    createPatient,
    createPatientManually,
    getPatient,
    getPatientPainAndFunction,
    updatePatient,
    updatePatientStatus,
    getPatientStatusDefinitions,
  }
}
