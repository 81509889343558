import {
  MSKAnswer,
  MSKIntakeAssessment,
  MSKQuestion,
  MSKQuestionsAndActions,
} from 'src/features/msk/domain'
import { MSKFormStateIntakeAssessment } from '../shared/infrastructure'

export const getQuestion = (
  id: string,
  questions: MSKQuestionsAndActions['questions']
) => questions[id] || null

export const getAction = (
  id: string,
  actions: MSKQuestionsAndActions['actions']
) => actions[id] || null

export const isQuestionId = (id?: string) => id && id.endsWith('q')

export const isAnActionId = (id?: string) => id && id.endsWith('a')

export const getCurrentAnswer = (
  question: MSKQuestion | null,
  answers: MSKIntakeAssessment['answers']
) => {
  if (!question) {
    return null
  }
  return answers[question.id] ? answers[question.id] : null
}

export const getQuestionOptionKey = (answer: MSKAnswer) =>
  answer.value === 'yes' ? 'y' : 'n'

export const getNextId = (
  answer: MSKAnswer,
  question: MSKQuestion,
  intakeAssessment: MSKFormStateIntakeAssessment
) => {
  const questionOptionKey = getQuestionOptionKey(answer)
  return question.options[questionOptionKey].nextStep[
    intakeAssessment.botheredBodyPart.id
  ]
}
