import { Box } from '@mui/material'
import { FC } from 'react'

import { LoadMSKFormReturns } from 'src/features/msk/domain'
import {
  MSKFormStateIntakeAssessment,
  useMSKFormStore,
} from 'src/features/shared/infrastructure'
import { SliderField } from 'src/features/shared/presentation'

import {
  FunctionLevelSliderFriendlyMarks,
  PainLevelSliderFriendlyMarks,
} from 'src/features/msk/presentation'

type IntakeAssessmentPainAndFunctionProps = {
  loadedMSKForm: LoadMSKFormReturns
  currentIntakeAssessment: MSKFormStateIntakeAssessment
}

const marks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 1,
  },
  {
    value: 2,
  },
  {
    value: 3,
  },
  {
    value: 4,
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 6,
  },
  {
    value: 7,
  },
  {
    value: 8,
  },
  {
    value: 9,
  },
  {
    value: 10,
    label: '10',
  },
]

export const IntakeAssessmentPainAndFunction: FC<
  IntakeAssessmentPainAndFunctionProps
> = ({ loadedMSKForm, currentIntakeAssessment }) => {
  const { setCurrentPainLevel, setCurrentFunctionLevel } = useMSKFormStore({
    loadedMSKForm,
  })

  const handlePainLevelChange = (event: Event, value: number | number[]) => {
    setCurrentPainLevel(value as number)
  }

  const handleFunctionLevelChange = (
    event: Event,
    value: number | number[]
  ) => {
    setCurrentFunctionLevel(value as number)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '80px',
      }}
    >
      <SliderField
        label={'What is your current pain level?'}
        sliderProps={{
          value: currentIntakeAssessment.currentPainLevel,
          onChange: handlePainLevelChange,
          marks: marks,
          track: false,
          step: 1,
          min: 0,
          max: 10,
          valueLabelDisplay: 'auto',
        }}
        friendlyMarks={
          <PainLevelSliderFriendlyMarks setPainLevel={setCurrentPainLevel} />
        }
      />
      <SliderField
        label={
          'How would you rate your ability to do your normal daily activities? (such as brushing your teeth, walking your dog, etc.)'
        }
        sliderProps={{
          value: currentIntakeAssessment.currentFunctionLevel,
          onChange: handleFunctionLevelChange,
          marks: marks,
          track: false,
          step: 1,
          min: 0,
          max: 10,
          valueLabelDisplay: 'auto',
        }}
        friendlyMarks={
          <FunctionLevelSliderFriendlyMarks
            setFunctionLevel={setCurrentFunctionLevel}
          />
        }
      />
    </Box>
  )
}
