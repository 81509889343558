import { useQuery } from '@tanstack/react-query'

import { mapToPatientHeaderData } from 'src/features/patients/adapters'
import { usePatientsUseCases } from 'src/features/patients/patients.service-locator'
import {
  GetPatientError,
  GetPatientReturns,
} from 'src/features/patients/domain'
import { usePatientId } from 'src/features/shared/presentation'

export const GET_PATIENT_QUERY_KEY = 'getPatient'

type UseGetPatientQueryProps = {
  patientId?: string
  throwGetPatientIdError?: boolean
}

export const useGetPatientQuery = ({
  patientId,
  throwGetPatientIdError = true,
}: UseGetPatientQueryProps = {}) => {
  const patientsUseCases = usePatientsUseCases()

  const _patientId = usePatientId({ patientId, throwGetPatientIdError })

  const { data, isLoading, isError } = useQuery<
    GetPatientReturns | null,
    GetPatientError
  >([GET_PATIENT_QUERY_KEY, _patientId], () => {
    return _patientId ? patientsUseCases.getPatient(_patientId) : null
  })
  return {
    patient: data,
    patientHeaderData: data ? mapToPatientHeaderData(data) : null,
    getPatientIsLoading: isLoading,
    getPatientIsError: isError,
  }
}
