import {
  openMSKFormModalSelector,
  setOpenMSKFormModalAction,
  useAppDispatch,
  useAppSelector,
} from 'src/features/shared/infrastructure'

export const usePatientPageStore = () => {
  const dispatch = useAppDispatch()

  const openMSKFormModal = useAppSelector(openMSKFormModalSelector)

  const setOpenMSKFormModal = (openMSKFormModal: boolean) => {
    dispatch(setOpenMSKFormModalAction(openMSKFormModal))
  }

  return {
    openMSKFormModal,
    setOpenMSKFormModal,
  }
}
