import { ErrorMessage } from '@hookform/error-message'
import {
  Box,
  Divider,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material'
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
  useWatch,
} from 'react-hook-form'
import {
  AdditionalQuestionsFormValues,
  agreeType,
} from 'src/features/shared/presentation'

export type IntakeQuestionsFormValues = Pick<
  AdditionalQuestionsFormValues,
  | 'agreeYouUnderstandCondition'
  | 'agreeYouUnderstandTreatmentOptions'
  | 'sdmPatientUnderstandsCondition'
  | 'sdmClinicalBelievesPatientUnderstandsCondition'
  | 'scheduled'
  | 'providerGroup'
  | 'providerGroupText'
  | 'preferredProvider'
  | 'providerGroupLocation'
  | 'sdmText'
  | 'providerName'
  | 'outsideSource'
>

export const IntakeAdditionalQuestionsForm = <T extends FieldValues>() => {
  const conditionName = 'agreeYouUnderstandCondition' as FieldPath<T>
  const treatmentName = 'agreeYouUnderstandTreatmentOptions' as FieldPath<T>

  const { register } = useFormContext()
  const watchers = useWatch()
  const sdmChecked = watchers.sdm

  return (
    <>
      {!sdmChecked ? (
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            width: 285,
            whiteSpace: 'nowrap',
            flexDirection: 'column',
          }}
        >
          <Divider />
          <Typography pb={2} color={'grey'}>
            Health Equity & Engagement Questions
          </Typography>
        </Box>
      ) : null}

      <Box display={'flex'} flexDirection={'column'} gap={1}>
        <Controller
          name={conditionName}
          {...register}
          render={({ field }) => (
            <Box>
              <InputLabel
                sx={{ fontWeight: 'bold', pb: 1 }}
                id={'understand-condition-label'}
              >
                Do you agree that you understand your condition?
              </InputLabel>

              <Select
                size="small"
                labelId="understand-condition-label"
                id="understand-condition"
                value={field.value}
                sx={{ width: '268px' }}
                displayEmpty
                onChange={(event) => {
                  field.onChange(event.target.value)
                }}
              >
                {agreeType.map((value) => (
                  <MenuItem key={value.id} value={value.id}>
                    {value.label}
                  </MenuItem>
                ))}
              </Select>
              <ErrorMessage
                name={conditionName}
                render={({ message }) => (
                  <FormHelperText error={true}>{message}</FormHelperText>
                )}
              />
            </Box>
          )}
        />
        <Controller
          name={treatmentName}
          {...register}
          render={({ field }) => (
            <Box>
              <InputLabel
                sx={{ fontWeight: 'bold', pb: 1 }}
                id={'understand-treatment-label'}
              >
                Do you agree that you understand the treatment options for your
                condition?
              </InputLabel>

              <Select
                size="small"
                labelId="understand-treatment-label"
                id="understand-treatment"
                sx={{ width: '268px' }}
                value={field.value}
                displayEmpty
                onChange={(event) => {
                  field.onChange(event.target.value)
                }}
              >
                {agreeType.map((value) => (
                  <MenuItem key={value.id} value={value.id}>
                    {value.label}
                  </MenuItem>
                ))}
              </Select>
              <ErrorMessage
                name={treatmentName}
                render={({ message }) => (
                  <FormHelperText error={true}>{message}</FormHelperText>
                )}
              />
            </Box>
          )}
        />
      </Box>
    </>
  )
}
