import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from 'src/features/shared/infrastructure'

export type PatientPageState = {
  openMSKFormModal: boolean
}

const initialState: PatientPageState = {
  openMSKFormModal: false,
}

const patientPageSlice = createSlice({
  name: 'patientPage',
  initialState,
  reducers: {
    setOpenMSKFormModalAction(
      state,
      action: PayloadAction<PatientPageState['openMSKFormModal']>
    ) {
      state.openMSKFormModal = action.payload
    },
  },
})

export const { setOpenMSKFormModalAction } = patientPageSlice.actions

export const openMSKFormModalSelector = (state: RootState) =>
  state.patientPage.openMSKFormModal

export default patientPageSlice.reducer
