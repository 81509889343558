/**
 * A wrapper for "JSON.parse()"" to support "undefined" value
 */
export function parseJSON<T>(value: string | null): T | undefined {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ?? '')
  } catch {
    return undefined
  }
}

export function readLocalStorage<TReturn>(key: string, initialValue?: TReturn) {
  try {
    const item = window.localStorage.getItem(key)
    return item ? (parseJSON(item) as TReturn) : initialValue
  } catch (error) {
    return initialValue
  }
}

export function saveInLocalStorage<TValue = any>(key: string, value: TValue) {
  window.localStorage.setItem(key, JSON.stringify(value))
}

export function removeFromLocalStorage(key: string) {
  window.localStorage.removeItem(key)
}
