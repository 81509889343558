import { Divider, Box, Typography } from '@mui/material'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { mapToTCReactSelectOption } from 'src/features/shared/adapters'
import { OTHER_LANGUAGES } from 'src/features/shared/constants'
import { getMainLanguageOptionsWithOther } from 'src/features/shared/utils'
import {
  TCReactSelect,
  VanillaFormFieldContainer,
} from 'src/features/shared/presentation'

type LanguageFieldsSectionProps = {
  otherLanguageIsRequired?: boolean
}

export const LanguageFieldsSection: FC<LanguageFieldsSectionProps> = ({
  otherLanguageIsRequired,
}) => {
  const formMethods = useFormContext()
  const languageWatcher = formMethods.watch('language')

  return (
    <>
      <Divider textAlign="left" sx={{ color: 'gray' }}>
        Language
      </Divider>
      <Box
        sx={{
          display: 'flex',
          columnGap: '16px',
        }}
      >
        <Controller
          name="language"
          control={formMethods.control}
          render={({ field }) => (
            <TCReactSelect
              placeholder={'Select Language'}
              options={getMainLanguageOptionsWithOther()}
              styles={{
                control: () => ({
                  width: '264px',
                }),
                menu: () => ({
                  width: '264px',
                }),
              }}
              value={
                field.value ? mapToTCReactSelectOption(field.value) : undefined
              }
              onBlur={field.onBlur}
              onChange={(option) => {
                const value = option ? option.value : ''
                field.onChange(value)
              }}
            />
          )}
        />
        {languageWatcher === 'Other' ? (
          <Controller
            name="otherLanguage"
            control={formMethods.control}
            render={({ field, fieldState }) => (
              <VanillaFormFieldContainer
                id={'patient_edit_form_other_language'}
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : ''}
              >
                <TCReactSelect
                  required={otherLanguageIsRequired}
                  error={!!fieldState.error}
                  placeholder={
                    <Typography>
                      Other Language{' '}
                      {otherLanguageIsRequired ? (
                        <Typography component={'span'} color={'#FF0000'}>
                          *
                        </Typography>
                      ) : null}
                    </Typography>
                  }
                  isClearable={true}
                  options={OTHER_LANGUAGES.map(mapToTCReactSelectOption)}
                  styles={{
                    control: () => ({
                      width: '264px',
                    }),
                    menu: () => ({
                      width: '264px',
                    }),
                  }}
                  value={
                    field.value
                      ? mapToTCReactSelectOption(field.value)
                      : undefined
                  }
                  onBlur={field.onBlur}
                  onChange={(option) => {
                    const value = option ? option.value : ''
                    field.onChange(value)
                  }}
                />
              </VanillaFormFieldContainer>
            )}
          />
        ) : null}
      </Box>
    </>
  )
}
