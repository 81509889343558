import { Box, Divider, Stack, Typography } from '@mui/material'
import { FieldPath, FieldValues, useWatch } from 'react-hook-form'
import { filterNoneValueOptions } from 'src/features/notes/adapters'
import {
  AdditionalQuestionsFormValues,
  CustomSelectField,
  CustomTextField,
  PTLocationField,
  levelScores,
  yesNo,
} from 'src/features/shared/presentation'

export type PostOpTouchpointQuestionsFormValues = Pick<
  AdditionalQuestionsFormValues,
  | 'isPainControlled'
  | 'accessToMedicineToControlPain'
  | 'participatingInPT'
  | 'physicalTherapyLocation'
  | 'additionalQuestionsOrConcerns'
  | 'sdmPatientUnderstandsCondition'
  | 'sdmClinicalBelievesPatientUnderstandsCondition'
  | 'sdmText'
  | 'procedure'
  | 'surgerySite'
  | 'dischargePosition'
>

export const PostOpTouchpointAdditionalQuestionsForm = <
  T extends FieldValues
>() => {
  const painLevel = 'painLevel' as FieldPath<T>
  const functionLevel = 'functionLevel' as FieldPath<T>

  const isPainControlled = 'isPainControlled' as FieldPath<T>
  const accessToMedicineToControlPain =
    'accessToMedicineToControlPain' as FieldPath<T>
  const participatingInPT = 'participatingInPT' as FieldPath<T>

  const additionalQuestionsOrConcerns =
    'additionalQuestionsOrConcerns' as FieldPath<T>

  // Field wathchers
  const watcher = useWatch()
  const watchisPainControlled = watcher.isPainControlled
  const watchParticipatingInPT = watcher.participatingInPT
  const watchPainLevel = watcher.painLevel

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          width: 170,
          whiteSpace: 'nowrap',
          flexDirection: 'column',
        }}
      >
        <Divider />
        <Typography pb={2} color={'grey'}>
          Assessment Questions
        </Typography>
      </Box>
      <Stack direction={'column'} rowGap={1}>
        <CustomSelectField
          name={painLevel}
          id="pain-level"
          label={'On a scale of 0 to 10, what is your current pain level?'}
          items={filterNoneValueOptions(levelScores)}
          defaultItem="Add Score"
          selectStyle={{ width: 'max-width' }}
          labelStyle={{ fontWeight: 'bold', pb: 1 }}
        />

        {watchPainLevel > 7 ? (
          <>
            <CustomSelectField
              name={isPainControlled}
              id="is-pain-controlled"
              label={'Do you feel your pain is controlled?'}
              items={filterNoneValueOptions(yesNo)}
              selectStyle={{ width: '88px' }}
              labelStyle={{
                fontWeight: 'bold',
                pb: 1,
              }}
            />
            {watchisPainControlled === 'No' ? (
              <CustomSelectField
                name={accessToMedicineToControlPain}
                id="access-to-medicine-to-control-pain"
                label={
                  'If not, do you feel you have access to the medication you need to control your pain? '
                }
                items={filterNoneValueOptions(yesNo)}
                defaultItem="Select Option"
                selectStyle={{ width: 'min-content' }}
                labelStyle={{
                  fontWeight: 'bold',
                  pb: 1,
                  whiteSpace: 'pre-wrap',
                  width: '90%',
                  height: 'min-height',
                }}
                helperText="If No, ensure the patient is connected to MD office"
              />
            ) : null}
          </>
        ) : null}

        <CustomSelectField
          name={functionLevel}
          id="function-level"
          label={
            'On a scale of 0 to 10, how would you rate your ability to do your normal daily activities, where 0 is completely unable, and 10 is able to perform as normal?'
          }
          items={filterNoneValueOptions(levelScores)}
          defaultItem="Add Score"
          selectStyle={{ width: 'max-width' }}
          labelStyle={{
            fontWeight: 'bold',
            pb: 1,
            whiteSpace: 'pre-wrap',
            width: '90%',
            height: 'min-height',
          }}
        />
        <CustomSelectField
          name={participatingInPT}
          id="participating-in-pt"
          label={'Are you participating in physical therapy?'}
          items={[
            { id: 'Yes', label: 'Yes' },
            { id: 'No - Not Prescribed', label: 'No - Not Prescribed' },
            {
              id: 'No - Needed Scheduling Support',
              label: 'No - Needed Scheduling Support',
            },
            {
              id: 'No - Opted Out',
              label: 'No - Opted Out',
            },
          ]}
          selectStyle={{ minWidth: '88px' }}
          labelStyle={{
            fontWeight: 'bold',
            pb: 1,
          }}
        />
        {watchParticipatingInPT === 'Yes' ? <PTLocationField /> : null}
        <CustomTextField
          name={additionalQuestionsOrConcerns}
          id="additional-questions-or-concerns"
          label={
            'What additional questions or concerns do you have about your condition? '
          }
          labelStyle={{ fontWeight: 'bold', pb: 1 }}
          inputStyle={{ width: '100%' }}
        />
      </Stack>
    </>
  )
}
