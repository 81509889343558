import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material'
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form'
import { REFERRAL_SOURCES } from 'src/features/shared/constants'

export const ReferralSourceField = <T extends FieldValues>() => {
  const name = 'referralSource' as FieldPath<T>
  const { register } = useFormContext()

  return (
    <Controller
      name={name}
      {...register}
      render={({ field, fieldState }) => (
        <FormControl size="small" sx={{ width: '100%' }}>
          <InputLabel id="referral-source-label">Referral Source</InputLabel>
          <Select
            labelId="referral-source-label"
            id="referral-source"
            label="Referral Source"
            value={field.value}
            onChange={(event) => {
              field.onChange(event.target.value)
            }}
          >
            {REFERRAL_SOURCES.map((referralSource) => (
              <MenuItem key={referralSource.id} value={referralSource.id}>
                {referralSource.label}
              </MenuItem>
            ))}
          </Select>
          {fieldState.error && (
            <FormHelperText error={!!fieldState.error}>
              {fieldState.error?.message}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  )
}
