import { configureStore } from '@reduxjs/toolkit'

import tasksSidebarSliceReducer from './slices/tasks-sidebar.slice'
import queuePatientSliceReducer from './slices/queue-patients.slice'
import eligibilitySliceReducer from './slices/eligibility.slice'
import baseLayoutUISliceReducer from './slices/base-layout-ui.slice'
import rolesAndPermissionsSliceReducer from './slices/roles-and-permissions.slice'
import patientPageSliceReducer from './slices/patient-page.slice'
import noteSliceReducer from './slices/notes.slice'
import mskFormSliceReducer from './slices/msk-form'

export const store = configureStore({
  reducer: {
    baseLayoutUI: baseLayoutUISliceReducer,
    tasksSidebar: tasksSidebarSliceReducer,
    queuePatients: queuePatientSliceReducer,
    eligibility: eligibilitySliceReducer,
    rolesAndPermissions: rolesAndPermissionsSliceReducer,
    mskForm: mskFormSliceReducer,
    patientPage: patientPageSliceReducer,
    notes: noteSliceReducer,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
