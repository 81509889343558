import { UsePatientsUseCases } from 'src/features/patients/domain'
import { usePatientsServiceAdapter } from 'src/features/patients/adapters'

type MakeUsePatientsUseCases = () => UsePatientsUseCases

const makeUsePatientsUseCases: MakeUsePatientsUseCases = () => () => {
  const patientsServiceAdapter = usePatientsServiceAdapter()

  return {
    createPatient: patientsServiceAdapter.createPatient,
    createPatientManually: patientsServiceAdapter.createPatientManually,
    getPatient: patientsServiceAdapter.getPatient,
    getPatientPainAndFunction: patientsServiceAdapter.getPatientPainAndFunction,
    getPatientStatusDefinitions:
      patientsServiceAdapter.getPatientStatusDefinitions,
    searchPatients: patientsServiceAdapter.searchPatients,
    searchDuplicatePatients: patientsServiceAdapter.searchDuplicatePatients,
    updatePatient: patientsServiceAdapter.updatePatient,
    updatePatientStatus: patientsServiceAdapter.updatePatientStatus,
  }
}

export const usePatientsUseCases: UsePatientsUseCases =
  makeUsePatientsUseCases()
