import { useQuery } from '@tanstack/react-query'

import { usePatientsUseCases } from 'src/features/patients/patients.service-locator'
import {
  GetPatientStatusDefinitionsError,
  GetPatientStatusDefinitionsReturns,
} from 'src/features/patients/domain'

export const GET_PATIENT_STATUS_DEFINITIONS_QUERY_KEY =
  'getPatientStatusDefinitions'

export const useGetPatientStatusDefinitionsQuery = () => {
  const patientsUseCases = usePatientsUseCases()

  const { data, isLoading, isError } = useQuery<
    GetPatientStatusDefinitionsReturns,
    GetPatientStatusDefinitionsError
  >([GET_PATIENT_STATUS_DEFINITIONS_QUERY_KEY], () => {
    return patientsUseCases.getPatientStatusDefinitions()
  })
  return {
    statusDefinitions: data ? data : {},
    getPatientStatusDefinitionsIsLoading: isLoading,
    getPatientStatusDefinitionsIsError: isError,
  }
}
