import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material'
import { FC } from 'react'
import { useFormContext, Controller } from 'react-hook-form'

import { mapToTCReactSelectOption } from 'src/features/shared/adapters'
import { REFERRAL_SOURCES } from 'src/features/shared/constants'
import { TCReactSelect } from 'src/features/shared/presentation'
import * as settings from 'src/config/settings'

export const ReferralSourceSelect: FC = () => {
  const formMethods = useFormContext()
  if (settings.USE_REACT_SELECT_ALTERNATIVE) {
    return (
      <Controller
        name="referralSource"
        control={formMethods.control}
        render={({ field }) => (
          <TCReactSelect
            placeholder={'Referral Source'}
            options={REFERRAL_SOURCES.map(mapToTCReactSelectOption)}
            value={
              field.value ? mapToTCReactSelectOption(field.value) : undefined
            }
            onChange={(option) => {
              const value = option ? option.value : ''
              field.onChange(value)
            }}
          />
        )}
      />
    )
  }

  return (
    <Controller
      name="referralSource"
      control={formMethods.control}
      render={({ field, fieldState }) => (
        <FormControl size={'small'}>
          <InputLabel
            id={'eligibility-patient-form-referral-source-label'}
            error={!!fieldState.error}
          >
            Referral Source
          </InputLabel>
          <Select
            labelId={'eligibility-patient-form-referral-source-label'}
            id={'eligibility-patient-form-referral-source'}
            label={'Referral Source'}
            value={field.value}
            onChange={(event) => {
              field.onChange(event.target.value)
            }}
            error={!!fieldState.error}
          >
            {REFERRAL_SOURCES.map((referralSource) => (
              <MenuItem key={referralSource.id} value={referralSource.id}>
                {referralSource.label}
              </MenuItem>
            ))}
          </Select>
          {fieldState.error ? (
            <FormHelperText error={!!fieldState.error}>
              {fieldState.error ? fieldState.error.message : ''}
            </FormHelperText>
          ) : null}
        </FormControl>
      )}
    />
  )
}
