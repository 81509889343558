import {
  InputLabel,
  FormHelperText,
  SxProps,
  Theme,
  Box,
  TextField,
} from '@mui/material'
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form'

type CustomTextFieldProps<T extends FieldValues> = {
  name?: FieldPath<T>
  label?: string
  id: string
  inputStyle?: SxProps<Theme>
  labelStyle?: SxProps<Theme>
  placeholder?: string
}

export const CustomTextField = <T extends FieldValues>({
  name,
  label,
  id,
  inputStyle,
  labelStyle,
  ...props
}: CustomTextFieldProps<T>) => {
  const customName = name as FieldPath<T>
  const { register } = useFormContext()

  return (
    <Controller
      name={customName}
      {...register}
      render={({ field, fieldState }) => (
        <Box>
          {label ? <InputLabel sx={labelStyle}>{label}</InputLabel> : null}
          <TextField
            size="small"
            id={id}
            value={field.value}
            sx={inputStyle}
            onChange={(event) => {
              field.onChange(event.target.value)
            }}
            {...props}
          />
          {fieldState.error ? (
            <FormHelperText error={!!fieldState.error}>
              {fieldState.error ? fieldState.error.message : ''}
            </FormHelperText>
          ) : null}
        </Box>
      )}
    />
  )
}
