import { Typography, Box, Stack, IconButton, styled } from '@mui/material'
import { FC } from 'react'

import EditIcon from '@mui/icons-material/Edit'

import {
  TCCopyToClipboard,
  PatientHeaderChip,
  useTooltipsState,
} from 'src/features/shared/presentation'
import { PatientMedicalProfile } from 'src/features/patients/domain'

export type PatientHeaderData = {
  id: string
  name: string
  notEnglishSpeaker: boolean
  hasPhone: boolean
  hasPCPInfo: boolean
  hasPayorInfo: boolean
  language?: string | null
  mbi?: string | null
  dob?: string | null
  phone1?: string
  phone2?: string
  doNotCall?: boolean
  dualCoverage?: boolean
  referralSource?: string
  stageAndStatus?: string | null
  medicalProfile?: PatientMedicalProfile | null
}

const PatientHeaderAttrKey = styled(Typography)(() => ({
  color: 'rgba(0, 0, 0, 0.38)',
  fontSize: '14px',
  letterSpacing: '0.17px',
}))

type PatientPageHeaderViewProps = {
  patient: PatientHeaderData
  onEditClick: () => void
}

const ErrorTrigger = () => {
  const handleClick = () => {
    throw new Error('This is a test error')
  }

  return (
    <div>
      <button onClick={handleClick}>Trigger Error</button>
    </div>
  )
}

export const PatientPageHeaderView: FC<PatientPageHeaderViewProps> = ({
  patient,
  onEditClick,
}) => {
  const {
    tooltipState,
    getTooltipCopyClickHandler,
    getTooltipCopiedTooltipCloseHandler,
  } = useTooltipsState([
    'tcId',
    'phone',
    'phone2',
    'mbi',
    'pcp',
    'planId',
    'pcpPhone',
  ])
  const handleEditTaskClick = () => onEditClick()
  return (
    <Box
      sx={{
        padding: '24px 16px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '5px',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', columnGap: '16px', alignItems: 'center' }}>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '24px',
            }}
          >
            {patient.name}
          </Typography>

          <Stack direction={'row'} gap={'4px'} alignItems={'center'}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '12px',
                letterSpacing: '0.15px',
                textDecoration: 'underline',
                color: 'magenta.300',
              }}
            >
              TC ID
            </Typography>
            <TCCopyToClipboard
              value={patient.id}
              tooltipOpen={tooltipState['tcId']}
              onCopy={getTooltipCopyClickHandler('tcId')}
              onTooltipClose={getTooltipCopiedTooltipCloseHandler('tcId')}
            />
          </Stack>

          {patient.dob ? (
            <Stack direction={'row'} gap={'8px'} alignItems={'center'}>
              <PatientHeaderAttrKey>DOB</PatientHeaderAttrKey>
              <Typography>{patient.dob}</Typography>
            </Stack>
          ) : null}

          {patient.notEnglishSpeaker && patient.language ? (
            <PatientHeaderChip label={`${patient.language} Speaker`} />
          ) : null}
          {patient.dualCoverage ? <PatientHeaderChip label="DSNP" /> : null}
          {patient.doNotCall ? <PatientHeaderChip label="DNC" /> : null}
        </Box>
        <Box sx={{ display: 'flex' }}>
          <IconButton onClick={handleEditTaskClick}>
            <EditIcon />
          </IconButton>
        </Box>
      </Box>

      {patient.referralSource || patient.stageAndStatus ? (
        <Stack direction={'row'} gap={'24px'} alignItems={'center'}>
          {patient.referralSource ? (
            <Stack direction={'row'} gap={'8px'} alignItems={'center'}>
              <PatientHeaderAttrKey>Ref Source</PatientHeaderAttrKey>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '16px',
                  color: 'rgba(0, 0, 0, 0.87)',
                }}
              >
                {patient.referralSource}
              </Typography>
            </Stack>
          ) : null}
          {patient.stageAndStatus ? (
            <Stack direction={'row'} gap={'8px'} alignItems={'center'}>
              <PatientHeaderAttrKey>Stage | Status</PatientHeaderAttrKey>
              <Stack direction={'row'} gap={'4px'}>
                <Typography>{patient.stageAndStatus}</Typography>
              </Stack>
            </Stack>
          ) : null}
        </Stack>
      ) : null}

      {patient.medicalProfile && patient.hasPayorInfo ? (
        <Stack direction={'row'} gap={'32px'}>
          {patient.medicalProfile.payor ? (
            <Stack direction={'row'} gap={'8px'} alignItems={'center'}>
              <PatientHeaderAttrKey>Plan Name</PatientHeaderAttrKey>
              <Typography>{patient.medicalProfile.payor}</Typography>
            </Stack>
          ) : null}

          {patient.medicalProfile.planId ? (
            <Stack direction={'row'} gap={'8px'} alignItems={'center'}>
              <PatientHeaderAttrKey>Plan ID</PatientHeaderAttrKey>
              <Stack direction={'row'} gap={'4px'}>
                <Typography>{patient.medicalProfile.planId}</Typography>
                <TCCopyToClipboard
                  value={patient.medicalProfile.planId}
                  tooltipOpen={tooltipState['planId']}
                  onCopy={getTooltipCopyClickHandler('planId')}
                  onTooltipClose={getTooltipCopiedTooltipCloseHandler('planId')}
                />
              </Stack>
            </Stack>
          ) : null}
        </Stack>
      ) : null}

      {patient.hasPhone ? (
        <Stack direction={'row'} gap={'32px'}>
          {patient.phone1 ? (
            <Stack direction={'row'} gap={'8px'} alignItems={'center'}>
              <PatientHeaderAttrKey>Phone 1</PatientHeaderAttrKey>
              <Stack direction={'row'} gap={'4px'}>
                <Typography>{patient.phone1}</Typography>
                <TCCopyToClipboard
                  value={patient.phone1}
                  tooltipOpen={tooltipState['phone']}
                  onCopy={getTooltipCopyClickHandler('phone')}
                  onTooltipClose={getTooltipCopiedTooltipCloseHandler('phone')}
                />
              </Stack>
            </Stack>
          ) : null}
          {patient.phone2 ? (
            <Stack direction={'row'} gap={'8px'} alignItems={'center'}>
              <PatientHeaderAttrKey>Phone 2</PatientHeaderAttrKey>
              <Stack direction={'row'} gap={'4px'}>
                <Typography>{patient.phone2}</Typography>
                <TCCopyToClipboard
                  value={patient.phone2}
                  tooltipOpen={tooltipState['phone2']}
                  onCopy={getTooltipCopyClickHandler('phone2')}
                  onTooltipClose={getTooltipCopiedTooltipCloseHandler('phone2')}
                />
              </Stack>
            </Stack>
          ) : null}
        </Stack>
      ) : null}

      {patient.medicalProfile && patient.hasPCPInfo ? (
        <>
          <Stack direction={'row'} gap={'32px'} marginTop={2}>
            {patient.medicalProfile.primaryCarePhysician ? (
              <Stack direction={'row'} gap={'8px'} alignItems={'center'}>
                <PatientHeaderAttrKey>PCP</PatientHeaderAttrKey>
                <Stack direction={'row'} gap={'4px'}>
                  <Typography>
                    {patient.medicalProfile.primaryCarePhysician}
                  </Typography>
                  <TCCopyToClipboard
                    value={patient.medicalProfile.primaryCarePhysician}
                    tooltipOpen={tooltipState['pcp']}
                    onCopy={getTooltipCopyClickHandler('pcp')}
                    onTooltipClose={getTooltipCopiedTooltipCloseHandler('pcp')}
                  />
                </Stack>
              </Stack>
            ) : null}
            {patient.medicalProfile.groupName ? (
              <Stack direction={'row'} gap={'8px'} alignItems={'center'}>
                <PatientHeaderAttrKey>Group Name</PatientHeaderAttrKey>
                <Stack direction={'row'} gap={'4px'}>
                  <Typography>{patient.medicalProfile.groupName}</Typography>
                  <TCCopyToClipboard
                    value={patient.medicalProfile.groupName}
                    tooltipOpen={tooltipState['groupName']}
                    onCopy={getTooltipCopyClickHandler('groupName')}
                    onTooltipClose={getTooltipCopiedTooltipCloseHandler(
                      'groupName'
                    )}
                  />
                </Stack>
              </Stack>
            ) : null}
          </Stack>
          <Stack direction={'row'} gap={'32px'}>
            {patient.medicalProfile.phone ? (
              <Stack direction={'row'} gap={'8px'} alignItems={'center'}>
                <PatientHeaderAttrKey>PCP Phone</PatientHeaderAttrKey>
                <Stack direction={'row'} gap={'4px'}>
                  <Typography>{patient.medicalProfile.phone}</Typography>
                  <TCCopyToClipboard
                    value={patient.medicalProfile.phone}
                    tooltipOpen={tooltipState['pcpPhone']}
                    onCopy={getTooltipCopyClickHandler('pcpPhone')}
                    onTooltipClose={getTooltipCopiedTooltipCloseHandler(
                      'pcpPhone'
                    )}
                  />
                </Stack>
              </Stack>
            ) : null}
            {patient.medicalProfile.fax ? (
              <Stack direction={'row'} gap={'8px'} alignItems={'center'}>
                <PatientHeaderAttrKey>PCP Fax</PatientHeaderAttrKey>
                <Stack direction={'row'} gap={'4px'}>
                  <Typography>{patient.medicalProfile.fax}</Typography>
                  <TCCopyToClipboard
                    value={patient.medicalProfile.fax}
                    tooltipOpen={tooltipState['fax']}
                    onCopy={getTooltipCopyClickHandler('fax')}
                    onTooltipClose={getTooltipCopiedTooltipCloseHandler('fax')}
                  />
                </Stack>
              </Stack>
            ) : null}
          </Stack>
        </>
      ) : null}
      <ErrorTrigger />
    </Box>
  )
}
