import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { FC } from 'react'

import { ChildrenInProps } from 'src/features/shared/types'

export const TCTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.navy['200'],
    color: theme.palette.common.white,
    fontSize: 14,
    fontWeight: 500,
    width: 'auto',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    fontWeight: 400,
    width: 'auto',
  },
}))

export const TCTableCellHead: FC<ChildrenInProps> = ({ children }) => {
  return (
    <TCTableCell
      variant={'head'}
      size={'medium'}
      sx={{
        whiteSpace: {
          lg: 'nowrap',
        },
      }}
    >
      {children}
    </TCTableCell>
  )
}
