import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Typography,
} from '@mui/material'
import { FC } from 'react'

import CloseIcon from '@mui/icons-material/Close'

import { ModalDialogTitleContainer } from 'src/features/shared/presentation'
import { LoadMSKFormReturns } from 'src/features/msk/domain'
import {
  useMSKFormStore,
  useNotesStore,
  usePatientPageStore,
} from 'src/features/shared/infrastructure'
import { Patient } from 'src/features/patients/domain'
import { MSKForm, MSKNoteField } from 'src/features/msk/presentation'

type MSKFormModalProps = {
  patient: Patient
  loadedMSKForm: LoadMSKFormReturns
}

export const MSKFormModal: FC<MSKFormModalProps> = ({
  patient,
  loadedMSKForm,
}) => {
  const { modalTitle } = useMSKFormStore({
    loadedMSKForm,
  })
  const { openMSKFormModal, setOpenMSKFormModal } = usePatientPageStore()
  const { setSelectedNoteType } = useNotesStore()

  const handleModalClose = () => {
    setOpenMSKFormModal(false)
    setSelectedNoteType('Intake')
  }

  return (
    <Dialog
      id="msk_form_modal_dialog"
      onClose={handleModalClose}
      open={openMSKFormModal}
      sx={{
        zIndex: 2,
        '& .MuiPaper-root': {
          xs: {
            maxWidth: '75vw',
            minWidth: '75vw',
            height: '85vh',
          },
        },
      }}
    >
      <ModalDialogTitleContainer id="msk_form_modal_dialog_title">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant={'h2'}>{modalTitle}</Typography>
          <IconButton onClick={handleModalClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </ModalDialogTitleContainer>
      <DialogContent
        id="msk_form_modal_dialog_content"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          rowGap: '32px',
          pb: '64px',
        }}
      >
        <MSKForm patient={patient} loadedMSKForm={loadedMSKForm} />
        <Divider
          sx={{
            width: {
              xs: '50%',
            },
            color: 'gray',
          }}
        />
        <MSKNoteField />
      </DialogContent>
    </Dialog>
  )
}
