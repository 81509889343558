import {
  NotesState,
  noteTextSelector,
  selectedNoteTypeSelector,
  setNoteTextAction,
  setSelectedNoteTypeAction,
  useAppDispatch,
  useAppSelector,
} from 'src/features/shared/infrastructure/store/redux'

export const useNotesStore = () => {
  const dispatch = useAppDispatch()

  const selectedNoteType = useAppSelector(selectedNoteTypeSelector)
  const noteText = useAppSelector(noteTextSelector)

  const setSelectedNoteType = (
    selectedNoteType: NotesState['selectedNoteType']
  ) => dispatch(setSelectedNoteTypeAction(selectedNoteType))

  const setNoteText = (noteText: NotesState['noteText']) =>
    dispatch(setNoteTextAction(noteText))

  return {
    selectedNoteType,
    setSelectedNoteType,
    noteText,
    setNoteText,
  }
}
