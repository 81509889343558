import {
  UseMutateAsyncFunction,
  UseMutateFunction,
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import {
  CreatePatientSelfCareTouchpointNoteReturns,
  CreatePatientSelfCareTouchpointNoteArgs,
  CreatePatientSelfCareTouchpointNoteError,
} from 'src/features/notes/domain'
import {
  GET_PATIENT_QUERY_KEY,
  GET_PATIENT_PAIN_AND_FUNCTION_QUERY_KEY,
} from 'src/features/patients/presentation'
import { GET_PATIENT_NOTES_QUERY_KEY } from 'src/features/notes/presentation'
import { useNotesUseCases } from 'src/features/notes/notes.service-locator'

type UseCreatePatientSelfCareTouchpointNoteProps = Omit<
  UseMutationOptions<
    CreatePatientSelfCareTouchpointNoteReturns,
    CreatePatientSelfCareTouchpointNoteError,
    CreatePatientSelfCareTouchpointNoteArgs,
    unknown
  >,
  'mutationFn'
>

type UseCreatePatientSelfCareTouchpointNote = (
  props?: UseCreatePatientSelfCareTouchpointNoteProps
) => {
  createPatientSelfCareTouchpointNote: UseMutateFunction<
    CreatePatientSelfCareTouchpointNoteReturns,
    CreatePatientSelfCareTouchpointNoteError,
    CreatePatientSelfCareTouchpointNoteArgs,
    unknown
  >
  createPatientSelfCareTouchpointNoteAsync: UseMutateAsyncFunction<
    CreatePatientSelfCareTouchpointNoteReturns,
    CreatePatientSelfCareTouchpointNoteError,
    CreatePatientSelfCareTouchpointNoteArgs,
    unknown
  >
  createPatientSelfCareTouchpointNoteIsLoading: boolean
  createPatientSelfCareTouchpointNoteIsError: boolean
  createPatientSelfCareTouchpointNoteStatus: string
}

export const useCreatePatientSelfCareTouchpointNote: UseCreatePatientSelfCareTouchpointNote =
  (props = {}) => {
    const queryClient = useQueryClient()
    const notesUseCases = useNotesUseCases()

    const { onSuccess } = props

    const { mutate, mutateAsync, isLoading, isError, status } = useMutation<
      CreatePatientSelfCareTouchpointNoteReturns,
      CreatePatientSelfCareTouchpointNoteError,
      CreatePatientSelfCareTouchpointNoteArgs,
      unknown
    >({
      mutationFn: (args: CreatePatientSelfCareTouchpointNoteArgs) => {
        return notesUseCases.createPatientSelfCareTouchpointNote(args)
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({
          queryKey: [GET_PATIENT_QUERY_KEY, variables.patientId],
        })
        queryClient.invalidateQueries({
          queryKey: [GET_PATIENT_NOTES_QUERY_KEY, variables.patientId],
        })
        queryClient.invalidateQueries({
          queryKey: [
            GET_PATIENT_PAIN_AND_FUNCTION_QUERY_KEY,
            variables.patientId,
          ],
        })
        onSuccess?.(data, variables, context)
      },
    })
    return {
      createPatientSelfCareTouchpointNote: mutate,
      createPatientSelfCareTouchpointNoteAsync: mutateAsync,
      createPatientSelfCareTouchpointNoteIsLoading: isLoading,
      createPatientSelfCareTouchpointNoteIsError: isError,
      createPatientSelfCareTouchpointNoteStatus: status,
    }
  }
