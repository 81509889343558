import { Box } from '@mui/material'
import { FC } from 'react'
import { Outlet } from 'react-router-dom'

import {
  MainBoxBaseLayout,
  MainStackBaseLayout,
  NavbarVertical,
} from 'src/features/shared/presentation'
import {
  TasksSidebar,
  TASKS_SIDEBAR_DESKTOP_WIDTH,
} from 'src/features/tasks/presentation'
import { useTasksSidebarStore } from 'src/features/shared/infrastructure'
import { ChildrenInProps } from 'src/features/shared/types'
import { NAVBAR_VERTICAL_DESKTOP_WIDTH } from 'src/features/shared/presentation'

const FirstBoxWrapperContentWithNavbarBaseLayout: FC<ChildrenInProps> = ({
  children,
}) => {
  const { open: tasksSidebarIsOpen } = useTasksSidebarStore()
  return (
    <Box
      id={'first-box-wrapper-content-with-navbar-base-layout'}
      sx={{
        boxSizing: 'border-box',
        height: '100%',
        maxWidth: '100vw',
        paddingLeft: { xs: `${NAVBAR_VERTICAL_DESKTOP_WIDTH}px` },
        paddingRight: {
          xs: `${
            tasksSidebarIsOpen ? `${TASKS_SIDEBAR_DESKTOP_WIDTH}px` : '0px'
          }`,
        },
        transitionDuration: '0.5s',
        transitionProperty: 'padding-right',
      }}
    >
      {children}
    </Box>
  )
}

const SecondBoxWrapperContentWithNavbarBaseLayout: FC<ChildrenInProps> = ({
  children,
}) => {
  const { open: tasksSidebarIsOpen } = useTasksSidebarStore()
  return (
    <Box
      id={'second-box-wrapper-content-with-navbar-base-layout'}
      sx={{
        boxSizing: 'border-box',
        pt: {
          xs: '48px',
        },
        pb: {
          xs: '62px',
        },
        px: {
          xs: `${tasksSidebarIsOpen ? '12px' : '24px'}`,
        },
        transitionDuration: '0.5s',
        transitionProperty: 'padding-right',
      }}
    >
      {children}
    </Box>
  )
}

export const WithNavbarBaseLayoutWrapper: FC<ChildrenInProps> = ({
  children,
}) => {
  return (
    <MainBoxBaseLayout>
      <MainStackBaseLayout>
        <NavbarVertical />
        <FirstBoxWrapperContentWithNavbarBaseLayout>
          <SecondBoxWrapperContentWithNavbarBaseLayout>
            {children}
          </SecondBoxWrapperContentWithNavbarBaseLayout>
        </FirstBoxWrapperContentWithNavbarBaseLayout>
      </MainStackBaseLayout>
      <TasksSidebar />
    </MainBoxBaseLayout>
  )
}

export const RouterWithNavbarBaseLayout: FC = () => {
  return (
    <WithNavbarBaseLayoutWrapper>
      <Outlet />
    </WithNavbarBaseLayoutWrapper>
  )
}
