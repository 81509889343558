import { Box } from '@mui/material'
import { FC } from 'react'

import { useMSKFormStore } from 'src/features/shared/infrastructure'
import { LoadMSKFormReturns } from 'src/features/msk/domain'
import { Patient } from 'src/features/patients/domain'

import {
  MSKFormStepOne,
  MSKFormStepThree,
  MSKFormStepTwo,
} from 'src/features/msk/presentation'

type MSKFormProps = {
  patient: Patient
  loadedMSKForm: LoadMSKFormReturns
}

export const MSKForm: FC<MSKFormProps> = ({ patient, loadedMSKForm }) => {
  const { step } = useMSKFormStore({ loadedMSKForm })
  return (
    <Box
      id="msk_form"
      sx={{
        width: {
          xs: '100%',
        },
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {step === 1 ? <MSKFormStepOne loadedMSKForm={loadedMSKForm} /> : null}
      {step === 2 ? (
        <MSKFormStepTwo patient={patient} loadedMSKForm={loadedMSKForm} />
      ) : null}
      {step === 3 ? (
        <MSKFormStepThree patient={patient} loadedMSKForm={loadedMSKForm} />
      ) : null}
    </Box>
  )
}
