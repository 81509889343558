import { FC, useState } from 'react'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'
import {
  Box,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  Button,
  styled,
  Typography,
  Stack,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  Paper,
} from '@mui/material'

import {
  BackdropLoader,
  FullSectionLoader,
  RefreshButton,
  TCBodyTableRow,
  TCCopyToClipboard,
  TCTableCell,
  TagChip,
  useTooltipsState,
} from 'src/features/shared/presentation'
import {
  useGetEligiblePatientsQuery,
  EligiblePatientsSearchInput,
  EligiblePatientsTableRow,
  EligiblePatientsTablePagination,
} from 'src/features/eligibility/presentation'
import {
  useEligibilityPatientStore,
  useQueuePatientsStore,
} from 'src/features/shared/infrastructure'
import { EligiblePatient } from 'src/features/eligibility/domain'
import { useCreatePatient } from 'src/features/patients/presentation'
import { mapToEligiblePatientsTableRow } from 'src/features/eligibility/adapters'
import { CreatePatientArgs } from 'src/features/patients/domain'
import { mapPatientToCreatePatientArgs } from 'src/features/patients/adapters'
import { REFERRAL_SOURCES } from 'src/features/shared/constants'

const EligiblePatientFormAttrKey = styled(Typography)(() => ({
  color: 'rgba(0, 0, 0, 0.38)',
  fontSize: '14px',
  letterSpacing: '0.17px',
}))

type EligiblePatientsFormFields = {
  referralSource: string
}

const eligibilityPatientFormSchema: yup.Schema<EligiblePatientsFormFields> = yup
  .object()
  .shape({
    referralSource: yup.string().required('Required'),
  })

const EligiblePatientForm: FC = () => {
  const [showLoader, setShowLoader] = useState(false)

  const {
    tooltipState,
    getTooltipCopyClickHandler,
    getTooltipCopiedTooltipCloseHandler,
  } = useTooltipsState(['phone', 'phone2', 'mbi'])

  const {
    selectedEligiblePatient,
    selectedEligiblePatientRow,
    clearSelectedEligiblePatient,
  } = useEligibilityPatientStore()

  const { setOpenCreatePatientModal } = useQueuePatientsStore()

  const { createPatient } = useCreatePatient()
  const navigate = useNavigate()

  const formMethods = useForm<EligiblePatientsFormFields>({
    resolver: yupResolver(eligibilityPatientFormSchema),
    defaultValues: {
      referralSource: '',
    },
  })

  const isPatientSelected =
    !!selectedEligiblePatient && !!selectedEligiblePatientRow

  const submitHandler = (data: EligiblePatientsFormFields) => {
    setShowLoader(true)

    if (!selectedEligiblePatient) {
      // TODO: handle error
      return
    }

    const createPatientArgs: CreatePatientArgs = {
      ...mapPatientToCreatePatientArgs(selectedEligiblePatient),
      ...data,
    }

    createPatient(createPatientArgs, {
      onSuccess: (data) => {
        toast.success(`Patient succesfully created!`)
        setOpenCreatePatientModal(false)
        clearSelectedEligiblePatient()
        navigate(`/patients/${data.patientId}`)
      },
      onError: (error) => {
        setShowLoader(false)
        toast.error(`Patient creation failed! ${error.message}`)
      },
    })
  }

  return (
    <Paper
      sx={{
        padding: '12px 16px',
        height: '100px',
      }}
    >
      {isPatientSelected ? (
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(submitHandler)}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '16px',
                  position: 'relative',
                }}
              >
                <Stack direction={'row'} gap={'24px'} alignItems={'center'}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: '24px',
                    }}
                  >
                    {selectedEligiblePatientRow?.name}
                  </Typography>
                  {selectedEligiblePatient?.dob ? (
                    <Stack direction={'row'} gap={'8px'}>
                      <EligiblePatientFormAttrKey>
                        DOB
                      </EligiblePatientFormAttrKey>
                      <Typography>{selectedEligiblePatient.dob}</Typography>
                    </Stack>
                  ) : null}
                </Stack>
                <Stack direction={'row'} gap={'32px'}>
                  {selectedEligiblePatient?.mbi ? (
                    <Stack direction={'row'} gap={'4px'} alignItems={'center'}>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: '16px',
                          color: 'rgba(0, 0, 0, 0.6)',
                        }}
                      >
                        {selectedEligiblePatient.mbi}
                      </Typography>
                      <TCCopyToClipboard
                        value={selectedEligiblePatient.mbi}
                        tooltipOpen={tooltipState['mbi']}
                        onCopy={getTooltipCopyClickHandler('mbi')}
                        onTooltipClose={getTooltipCopiedTooltipCloseHandler(
                          'mbi'
                        )}
                      />
                    </Stack>
                  ) : null}
                  {selectedEligiblePatient?.primaryPhone ? (
                    <Stack direction={'row'} gap={'8px'} alignItems={'center'}>
                      <EligiblePatientFormAttrKey>
                        Phone 1
                      </EligiblePatientFormAttrKey>
                      <Stack direction={'row'} gap={'4px'}>
                        <Typography>
                          {selectedEligiblePatient.primaryPhone}
                        </Typography>
                        <TCCopyToClipboard
                          value={selectedEligiblePatient.primaryPhone}
                          tooltipOpen={tooltipState['phone']}
                          onCopy={getTooltipCopyClickHandler('phone')}
                          onTooltipClose={getTooltipCopiedTooltipCloseHandler(
                            'phone'
                          )}
                        />
                      </Stack>
                    </Stack>
                  ) : null}
                  {selectedEligiblePatient?.secondaryPhone ? (
                    <Stack direction={'row'} gap={'8px'} alignItems={'center'}>
                      <EligiblePatientFormAttrKey>
                        Phone 2
                      </EligiblePatientFormAttrKey>
                      <Stack direction={'row'} gap={'4px'}>
                        <Typography>
                          {selectedEligiblePatient?.secondaryPhone}
                        </Typography>
                        <TCCopyToClipboard
                          value={selectedEligiblePatient?.secondaryPhone}
                          tooltipOpen={tooltipState['phone2']}
                          onCopy={getTooltipCopyClickHandler('phone2')}
                          onTooltipClose={getTooltipCopiedTooltipCloseHandler(
                            'phone2'
                          )}
                        />
                      </Stack>
                    </Stack>
                  ) : null}
                  {selectedEligiblePatient?.doNotCall ? (
                    <TagChip label="DNC" />
                  ) : null}
                </Stack>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  columnGap: '16px',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Controller
                  name="referralSource"
                  control={formMethods.control}
                  render={({ field, fieldState }) => (
                    <FormControl
                      size={'small'}
                      sx={{
                        width: '210px',
                      }}
                    >
                      <InputLabel
                        id={'eligibility-patient-form-referral-source-label'}
                      >
                        Referral Source
                      </InputLabel>
                      <Select
                        labelId={
                          'eligibility-patient-form-referral-source-label'
                        }
                        id={'eligibility-patient-form-referral-source'}
                        label={'Referral Source'}
                        value={field.value}
                        onChange={(event) => {
                          field.onChange(event.target.value)
                        }}
                      >
                        {REFERRAL_SOURCES.map((referralSource) => (
                          <MenuItem
                            key={referralSource.id}
                            value={referralSource.id}
                          >
                            {referralSource.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {fieldState.error ? (
                        <FormHelperText error={!!fieldState.error}>
                          {fieldState.error ? fieldState.error.message : ''}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  )}
                />
                <Button
                  variant="outlined"
                  color="secondary"
                  size="medium"
                  type="submit"
                >
                  ACCEPT
                </Button>
              </Box>
            </Box>
          </form>
        </FormProvider>
      ) : (
        <Box
          sx={{
            display: 'flex',
            columnGap: '16px',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '24px',
              color: 'rgba(0, 0, 0, 0.38)',
            }}
          >
            Select a patient to convert
          </Typography>
        </Box>
      )}
      <BackdropLoader open={showLoader} />
    </Paper>
  )
}

type EligiblePatientsTableProps = {
  isLoading: boolean
  patients: EligiblePatient[]
  totalCount: number
}

const EligiblePatientsTable: FC<EligiblePatientsTableProps> = ({
  isLoading,
  patients,
  totalCount,
}) => {
  const {
    setSelectedEligiblePatient,
    setSelectedEligiblePatientRow,
    isEligiblePatientsTableRowSelected,
    clearSelectedEligiblePatient,
  } = useEligibilityPatientStore()

  const getSelectedRowSx = (row: EligiblePatientsTableRow) => {
    if (isEligiblePatientsTableRowSelected(row)) {
      return {
        backgroundColor: 'neutral.grey.light',
        '& .MuiTableCell-root.MuiTableCell-body': {
          color: 'primary.main',
        },
      }
    }
    return {}
  }

  const getRowClickHandler =
    (patient: EligiblePatient, row: EligiblePatientsTableRow) => () => {
      if (isEligiblePatientsTableRowSelected(row)) {
        clearSelectedEligiblePatient()
        return
      }
      setSelectedEligiblePatient(patient)
      setSelectedEligiblePatientRow(row)
    }

  if (isLoading) {
    return (
      <Box
        sx={{
          height: '296px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <FullSectionLoader />
      </Box>
    )
  }

  return (
    <Box>
      <TableContainer
        sx={{
          height: '296px',
          overflowY: 'scroll',
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TCTableCell align="left">Name</TCTableCell>
              <TCTableCell align="left">DOB</TCTableCell>
              <TCTableCell align="left">MBI #</TCTableCell>
              <TCTableCell align="left">Zip Code</TCTableCell>
              <TCTableCell align="left">Phone 1</TCTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {patients.map((patient, index) => {
              const row = mapToEligiblePatientsTableRow(patient)
              return (
                <TCBodyTableRow
                  key={index}
                  onClick={getRowClickHandler(patient, row)}
                  sx={{ ...getSelectedRowSx(row) }}
                >
                  <TCTableCell align="left">{row.name}</TCTableCell>
                  <TCTableCell align="left">{row.dob}</TCTableCell>
                  <TCTableCell align="left">{row.mbi}</TCTableCell>
                  <TCTableCell align="left">{row.zip}</TCTableCell>
                  <TCTableCell align="left">{row.primaryPhone}</TCTableCell>
                </TCBodyTableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Table>
        <TableFooter>
          <TableRow>
            <EligiblePatientsTablePagination totalCount={totalCount} />
          </TableRow>
        </TableFooter>
      </Table>
    </Box>
  )
}

type PartnersEligibilityFilesTabProps = {
  onCancelClick: () => void
}
export const PartnersEligibilityFilesTab: FC<
  PartnersEligibilityFilesTabProps
> = ({ onCancelClick }) => {
  const {
    eligibilityPatients,
    totalCount,
    refetchEligiblePatients,
    getEligiblePatientsIsRefetching,
    getEligiblePatientsIsLoading,
  } = useGetEligiblePatientsQuery()

  const isLoading = () =>
    getEligiblePatientsIsLoading || getEligiblePatientsIsRefetching

  const handleRefreshClick = () => {
    refetchEligiblePatients()
  }

  return (
    <>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '20px',
          px: '32px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <EligiblePatientsSearchInput />
          <RefreshButton
            onClick={handleRefreshClick}
            isRefreshing={isLoading()}
          />
        </Box>
        <Box>
          <EligiblePatientsTable
            isLoading={isLoading()}
            patients={eligibilityPatients}
            totalCount={totalCount}
          />
        </Box>
        {isLoading() ? null : <EligiblePatientForm />}
      </DialogContent>
      <DialogActions sx={{ pr: '32px' }}>
        <Button color="secondary" onClick={onCancelClick}>
          CANCEL
        </Button>
      </DialogActions>
    </>
  )
}
