import { ErrorMessage } from '@hookform/error-message'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form'
import { referralTypes } from 'src/features/shared/presentation'

export const ReferralTypeField = <T extends FieldValues>() => {
  const name = 'referralType' as FieldPath<T>
  const { register } = useFormContext()

  return (
    <Controller
      name={name}
      {...register}
      render={({ field }) => (
        <FormControl size={'small'} sx={{ width: '100%' }}>
          <InputLabel id={'referral-type-label'}>Referral Type</InputLabel>
          <Select
            labelId={'referral-type-label'}
            id={'referral-type'}
            label={'Referral Type'}
            value={field.value}
            onChange={(event) => {
              field.onChange(event.target.value)
            }}
          >
            {referralTypes.map((referralType) => (
              <MenuItem key={referralType.id} value={referralType.id}>
                {referralType.label}
              </MenuItem>
            ))}
          </Select>
          <ErrorMessage
            name={name}
            render={({ message }) => (
              <FormHelperText sx={{ m: 0 }} error={true}>
                {message}
              </FormHelperText>
            )}
          />
        </FormControl>
      )}
    />
  )
}
