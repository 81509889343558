import { differenceInMinutes } from 'date-fns'

import {
  SQueue,
  GetQueuesServiceResponse,
  SQueuePatientView,
  SQueuePatient,
  GetQueuePatientsServiceParams,
  GetQueuePatientsServiceFilters,
  GetQueuePatientsServiceResponse,
  GetQueuePatientsServiceArgs,
  GetQueuesServiceErrorResponse,
  GetQueuePatientsServiceErrorResponse,
} from 'src/features/queues/infrastructure'
import {
  Queue,
  GetQueuesReturns,
  QueuePatientView,
  QueuePatient,
  GetQueuePatientsParams,
  GetQueuePatientsFilters,
  GetQueuePatientsReturns,
  GetQueuePatientsArgs,
  GetQueuesError,
  GetQueuePatientsError,
} from 'src/features/queues/domain'
import { QueuePatientsTableRow } from 'src/features/queues/presentation'
import {
  formatDate,
  queuePatientHasLockedPathway,
} from 'src/features/shared/utils'

// ------------
// GET QUEUES /
// -----------
type MapToQueue = (queue: SQueue) => Queue

export const mapToQueue: MapToQueue = (queue) => ({ ...queue })

type MapToGetQueuesReturns = (
  response: GetQueuesServiceResponse
) => GetQueuesReturns

export const mapToGetQueuesReturns: MapToGetQueuesReturns = (response) => {
  const sortFn = (a: Queue, b: Queue) => a.position - b.position

  const myDataQueue = response.queues.find(
    (queue) => queue.name.toLowerCase() === 'assigned to me'
  )

  const referralsQueues = response.queues
    .filter((queue) => queue && queue.name.toLowerCase().includes('referrals'))
    .sort(sortFn)

  const allQueues = response.queues.filter((queue) => queue).sort(sortFn)

  return {
    queues: allQueues,
    myDataQueue,
    referralsQueues,
  }
}

type MapToGetQueuesError = (
  error: GetQueuesServiceErrorResponse
) => GetQueuesError

export const mapToGetQueuesError: MapToGetQueuesError = (error) => {
  return { message: error.message }
}

// --------------------
// GET QUEUE PATIENTS /
// -------------------

type MapToQueuePatientView = (sPatient: SQueuePatientView) => QueuePatientView

export const mapToQueuePatientView: MapToQueuePatientView = (
  sQueuePatientView
) => {
  const patientView: SQueuePatientView = { ...sQueuePatientView }

  const viewedBy = {
    name: patientView.name,
    id: patientView.viewedBy,
    email: patientView.email,
  }

  return {
    id: patientView.id,
    viewedAt: patientView.viewedAt,
    viewedBy,
  }
}

const getQueuePatientViewersHistory = (
  sQueuePatient: SQueuePatient
): QueuePatient['patientViewersHistory'] => {
  if (sQueuePatient.patientViewersHistory) {
    const patientViewersHistory = sQueuePatient.patientViewersHistory.map(
      mapToQueuePatientView
    )

    // order by viewedAt descending
    return patientViewersHistory.sort((a, b) => {
      return new Date(b.viewedAt).getTime() - new Date(a.viewedAt).getTime()
    })
  }
  return null
}

const getQueuePatientRecentViewersHistory = (
  patientViewersHistory: QueuePatient['patientViewersHistory']
): QueuePatient['patientRecentViewersHistory'] => {
  if (patientViewersHistory) {
    // filter by viewedAt within the last 30 minutes
    return patientViewersHistory.filter((view) => {
      const thirtyMinutesAgo = new Date()
      thirtyMinutesAgo.setMinutes(thirtyMinutesAgo.getMinutes() - 30)
      return differenceInMinutes(new Date(view.viewedAt), thirtyMinutesAgo) < 30
    })
  }
  return null
}

const getQueuePatientIsBusy = (
  patientViewersHistory: QueuePatient['patientViewersHistory']
) => {
  if (patientViewersHistory) {
    const thirtyMinutesAgo = new Date()
    thirtyMinutesAgo.setMinutes(thirtyMinutesAgo.getMinutes() - 30)
    for (const view of patientViewersHistory) {
      if (differenceInMinutes(new Date(view.viewedAt), thirtyMinutesAgo) < 30) {
        return true
      }
    }
  }
  return false
}

type MapToQueuePatient = (sPatient: SQueuePatient) => QueuePatient

export const mapToQueuePatient: MapToQueuePatient = (sPatient) => {
  const patientViewersHistory = getQueuePatientViewersHistory(sPatient)
  const patientRecentViewersHistory = getQueuePatientRecentViewersHistory(
    patientViewersHistory
  )

  return {
    ...sPatient,
    patientViewersHistory: patientViewersHistory,
    patientRecentViewersHistory: patientRecentViewersHistory,
    isBusy: getQueuePatientIsBusy(patientViewersHistory),
    dateOfBirth: formatDate(sPatient.dateOfBirth, true),
  }
}

type MapToGetQueuePatientsServiceParams = (
  params?: GetQueuePatientsParams
) => GetQueuePatientsServiceParams | undefined

export const mapToGetQueuePatientsServiceParams: MapToGetQueuePatientsServiceParams =
  (params) => {
    if (!params) return undefined
    return {
      limit: params?.limit,
      page: params?.page,
      q: params?.q,
      assignedToMe: params?.assignedToMe,
    }
  }

type MapToGetQueuePatientsServiceFilters = (
  filters?: GetQueuePatientsFilters
) => GetQueuePatientsServiceFilters | undefined

export const mapToGetQueuePatientsServiceFilters: MapToGetQueuePatientsServiceFilters =
  (filters) => {
    if (!filters) return undefined
    return {
      referralSource: filters?.referralSource,
      stage: filters?.stage,
      payor: filters?.payor,
      assignedTo: filters?.assignedTo,
      lastToContact: filters?.lastToContact,
      dateOfLastContact: filters?.dateOfLastContactRange,
      attempts: filters?.attemptsQtyRange,
    }
  }

type MapToGetQueuePatientsServiceArgs = (
  args?: GetQueuePatientsArgs
) => GetQueuePatientsServiceArgs

export const mapToGetQueuePatientsServiceArgs: MapToGetQueuePatientsServiceArgs =
  (args) => {
    return {
      params: mapToGetQueuePatientsServiceParams(args?.params),
      filters: mapToGetQueuePatientsServiceFilters(args?.filters),
    }
  }

type MapToGetQueuePatientsReturns = (
  response: GetQueuePatientsServiceResponse
) => GetQueuePatientsReturns

export const mapToGetQueuePatientsReturns: MapToGetQueuePatientsReturns = (
  response
) => {
  return {
    patients: response.patients.map(mapToQueuePatient),
    count: response.count,
  }
}

type MapToGetQueuePatientsError = (
  error: GetQueuePatientsServiceErrorResponse
) => GetQueuePatientsError

export const mapToGetQueuePatientsError: MapToGetQueuePatientsError = (
  error
) => {
  return { message: error.message }
}

// ----------------------
// PRESENTATION MAPPERS /
// --------------------

type MapToQueuePatientsTableRow = (
  patient: QueuePatient
) => QueuePatientsTableRow

export const mapToQueuePatientsTableRow: MapToQueuePatientsTableRow = (
  patient
) => {
  const dob = formatDate(patient.dateOfBirth)

  const dateOfLastAttempt = patient.notes?.lastContact
    ? formatDate(patient.notes.lastContact)
    : '-'

  const doNotCall = !!patient.doNotCall

  const lastToOutreach = patient.lastToContact || '-'

  const status = patient.status || '-'

  return {
    id: patient.patientId,
    name: patient.name,
    hasLockedPathway: queuePatientHasLockedPathway(patient),
    isBusy: patient.isBusy,
    patientViewersHistory: patient.patientViewersHistory,
    eligible: patient.eligible,
    attempts: patient.attempts,
    lastToOutreach,
    dateOfLastAttempt,
    dob,
    doNotCall,
    status,
  }
}
