import { Box } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import 'react-quill/dist/quill.snow.css'

import { Patient } from 'src/features/patients/domain'
import {
  FullSectionLoader,
  BackdropLoader,
  useStatusDefinitions,
} from 'src/features/shared/presentation'

import {
  CustomNoteForm,
  IntakeNoteForm,
  OrthoChartCheckNoteForm,
  OrthoTouchpointNoteForm,
  OutreachNoteForm,
  PTTouchpointNoteForm,
  PostOpTouchpointNoteForm,
  PreOpTouchpointNoteForm,
  ReferralUploadNoteForm,
  SelfCareTPNoteForm,
} from 'src/features/notes/presentation/components/forms'
import { useNotesStore } from 'src/features/shared/infrastructure/store/hooks/use-notes.store'

export type NoteFormProps = {
  patient: Patient
  setShowLoader?: (show: boolean) => void
}

type CreatePatientNoteFormProps = {
  patient: Patient
}

export const CreatePatientNoteForm: FC<CreatePatientNoteFormProps> = ({
  patient,
}) => {
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const { selectedNoteType, setNoteText, setSelectedNoteType } = useNotesStore()

  const { getPatientStatusDefinitionsIsLoading } = useStatusDefinitions(patient)

  useEffect(() => {
    return () => {
      setNoteText('')
      setSelectedNoteType('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (getPatientStatusDefinitionsIsLoading) {
    return <FullSectionLoader />
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '32px',
          padding: '24px 40px 12px 40px',
        }}
      >
        {selectedNoteType === 'Custom' ? (
          <CustomNoteForm patient={patient} setShowLoader={setShowLoader} />
        ) : null}
        {selectedNoteType === 'Outreach' ? (
          <OutreachNoteForm patient={patient} setShowLoader={setShowLoader} />
        ) : null}
        {selectedNoteType === 'Intake' ? (
          <IntakeNoteForm patient={patient} setShowLoader={setShowLoader} />
        ) : null}
        {selectedNoteType === 'Referral Upload' ? (
          <ReferralUploadNoteForm
            patient={patient}
            setShowLoader={setShowLoader}
          />
        ) : null}
        {selectedNoteType === 'Self-Care Touchpoint' ? (
          <SelfCareTPNoteForm patient={patient} setShowLoader={setShowLoader} />
        ) : null}
        {selectedNoteType === 'PT Touchpoint' ? (
          <PTTouchpointNoteForm
            patient={patient}
            setShowLoader={setShowLoader}
          />
        ) : null}
        {selectedNoteType === 'Ortho Touchpoint' ? (
          <OrthoTouchpointNoteForm
            patient={patient}
            setShowLoader={setShowLoader}
          />
        ) : null}
        {selectedNoteType === 'Ortho Chart Check' ? (
          <OrthoChartCheckNoteForm
            patient={patient}
            setShowLoader={setShowLoader}
          />
        ) : null}
        {selectedNoteType === 'Post-Op Touchpoint' ? (
          <PostOpTouchpointNoteForm
            patient={patient}
            setShowLoader={setShowLoader}
          />
        ) : null}
        {selectedNoteType === 'Pre-Op Touchpoint' ? (
          <PreOpTouchpointNoteForm
            patient={patient}
            setShowLoader={setShowLoader}
          />
        ) : null}
      </Box>

      <BackdropLoader open={showLoader} />
    </>
  )
}
