import {
  UseMutateFunction,
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import {
  CreateEnablingTherapyArgs,
  CreateEnablingTherapyError,
  CreateEnablingTherapyReturns,
} from 'src/features/msk/domain'
import { useMSKUseCases } from 'src/features/msk/msk.service-locator'
import {
  GET_PATIENT_INTAKE_FLOWS_QUERY_KEY,
  GET_PATIENT_LAST_INTAKE_FLOW_QUERY_KEY,
} from 'src/features/msk/presentation'
import { usePatientId } from 'src/features/shared/presentation'

type UseCreateEnablingTherapyProps = Omit<
  UseMutationOptions<
    CreateEnablingTherapyReturns,
    CreateEnablingTherapyError,
    CreateEnablingTherapyArgs,
    unknown
  >,
  'mutationFn'
> & {
  patientId?: string
  throwGetPatientIdError?: boolean
}

type UseCreateEnablingTherapy = (props?: UseCreateEnablingTherapyProps) => {
  createEnablingTherapy: UseMutateFunction<
    CreateEnablingTherapyReturns,
    CreateEnablingTherapyError,
    CreateEnablingTherapyArgs,
    unknown
  >
  createEnablingTherapyIsLoading: boolean
  createEnablingTherapyStatus: string
}

export const useCreateEnablingTherapy: UseCreateEnablingTherapy = (
  props = {}
) => {
  const queryClient = useQueryClient()
  const patientsUseCases = useMSKUseCases()

  const { onSuccess, patientId, throwGetPatientIdError = false } = props

  const _patientId = usePatientId({ patientId, throwGetPatientIdError })

  const { mutate, isLoading, status } = useMutation<
    CreateEnablingTherapyReturns,
    CreateEnablingTherapyError,
    CreateEnablingTherapyArgs,
    unknown
  >({
    mutationFn: (args: CreateEnablingTherapyArgs) => {
      return patientsUseCases.createEnablingTherapy(args)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [GET_PATIENT_LAST_INTAKE_FLOW_QUERY_KEY, _patientId],
      })
      queryClient.invalidateQueries({
        queryKey: [GET_PATIENT_INTAKE_FLOWS_QUERY_KEY, _patientId],
      })
      onSuccess?.(data, variables, context)
    },
  })
  return {
    createEnablingTherapy: mutate,
    createEnablingTherapyIsLoading: isLoading,
    createEnablingTherapyStatus: status,
  }
}
