import { Stack, Typography } from '@mui/material'
import { FieldPath, FieldValues } from 'react-hook-form'
import { useGetPatientIntakeFlowsQuery } from 'src/features/msk/presentation'
import { filterNoneValueOptions } from 'src/features/notes/adapters'
import {
  AdditionalQuestionsFormValues,
  CustomSelectField,
  CustomTextField,
  levelScores,
  yesNo,
} from 'src/features/shared/presentation/components/form-fields'

export type SelfCareTouchpointAdditionalQuestionsFormValues = Pick<
  AdditionalQuestionsFormValues,
  | 'painLevelDecreased'
  | 'newSymptoms'
  | 'additionalQuestionsOrConcerns'
  | 'sdmText'
  | 'additionalConditionPain'
  | 'additionalConditionFunction'
  | 'sdmPatientUnderstandsCondition'
  | 'sdmClinicalBelievesPatientUnderstandsCondition'
>

export const SelfCareTouchpointAdditionalQuestionsForm = <
  T extends FieldValues
>() => {
  const { intakeFlows } = useGetPatientIntakeFlowsQuery()

  const painLevelDecreased = 'painLevelDecreased' as FieldPath<T>
  const additionalConditionPain = 'additionalConditionPain' as FieldPath<T>
  const additionalConditionFunction =
    'additionalConditionFunction' as FieldPath<T>

  const painLevel = 'painLevel' as FieldPath<T>
  const functionLevel = 'functionLevel' as FieldPath<T>
  const newSymptoms = 'newSymptoms' as FieldPath<T>
  const additionalQuestionsOrConcerns =
    'additionalQuestionsOrConcerns' as FieldPath<T>

  return (
    <>
      <Typography pb={2}>Additional Questions</Typography>
      <Stack direction={'column'} rowGap={1}>
        <CustomSelectField
          name={painLevelDecreased}
          id="pain-level-decreased"
          label={'Has your pain level decreased since you started self-care?'}
          items={filterNoneValueOptions(yesNo)}
          selectStyle={{ width: '88px' }}
          labelStyle={{ fontWeight: 'bold', pb: 1 }}
        />
        <CustomSelectField
          name={painLevel}
          id="pain-level"
          label={'On a scale of 0 to 10, what is your current pain level?'}
          items={filterNoneValueOptions(levelScores)}
          defaultItem="Add Score"
          selectStyle={{ width: 'max-width' }}
          labelStyle={{ fontWeight: 'bold', pb: 1 }}
        />

        <CustomSelectField
          name={functionLevel}
          id="function-level"
          label={
            'On a scale of 0 to 10, how would you rate your ability to do your normal daily activities, where 0 is completely unable and 10 is able to perform as normal?'
          }
          items={filterNoneValueOptions(levelScores)}
          defaultItem="Add Score"
          selectStyle={{ width: 'max-width' }}
          labelStyle={{
            fontWeight: 'bold',
            pb: 1,
            whiteSpace: 'pre-wrap',
            width: '90%',
            height: 'min-height',
          }}
        />
        <CustomTextField
          name={newSymptoms}
          id="new-symptoms"
          label={'Do you have any new symptoms? (related to problem area)'}
          labelStyle={{ fontWeight: 'bold', pb: 1 }}
          inputStyle={{ width: '100%' }}
        />
        <CustomTextField
          name={additionalQuestionsOrConcerns}
          id="additional-questions-or-concerns"
          label={
            'What additional questions or concerns do you have about your condition? '
          }
          labelStyle={{ fontWeight: 'bold', pb: 1 }}
          inputStyle={{ width: '100%' }}
        />

        {intakeFlows && intakeFlows.length > 1 ? (
          <>
            <CustomSelectField
              name={additionalConditionPain}
              id="additional-pain-level"
              label={
                'Regarding your additional condition. On a scale of 0 to 10, what is your current pain level?'
              }
              items={filterNoneValueOptions(levelScores)}
              defaultItem="Add Score"
              selectStyle={{ width: 'min-content' }}
              labelStyle={{
                fontWeight: 'bold',
                pb: 1,
                whiteSpace: 'pre-wrap',
                width: '90%',
                height: 'min-height',
              }}
            />{' '}
            <CustomSelectField
              name={additionalConditionFunction}
              id="additional-function-level"
              label={
                'Regarding your additional condition. On a scale of 0 to 10, how would you rate your ability to do your normal daily activities, where 0 is completely unable, and 10 is able to perform as normal?'
              }
              items={filterNoneValueOptions(levelScores)}
              defaultItem="Add Score"
              selectStyle={{ width: 'min-content' }}
              labelStyle={{
                fontWeight: 'bold',
                pb: 1,
                whiteSpace: 'pre-wrap',
                width: '90%',
                height: 'min-height',
              }}
            />
          </>
        ) : null}
      </Stack>
    </>
  )
}
