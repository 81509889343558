import {
  UseMutateFunction,
  UseMutationOptions,
  useMutation,
} from '@tanstack/react-query'

import {
  Task,
  UpdateTaskArgs,
  UpdateTaskError,
} from 'src/features/tasks/domain'
import { useRefreshTasks } from 'src/features/tasks/presentation'
import { useTasksUseCases } from 'src/features/tasks/tasks.service-locator'

type useUpdateTaskProps = Omit<
  UseMutationOptions<Task, UpdateTaskError, UpdateTaskArgs, unknown>,
  'mutationFn'
>

type UseUpdateTask = (props?: useUpdateTaskProps) => {
  updateTask: UseMutateFunction<Task, UpdateTaskError, UpdateTaskArgs, unknown>
  updateTaskIsLoading: boolean
  updateTaskStatus: string
}

export const useUpdateTask: UseUpdateTask = (props = {}) => {
  const tasksUseCases = useTasksUseCases()
  const { refreshTasks } = useRefreshTasks()

  const { onSuccess } = props

  const { mutate, isLoading, status } = useMutation<
    Task,
    UpdateTaskError,
    UpdateTaskArgs,
    unknown
  >({
    mutationFn: (args: UpdateTaskArgs) => {
      return tasksUseCases.updateTask(args)
    },
    onSuccess: (data, variables, context) => {
      refreshTasks()
      onSuccess?.(data, variables, context)
    },
  })
  return {
    updateTask: mutate,
    updateTaskIsLoading: isLoading,
    updateTaskStatus: status,
  }
}
