import { BodyRegionToNote } from 'src/features/msk/domain'
import { MSKFormStateAdditionalQuestionsFieldOptions } from 'src/features/shared/infrastructure'

export const QUESTIONS_LABELS = {
  phQ1: 'Over the last 2 weeks, how bothered have you been by the following problem: "Little or no interest in doing things"?',
  phQ2: 'Over the last 2 weeks, how bothered have you been by the following problem: "Feeling down, depressed, or hopeless"?',
  gad2Q1:
    'Over the last 2 weeks, how bothered have you been by the following problem: "Feeling nervous, anxious, or on edge"?',
  gad2Q2:
    'Over the last 2 weeks, how bothered have you been by the following problem: "Not being able to stop or control worrying"',
  bhAction2: 'Did the patient screen positive?',
  fallScreenQ1: 'Have you had 2 or more falls in the past 6 months? ',
  fallScreenQ2:
    'Did you seek medical care following any fall in the past 6 months? ',
  bodyRegionToNote: 'Any specific body region to note?',
  tabletComputerOrPhone: 'Do you have a smartphone/tablet/computer at home?',
  barriersToBeAwareOf: 'Are there any barriers to care we should be aware of?',
  additionalPhone:
    'Do you have a cell phone or any other number we should use to reach you?',
  peopleAllowedToReachOutTo:
    'Is there anyone else you would like to us to reach out to about your care, like a child or friend? If yes, what is their phone number?',
  pcpName: 'PCP Name',
  pcpPhone: 'PCP Phone',
  pcpFax: 'PCP Fax',
  pcpGroupName: 'PCP Group Name',
  pcpAddressLine1: 'PCP Address Line 1',
  pcpAddressLine2: 'PCP Address Line 2',
  pcpCity: 'PCP City',
  pcpState: 'PCP State',
  pcpZip: 'PCP Zip',
  currentlyUsingOpioids: 'Is currently using opioids?',
  otherPreviouslyTriedActionText: 'Other previously tried action',
}

type FieldOptions = {
  v1: {
    value: MSKFormStateAdditionalQuestionsFieldOptions['v1']
    display: string
  }[]
  v2: {
    value: MSKFormStateAdditionalQuestionsFieldOptions['v2']
    display: string
  }[]
  v3: {
    value: MSKFormStateAdditionalQuestionsFieldOptions['v3']
    display: string
  }[]
  v4: {
    value: MSKFormStateAdditionalQuestionsFieldOptions['v4']
    display: string
  }[]
}

export const fieldOptions: FieldOptions = {
  v1: [
    { value: 'Select an option', display: 'Select an option' },
    { value: 'Not At All', display: 'Not At All' },
    { value: 'Several Days', display: 'Several Days' },
    { value: 'More than Half of Days', display: 'More than Half of Days' },
    { value: 'Nearly Every Day', display: 'Nearly Every Day' },
  ],
  v2: [
    { value: 'Select an option', display: 'Select an option' },
    { value: 'Y', display: 'Yes' },
    { value: 'N', display: 'No' },
  ],
  v3: [
    { value: 'Select an option', display: 'Select an option' },
    { value: 'Referred back to PCP', display: 'Referred back to PCP' },
    { value: 'Already Under Care', display: 'Already Under Care' },
  ],
  v4: [
    { value: 'Select an option', display: 'Select an option' },
    { value: 'Transportation', display: 'Transportation' },
    { value: 'homebound', display: 'homebound' },
    { value: 'co-pays', display: 'co-pays' },
    { value: 'other', display: 'other' },
  ],
}

export type BodyRegionToNoteOption = {
  id: BodyRegionToNote
  display: string
}

export const bodyRegionsToNoteMultiSelectOptions: BodyRegionToNoteOption[] = [
  { id: 'Low Back', display: 'Low Back' },
  { id: 'Thoracic Spine', display: 'Thoracic Spine' },
  { id: 'Neck', display: 'Neck' },
  { id: 'Index Finger', display: 'Index Finger' },
  { id: 'Long Finger', display: 'Long Finger' },
  { id: 'Ring Finger', display: 'Ring Finger' },
  { id: 'Small Finger', display: 'Small Finger' },
  { id: 'Thumb', display: 'Thumb' },
  { id: 'Ankle', display: 'Ankle' },
  { id: 'Foot', display: 'Foot' },
  { id: 'Great Toe', display: 'Great Toe' },
  { id: '2nd Toe', display: '2nd Toe' },
  { id: '3rd Toe', display: '3rd Toe' },
  { id: '4th Toe', display: '4th Toe' },
  { id: '5th Toe', display: '5th Toe' },
  { id: 'N/A', display: 'N/A' },
]

export const getSelectDefaultSx = () => ({
  width: '224px',
})

export const getTextFieldDefaultSx = () => ({
  width: '224px',
})

export const scrolToErrorElement = (selector = '.Mui-error') => {
  const firstErrorElement = document.querySelector(selector)
  if (firstErrorElement) {
    setTimeout(() => {
      firstErrorElement.scrollIntoView({ behavior: 'smooth' })
    }, 0)
  }
}
