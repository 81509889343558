import { Box, Divider, Stack, Typography } from '@mui/material'
import { FieldPath, FieldValues, useWatch } from 'react-hook-form'
import { useGetPatientIntakeFlowsQuery } from 'src/features/msk/presentation'
import { filterNoneValueOptions } from 'src/features/notes/adapters'
import {
  AdditionalQuestionsFormValues,
  CustomSelectField,
  CustomTextField,
  InjectionImprovementField,
  InjectionTypeField,
  MissedAppointmentDetailsField,
  MissedAppointmentReasonField,
  MissedAppointmentRescheduledField,
  OutcomeNoteField,
  levelScores,
  visitOutcomes,
  yesNo,
} from 'src/features/shared/presentation'

export type OrthoTouchpointQuestionsFormValues = Pick<
  AdditionalQuestionsFormValues,
  | 'completedAppointment'
  | 'reasonForMissingAppointment'
  | 'detailsOfMissedAppointment'
  | 'appointmentRescheduled'
  | 'outcomeOfVisit'
  | 'outcomeNote'
  | 'improvementFromInjection'
  | 'typeOfInjection'
  | 'additionalQuestionsOrConcerns'
  | 'additionalConditionPain'
  | 'additionalConditionFunction'
  | 'sdmText'
  | 'sdmPatientUnderstandsCondition'
  | 'sdmClinicalBelievesPatientUnderstandsCondition'
>

export const OrthoTouchpointAdditionalQuestionsForm = <
  T extends FieldValues
>() => {
  const { intakeFlows } = useGetPatientIntakeFlowsQuery()

  const painLevel = 'painLevel' as FieldPath<T>
  const functionLevel = 'functionLevel' as FieldPath<T>
  const completedAppointment = 'completedAppointment' as FieldPath<T>
  const outcomeOfVisit = 'outcomeOfVisit' as FieldPath<T>
  const additionalQuestionsOrConcerns =
    'additionalQuestionsOrConcerns' as FieldPath<T>
  const additionalConditionPain = 'additionalConditionPain' as FieldPath<T>
  const additionalConditionFunction =
    'additionalConditionFunction' as FieldPath<T>

  // Field wathchers
  const watcher = useWatch()
  const watchCompletedAppointment = watcher.completedAppointment
  const watchOutcomeOfVisit = watcher.outcomeOfVisit

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          width: 170,
          whiteSpace: 'nowrap',
          flexDirection: 'column',
        }}
      >
        <Divider />
        <Typography pb={2} color={'grey'}>
          Assessment Questions
        </Typography>
      </Box>
      <Stack direction={'column'} rowGap={1}>
        <CustomSelectField
          name={completedAppointment}
          id="completed-appointment"
          label={'Did you go to your most recent appointment?'}
          items={filterNoneValueOptions(yesNo)}
          selectStyle={{ width: '88px' }}
          labelStyle={{ fontWeight: 'bold', pb: 1 }}
        />
        {watchCompletedAppointment === 'No' ? (
          <>
            <MissedAppointmentReasonField />
            <MissedAppointmentDetailsField />
            <MissedAppointmentRescheduledField />
          </>
        ) : null}

        <CustomSelectField
          name={painLevel}
          id="pain-level"
          label={'On a scale of 0 to 10, what is your current pain level?'}
          items={levelScores}
          defaultItem="Add Score"
          selectStyle={{ width: 'max-width' }}
          labelStyle={{ fontWeight: 'bold', pb: 1 }}
        />
        <CustomSelectField
          name={functionLevel}
          id="function-level"
          label={
            'On a scale of 0 to 10, how would you rate your ability to do your normal daily activities, where 0 is completely unable and 10 is able to perform as normal?'
          }
          items={levelScores}
          defaultItem="Add Score"
          selectStyle={{ width: 'max-width' }}
          labelStyle={{
            fontWeight: 'bold',
            pb: 1,
            whiteSpace: 'pre-wrap',
            width: '90%',
            height: 'min-height',
          }}
        />

        <CustomSelectField
          name={outcomeOfVisit}
          id="outcome-of-visit"
          label={'What was the outcome of the visit?'}
          items={filterNoneValueOptions(visitOutcomes)}
          selectStyle={{ width: '323px' }}
          defaultItem="None"
          labelStyle={{
            fontWeight: 'bold',
            pb: 1,
          }}
        />
        {watchOutcomeOfVisit === 'Other' ? <OutcomeNoteField /> : null}

        {watchOutcomeOfVisit === 'Pathway Change - Injection' ? (
          <>
            <InjectionImprovementField />
            <InjectionTypeField />
          </>
        ) : null}
        <CustomTextField
          name={additionalQuestionsOrConcerns}
          id="additional-questions-or-concerns"
          label={
            'What additional questions or concerns do you have about your condition? '
          }
          labelStyle={{ fontWeight: 'bold', pb: 1 }}
          inputStyle={{ width: '100%' }}
        />

        {intakeFlows && intakeFlows.length > 1 ? (
          <>
            <CustomSelectField
              name={additionalConditionPain}
              id="additional-pain-level"
              label={
                'Regarding your additional condition. On a scale of 0 to 10, what is your current pain level?'
              }
              items={filterNoneValueOptions(levelScores)}
              defaultItem="Add Score"
              selectStyle={{ width: 'min-content' }}
              labelStyle={{
                fontWeight: 'bold',
                pb: 1,
                whiteSpace: 'pre-wrap',
                width: '90%',
                height: 'min-height',
              }}
            />
            <CustomSelectField
              name={additionalConditionFunction}
              id="additional-function-level"
              label={
                'Regarding your additional condition. On a scale of 0 to 10, how would you rate your ability to do your normal daily activities, where 0 is completely unable, and 10 is able to perform as normal?'
              }
              items={filterNoneValueOptions(levelScores)}
              defaultItem="Add Score"
              selectStyle={{ width: 'min-content' }}
              labelStyle={{
                fontWeight: 'bold',
                pb: 1,
                whiteSpace: 'pre-wrap',
                width: '90%',
                height: 'min-height',
              }}
            />
          </>
        ) : null}
      </Stack>
    </>
  )
}
