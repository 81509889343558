import { useInfiniteQuery } from '@tanstack/react-query'

import { useTasksUseCases } from 'src/features/tasks/tasks.service-locator'
import * as settings from 'src/config/settings'

export const GET_DONE_TASKS_BY_USER_ID_INFINITE_QUERY_KEY =
  'getDoneTasksByUserIdInfinite'

export const useDoneTasksByUserIdInfiniteQuery = () => {
  const tasksUseCases = useTasksUseCases()

  const {
    data,
    refetch,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isError,
    isFetching,
    isFetchingNextPage,
    isRefetching,
  } = useInfiniteQuery({
    queryKey: [GET_DONE_TASKS_BY_USER_ID_INFINITE_QUERY_KEY],
    queryFn: ({ pageParam = undefined }) => {
      return tasksUseCases.getDoneTasksByUserId({
        params: {
          limit: settings.DONE_TASKS_PER_LOAD,
          lastTaskId: pageParam?.lastTaskId,
          lastTaskDate: pageParam?.lastTaskDate,
        },
      })
    },
    getNextPageParam: (lastPage) => {
      if (!lastPage.lastTaskId || !lastPage.lastTaskDate) return undefined
      return {
        lastTaskId: lastPage.lastTaskId,
        lastTaskDate: lastPage.lastTaskDate,
      }
    },
  })
  return {
    doneTasksData: data,
    refetchDoneTasks: refetch,
    fetchNextPageDoneTasks: fetchNextPage,
    hasNextPageDoneTasks: hasNextPage,
    doneTasksIsLoading: isLoading,
    doneTasksIsError: isError,
    doneTasksIsFetching: isFetching,
    doneTasksIsFetchingNextPage: isFetchingNextPage,
    doneTasksIsRefetching: isRefetching,
  }
}
