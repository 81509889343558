import { InfiniteData } from '@tanstack/react-query'
import { format } from 'date-fns'

import { formatDate } from 'src/features/shared/utils'
import {
  CreateTaskArgs,
  CreateTaskError,
  CreateTaskReturns,
  GetDoneTasksByUserIdError,
  GetDoneTasksByUserIdReturns,
  GetTasksArgs,
  GetTasksParams,
  GetTodayTasksByUserIdError,
  GetTodayTasksByUserIdReturns,
  GetUrgentTasksByUserIdError,
  GetUrgentTasksByUserIdReturns,
  GetOverdueTasksByUserIdError,
  GetOverdueTasksByUserIdReturns,
  GetUpcomingTasksByUserIdError,
  GetUpcomingTasksByUserIdReturns,
  Task,
  TaskComment,
  UpdateTaskArgs,
  UpdateTaskError,
  UpdateTaskReturns,
  DoneTask,
} from 'src/features/tasks/domain'
import {
  CreateTaskServiceErrorResponse,
  CreateTaskServicePayload,
  CreateTaskServiceResponse,
  GetDoneTasksByUserIdServiceErrorResponse,
  GetDoneTasksByUserIdServiceResponse,
  GetTasksServiceArgs,
  GetTasksServiceParams,
  GetTodayTasksByUserIdServiceErrorResponse,
  GetTodayTasksByUserIdServiceResponse,
  GetUrgentTasksByUserIdServiceErrorResponse,
  GetUrgentTasksByUserIdServiceResponse,
  GetOverdueTasksByUserIdServiceErrorResponse,
  GetOverdueTasksByUserIdServiceResponse,
  GetUpcomingTasksByUserIdServiceErrorResponse,
  GetUpcomingTasksByUserIdServiceResponse,
  STask,
  STaskComment,
  UpdateTaskServiceArgs,
  UpdateTaskServiceErrorResponse,
  UpdateTaskServiceResponse,
  SDoneTaks,
} from 'src/features/tasks/infrastructure'
import {
  DoneTaskGroupedByDate,
  TaskGroupedByDate,
} from 'src/features/tasks/presentation'

type MapToGetTasksServiceParams = (
  params: GetTasksParams
) => GetTasksServiceParams

export const mapToGetTasksServiceParams: MapToGetTasksServiceParams = (
  params
) => {
  return {
    ...params,
  }
}

type MapToGetTodayTasksServiceArgs = (
  args?: GetTasksArgs
) => GetTasksServiceArgs

export const mapToGetTodayTasksServiceArgs: MapToGetTodayTasksServiceArgs = (
  args
) => {
  const defaultServiceParams: GetTasksServiceArgs['params'] = {
    timeFrame: 'today',
  }

  const serviceParams: GetTasksServiceArgs['params'] = args?.params
    ? {
        ...defaultServiceParams,
        ...mapToGetTasksServiceParams(args.params),
      }
    : defaultServiceParams

  return {
    ...args,
    params: serviceParams,
  }
}

type MapToGetUrgentTasksServiceArgs = (
  args?: GetTasksArgs
) => GetTasksServiceArgs

export const mapToGetUrgentTasksServiceArgs: MapToGetUrgentTasksServiceArgs = (
  args
) => {
  const defaultServiceParams: GetTasksServiceArgs['params'] = {
    timeFrame: 'urgent',
  }

  const serviceParams: GetTasksServiceArgs['params'] = args?.params
    ? {
        ...defaultServiceParams,
        ...mapToGetTasksServiceParams(args.params),
      }
    : defaultServiceParams

  return {
    ...args,
    params: serviceParams,
  }
}

type MapToGetOverdueTasksServiceArgs = (
  args?: GetTasksArgs
) => GetTasksServiceArgs

export const mapToGetOverdueTasksServiceArgs: MapToGetOverdueTasksServiceArgs =
  (args) => {
    const defaultServiceParams: GetTasksServiceArgs['params'] = {
      timeFrame: 'overdue',
    }

    const serviceParams: GetTasksServiceArgs['params'] = args?.params
      ? {
          ...defaultServiceParams,
          ...mapToGetTasksServiceParams(args.params),
        }
      : defaultServiceParams

    return {
      ...args,
      params: serviceParams,
    }
  }

type MapToGetDoneTasksServiceArgs = (args?: GetTasksArgs) => GetTasksServiceArgs

export const mapToGetDoneTasksServiceArgs: MapToGetDoneTasksServiceArgs = (
  args
) => {
  const defaultServiceParams: GetTasksServiceArgs['params'] = {
    timeFrame: 'done',
  }

  const serviceParams: GetTasksServiceArgs['params'] = args?.params
    ? {
        ...defaultServiceParams,
        ...mapToGetTasksServiceParams(args.params),
      }
    : defaultServiceParams

  return {
    ...args,
    params: serviceParams,
  }
}

type MapToGetUpcomingTasksServiceArgs = (
  args?: GetTasksArgs
) => GetTasksServiceArgs

export const mapToGetUpcomingTasksServiceArgs: MapToGetUpcomingTasksServiceArgs =
  (args) => {
    const defaultServiceParams: GetTasksServiceArgs['params'] = {
      timeFrame: 'upcoming',
    }

    const serviceParams: GetTasksServiceArgs['params'] = args?.params
      ? {
          ...defaultServiceParams,
          ...mapToGetTasksServiceParams(args.params),
        }
      : defaultServiceParams

    return {
      ...args,
      params: serviceParams,
    }
  }
type MapToTaskComment = (sTaskComment: STaskComment) => TaskComment

export const mapToTaskComment: MapToTaskComment = (sTaskComment) => {
  const createdAt = format(
    new Date(sTaskComment.createdAt),
    "MM/dd/yy 'at' p"
  ).toLowerCase()

  const header = `${sTaskComment.name} commented ${createdAt}`
  return {
    ...sTaskComment,
    id: sTaskComment.eventCommentId,
    body: sTaskComment.comment,
    createdBy: sTaskComment.name,
    header,
  }
}

type MapToTask = (sTask: STask) => Task

export const mapToTask: MapToTask = (sTask) => {
  return {
    ...sTask,
    assignedTCMemberName: sTask.assignedTCMember,
    comments: sTask.comments?.map(mapToTaskComment),
    id: sTask.eventId,
    patientName: sTask.name,
    dueDate: formatDate(sTask.dueDate, true),
  }
}

type MapToDoneTask = (sDoneTask: SDoneTaks) => DoneTask

export const mapToDoneTask: MapToDoneTask = (sDoneTask) => {
  return {
    ...sDoneTask,
    assignedTCMemberName: sDoneTask.assignedTCMember,
    comments: sDoneTask.comments?.map(mapToTaskComment),
    id: sDoneTask.eventId,
    patientName: sDoneTask.name,
    dueDate: formatDate(sDoneTask.dueDate, true),
    completedAt: formatDate(sDoneTask.completedAt, true),
  }
}

type MapToGetTodayTasksByUserIdReturns = (
  response: GetTodayTasksByUserIdServiceResponse
) => GetTodayTasksByUserIdReturns

export const mapToGetTodayTasksByUserIdReturns: MapToGetTodayTasksByUserIdReturns =
  (response) => {
    const todayDate = formatDate(new Date())
    return {
      tasks: response.today.map(mapToTask),
      lastTaskId: response.lastTaskId,
      lastTaskDate: response.lastTaskDate,
      todayDate,
    }
  }

type MapToGetTodayTasksByUserIdError = (
  response: GetTodayTasksByUserIdServiceErrorResponse
) => GetTodayTasksByUserIdError

export const mapToGetTodayTasksByUserIdError: MapToGetTodayTasksByUserIdError =
  (response) => {
    return {
      message: response.message,
    }
  }

type MapToGetUrgentTasksByUserIdReturns = (
  response: GetUrgentTasksByUserIdServiceResponse
) => GetUrgentTasksByUserIdReturns

export const mapToGetUrgentTasksByUserIdReturns: MapToGetUrgentTasksByUserIdReturns =
  (response) => {
    return {
      tasks: response.urgent.map(mapToTask),
      lastTaskId: response.lastTaskId,
      lastTaskDate: response.lastTaskDate,
    }
  }

type MapToGetUrgentTasksByUserIdError = (
  response: GetUrgentTasksByUserIdServiceErrorResponse
) => GetUrgentTasksByUserIdError

export const mapToGetUrgentTasksByUserIdError: MapToGetUrgentTasksByUserIdError =
  (response) => {
    return {
      message: response.message,
    }
  }

type MapToGetOverdueTasksByUserIdReturns = (
  response: GetOverdueTasksByUserIdServiceResponse
) => GetOverdueTasksByUserIdReturns

export const mapToGetOverdueTasksByUserIdReturns: MapToGetOverdueTasksByUserIdReturns =
  (response) => {
    return {
      tasks: response.overdue.map(mapToTask),
      lastTaskId: response.lastTaskId,
      lastTaskDate: response.lastTaskDate,
    }
  }

type MapToGetOverdueTasksByUserIdError = (
  response: GetOverdueTasksByUserIdServiceErrorResponse
) => GetOverdueTasksByUserIdError

export const mapToGetOverdueTasksByUserIdError: MapToGetOverdueTasksByUserIdError =
  (response) => {
    return {
      message: response.message,
    }
  }

type MapToGetDoneTasksByUserIdReturns = (
  response: GetDoneTasksByUserIdServiceResponse
) => GetDoneTasksByUserIdReturns

export const mapToGetDoneTasksByUserIdReturns: MapToGetDoneTasksByUserIdReturns =
  (response) => {
    return {
      tasks: response.done.map(mapToDoneTask),
      lastTaskId: response.lastTaskId,
      lastTaskDate: response.lastTaskDate,
    }
  }

type MapToGetDoneTaskGroupedByDate = (
  data: InfiniteData<GetDoneTasksByUserIdReturns>
) => DoneTaskGroupedByDate

export const mapToGetDoneTaskGroupedByDate: MapToGetDoneTaskGroupedByDate = (
  data
) => {
  // get all done tasks in one array
  const doneTasks = data.pages.reduce(
    (acc: GetDoneTasksByUserIdReturns['tasks'], page) => {
      return [...acc, ...page.tasks]
    },
    []
  )

  // group tasks by completed date
  const groupedTasks: DoneTaskGroupedByDate = {}
  doneTasks.forEach((task) => {
    if (!groupedTasks[task.completedAt]) {
      groupedTasks[task.completedAt] = []
    }
    groupedTasks[task.completedAt].push(task)
  })

  // order grouped tasks by completed date
  return Object.keys(groupedTasks)
    .sort((a, b) => {
      return new Date(b).getTime() - new Date(a).getTime()
    })
    .reduce((acc: DoneTaskGroupedByDate, key) => {
      acc[key] = groupedTasks[key]
      return acc
    }, {})
}

type MapToGetDoneTasksByUserIdError = (
  response: GetDoneTasksByUserIdServiceErrorResponse
) => GetDoneTasksByUserIdError

export const mapToGetDoneTasksByUserIdError: MapToGetDoneTasksByUserIdError = (
  response
) => {
  return {
    message: response.message,
  }
}

type MapToGetUpcomingTasksByUserIdReturns = (
  response: GetUpcomingTasksByUserIdServiceResponse
) => GetUpcomingTasksByUserIdReturns

export const mapToGetUpcomingTasksByUserIdReturns: MapToGetUpcomingTasksByUserIdReturns =
  (response) => {
    return {
      tasks: response.upcoming.map(mapToTask),
      lastTaskId: response.lastTaskId,
      lastTaskDate: response.lastTaskDate,
    }
  }

type MapToGetUpcomingTaskGroupedByDate = (
  data: InfiniteData<GetUpcomingTasksByUserIdReturns>
) => TaskGroupedByDate

export const mapToGetUpcomingTaskGroupedByDate: MapToGetUpcomingTaskGroupedByDate =
  (data) => {
    // get all done tasks in one array
    const upcomingTasks = data.pages.reduce(
      (acc: GetUpcomingTasksByUserIdReturns['tasks'], page) => {
        return [...acc, ...page.tasks]
      },
      []
    )

    // group tasks by completed date
    const groupedTasks: TaskGroupedByDate = {}
    upcomingTasks.forEach((task) => {
      if (!groupedTasks[task.dueDate]) {
        groupedTasks[task.dueDate] = []
      }
      groupedTasks[task.dueDate].push(task)
    })

    // order tasks by due date
    return Object.keys(groupedTasks)
      .sort((a, b) => {
        return new Date(a).getTime() - new Date(b).getTime()
      })
      .reduce((acc: TaskGroupedByDate, key) => {
        acc[key] = groupedTasks[key]
        return acc
      }, {})
  }

type MapToGetUpcomingTasksByUserIdError = (
  response: GetUpcomingTasksByUserIdServiceErrorResponse
) => GetUpcomingTasksByUserIdError

export const mapToGetUpcomingTasksByUserIdError: MapToGetUpcomingTasksByUserIdError =
  (response) => {
    return {
      message: response.message,
    }
  }

type MapToUpdateTaskError = (
  error: UpdateTaskServiceErrorResponse
) => UpdateTaskError

export const mapToUpdateTaskError: MapToUpdateTaskError = (error) => {
  return {
    message: error.message,
  }
}
type MapToUpdateTaskReturns = (
  response: UpdateTaskServiceResponse
) => UpdateTaskReturns

export const mapToUpdateTaskReturns: MapToUpdateTaskReturns = (response) =>
  mapToTask(response)

type MapToUpdateTaskServiceArgs = (
  args: UpdateTaskArgs
) => UpdateTaskServiceArgs

export const mapToUpdateTaskServiceArgs: MapToUpdateTaskServiceArgs = (
  args
) => {
  const payload = args.payload

  if (payload && !payload?.comment) {
    delete payload.comment
  }

  return {
    taskId: args.taskId,
    payload: args.payload,
  }
}

type MapToCreateTaskServicePayload = (
  args: CreateTaskArgs
) => CreateTaskServicePayload

export const mapToCreateTaskServicePayload: MapToCreateTaskServicePayload = (
  args
) => {
  return {
    assignedUserId: args.assignedUserId,
    patientId: args.patientId,
    title: args.title,
    description: args.description,
    dueDate: args.dueDate,
    urgent: args.urgent,
  }
}

type MapToCreateTaskReturns = (
  response: CreateTaskServiceResponse
) => CreateTaskReturns

export const mapToCreateTaskReturns: MapToCreateTaskReturns = (response) =>
  mapToTask(response)

type MapToCreateTaskError = (
  error: CreateTaskServiceErrorResponse
) => CreateTaskError

export const mapToCreateTaskError: MapToCreateTaskError = (error) => {
  return {
    message: error.message,
  }
}
