import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip as ChartJSTooltip,
  ChartData,
  CoreChartOptions,
  DatasetChartOptions,
  ElementChartOptions,
  LineControllerChartOptions,
  PluginChartOptions,
  ScaleChartOptions,
} from 'chart.js'
import { _DeepPartialObject } from 'chart.js/dist/types/utils'
import { Box, Typography } from '@mui/material'
import { FC, useMemo } from 'react'

import {
  GetPatientPainAndFunctionReturns,
  FunctionHistoryItem,
} from 'src/features/patients/domain'
import { useGetPatientPainAndFunctionQuery } from 'src/features/patients/presentation'

ChartJSTooltip.positioners.bottom = function (elements, eventPosition) {
  const chart = this.chart

  return {
    x: eventPosition.x,
    y: chart.chartArea.bottom,
    xAlign: 'center',
    yAlign: 'bottom',
  }
}

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ChartJSTooltip
)

const getPainLevelColor = (painLevel: number) => {
  switch (painLevel) {
    case 0:
      return '#1B5E20'
    case 1:
      return '#2E7D32'
    case 2:
      return '#4CAF50'
    case 3:
      return '#C0CA33'
    case 4:
      return '#FFB400'
    case 5:
      return '#FF9800'
    case 6:
      return '#ED6C02'
    case 7:
      return '#E65100'
    case 8:
      return '#EF5350'
    case 9:
      return '#D32F2F'
    case 10:
      return '#C62828'
    default:
      throw new Error('Invalid pain level must be between 0 and 10')
  }
}

const getFunctionLevelColor = (functionLevel: number) => {
  switch (functionLevel) {
    case 100:
      return '#1B5E20'
    case 90:
      return '#2E7D32'
    case 80:
      return '#4CAF50'
    case 70:
      return '#C0CA33'
    case 60:
      return '#FFB400'
    case 50:
      return '#FF9800'
    case 40:
      return '#ED6C02'
    case 30:
      return '#E65100'
    case 20:
      return '#EF5350'
    case 10:
      return '#D32F2F'
    case 0:
      return '#C62828'
    default:
      throw new Error('Invalid function level must be between 0 and 100')
  }
}

type PatientPainFunctionGraphsSectionProps = {
  patientPainAndFunction: GetPatientPainAndFunctionReturns
}

export const PatientPainFunctionGraphsSection: FC<
  PatientPainFunctionGraphsSectionProps
> = ({ patientPainAndFunction }) => {
  const { hasFunctionHistory, hasPainHistory } =
    useGetPatientPainAndFunctionQuery()

  const percentageFunctionLevelHistory: FunctionHistoryItem[] = useMemo(() => {
    return patientPainAndFunction.functionHistory.map((item) => ({
      ...item,
      functionLevel: item.functionLevel * 10,
    }))
  }, [patientPainAndFunction.functionHistory])

  const getCurrentPainLevel = () => {
    if (hasPainHistory) {
      return `${
        patientPainAndFunction.painHistory[
          patientPainAndFunction.painHistory.length - 1
        ].painLevel
      }`
    }
    return null
  }

  const getCurrentFunctionLevel = () => {
    if (hasFunctionHistory) {
      return `${
        percentageFunctionLevelHistory[
          percentageFunctionLevelHistory.length - 1
        ].functionLevel
      }%`
    }

    return null
  }

  const painData: ChartData<'line', number[], string> = {
    labels: patientPainAndFunction.painHistory.map((item) => item.date),
    datasets: [
      {
        data: patientPainAndFunction.painHistory.map((item) => item.painLevel),
        pointBackgroundColor: (ctx) => {
          if (ctx.parsed) {
            return getPainLevelColor(ctx.parsed.y)
          }
        },
        pointBorderColor(ctx) {
          if (ctx.parsed) {
            return getPainLevelColor(ctx.parsed.y)
          }
        },
        segment: {
          borderWidth: 1,
          borderColor: (ctx) => {
            return getPainLevelColor(ctx.p1.parsed.y)
          },
        },
      },
    ],
  }

  const functionData: ChartData<'line', number[], string> = {
    labels: patientPainAndFunction.functionHistory.map((item) => item.date),
    datasets: [
      {
        data: percentageFunctionLevelHistory.map((item) => item.functionLevel),
        pointBackgroundColor: (ctx) => {
          if (ctx.parsed) {
            return getFunctionLevelColor(ctx.parsed.y)
          }
        },
        pointBorderColor(ctx) {
          if (ctx.parsed) {
            return getFunctionLevelColor(ctx.parsed.y)
          }
        },
        segment: {
          borderWidth: 1,
          borderColor: (ctx) => {
            return getFunctionLevelColor(ctx.p1.parsed.y)
          },
        },
      },
    ],
  }

  const painOptions: _DeepPartialObject<
    CoreChartOptions<'line'> &
      ElementChartOptions<'line'> &
      PluginChartOptions<'line'> &
      DatasetChartOptions<'line'> &
      ScaleChartOptions<'line'> &
      LineControllerChartOptions
  > = {
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        position: 'bottom',
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        offset: true,
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 5,
        },
        min: 0,
        max: 10,
      },
    },
  }

  const functionOptions: _DeepPartialObject<
    CoreChartOptions<'line'> &
      ElementChartOptions<'line'> &
      PluginChartOptions<'line'> &
      DatasetChartOptions<'line'> &
      ScaleChartOptions<'line'> &
      LineControllerChartOptions
  > = {
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        position: 'bottom',
        callbacks: {
          label: (ctx) => {
            if (ctx.dataset) {
              return `${ctx.dataset.data[ctx.dataIndex]}%`
            }
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      y: {
        offset: true,
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 50,
          callback: (value) => {
            return `${value}%`
          },
        },
        min: 0,
        max: 100,
      },
    },
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        columnGap: '56px',
        alignItems: 'center',
        width: '100%',
        padding: '24px 18px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          columnGap: '24px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '8px',
            alignItems: 'center',
            paddingTop: '20px',
          }}
        >
          <Typography
            component={'span'}
            sx={{
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '25px',
              color: 'grey.200',
            }}
          >
            Current Pain
          </Typography>
          {hasPainHistory ? (
            <Typography
              component={'span'}
              sx={{
                fontWeight: 400,
                fontSize: '34px',
                lineHeight: '123.5%',
                color: 'common.black',
              }}
            >
              {getCurrentPainLevel()}
            </Typography>
          ) : null}
        </Box>
        <Box
          sx={{
            width: {
              xs: '224px',
            },
          }}
        >
          <Line data={painData} options={painOptions}></Line>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          columnGap: '24px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '8px',
            alignItems: 'center',
            paddingTop: '20px',
          }}
        >
          <Typography
            component={'span'}
            sx={{
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '25px',
              color: 'grey.200',
            }}
          >
            Current Function
          </Typography>
          {hasFunctionHistory ? (
            <Typography
              component={'span'}
              sx={{
                fontWeight: 400,
                fontSize: '34px',
                lineHeight: '123.5%',
                color: 'common.black',
              }}
            >
              {getCurrentFunctionLevel()}
            </Typography>
          ) : null}
        </Box>
        <Box
          sx={{
            width: {
              xs: '224px',
            },
          }}
        >
          <Line data={functionData} options={functionOptions}></Line>
        </Box>
      </Box>
    </Box>
  )
}
