import {
  UseMutateFunction,
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'

import {
  ProcessMSKFormArgs,
  ProcessMSKFormError,
  ProcessMSKFormReturns,
} from 'src/features/msk/domain'
import { GET_PATIENT_QUERY_KEY } from 'src/features/patients/presentation'
import {
  GET_PATIENT_INTAKE_FLOWS_QUERY_KEY,
  GET_PATIENT_LAST_INTAKE_FLOW_QUERY_KEY,
} from 'src/features/msk/presentation'
import { usePatientId } from 'src/features/shared/presentation'
import { useMSKUseCases } from 'src/features/msk/msk.service-locator'
import { removeMSKFormFromLocalStorage } from 'src/features/shared/infrastructure'

type useProcessMSKFormProps = Omit<
  UseMutationOptions<
    ProcessMSKFormReturns,
    ProcessMSKFormError,
    ProcessMSKFormArgs,
    unknown
  >,
  'mutationFn'
> & {
  patientId?: string
  throwGetPatientIdError?: boolean
}

type UseProcessMSKForm = (props?: useProcessMSKFormProps) => {
  processMSKForm: UseMutateFunction<
    ProcessMSKFormReturns,
    ProcessMSKFormError,
    ProcessMSKFormArgs,
    unknown
  >
  processMSKFormIsLoading: boolean
  processMSKFormIsError: boolean
  processMSKFormStatus: string
}

export const useProcessMSKForm: UseProcessMSKForm = (props = {}) => {
  const { onSuccess, patientId, throwGetPatientIdError } = props

  const _patientId = usePatientId({ patientId, throwGetPatientIdError })

  const queryClient = useQueryClient()
  const mskUseCases = useMSKUseCases()

  const { mutate, isLoading, isError, status } = useMutation<
    ProcessMSKFormReturns,
    ProcessMSKFormError,
    ProcessMSKFormArgs,
    unknown
  >({
    mutationFn: (args: ProcessMSKFormArgs) => {
      return mskUseCases.processMSKForm(args)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [GET_PATIENT_QUERY_KEY],
      })
      if (_patientId) {
        queryClient.invalidateQueries({
          queryKey: [GET_PATIENT_LAST_INTAKE_FLOW_QUERY_KEY, _patientId],
        })
        queryClient.invalidateQueries({
          queryKey: [GET_PATIENT_INTAKE_FLOWS_QUERY_KEY, _patientId],
        })
        removeMSKFormFromLocalStorage(_patientId)
      }
      onSuccess?.(data, variables, context)
    },
  })
  return {
    processMSKForm: mutate,
    processMSKFormIsLoading: isLoading,
    processMSKFormIsError: isError,
    processMSKFormStatus: status,
  }
}
