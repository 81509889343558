import { FieldPath, FieldValues } from 'react-hook-form'
import { yesNo, CustomSelectField } from 'src/features/shared/presentation'

import { filterNoneValueOptions } from 'src/features/notes/adapters'

export const SDMCompletedField = <T extends FieldValues>() => {
  const name = 'sdmPatientUnderstandsCondition' as FieldPath<T>

  return (
    <CustomSelectField
      name={name}
      id="sdm-patient-understands-condition"
      label={
        'Was SDM completed and does the patient understand benefits and risks of pathway choice?'
      }
      items={filterNoneValueOptions(yesNo)}
      selectStyle={{ width: '88px' }}
      labelStyle={{ fontWeight: 'bold', pb: 1 }}
    />
  )
}
