import {
  UseMutateAsyncFunction,
  UseMutateFunction,
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import {
  CreatePatientIntakeNoteReturns,
  CreatePatientIntakeNoteArgs,
  CreatePatientIntakeNoteError,
} from 'src/features/notes/domain'
import {
  GET_PATIENT_QUERY_KEY,
  GET_PATIENT_PAIN_AND_FUNCTION_QUERY_KEY,
} from 'src/features/patients/presentation'
import { GET_PATIENT_NOTES_QUERY_KEY } from 'src/features/notes/presentation'
import { useNotesUseCases } from 'src/features/notes/notes.service-locator'

type UseCreatePatientIntakeNoteProps = Omit<
  UseMutationOptions<
    CreatePatientIntakeNoteReturns,
    CreatePatientIntakeNoteError,
    CreatePatientIntakeNoteArgs,
    unknown
  >,
  'mutationFn'
>

type UseCreatePatientIntakeNote = (props?: UseCreatePatientIntakeNoteProps) => {
  createPatientIntakeNote: UseMutateFunction<
    CreatePatientIntakeNoteReturns,
    CreatePatientIntakeNoteError,
    CreatePatientIntakeNoteArgs,
    unknown
  >
  createPatientIntakeNoteAsync: UseMutateAsyncFunction<
    CreatePatientIntakeNoteReturns,
    CreatePatientIntakeNoteError,
    CreatePatientIntakeNoteArgs,
    unknown
  >
  createPatientIntakeNoteIsLoading: boolean
  createPatientIntakeNoteIsError: boolean
  createPatientIntakeNoteStatus: string
}

export const useCreatePatientIntakeNote: UseCreatePatientIntakeNote = (
  props = {}
) => {
  const queryClient = useQueryClient()
  const notesUseCases = useNotesUseCases()

  const { onSuccess } = props

  const { mutate, mutateAsync, isLoading, isError, status } = useMutation<
    CreatePatientIntakeNoteReturns,
    CreatePatientIntakeNoteError,
    CreatePatientIntakeNoteArgs,
    unknown
  >({
    mutationFn: (args: CreatePatientIntakeNoteArgs) => {
      return notesUseCases.createPatientIntakeNote(args)
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [GET_PATIENT_QUERY_KEY, variables.patientId],
      })
      queryClient.invalidateQueries({
        queryKey: [GET_PATIENT_NOTES_QUERY_KEY, variables.patientId],
      })
      queryClient.invalidateQueries({
        queryKey: [
          GET_PATIENT_PAIN_AND_FUNCTION_QUERY_KEY,
          variables.patientId,
        ],
      })
      onSuccess?.(data, variables, context)
    },
  })
  return {
    createPatientIntakeNote: mutate,
    createPatientIntakeNoteAsync: mutateAsync,
    createPatientIntakeNoteIsLoading: isLoading,
    createPatientIntakeNoteIsError: isError,
    createPatientIntakeNoteStatus: status,
  }
}
