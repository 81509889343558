import { Box } from '@mui/material'
import { FC } from 'react'
import { Outlet } from 'react-router-dom'

import {
  LogoHeader,
  QueuePatientsActionsHeader,
  QueuePatientsFilterModal,
} from 'src/features/queues/presentation'
import {
  TasksSidebar,
  TASKS_SIDEBAR_DESKTOP_WIDTH,
} from 'src/features/tasks/presentation'
import {
  HomeButton,
  HelpButton,
  LogoutButton,
  MainBoxBaseLayout,
  MainStackBaseLayout,
} from 'src/features/shared/presentation'
import { useTasksSidebarStore } from 'src/features/shared/infrastructure'
import { ChildrenInProps } from 'src/features/shared/types'
import { CreatePatientModal } from 'src/features/queues/presentation'

const FirstBoxWrapperContentQueuesBaseLayout: FC<ChildrenInProps> = ({
  children,
}) => {
  const { open: tasksSidebarIsOpen } = useTasksSidebarStore()
  return (
    <Box
      id={'first-box-wrapper-content-queues-base-layout'}
      sx={{
        boxSizing: 'border-box',
        height: '100vh',
        maxWidth: '100vw',
        paddingTop: { xs: '48px' },
        paddingLeft: { xs: '40px' },
        paddingRight: {
          xs: `${
            tasksSidebarIsOpen
              ? `${TASKS_SIDEBAR_DESKTOP_WIDTH + 16}px`
              : '52px'
          }`,
        },
        transitionDuration: '0.5s',
        transitionProperty: 'padding-right',
      }}
    >
      {children}
    </Box>
  )
}

const SecondBoxWrapperContentQueuesBaseLayout: FC<ChildrenInProps> = ({
  children,
}) => {
  return (
    <Box
      id={'second-box-wrapper-content-queues-base-layout'}
      sx={{
        boxSizing: 'border-box',
        pt: {
          xs: '48px',
        },
        pb: {
          xs: '62px',
        },
        transitionDuration: '0.5s',
        transitionProperty: 'padding-right',
      }}
    >
      {children}
    </Box>
  )
}

export const QueuesBaseLayoutHeader: FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        <LogoHeader />
      </Box>
      <Box>
        <HomeButton />
        <HelpButton />
        <LogoutButton />
      </Box>
    </Box>
  )
}

const QueuesBaseLayoutWrapper: FC<ChildrenInProps> = ({ children }) => {
  return (
    <MainBoxBaseLayout>
      <MainStackBaseLayout>
        <FirstBoxWrapperContentQueuesBaseLayout>
          <QueuesBaseLayoutHeader />
          <SecondBoxWrapperContentQueuesBaseLayout>
            <QueuePatientsActionsHeader />
            {children}
            <QueuePatientsFilterModal />
          </SecondBoxWrapperContentQueuesBaseLayout>
        </FirstBoxWrapperContentQueuesBaseLayout>
      </MainStackBaseLayout>
      <CreatePatientModal />
      <TasksSidebar />
    </MainBoxBaseLayout>
  )
}

export const RouterQueuesBaseLayout: FC = () => {
  return (
    <QueuesBaseLayoutWrapper>
      <Outlet />
    </QueuesBaseLayoutWrapper>
  )
}
