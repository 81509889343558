import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Modal,
  Typography,
} from '@mui/material'
import { FC, useCallback, useEffect } from 'react'
import { toast } from 'react-toastify'

import {
  MSKFormState,
  MSKFormStateIntakeAssessment,
  useMSKFormStore,
  useNotesStore,
  usePatientPageStore,
} from 'src/features/shared/infrastructure'
import { LoadMSKFormReturns, MSKQuestion } from 'src/features/msk/domain'
import {
  LeftAlignedItem,
  MSKFormPagination,
  PathQuestionItem,
  TwoColumnsItem,
  useProcessMSKForm,
  IntakeAssessmentActions,
  IntakeAssessmentPainAndFunction,
  IntakeAssessmentPathQuestions,
  MSKFormStepContainer,
  QuestionItem,
  QUESTIONS_LABELS,
} from 'src/features/msk/presentation'
import { getNextId, isAnActionId, isQuestionId } from 'src/features/msk/utils'
import { Patient } from 'src/features/patients/domain'
import { mapToProcessMSKFormArgs } from 'src/features/msk/adapters'

const IS_MSK_STEP_TWO_FULL_SUMMARY_ACTIVE = false

type MSKFormStepTwoIntakeAssessmentSummaryProps = {
  intakeAssessment: MSKFormStateIntakeAssessment
  intakeAssessments: MSKFormStateIntakeAssessment[]
  loadedMSKForm: LoadMSKFormReturns
  isInStepTwoFullSummary?: boolean
}

export const MSKFormStepTwoIntakeAssessmentSummary: FC<
  MSKFormStepTwoIntakeAssessmentSummaryProps
> = ({
  intakeAssessments,
  intakeAssessment,
  loadedMSKForm,
  isInStepTwoFullSummary = false,
}) => {
  const intakeAssessmentIdx = intakeAssessments.findIndex(
    (_intakeAssessment) =>
      _intakeAssessment.botheredBodyPart.id ===
      intakeAssessment.botheredBodyPart.id
  )
  const nextIntakeAssessmentIdx = intakeAssessmentIdx + 1
  const nextIntakeAssessment =
    intakeAssessments.length > nextIntakeAssessmentIdx
      ? intakeAssessments[nextIntakeAssessmentIdx]
      : null

  const recommendedAction = intakeAssessment.recommendedAction

  const answers = Object.keys(intakeAssessment.answers).map((questionId) => ({
    questionId: questionId,
    question: loadedMSKForm.questionsAndActions.questions[questionId].q,
    value: intakeAssessment.answers[questionId].value,
  }))

  const selectedPreviouslyTriedActions =
    intakeAssessment.previouslyTriedActions.map((action) => action.id)

  const selectedActionsOpenToTry = intakeAssessment.actionsOpenToTry.map(
    (action) => action.id
  )

  const getTitle = () => {
    if (isInStepTwoFullSummary) {
      return `Intake Assessment for ${intakeAssessment.botheredBodyPart.display}.`
    }

    let title = 'You have completed the Intake Assessment for '

    title += intakeAssessment.botheredBodyPart.display

    if (intakeAssessment.confirmed) {
      title += ', click next to '
    } else {
      title += ', confirm to '
    }

    if (nextIntakeAssessment) {
      title += `go to ${nextIntakeAssessment.botheredBodyPart.display} Intake Assessment.`
    } else {
      title += 'continue.'
    }

    return title
  }

  const getCurrentlyUsingOpioids = () => {
    let result

    if (intakeAssessment.currentlyUsingOpioids === true) {
      result = 'Yes'
    } else if (intakeAssessment.currentlyUsingOpioids === false) {
      result = 'No'
    }

    return result
  }

  const getPreviouslyTriedActions = () => {
    const previouslyTriedActions = loadedMSKForm.previouslyTriedActions.filter(
      (action) => selectedPreviouslyTriedActions.includes(action.id)
    )
    if (previouslyTriedActions.length === 0) {
      return '-'
    }
    return previouslyTriedActions.map((action) => action.display).join(', ')
  }

  const getOpenToTryActions = () => {
    const actionsOpenToTry = loadedMSKForm.actionsOpenToTry.filter((action) =>
      selectedActionsOpenToTry.includes(action.id)
    )
    if (actionsOpenToTry.length === 0) {
      return '-'
    }
    return actionsOpenToTry.map((action) => action.display).join(', ')
  }

  const getBodyRegionToNoteAnswer = () => {
    if (intakeAssessment.bodyRegionToNote.length) {
      return intakeAssessment.bodyRegionToNote.join(', ')
    }
    return 'Not answered'
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '16px' }}>
      <Typography
        variant={'h4'}
        sx={{
          fontSize: '24px',
          color: '#40787B',
        }}
        data-testid={
          'msk_form_step_two_intake_assessment_summary_recommended_care_pathway_label'
        }
      >
        {getTitle()}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '32px' }}>
        <Box>
          <LeftAlignedItem
            label={'Body Region'}
            value={intakeAssessment.botheredBodyPart.display}
            data-testid={
              'msk_form_step_two_intake_assessment_summary_body_region'
            }
          />
          {recommendedAction ? (
            <>
              <LeftAlignedItem
                label={'Recommended Care Pathway'}
                value={recommendedAction.action.pathway}
                data-testid={
                  'msk_form_step_two_intake_assessment_summary_recommended_care_pathway'
                }
              />
              <LeftAlignedItem
                label={'Recommended Type'}
                value={recommendedAction.action.type}
                data-testid={
                  'msk_form_step_two_intake_assessment_summary_recommended_type'
                }
              />
              <LeftAlignedItem
                label={'Care Pathway ID'}
                value={recommendedAction.id}
                data-testid={
                  'msk_form_step_two_intake_assessment_summary_recommended_care_pathway_id'
                }
              />
            </>
          ) : null}
        </Box>
        <Box>
          <TwoColumnsItem
            left={'Pain Level'}
            right={`${intakeAssessment.currentPainLevel}`}
            data-testid={
              'msk_form_step_two_intake_assessment_summary_pain_level'
            }
          />
          <TwoColumnsItem
            left={'Function Level'}
            right={`${intakeAssessment.currentFunctionLevel}`}
            data-testid={
              'msk_form_step_two_intake_assessment_summary_function_level'
            }
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '16px' }}>
          <TwoColumnsItem
            left={'Previously Tried'}
            right={getPreviouslyTriedActions()}
            data-testid={
              'msk_form_step_two_intake_assessment_summary_previously_tried'
            }
          />
          <TwoColumnsItem
            left={'Open to Trying'}
            right={getOpenToTryActions()}
            data-testid={
              'msk_form_step_two_intake_assessment_summary_open_to_trying'
            }
          />
          {intakeAssessment.otherPreviouslyTriedActionText ? (
            <QuestionItem
              question={QUESTIONS_LABELS.otherPreviouslyTriedActionText}
              answer={intakeAssessment.otherPreviouslyTriedActionText}
              data-testid={
                'msk_form_step_two_intake_assessment_summary_other_previously_tried'
              }
            />
          ) : null}
          {getCurrentlyUsingOpioids() ? (
            <QuestionItem
              question={QUESTIONS_LABELS.currentlyUsingOpioids}
              answer={getCurrentlyUsingOpioids() as string}
              data-testid={
                'msk_form_step_two_intake_assessment_summary_currently_using_opioids'
              }
            />
          ) : null}
          <QuestionItem
            question={QUESTIONS_LABELS.bodyRegionToNote}
            answer={getBodyRegionToNoteAnswer()}
            data-testid={
              'msk_form_step_two_intake_assessment_summary_body_region_to_note'
            }
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '16px' }}>
          {answers.map((answer, index) => (
            <PathQuestionItem
              index={index + 1}
              data-testid={`msk_form_step_two_intake_assessment_summary_path_question_${
                index + 1
              }`}
              key={answer.questionId}
              question={answer.question}
              answer={answer.value}
            />
          ))}
        </Box>
      </Box>
    </Box>
  )
}

type StaticPage = {
  name: string
  number: number
}

// Pages constants
const staticPages: StaticPage[] = [
  { name: 'Summary', number: 0 },
  { name: 'Actions', number: 1 },
  { name: 'Pain and Function', number: 2 },
]
const getPageNumber = (pageName: StaticPage['name']): StaticPage['number'] => {
  return staticPages.filter((page) => page.name === pageName)[0].number
}
const summaryPageNumber = getPageNumber('Summary')
const actionsPageNumber = getPageNumber('Actions')
const painAndFunctionPageNumber = getPageNumber('Pain and Function')

type MSKFormStepTwoProps = {
  patient: Patient
  loadedMSKForm: LoadMSKFormReturns
}

export const MSKFormStepTwo: FC<MSKFormStepTwoProps> = ({
  patient,
  loadedMSKForm,
}) => {
  const { setSelectedNoteType } = useNotesStore()
  const { processMSKForm, processMSKFormIsLoading } = useProcessMSKForm()
  const { setOpenMSKFormModal } = usePatientPageStore()
  const mskFormStore = useMSKFormStore({
    loadedMSKForm,
  })
  const {
    addNewErrors,
    currentIntakeAssessmentIdx,
    currentPathQuestion,
    currentPathQuestionAnswer,
    deleteCurrentIntakeAssesmentAnswer,
    intakeAssessments,
    page,
    pathQuestionsNext,
    resetMSKForm,
    setCurrentCondition,
    setCurrentIntakeAssessmentConfirmed,
    setCurrentIntakeAssessmentHardstop,
    setCurrentIntakeAssessmentRecommendedAction,
    setCurrentPathQuestion,
    setCurrentPathQuestionAnswer,
    setIsCurrentPathQuestionValidated,
    setModalTitle,
    setNextIntakeAssessmentIdx,
    setPage,
    setPathQuestionsNext,
    setPreviousIntakeAssessmentIdx,
    setShowHardstopWarning,
    setShowStepTwoFullSummary,
    setStep,
    showHardstopWarning,
    showStepTwoFullSummary,
  } = mskFormStore

  const currentIntakeAssessment = intakeAssessments[currentIntakeAssessmentIdx]
  const isFirstIntakeAssessment = currentIntakeAssessmentIdx === 0
  const isLastIntakeAssessment =
    currentIntakeAssessmentIdx === intakeAssessments.length - 1

  const answers = currentIntakeAssessment.answers
  const pathQuestionsHistory = currentIntakeAssessment.questionHistory
  const startQuestionId = currentIntakeAssessment.startQuestionId
  const questions = loadedMSKForm.questionsAndActions.questions
  const actions = loadedMSKForm.questionsAndActions.actions

  const showCurrentIntakeAssessmentSummary = page === summaryPageNumber
  const showActions = page === actionsPageNumber
  const showPainAndFunction = page === painAndFunctionPageNumber
  const showPathQuestions = page > painAndFunctionPageNumber

  const showEdit =
    !showStepTwoFullSummary &&
    showCurrentIntakeAssessmentSummary &&
    currentIntakeAssessment.confirmed

  const totalPages =
    staticPages.filter((page) => page.name !== 'Summary').length +
    pathQuestionsHistory.length

  const getModalTitle = useCallback(() => {
    if (showStepTwoFullSummary) {
      return 'Intake Assessments Summary'
    }
    return `Intake Assessment ${currentIntakeAssessment.botheredBodyPart.display}`
  }, [currentIntakeAssessment.botheredBodyPart.display, showStepTwoFullSummary])

  const isActionsPageValid = () => {
    const newErrors: MSKFormState['errors'] = {}

    if (
      currentIntakeAssessment.previouslyTriedActions.find(
        (action) => action.id === 'opioids'
      ) &&
      currentIntakeAssessment.currentlyUsingOpioids === undefined
    ) {
      newErrors.currentlyUsingOpioids = 'Required'
    }

    if (
      currentIntakeAssessment.previouslyTriedActions.find(
        (action) => action.id === 'other'
      ) &&
      !currentIntakeAssessment.otherPreviouslyTriedActionText
    ) {
      newErrors.otherPreviouslyTriedActionText = 'Required'
    }

    if (Object.keys(newErrors).length) {
      addNewErrors(newErrors)
      return false
    }

    return true
  }

  const getNextText = () => {
    if (
      showCurrentIntakeAssessmentSummary &&
      !currentIntakeAssessment.confirmed
    ) {
      return 'CONFIRM'
    }

    return 'NEXT'
  }

  const getLastQuestionId = (intakeAssessmentIdx: number) => {
    return intakeAssessments[intakeAssessmentIdx].questionHistory[
      intakeAssessments[intakeAssessmentIdx].questionHistory.length - 1
    ]
  }

  const clearPathQuestion = () => {
    setCurrentPathQuestion(null)
    setCurrentPathQuestionAnswer(null)
    setPathQuestionsNext(null)
    setIsCurrentPathQuestionValidated(false)
  }

  const changePathQuestionById = (questionId: MSKQuestion['id']) => {
    const question = questions[questionId]
    const answer = answers[questionId]
    setCurrentPathQuestion(question)
    setCurrentPathQuestionAnswer(answer || null)
    if (answer) {
      const nextId = getNextId(answer, question, currentIntakeAssessment)
      if (isQuestionId(nextId)) {
        setPathQuestionsNext(questions[nextId])
      } else if (isAnActionId(nextId)) {
        setPathQuestionsNext(actions[nextId])
      } else {
        setPathQuestionsNext(null)
      }
    } else {
      setPathQuestionsNext(null)
    }
  }

  const handleNext = () => {
    if (showStepTwoFullSummary) {
      setStep(3)
      setPage(1)
      return
    }

    if (showCurrentIntakeAssessmentSummary) {
      if (!currentIntakeAssessment.confirmed) {
        setCurrentIntakeAssessmentConfirmed(true)
        clearPathQuestion()
      }
      if (isLastIntakeAssessment) {
        if (IS_MSK_STEP_TWO_FULL_SUMMARY_ACTIVE) {
          setShowStepTwoFullSummary(true)
          return
        }

        setStep(3)
        setPage(1)
        return
      }
      setNextIntakeAssessmentIdx()
      setPage(actionsPageNumber)
      return
    }

    if (showActions && isActionsPageValid()) {
      setPage(painAndFunctionPageNumber)
      return
    }

    if (showPainAndFunction) {
      changePathQuestionById(startQuestionId)
      setPage(page + 1)
      return
    }

    if (showPathQuestions) {
      setIsCurrentPathQuestionValidated(true)
      if (currentPathQuestionAnswer && pathQuestionsNext) {
        if (pathQuestionsNext.type === 'question') {
          changePathQuestionById(pathQuestionsNext.id)
          setIsCurrentPathQuestionValidated(false)
          setPage(page + 1)
          return
        }

        if (pathQuestionsNext.type === 'action' && pathQuestionsNext.hardstop) {
          setCurrentIntakeAssessmentRecommendedAction(pathQuestionsNext)
          setCurrentIntakeAssessmentHardstop(true)
          setShowHardstopWarning(true)
        }

        if (
          pathQuestionsNext.type === 'action' &&
          !pathQuestionsNext.hardstop
        ) {
          setCurrentIntakeAssessmentRecommendedAction(pathQuestionsNext)
          clearPathQuestion()
          setPage(summaryPageNumber)
          return
        }
      }
    }
  }

  const handlePrevious = () => {
    if (showStepTwoFullSummary) {
      setShowStepTwoFullSummary(false)
      return
    }

    if (showCurrentIntakeAssessmentSummary) {
      if (!currentIntakeAssessment.confirmed) {
        changePathQuestionById(getLastQuestionId(currentIntakeAssessmentIdx))
        setPage(totalPages)
        return
      }
      if (currentIntakeAssessment.confirmed) {
        if (isFirstIntakeAssessment) {
          setStep(1)
          setPage(1)
          return
        }
        setPreviousIntakeAssessmentIdx()
        return
      }
    }

    if (showActions && isFirstIntakeAssessment) {
      setStep(1)
      setPage(1)
      return
    }

    if (showActions && !isFirstIntakeAssessment) {
      setPreviousIntakeAssessmentIdx()
      return
    }

    if (showPainAndFunction) {
      setPage(actionsPageNumber)
      return
    }

    if (showPathQuestions) {
      const isFirsQuestionOfPath = page === painAndFunctionPageNumber + 1

      // If im on the first question of the path questions
      if (isFirsQuestionOfPath) {
        setPage(painAndFunctionPageNumber)
        clearPathQuestion()
        return
      }

      const currentQuestionIdx = pathQuestionsHistory.findIndex(
        (questionId) => questionId === currentPathQuestion?.id
      )
      const lastQuestionIdInHistory =
        pathQuestionsHistory[pathQuestionsHistory.length - 1]

      // Try to go to the last question of the history by default
      let questionId = lastQuestionIdInHistory

      // If the current question is already in the history, go to the previous one
      if (currentQuestionIdx > 0) {
        questionId = pathQuestionsHistory[currentQuestionIdx - 1]
      }

      // Set the question, answer and next to the previous one
      changePathQuestionById(questionId)

      setIsCurrentPathQuestionValidated(false)
      setPage(page - 1)
    }
  }

  const handleEdit = () => {
    setCurrentIntakeAssessmentConfirmed(false)
    setCurrentIntakeAssessmentRecommendedAction(null)
    setPage(actionsPageNumber)
  }

  const handleHardstopWarningClose = () => {
    setIsCurrentPathQuestionValidated(false)
    setShowHardstopWarning(false)
    setCurrentIntakeAssessmentHardstop(false)
    if (currentPathQuestion) {
      deleteCurrentIntakeAssesmentAnswer(currentPathQuestion)
    }
    setCurrentPathQuestionAnswer(null)
  }

  const handleHardstopWarningConfirm = () => {
    const processMSKFormArgs = mapToProcessMSKFormArgs(mskFormStore)
    processMSKForm(processMSKFormArgs, {
      onSuccess: () => {
        toast.success('Intake Assessment submitted successfully')
        setShowHardstopWarning(false)
        setOpenMSKFormModal(false)
        resetMSKForm(patient.patientId)
        setSelectedNoteType('Intake')
      },
      onError: () => {
        toast.error('Something went wrong. Please try again later')
      },
    })
  }

  useEffect(() => {
    setCurrentCondition(currentIntakeAssessment.botheredBodyPart)
    setModalTitle(getModalTitle())
    if (currentIntakeAssessment.confirmed) {
      setPage(summaryPageNumber)
    }
  }, [
    currentIntakeAssessment,
    getModalTitle,
    setCurrentCondition,
    setModalTitle,
    setPage,
  ])

  return (
    <>
      <Modal
        open={showHardstopWarning}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 700,
            bgcolor: 'background.paper',
            borderRadius: '4px',
            boxShadow: 24,
          }}
        >
          <Alert severity="error">
            <AlertTitle>Serious Symptom Warning</AlertTitle>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '32px',
              }}
            >
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                This answer indicates a serious concern.
                <br />
                If true, please stop this Intake Assessment and direct the
                patient to visit their nearest emergency room as soon as
                possible.
                <br />
                Are you sure the patient is experiencing saddle numbness and
                loss of bowel or bladder function?
                <br />
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  columnGap: '16px',
                }}
              >
                <Button
                  data-testid={'msk_form_red_flag_warning_confirm_no'}
                  variant={'outlined'}
                  color={'secondary'}
                  onClick={handleHardstopWarningClose}
                  disabled={processMSKFormIsLoading}
                >
                  NO, CONTINUE THE ASSESSMENT
                </Button>
                <Button
                  data-testid={'msk_form_red_flag_warning_confirm_yes'}
                  variant={'contained'}
                  color={'secondary'}
                  onClick={handleHardstopWarningConfirm}
                  disabled={processMSKFormIsLoading}
                >
                  YES, EXIT ASSESSMENT
                </Button>
              </Box>
            </Box>
          </Alert>
        </Box>
      </Modal>
      <MSKFormStepContainer>
        <Box id="msk_form_step_two">
          {showStepTwoFullSummary ? (
            <Box id="msk_form_step_two_intake_assessment_full_summary">
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '64px',
                }}
              >
                {intakeAssessments.map((intakeAssessment) => (
                  <MSKFormStepTwoIntakeAssessmentSummary
                    key={intakeAssessment.botheredBodyPart.id}
                    intakeAssessment={intakeAssessment}
                    loadedMSKForm={loadedMSKForm}
                    intakeAssessments={intakeAssessments}
                    isInStepTwoFullSummary={true}
                  />
                ))}
              </Box>
            </Box>
          ) : showCurrentIntakeAssessmentSummary ? (
            <Box id="msk_form_step_two_current_intake_assessment_summary">
              <MSKFormStepTwoIntakeAssessmentSummary
                intakeAssessment={currentIntakeAssessment}
                loadedMSKForm={loadedMSKForm}
                intakeAssessments={intakeAssessments}
              />
            </Box>
          ) : showActions ? (
            <Box id="msk_form_step_two_actions">
              <IntakeAssessmentActions
                loadedMSKForm={loadedMSKForm}
                currentIntakeAssessment={currentIntakeAssessment}
              />
            </Box>
          ) : showPainAndFunction ? (
            <Box id="msk_form_step_two_pain_and_function">
              <IntakeAssessmentPainAndFunction
                loadedMSKForm={loadedMSKForm}
                currentIntakeAssessment={currentIntakeAssessment}
              />
            </Box>
          ) : showPathQuestions ? (
            <Box
              id="msk_form_step_two_path_questions"
              data-testid="msk_form_step_two_path_questions"
            >
              <IntakeAssessmentPathQuestions
                loadedMSKForm={loadedMSKForm}
                currentIntakeAssessment={currentIntakeAssessment}
              />
            </Box>
          ) : null}
        </Box>
        <MSKFormPagination
          onNext={handleNext}
          onPrevious={handlePrevious}
          nextText={getNextText()}
          showEdit={showEdit}
          onEdit={handleEdit}
          showNextEndIcon={!showStepTwoFullSummary}
          disabledNext={processMSKFormIsLoading}
          disabledPrevious={processMSKFormIsLoading}
        />
      </MSKFormStepContainer>
    </>
  )
}
