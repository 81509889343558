import { useCallback } from 'react'
import axios from 'axios'

import {
  GetMyCompassUserService,
  GetMyCompassUserServiceResponse,
  GetNavigatorsService,
  GetNavigatorsServiceResponse,
  useCompassApiClient,
} from 'src/features/shared/infrastructure'
import * as settings from 'src/config/settings'

export const useNavigatorsService = () => {
  const { getCompassApiClient } = useCompassApiClient()

  const getNavigators: GetNavigatorsService = useCallback(
    async (params) => {
      const compassClient = await getCompassApiClient()
      return await compassClient.get<GetNavigatorsServiceResponse>(
        settings.COMPASS_API_BASE_URL + '/users',
        {
          params,
        }
      )
    },
    [getCompassApiClient]
  )

  return {
    getNavigators,
  }
}

export const useCompassUsersService = () => {
  const getMyCompassUser: GetMyCompassUserService = useCallback((idToken) => {
    const axiosClient = axios.create({
      headers: {
        'x-api-key': settings.COMPASS_API_KEY,
        Authorization: `Bearer ${idToken}`,
      },
    })
    return axiosClient.get<GetMyCompassUserServiceResponse>(
      settings.COMPASS_API_BASE_URL + '/users/me'
    )
  }, [])

  return {
    getMyCompassUser,
  }
}
