import {
  LoadMSKFormServiceErrorResponse,
  LoadMSKFormServiceResponse,
  ProcessMSKFormServiceErrorResponse,
  ProcessMSKFormServicePayload,
  ProcessMSKFormServiceResponse,
  SMSKAction,
  SMSKAnswer,
  SMSKIntakeAssessment,
  SMSKPreviouslyTriedActionQuestion,
  SMSKQuestion,
  SMSKQuestionOption,
  SMSKQuestionsAndActions,
} from 'src/features/msk/infrastructure'
import {
  MSKActionOpenToTry,
  MSKBodyPart,
  LoadMSKFormError,
  LoadMSKFormReturns,
  MSKQuestion,
  MSKQuestionOption,
  MSKPreviouslyTriedAction,
  ProcessMSKFormArgs,
  ProcessMSKFormReturns,
  MSKQuestionsAndActions,
  MSKPreviouslyTriedActionQuestion,
  MSKAnswer,
  MSKIntakeAssessment,
  MSKAction,
  ProcessMSKFormError,
  MSKAdditionalQuestions,
} from 'src/features/msk/domain'
import { MultiSelectOption } from 'src/features/shared/presentation'
import { CheckboxGroupOption } from 'src/features/shared/presentation'
import {
  MSKFormState,
  MSKFormStateIntakeAssessment,
} from 'src/features/shared/infrastructure'
import {
  Patient,
  PatientContact,
  UpdatePatientPayload,
} from 'src/features/patients/domain'
import {
  getHeightInInches,
  getPatientCurrentContacts,
} from 'src/features/shared/utils'
import { BodyRegionToNoteOption } from 'src/features/msk/presentation'
import { mapToUseCaseDefaultError } from 'src/features/shared/adapters'

type MapToMSKPreviouslyTriedActionQuestion = (
  question: SMSKPreviouslyTriedActionQuestion
) => MSKPreviouslyTriedActionQuestion

export const mapToMSKPreviouslyTriedActionQuestion: MapToMSKPreviouslyTriedActionQuestion =
  (question) => {
    return {
      question: question.question,
      id: question.id,
      options: question.answers,
    }
  }

type MapToMSKQuestionOption = (
  question: SMSKQuestionOption
) => MSKQuestionOption

export const mapToMSKQuestionOption: MapToMSKQuestionOption = (question) => {
  return {
    value: question.value,
    nextStep: question.nextStep,
  }
}

type MapToMSKQuestion = (question: SMSKQuestion) => MSKQuestion

export const mapToMSKQuestion: MapToMSKQuestion = (question) => {
  return {
    id: question.id,
    type: question.type,
    q: question.q,
    options: {
      y: mapToMSKQuestionOption(question.answers.y),
      n: mapToMSKQuestionOption(question.answers.n),
    },
  }
}

type MapToMSKAction = (action: SMSKAction) => MSKAction

export const mapToMSKAction: MapToMSKAction = (action) => {
  return {
    id: action.id,
    type: action.type,
    action: {
      code: action.action,
      pathway: action.action.split(':')[0],
      type: action.action.split(':')[1],
    },
    hardstop: action.hardstop,
  }
}

type MapToMSKQuestionsAndActions = (
  questionsAndActions: SMSKQuestionsAndActions
) => MSKQuestionsAndActions

export const mapToMSKQuestionsAndActions: MapToMSKQuestionsAndActions = (
  questionsAndActions
) => {
  const questions: Record<string, MSKQuestion> = {}
  Object.entries(questionsAndActions.questions).forEach(([key, value]) => {
    questions[key] = mapToMSKQuestion(value)
  })

  const actions: Record<string, MSKAction> = {}
  Object.entries(questionsAndActions.actions).forEach(([key, value]) => {
    actions[key] = mapToMSKAction(value)
  })

  return {
    actions: actions,
    start: questionsAndActions.start,
    questions,
  }
}

type MapToSMSKAnswer = (answer: MSKAnswer) => SMSKAnswer

export const mapToSMSKAnswer: MapToSMSKAnswer = (answer) => {
  return {
    id: answer.questionId,
    answer: answer.value,
  }
}

type MapToSMSKIntakeAssessmentQuestions = (
  answers: MSKIntakeAssessment['answers']
) => SMSKIntakeAssessment['questions']

export const mapToSMSKIntakeAssessmentQuestions: MapToSMSKIntakeAssessmentQuestions =
  (questions) => {
    const mappedQuestions: Record<string, SMSKAnswer> = {}
    Object.entries(questions).forEach(([key, value]) => {
      mappedQuestions[key] = mapToSMSKAnswer(value)
    })

    return mappedQuestions
  }

type MapToSMSKIntakeAssessment = (
  intakeAssessment: MSKIntakeAssessment
) => SMSKIntakeAssessment

export const mapToSMSKIntakeAssessment: MapToSMSKIntakeAssessment = (
  intakeAssessment
) => {
  const smskIntakeAssessment: SMSKIntakeAssessment = {
    botheredBodyPart: intakeAssessment.botheredBodyPart,
    currentPainLevel: intakeAssessment.currentPainLevel,
    currentFunctionLevel: intakeAssessment.currentFunctionLevel,
    actionsOpenToTry: intakeAssessment.actionsOpenToTry,
    previouslyTriedActions: intakeAssessment.previouslyTriedActions,
    questions: mapToSMSKIntakeAssessmentQuestions(intakeAssessment.answers),
    recommendedAction: intakeAssessment.recommendedAction,
    hardStop: intakeAssessment.hardstop,
  }
  let currentlyUsingOpiods: SMSKIntakeAssessment['currentlyUsingOpiods']
  if (intakeAssessment.currentlyUsingOpioids === true) {
    currentlyUsingOpiods = 'yes'
  } else if (intakeAssessment.currentlyUsingOpioids === false) {
    currentlyUsingOpiods = 'no'
  }

  if (currentlyUsingOpiods) {
    smskIntakeAssessment.currentlyUsingOpiods = currentlyUsingOpiods
  }

  if (intakeAssessment.otherPreviouslyTriedActionText) {
    smskIntakeAssessment.otherText =
      intakeAssessment.otherPreviouslyTriedActionText
  }

  if (intakeAssessment.bodyRegionToNote) {
    smskIntakeAssessment.bodyRegionToNote = intakeAssessment.bodyRegionToNote
  }

  return smskIntakeAssessment
}

type MapToLoadMSKFormReturns = (
  response: LoadMSKFormServiceResponse
) => LoadMSKFormReturns

export const mapToLoadMSKFormReturns: MapToLoadMSKFormReturns = (response) => {
  return {
    botheredBodyParts: response.botheredBodyParts,
    previouslyTriedActions: response.previouslyTriedActions,
    previouslyTriedQuestions: response.previouslyTriedQuestions.map(
      mapToMSKPreviouslyTriedActionQuestion
    ),
    actionsOpenToTry: response.actionsOpenToTry,
    questionsAndActions: mapToMSKQuestionsAndActions(
      response.questionsAndActions
    ),
  }
}

type MapToLoadMSKFormError = (
  error: LoadMSKFormServiceErrorResponse
) => LoadMSKFormError

export const mapToLoadMSKFormError: MapToLoadMSKFormError = (error) => {
  return {
    message: error.message,
  }
}

type MapToProcessMSKFormServicePayload = (
  args: ProcessMSKFormArgs
) => ProcessMSKFormServicePayload

export const mapToProcessMSKFormServicePayload: MapToProcessMSKFormServicePayload =
  (args) => {
    return {
      patientId: args.patientId,
      intakeAssessments: args.intakeAssessments.map(mapToSMSKIntakeAssessment),
      profile: {
        id: args.profile.patientId,
        height: args.profile.height,
        weight: args.profile.weight,
      },
      additionalQuestions: args.additionalQuestions,
    }
  }

type MapToProcessMSKFormReturns = (
  response: ProcessMSKFormServiceResponse
) => ProcessMSKFormReturns

export const mapToProcessMSKFormReturns: MapToProcessMSKFormReturns = (
  response
) => {
  return {
    bmi: response.bmi,
    pathway: response.pathway,
    pathwayId: response.pathwayId,
    recommendedAction: response.recommendedAction,
    track: response.track,
    questionPath: response.questionPath,
  }
}

type MapToProcessMSKFormError = (
  error: ProcessMSKFormServiceErrorResponse
) => ProcessMSKFormError

export const mapToProcessMSKFormError: MapToProcessMSKFormError = (error) =>
  mapToUseCaseDefaultError<ProcessMSKFormServiceErrorResponse>(error)

type MapBodyPartToMultiSelectOption = (
  bodyPart: MSKBodyPart
) => MultiSelectOption

export const mapBodyPartToMultiSelectOption: MapBodyPartToMultiSelectOption = (
  bodyPart
) => {
  return {
    key: bodyPart.id,
    value: bodyPart.id,
    label: bodyPart.display,
  }
}

type MapBodyRegionToNoteToMultiSelectOption = (
  bodyPart: BodyRegionToNoteOption
) => MultiSelectOption

export const mapBodyRegionToNoteToMultiSelectOption: MapBodyRegionToNoteToMultiSelectOption =
  (bodyRegion) => {
    return {
      key: bodyRegion.id,
      value: bodyRegion.id,
      label: bodyRegion.display,
    }
  }

type MapActionToCheckboxGroupOption = (
  action: MSKPreviouslyTriedAction | MSKActionOpenToTry
) => CheckboxGroupOption

export const mapActionToCheckboxGroupOption: MapActionToCheckboxGroupOption = (
  action
) => {
  return {
    key: action.id,
    value: action.id,
    label: action.display,
  }
}

type MapToMSKIntakeAssessment = (
  formIntakeAssessment: MSKFormStateIntakeAssessment
) => MSKIntakeAssessment

export const mapToMSKIntakeAssessment: MapToMSKIntakeAssessment = (
  formIntakeAssessment
) => {
  const mskIntakeAssessment: MSKIntakeAssessment = {
    currentPainLevel: formIntakeAssessment.currentPainLevel,
    currentFunctionLevel: formIntakeAssessment.currentFunctionLevel,
    currentlyUsingOpioids: formIntakeAssessment.currentlyUsingOpioids,
    otherPreviouslyTriedActionText:
      formIntakeAssessment.otherPreviouslyTriedActionText,
    actionsOpenToTry: formIntakeAssessment.actionsOpenToTry,
    previouslyTriedActions: formIntakeAssessment.previouslyTriedActions,
    answers: formIntakeAssessment.answers,
    hardstop: formIntakeAssessment.hardstop,
    botheredBodyPart: formIntakeAssessment.botheredBodyPart.id,
    recommendedAction:
      formIntakeAssessment.recommendedAction?.action.code ?? '',
  }

  if (formIntakeAssessment.bodyRegionToNote.length) {
    mskIntakeAssessment.bodyRegionToNote = formIntakeAssessment.bodyRegionToNote
  }

  return mskIntakeAssessment
}

type MSKFormStatePCPAdditionalQuestion =
  | 'pcpName'
  | 'pcpPhone'
  | 'pcpFax'
  | 'pcpGroupName'
  | 'pcpAddressLine1'
  | 'pcpAddressLine2'
  | 'pcpCity'
  | 'pcpState'
  | 'pcpZip'

type MSKFormStateMainAdditionalQuestions = Omit<
  MSKFormState['additionalQuestions'],
  MSKFormStatePCPAdditionalQuestion | 'additionalPhone' | 'confirmed'
> & {
  peopleAllowedToReachOutToWho: string
  peopleAllowedToReachOutToPhone: string
}

type MapToMSKFormStateMainAdditionalQuestions = (
  additionalQuestions: MSKFormState['additionalQuestions']
) => MSKFormStateMainAdditionalQuestions

export const mapToMSKFormStateMainAdditionalQuestions: MapToMSKFormStateMainAdditionalQuestions =
  (additionalQuestions) => {
    return {
      barriersToBeAwareOf: additionalQuestions.barriersToBeAwareOf,
      barriersToBeAwareOfText: additionalQuestions.barriersToBeAwareOfText,
      bhAction1: additionalQuestions.bhAction1,
      bhAction2: additionalQuestions.bhAction2,
      fallScreenQ1: additionalQuestions.fallScreenQ1,
      fallScreenQ2: additionalQuestions.fallScreenQ2,
      fallScreenResult: additionalQuestions.fallScreenResult,
      gad2Assessment: additionalQuestions.gad2Assessment,
      gad2Q1: additionalQuestions.gad2Q1,
      gad2Q2: additionalQuestions.gad2Q2,
      gad2Score: additionalQuestions.gad2Score,
      peopleAllowedToReachOutToWho:
        additionalQuestions.peopleAllowedToReachOutToWho,
      peopleAllowedToReachOutToPhone:
        additionalQuestions.peopleAllowedToReachOutToPhone,
      phAssessment: additionalQuestions.phAssessment,
      phQ1: additionalQuestions.phQ1,
      phQ2: additionalQuestions.phQ2,
      phScore: additionalQuestions.phScore,
      tabletComputerOrPhone: additionalQuestions.tabletComputerOrPhone,
    }
  }

type MapToMSKAdditionalQuestions = (
  formStateAdditionalQuestions: MSKFormStateMainAdditionalQuestions
) => MSKAdditionalQuestions | undefined

export const mapToMSKAdditionalQuestions: MapToMSKAdditionalQuestions = (
  formStateAdditionalQuestions
) => {
  const mskAdditionalQuestions: MSKAdditionalQuestions = {}

  const v1OptionQuestions = ['phQ1', 'phQ2', 'gad2Q1', 'gad2Q2']

  v1OptionQuestions.forEach((questionId) => {
    const key = questionId as keyof Pick<
      MSKAdditionalQuestions,
      'phQ1' | 'phQ2' | 'gad2Q1' | 'gad2Q2'
    >
    const value = formStateAdditionalQuestions[key]
    if (value !== 'Select an option') {
      mskAdditionalQuestions[key] = value
    }
  })

  if (formStateAdditionalQuestions.phScore) {
    mskAdditionalQuestions.phScore = formStateAdditionalQuestions.phScore
  }

  if (formStateAdditionalQuestions.phAssessment) {
    mskAdditionalQuestions.phAssessment =
      formStateAdditionalQuestions.phAssessment
  }

  if (formStateAdditionalQuestions.gad2Score) {
    mskAdditionalQuestions.gad2Score = formStateAdditionalQuestions.gad2Score
  }

  if (formStateAdditionalQuestions.gad2Assessment) {
    mskAdditionalQuestions.gad2Assessment =
      formStateAdditionalQuestions.gad2Assessment
  }

  if (
    (formStateAdditionalQuestions.phAssessment !== 'N/A' ||
      formStateAdditionalQuestions.gad2Assessment !== 'N/A') &&
    formStateAdditionalQuestions.bhAction1
  ) {
    mskAdditionalQuestions.bhAction1 = formStateAdditionalQuestions.bhAction1
  }

  if (formStateAdditionalQuestions.bhAction2 !== 'Select an option') {
    mskAdditionalQuestions.bhAction2 = formStateAdditionalQuestions.bhAction2
  }

  const v2OptionQuestions = [
    'fallScreenQ1',
    'fallScreenQ2',
    'tabletComputerOrPhone',
  ]

  v2OptionQuestions.forEach((questionId) => {
    const key = questionId as keyof Pick<
      MSKAdditionalQuestions,
      'fallScreenQ1' | 'fallScreenQ2' | 'tabletComputerOrPhone'
    >
    const value = formStateAdditionalQuestions[key]
    if (value !== 'Select an option') {
      mskAdditionalQuestions[key] = value
    }
  })

  if (formStateAdditionalQuestions.fallScreenResult) {
    mskAdditionalQuestions.fallScreenResult =
      formStateAdditionalQuestions.fallScreenResult
  }

  if (formStateAdditionalQuestions.barriersToBeAwareOf !== 'Select an option') {
    mskAdditionalQuestions.barriersToBeAwareOf =
      formStateAdditionalQuestions.barriersToBeAwareOf
  }

  if (formStateAdditionalQuestions.barriersToBeAwareOfText) {
    mskAdditionalQuestions.barriersToBeAwareOfText =
      formStateAdditionalQuestions.barriersToBeAwareOfText
  }

  const peopleAllowedToReachOutTo: string[] = []
  if (formStateAdditionalQuestions.peopleAllowedToReachOutToWho) {
    peopleAllowedToReachOutTo.push(
      formStateAdditionalQuestions.peopleAllowedToReachOutToWho
    )
  }
  if (formStateAdditionalQuestions.peopleAllowedToReachOutToPhone) {
    peopleAllowedToReachOutTo.push(
      formStateAdditionalQuestions.peopleAllowedToReachOutToPhone
    )
  }
  if (peopleAllowedToReachOutTo.length) {
    mskAdditionalQuestions.peopleAllowedToReachOutTo =
      peopleAllowedToReachOutTo.join(' - ')
  }

  if (Object.keys(mskAdditionalQuestions).length) {
    return mskAdditionalQuestions
  }
}

type MapToProcessMSKFormArgs = (formState: MSKFormState) => ProcessMSKFormArgs

export const mapToProcessMSKFormArgs: MapToProcessMSKFormArgs = (formState) => {
  const height =
    formState.profileHeightFt || formState.profileHeightIn
      ? getHeightInInches(
          Number(formState.profileHeightFt),
          Number(formState.profileHeightIn)
        )
      : '0'

  const weight = formState.profileWeight || '0'

  return {
    patientId: formState.mskFormPatientId,
    intakeAssessments: formState.intakeAssessments.map(
      mapToMSKIntakeAssessment
    ),
    profile: {
      patientId: formState.mskFormPatientId,
      height,
      weight,
    },
    additionalQuestions: mapToMSKAdditionalQuestions(
      mapToMSKFormStateMainAdditionalQuestions(formState.additionalQuestions)
    ),
  }
}

type MSKFormStateSecondaryAdditionalQuestions = Pick<
  MSKFormState['additionalQuestions'],
  MSKFormStatePCPAdditionalQuestion | 'additionalPhone'
>

type MapToMSKFormStateSecondaryAdditionalQuestions = (
  additionalQuestions: MSKFormState['additionalQuestions']
) => MSKFormStateSecondaryAdditionalQuestions

export const mapToMSKFormStateSecondaryAdditionalQuestions: MapToMSKFormStateSecondaryAdditionalQuestions =
  (additionalQuestions) => {
    return {
      pcpName: additionalQuestions.pcpName,
      pcpPhone: additionalQuestions.pcpPhone,
      pcpFax: additionalQuestions.pcpFax,
      pcpGroupName: additionalQuestions.pcpGroupName,
      pcpAddressLine1: additionalQuestions.pcpAddressLine1,
      pcpAddressLine2: additionalQuestions.pcpAddressLine2,
      pcpCity: additionalQuestions.pcpCity,
      pcpState: additionalQuestions.pcpState,
      pcpZip: additionalQuestions.pcpZip,
      additionalPhone: additionalQuestions.additionalPhone,
    }
  }

type MapMSKToUpdatePatientPayload = (
  patient: Patient,
  formState: MSKFormState
) => UpdatePatientPayload

export const mapMSKToUpdatePatientPayload: MapMSKToUpdatePatientPayload = (
  patient,
  formState
) => {
  const formStateAdditionalQuestions =
    mapToMSKFormStateSecondaryAdditionalQuestions(formState.additionalQuestions)

  const updatePatientPayload: UpdatePatientPayload = {}

  const currentContacts = getPatientCurrentContacts(patient)

  const contacts: UpdatePatientPayload['contacts'] = []
  const { phone2: currentPhone2 } = getPatientCurrentContacts(patient)

  if (
    formStateAdditionalQuestions.additionalPhone &&
    formStateAdditionalQuestions.additionalPhone !== currentPhone2?.phone
  ) {
    const phone2: PatientContact = {
      patientId: patient.patientId,
      phone: formStateAdditionalQuestions.additionalPhone,
    }
    if (currentContacts.phone2?.id) {
      phone2.id = currentContacts.phone2.id
    }
    contacts.push(phone2)
  }

  if (contacts.length) {
    updatePatientPayload.contacts = contacts
  }

  const medicalProfile: UpdatePatientPayload['medicalProfile'] = {}

  if (
    formStateAdditionalQuestions.pcpName &&
    formStateAdditionalQuestions.pcpName !==
      patient.medicalProfile?.primaryCarePhysician
  ) {
    medicalProfile.primaryCarePhysician = formStateAdditionalQuestions.pcpName
  }

  if (
    formStateAdditionalQuestions.pcpPhone &&
    formStateAdditionalQuestions.pcpPhone !== patient.medicalProfile?.phone
  ) {
    medicalProfile.phone = formStateAdditionalQuestions.pcpPhone
  }

  if (
    formStateAdditionalQuestions.pcpFax &&
    formStateAdditionalQuestions.pcpFax !== patient.medicalProfile?.fax
  ) {
    medicalProfile.fax = formStateAdditionalQuestions.pcpFax
  }

  if (
    formStateAdditionalQuestions.pcpGroupName &&
    formStateAdditionalQuestions.pcpGroupName !==
      patient.medicalProfile?.groupName
  ) {
    medicalProfile.groupName = formStateAdditionalQuestions.pcpGroupName
  }

  if (
    formStateAdditionalQuestions.pcpAddressLine1 &&
    formStateAdditionalQuestions.pcpAddressLine1 !==
      patient.medicalProfile?.addressLine1
  ) {
    medicalProfile.addressLine1 = formStateAdditionalQuestions.pcpAddressLine1
  }

  if (
    formStateAdditionalQuestions.pcpAddressLine2 &&
    formStateAdditionalQuestions.pcpAddressLine2 !==
      patient.medicalProfile?.addressLine2
  ) {
    medicalProfile.addressLine2 = formStateAdditionalQuestions.pcpAddressLine2
  }

  if (
    formStateAdditionalQuestions.pcpCity &&
    formStateAdditionalQuestions.pcpCity !== patient.medicalProfile?.city
  ) {
    medicalProfile.city = formStateAdditionalQuestions.pcpCity
  }

  if (
    formStateAdditionalQuestions.pcpState &&
    formStateAdditionalQuestions.pcpState !== patient.medicalProfile?.state
  ) {
    medicalProfile.state = formStateAdditionalQuestions.pcpState
  }

  if (
    formStateAdditionalQuestions.pcpZip &&
    formStateAdditionalQuestions.pcpZip !== patient.medicalProfile?.zip
  ) {
    medicalProfile.zip = formStateAdditionalQuestions.pcpZip
  }

  if (Object.keys(medicalProfile).length) {
    if (patient.medicalProfile) {
      medicalProfile.id = patient.medicalProfile.id
      medicalProfile.payor = patient.medicalProfile.payor
    }
    updatePatientPayload.medicalProfile = medicalProfile
  }

  return updatePatientPayload
}
