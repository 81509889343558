import {
  CreatePathwayArgs,
  CreatePathwayError,
  CreatePathwayReturns,
  GetPathwaysError,
  GetPathwaysReturns,
  Pathway,
  ReassignPathwayArgs,
  ReassignPathwayError,
  ReassignPathwayReturns,
  UpdatePathwayArgs,
  UpdatePathwayError,
  UpdatePathwayPayload,
  UpdatePathwayReturns,
} from 'src/features/pathways/domain'
import { Patient } from 'src/features/patients/domain'
import {
  CreatePathwayServiceErrorResponse,
  CreatePathwayServicePayload,
  CreatePathwayServiceResponse,
  GetPathwaysServiceErrorResponse,
  GetPathwaysServiceResponse,
  ReassignPathwayServiceErrorResponse,
  ReassignPathwayServicePayload,
  ReassignPathwayServiceResponse,
  UpdatePathwayServiceArgs,
  UpdatePathwayServiceErrorResponse,
  UpdatePathwayServicePayload,
  UpdatePathwayServiceResponse,
} from 'src/features/pathways/infrastructure'
import { PatientPathwayFormFields } from 'src/features/pathways/presentation'
import {
  formatDate,
  pathwayDateFieldIsRequired,
} from 'src/features/shared/utils'
import { SelectOption } from 'src/features/shared/types'

type MapToGetPathwaysReturns = (
  response: GetPathwaysServiceResponse
) => GetPathwaysReturns

export const mapToGetPathwaysReturns: MapToGetPathwaysReturns = (response) => {
  return {
    pathways: response.definitions,
    pathwayTypes: response.pathwayTypes,
  }
}

type MapToGetPathwaysError = (
  response: GetPathwaysServiceErrorResponse
) => GetPathwaysError

export const mapToGetPathwaysError: MapToGetPathwaysError = (response) => {
  return {
    message: response.message,
  }
}

type MapToCreatePathwayServicePayload = (
  args: CreatePathwayArgs
) => CreatePathwayServicePayload

export const mapToCreatePathwayServicePayload: MapToCreatePathwayServicePayload =
  (args) => {
    return {
      ...args,
    }
  }

type MapToCreatePathwayReturns = (
  response: CreatePathwayServiceResponse
) => CreatePathwayReturns

export const mapToCreatePathwayReturns: MapToCreatePathwayReturns = (
  response
) => {
  return {
    ...response,
  }
}

type MapToCreatePathwayError = (
  response: CreatePathwayServiceErrorResponse
) => CreatePathwayError

export const mapToCreatePathwayError: MapToCreatePathwayError = (response) => {
  return {
    ...response,
  }
}

type MapToReassignPathwayServicePayload = (
  args: ReassignPathwayArgs
) => ReassignPathwayServicePayload

export const mapToReassignPathwayServicePayload: MapToReassignPathwayServicePayload =
  (args) => {
    return {
      ...args,
    }
  }

type MapToReassignPathwayReturns = (
  response: ReassignPathwayServiceResponse
) => ReassignPathwayReturns

export const mapToReassignPathwayReturns: MapToReassignPathwayReturns = (
  response
) => {
  return {
    ...response,
  }
}

type MapToReassignPathwayError = (
  response: ReassignPathwayServiceErrorResponse
) => ReassignPathwayError

export const mapToReassignPathwayError: MapToReassignPathwayError = (
  response
) => {
  return {
    ...response,
  }
}

type MapToUpdatePathwayServicePayload = (
  payload: UpdatePathwayPayload
) => UpdatePathwayServicePayload

export const mapToUpdatePathwayServicePayload: MapToUpdatePathwayServicePayload =
  (payload) => {
    return {
      ...payload,
    }
  }

type MapToUpdatePathwayServiceArgs = (
  args: UpdatePathwayArgs
) => UpdatePathwayServiceArgs

export const mapToUpdatePathwayServiceArgs: MapToUpdatePathwayServiceArgs = (
  args
) => {
  return {
    pathwayId: args.pathwayId,
    payload: mapToUpdatePathwayServicePayload(args.payload),
  }
}

type MapToUpdatePathwayReturns = (
  response: UpdatePathwayServiceResponse
) => UpdatePathwayReturns

export const mapToUpdatePathwayReturns: MapToUpdatePathwayReturns = (
  response
) => {
  return {
    ...response,
  }
}

type MapToUpdatePathwayError = (
  response: UpdatePathwayServiceErrorResponse
) => UpdatePathwayError

export const mapToUpdatePathwayError: MapToUpdatePathwayError = (response) => {
  return {
    ...response,
  }
}

type MapToCreatePathwayArgs = (
  pathway: Pathway,
  patient: Patient,
  formValues: PatientPathwayFormFields
) => CreatePathwayArgs

export const mapToCreatePathwayArgs: MapToCreatePathwayArgs = (
  pathway,
  patient,
  formValues
) => {
  const createPathwayArgs: CreatePathwayArgs = {
    patientId: patient.patientId,
    navigatorId: formValues.navigatorId,
    pathwaySequenceIdentifier: pathway.id,
    pathwaySequenceCode: pathway.code,
    pathway: pathway.pathway,
    pathwayType: formValues.pathwayType,
  }
  if (pathwayDateFieldIsRequired(pathway.track) && formValues.commsTrigger) {
    createPathwayArgs.lockDate = formatDate(formValues.commsTrigger)
  }
  return createPathwayArgs
}

type MapToReassignPathwayArgs = (
  pathway: Pathway,
  patientId: string,
  assignedPathwayId: string,
  formValues: PatientPathwayFormFields
) => ReassignPathwayArgs

export const mapToReassignPathwayArgs: MapToReassignPathwayArgs = (
  pathway,
  patientId,
  assignedPathwayId,
  formValues
) => {
  const reassignPathwayArgs: ReassignPathwayArgs = {
    patientId: patientId,
    previousAssignedPathwayId: assignedPathwayId,
    navigatorId: formValues.navigatorId,
    pathwaySequenceIdentifier: pathway.id,
    pathwaySequenceCode: pathway.code,
    pathway: pathway.pathway,
    pathwayType: formValues.pathwayType,
  }
  if (pathwayDateFieldIsRequired(pathway.track) && formValues.commsTrigger) {
    reassignPathwayArgs.lockDate = formatDate(formValues.commsTrigger)
  }
  return reassignPathwayArgs
}

type MapToUpdatePathwayPayload = (
  patientId: string,
  pathway: Pathway,
  formValues: PatientPathwayFormFields
) => UpdatePathwayPayload

export const mapToUpdatePathwayPayload: MapToUpdatePathwayPayload = (
  patientId: string,
  pathway,
  formValues
) => {
  const updatePathwayPayload: UpdatePathwayPayload = {
    patientId: patientId,
    navigatorId: formValues.navigatorId,
    pathway: pathway.pathway,
    pathwayType: formValues.pathwayType,
  }

  if (pathwayDateFieldIsRequired(pathway.track) && formValues.commsTrigger) {
    updatePathwayPayload.lockDate = formatDate(formValues.commsTrigger)
  }
  return updatePathwayPayload
}

export const orthoTrackSplitter = (orthoTrack: string) => {
  const trackSplit = orthoTrack.split(' - ')
  if (trackSplit.length < 2) {
    throw new Error('Ortho Track is not in the correct format')
  }
  return {
    trackType: trackSplit[0],
    track: trackSplit[1],
  }
}

export const orthoTrackJoiner = (trackType: string, track: string) => {
  return `${trackType} - ${track}`
}

type MapToAllTracks = (pathways: Pathway[]) => SelectOption[]

export const mapToAllTracks: MapToAllTracks = (pathways) =>
  Array.from(
    new Set(
      pathways.map((pathwayItem) => {
        const label =
          pathwayItem.pathway === 'Ortho'
            ? orthoTrackSplitter(pathwayItem.track).track
            : pathwayItem.track

        return {
          key: pathwayItem.code,
          value: pathwayItem.code,
          label,
        }
      })
    )
  )

type MapToTracksByPathway = (
  pathways: Pathway[],
  filterBy: string
) => SelectOption[]

export const mapToTracksByPathway: MapToTracksByPathway = (
  pathways: Pathway[],
  filterBy
) =>
  Array.from(
    new Set(
      pathways
        ?.filter((pathwayItem) => pathwayItem.pathway === filterBy)
        .map((pathwayItem) => ({
          key: pathwayItem.code,
          value: pathwayItem.code,
          label: pathwayItem.track,
        }))
    )
  )

type MapToOrthoTrackTypes = (pathways: Pathway[]) => string[]

export const mapToOrthoTrackTypes: MapToOrthoTrackTypes = (pathways) => {
  return Array.from(
    new Set(
      mapToTracksByPathway(pathways, 'Ortho').map(
        (track) => orthoTrackSplitter(track.label).trackType
      )
    )
  )
}

type MapToOrthoTracks = (
  pathways: Pathway[],
  trackType: string
) => SelectOption[]

export const mapToOrthoTracks: MapToOrthoTracks = (pathways, trackType) =>
  Array.from(
    new Set(
      mapToTracksByPathway(pathways, 'Ortho')
        .filter(
          (track) => orthoTrackSplitter(track.label).trackType === trackType
        )
        .map((track) => ({
          key: track.key,
          value: track.value,
          label: orthoTrackSplitter(track.label).track,
        }))
    )
  )
