import {
  LocalizationProvider,
  DatePicker,
  DatePickerProps,
} from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

type TCDatePickerProps = DatePickerProps<Date>

export const TCDatePicker: FC<TCDatePickerProps> = (props) => {
  const { slotProps, ...restProps } = props
  const { textField: textFieldSlotProps } = slotProps || {}
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        views={['year', 'month', 'day']}
        format={'MM/dd/yyyy'}
        slotProps={{
          textField: {
            size: 'small',
            ...textFieldSlotProps,
          },
        }}
        {...restProps}
      />
    </LocalizationProvider>
  )
}

type ControlledDatePickerProps = {
  name: string
  datePickerProps?: TCDatePickerProps
}

export const ControlledDatePicker: FC<ControlledDatePickerProps> = ({
  name,
  datePickerProps,
}) => {
  const formMethods = useFormContext()
  const { slotProps, ...restDatePickerProps } = datePickerProps || {}
  const { textField: textFieldSlotProps } = slotProps || {}
  return (
    <Controller
      name={name}
      control={formMethods.control}
      render={({ field, fieldState }) => (
        <TCDatePicker
          value={field.value ? field.value : null}
          onChange={field.onChange}
          slotProps={{
            textField: {
              onBlur: field.onBlur,
              error: !!fieldState.error,
              helperText: fieldState.error ? fieldState.error.message : '',
              ...textFieldSlotProps,
            },
          }}
          {...restDatePickerProps}
        />
      )}
    />
  )
}
