import { useInfiniteQuery } from '@tanstack/react-query'

import { useTasksUseCases } from 'src/features/tasks/tasks.service-locator'
import * as settings from 'src/config/settings'

export const GET_TODAY_TASKS_BY_USER_ID_INFINITE_QUERY_KEY =
  'getTodayTasksByUserIdInfinite'

export const useTodayTasksByUserIdInfiniteQuery = () => {
  const tasksUseCases = useTasksUseCases()

  const {
    data,
    refetch,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isError,
    isFetching,
    isFetchingNextPage,
    isRefetching,
  } = useInfiniteQuery({
    queryKey: [GET_TODAY_TASKS_BY_USER_ID_INFINITE_QUERY_KEY],
    queryFn: ({ pageParam = undefined }) => {
      return tasksUseCases.getTodayTasksByUserId({
        params: {
          limit: settings.TODAY_TASKS_PER_LOAD,
          lastTaskId: pageParam?.lastTaskId,
          lastTaskDate: pageParam?.lastTaskDate,
        },
      })
    },
    getNextPageParam: (lastPage) => {
      if (!lastPage.lastTaskId || !lastPage.lastTaskDate) return undefined
      return {
        lastTaskId: lastPage.lastTaskId,
        lastTaskDate: lastPage.lastTaskDate,
      }
    },
  })
  return {
    todayTasksData: data,
    refetchTodayTasks: refetch,
    fetchNextPageTodayTasks: fetchNextPage,
    hasNextPageTodayTasks: hasNextPage,
    todayTasksIsLoading: isLoading,
    todayTasksIsError: isError,
    todayTasksIsFetching: isFetching,
    todayTasksIsFetchingNextPage: isFetchingNextPage,
    todayTasksIsRefetching: isRefetching,
  }
}

export const GET_URGENT_TASKS_BY_USER_ID_INFINITE_QUERY_KEY =
  'getUrgentTasksByUserIdInfinite'

export const useUrgentTasksByUserIdInfiniteQuery = () => {
  const tasksUseCases = useTasksUseCases()

  const {
    data,
    refetch,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isError,
    isFetching,
    isFetchingNextPage,
    isRefetching,
  } = useInfiniteQuery({
    queryKey: [GET_URGENT_TASKS_BY_USER_ID_INFINITE_QUERY_KEY],
    queryFn: ({ pageParam = undefined }) => {
      return tasksUseCases.getUrgentTasksByUserId({
        params: {
          limit: settings.URGENT_TASKS_PER_LOAD,
          lastTaskId: pageParam?.lastTaskId,
          lastTaskDate: pageParam?.lastTaskDate,
        },
      })
    },
    getNextPageParam: (lastPage) => {
      if (!lastPage.lastTaskId || !lastPage.lastTaskDate) return undefined
      return {
        lastTaskId: lastPage.lastTaskId,
        lastTaskDate: lastPage.lastTaskDate,
      }
    },
  })
  return {
    urgentTasksData: data,
    refetchUrgentTasks: refetch,
    fetchNextPageUrgentTasks: fetchNextPage,
    hasNextPageUrgentTasks: hasNextPage,
    urgentTasksIsLoading: isLoading,
    urgentTasksIsError: isError,
    urgentTasksIsFetching: isFetching,
    urgentTasksIsFetchingNextPage: isFetchingNextPage,
    urgentTasksIsRefetching: isRefetching,
  }
}

export const GET_OVERDUE_TASKS_BY_USER_ID_INFINITE_QUERY_KEY =
  'getOverdueTasksByUserIdInfinite'

export const useOverdueTasksByUserIdInfiniteQuery = () => {
  const tasksUseCases = useTasksUseCases()

  const {
    data,
    refetch,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isError,
    isFetching,
    isFetchingNextPage,
    isRefetching,
  } = useInfiniteQuery({
    queryKey: [GET_OVERDUE_TASKS_BY_USER_ID_INFINITE_QUERY_KEY],
    queryFn: ({ pageParam = undefined }) => {
      return tasksUseCases.getOverdueTasksByUserId({
        params: {
          limit: settings.OVERDUE_TASKS_PER_LOAD,
          lastTaskId: pageParam?.lastTaskId,
          lastTaskDate: pageParam?.lastTaskDate,
        },
      })
    },
    getNextPageParam: (lastPage) => {
      if (!lastPage.lastTaskId || !lastPage.lastTaskDate) return undefined
      return {
        lastTaskId: lastPage.lastTaskId,
        lastTaskDate: lastPage.lastTaskDate,
      }
    },
  })
  return {
    overdueTasksData: data,
    refetchOverdueTasks: refetch,
    fetchNextPageOverdueTasks: fetchNextPage,
    hasNextPageOverdueTasks: hasNextPage,
    overdueTasksIsLoading: isLoading,
    overdueTasksIsError: isError,
    overdueTasksIsFetching: isFetching,
    overdueTasksIsFetchingNextPage: isFetchingNextPage,
    overdueTasksIsRefetching: isRefetching,
  }
}
