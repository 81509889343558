import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from 'src/features/shared/infrastructure'
import { NoteType } from 'src/features/notes/domain'

export type NotesState = {
  selectedNoteType: NoteType
  noteText: string
}

const initialState: NotesState = {
  selectedNoteType: '',
  noteText: '',
}

const notesSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    setSelectedNoteTypeAction(
      state,
      action: PayloadAction<NotesState['selectedNoteType']>
    ) {
      state.selectedNoteType = action.payload
    },
    setNoteTextAction(state, action: PayloadAction<NotesState['noteText']>) {
      state.noteText = action.payload
    },
  },
})

export const { setSelectedNoteTypeAction, setNoteTextAction } =
  notesSlice.actions

export const selectedNoteTypeSelector = (state: RootState) =>
  state.notes.selectedNoteType

export const noteTextSelector = (state: RootState) => state.notes.noteText

export default notesSlice.reducer
