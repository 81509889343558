import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, Stack } from '@mui/material'
import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import {
  DischargePositionField,
  FormLayoutWithCancelSave,
  FormControlledNoteField,
  ProcedureTypeField,
  SDMField,
  SpokeWithField,
  StatusField,
  SurgerySiteField,
  SDMCompletedField,
  SDMPatientUnderstandsField,
} from 'src/features/shared/presentation/components/form-fields'
import { getPatientHasStage } from 'src/features/shared/utils'
import { useStatusDefinitions } from 'src/features/shared/presentation'
import {
  NoteFormProps,
  useCreatePatientPostOpTouchpointNote,
} from 'src/features/notes/presentation'
import {
  PostOpTouchpointAdditionalQuestionsForm,
  PostOpTouchpointQuestionsFormValues,
} from 'src/features/notes/presentation/components/additional-questions'
import { mapToCreatePatientPostOpTouchpointNoteArgs } from 'src/features/notes/adapters'
import { useUpdatePatientStatus } from 'src/features/patients/presentation'
import { useEffect } from 'react'
import { useNotesStore } from 'src/features/shared/infrastructure'
import { DevTool } from '@hookform/devtools'
import { mapToPatientUpdateStatus } from 'src/features/patients/adapters'

export type PostOpTouchpointNoteFormFields =
  PostOpTouchpointQuestionsFormValues & {
    note: string
    status: string
    spokeWith?: string
    sdm: boolean
    sdmText?: string
    painLevel?: string
    functionLevel?: string
    procedure?: string
    surgerySite?: string
    dischargePosition?: string
    communication: string
  }

type PostOpTouchpointNoteFormProps = NoteFormProps

export const PostOpTouchpointNoteForm = ({
  patient,
  setShowLoader,
}: PostOpTouchpointNoteFormProps) => {
  const patientHasStage = getPatientHasStage(patient)

  const { getAllStatusDefinitions } = useStatusDefinitions(patient)
  const { updatePatientStatusAsync } = useUpdatePatientStatus()

  const { createPatientPostOpTouchpointNoteAsync } =
    useCreatePatientPostOpTouchpointNote()
  const { noteText } = useNotesStore()

  const createPostOpTouchpointNoteFormSchema: yup.Schema<PostOpTouchpointNoteFormFields> =
    yup.object().shape(
      {
        note: yup.string().required('note is required'),
        sdm: yup.boolean().required(),
        sdmText: yup.string().when('sdm', {
          is: (checked: boolean) => !!checked,
          then: (schema) => schema.required('This field is required'),
        }),
        spokeWith: yup.string().required('This field is required'),
        status: yup.string().required('This field is required'),
        painLevel: yup.string().when('functionLevel', {
          is: (functionLevel: string) => functionLevel,
          then: (schema) => schema.required('Pain is required'),
        }),
        functionLevel: yup.string().when('painLevel', {
          is: (painLevel: string) => painLevel,
          then: (schema) => schema.required('Function is required'),
        }),
        procedure: yup.string().required('This field is required'),
        surgerySite: yup.string().required('This field is required'),
        dischargePosition: yup.string().required('This field is required'),
        communication: yup.string().required(),
        isPainControlled: yup.string().when('painLevel', {
          is: (painLevel: string) => Number(painLevel) > 7,
          then: (schema) => schema.required('This field is required'),
        }),
        accessToMedicineToControlPain: yup.string().when('isPainControlled', {
          is: (isPainControlled: string) => isPainControlled === 'No',
          then: (schema) => schema.required('This field is required'),
        }),
        participatingInPT: yup.string().required('This field is required'),
        physicalTherapyLocation: yup.string().when('participatingInPT', {
          is: (physicalTherapyLocation: string) =>
            physicalTherapyLocation === 'Yes',
          then: (schema) => schema.required('This field is required'),
        }),
        additionalQuestionsOrConcerns: yup.string(),
        sdmPatientUnderstandsCondition: yup.string().when('sdm', {
          is: (checked: string) => !!checked,
          then: (schema) => schema.required('This field is required'),
        }),
        sdmClinicalBelievesPatientUnderstandsCondition: yup
          .string()
          .when('sdm', {
            is: (checked: string) => !!checked,
            then: (schema) => schema.required('This field is required'),
          }),
      },
      [['painLevel', 'functionLevel']]
    )

  const formMethods = useForm<PostOpTouchpointNoteFormFields>({
    resolver: yupResolver(createPostOpTouchpointNoteFormSchema),
    context: { patientHasStage },

    defaultValues: {
      note: noteText,
      sdm: false,
      sdmText: '',
      spokeWith: '',
      status: '',
      painLevel: '',
      functionLevel: '',
      procedure: '',
      surgerySite: '',
      dischargePosition: '',
      communication: 'outbound',
      isPainControlled: '',
      accessToMedicineToControlPain: '',
      participatingInPT: '',
      physicalTherapyLocation: '',
      additionalQuestionsOrConcerns: '',
    },
  })

  useEffect(() => {
    const subscription = formMethods.watch((value, { name, type }) => {
      if (
        name === 'painLevel' &&
        type === 'change' &&
        value['isPainControlled']
      ) {
        if (Number(value['painLevel']) < 8) {
          formMethods.reset((formValues) => ({
            ...formValues,
            isPainControlled: '',
            accessToMedicineToControlPain: '',
          }))
        }
      }

      if (
        name === 'participatingInPT' &&
        type === 'change' &&
        value['physicalTherapyLocation']
      ) {
        formMethods.reset((formValues) => ({
          ...formValues,
          physicalTherapyLocation: '',
        }))
      }
    })

    return () => subscription.unsubscribe()
  }, [formMethods])

  const submitHandler: SubmitHandler<PostOpTouchpointNoteFormFields> = async (
    data
  ) => {
    setShowLoader?.(true)

    try {
      const createPatientPostOpTouchpointNoteArgs =
        mapToCreatePatientPostOpTouchpointNoteArgs(patient, data)

      if (
        patient.stageAndStatus &&
        patient.stageAndStatus.stage &&
        data.status
      ) {
        const createPatientUpdateStatusArgs = mapToPatientUpdateStatus(
          patient,
          data.status
        )
        await updatePatientStatusAsync(createPatientUpdateStatusArgs)

        await createPatientPostOpTouchpointNoteAsync(
          createPatientPostOpTouchpointNoteArgs
        )

        toast.success('Patient Note successfully created!')
        toast.success('Patient Status successfully updated!')
      } else {
        await createPatientPostOpTouchpointNoteAsync(
          createPatientPostOpTouchpointNoteArgs
        )
        toast.success('Patient Note successfully created!')
      }
    } catch (error) {
      toast.error('Failed creating patient note!')
      toast.error('Patient Status update failed!')
    }

    setShowLoader?.(false)
  }

  return (
    <FormLayoutWithCancelSave
      onSubmit={submitHandler}
      formMethods={formMethods}
    >
      <Grid container rowSpacing={2} pb={2}>
        <Grid item sm={12} pb={2}>
          <FormControlledNoteField />
        </Grid>
        <Grid item container columnSpacing={2} sm={12}>
          <Grid item sm={3}>
            <StatusField statusDefinitions={getAllStatusDefinitions()} />
          </Grid>
          <Grid item sm={2}>
            <SpokeWithField />
          </Grid>
        </Grid>

        <Grid container item sm={12} spacing={2}>
          <Grid item sm={4}>
            <ProcedureTypeField />
          </Grid>
          <Grid item sm={2.5}>
            <SurgerySiteField />
          </Grid>
          <Grid item sm={3}>
            <DischargePositionField />
          </Grid>
        </Grid>
        <Grid item container columnGap={2} sm={10}>
          <Box pt={1} width="100%">
            <SDMField />
          </Box>
          {formMethods.watch('sdm') ? (
            <Stack direction={'column'} rowGap={1} ml={10} py={2}>
              <SDMCompletedField />
              <SDMPatientUnderstandsField />
            </Stack>
          ) : null}
        </Grid>
      </Grid>

      <PostOpTouchpointAdditionalQuestionsForm />
      <DevTool control={formMethods.control} placement={'top-left'} />
    </FormLayoutWithCancelSave>
  )
}
