import {
  EligiblePatient,
  GetEligiblePatientsError,
  GetEligiblePatientsFilters,
  GetEligiblePatientsParams,
  GetEligiblePatientsReturns,
} from 'src/features/eligibility/domain'
import {
  GetEligiblePatientsServiceErrorResponse,
  GetEligiblePatientsServiceFilters,
  GetEligiblePatientsServiceParams,
  GetEligiblePatientsServiceResponse,
  SEligiblePatient,
} from 'src/features/eligibility/infrastructure'
import { EligiblePatientsTableRow } from 'src/features/eligibility/presentation'
import { formatDate } from 'src/features/shared/utils'

type MapToEligiblePatient = (patient: SEligiblePatient) => EligiblePatient

export const mapToEligiblePatient: MapToEligiblePatient = (patient) => {
  return {
    ...patient,
    doNotCall: patient.doNotCall === 'Y' ? true : false,
    count: patient.count ? Number(patient.count) : 0,
    dob: formatDate(patient.dob, true),
  }
}

type MapToGetEligiblePatientsServiceParams = (
  params?: GetEligiblePatientsParams
) => GetEligiblePatientsServiceParams | undefined

export const mapToGetEligiblePatientsServiceParams: MapToGetEligiblePatientsServiceParams =
  (params) => {
    if (!params) return undefined
    return {
      limit: params?.limit,
      page: params?.page,
      q: params?.q,
    }
  }

type MapToGetEligiblePatientsServiceFilters = (
  filters?: GetEligiblePatientsFilters
) => GetEligiblePatientsServiceFilters | undefined

export const mapToGetEligiblePatientsServiceFilters: MapToGetEligiblePatientsServiceFilters =
  (filters) => {
    if (!filters) return undefined
    return {
      mbi: filters?.mbi,
    }
  }

type MapToGetEligiblePatientsReturns = (
  response: GetEligiblePatientsServiceResponse
) => GetEligiblePatientsReturns

export const mapToGetEligiblePatientsReturns: MapToGetEligiblePatientsReturns =
  (response) => {
    return {
      patients: response.eligibility.map(mapToEligiblePatient),
      totalCount: Number(response.count),
    }
  }

type MapToEligiblePatientsTableRow = (
  patient: EligiblePatient
) => EligiblePatientsTableRow

export const mapToEligiblePatientsTableRow: MapToEligiblePatientsTableRow = (
  patient
) => {
  const dob = patient.dob ? patient.dob : '-'
  const mbi = patient.mbi ? patient.mbi : '-'
  const zip = patient.zip ? patient.zip : '-'
  const primaryPhone = patient.primaryPhone ? patient.primaryPhone : '-'

  const nameArray: string[] = []
  const validNameAttrs: (keyof EligiblePatient)[] = [
    'firstName',
    'middleName',
    'lastName',
  ]
  validNameAttrs.forEach((key: keyof EligiblePatient) => {
    if (patient[key] && typeof patient[key] === 'string')
      nameArray.push(patient[key] as string)
  })

  return {
    name: nameArray.join(' '),
    doNotCall: !!patient.doNotCall,
    dob,
    mbi,
    zip,
    primaryPhone,
  }
}

type MapToGetEligiblePatientsError = (
  error: GetEligiblePatientsServiceErrorResponse
) => GetEligiblePatientsError

export const mapToGetEligiblePatientsError: MapToGetEligiblePatientsError = (
  error
) => {
  return {
    message: error.message,
  }
}
