import { GetPatientServiceResponse } from 'src/features/patients/infrastructure'

const patientWithLockedPathway = {
  patientId: 'fb3479bc-b59a-4c45-9cd0-742bcb508820',
  firstName: 'Jennifer',
  middleName: null,
  lastName: 'Lipson',
  dob: '11/09/2043',
  mbi: '3VN2T78PV12',
  doNotCall: false,
  referralSource: 'PCP - Iora',
  // eligible: true,
  dualCoverage: false,
  notes: null,
  contacts: [
    {
      id: '5bf72633-3b25-4dde-8bb3-1e7c8408243d',
      email: null,
      phone: '4542620523  ',
    },
    {
      id: '0ed06dac-05ed-454d-ab33-f7c224450472',
      email: 'dmiddlewickr9@is.gd',
      phone: null,
    },
  ],
  addressLine1: '081 Quincy Center',
  addressLine2: 'Suite 86',
  city: 'Santa Fe',
  state: 'NM',
  zip: '36880',
  addressId: '7266c220-c263-4d8e-9862-63bd3e7e510a',
  lockDate: '06/17/2023',
  pathwayId: '19983631-218e-449c-b60d-4a19cacc9cf6',
  assignedPathwayId: '1c9361dc-b0a2-49ea-b502-9c091ccccdbc',
  assignedUserId: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
  pathwayType: 'Traditional',
  navigator: 'E2E Automation ',
  pathway: 'PT',
  track: 'In-Person Appt. date unknown',
  type: 'phone',
  pathwaycode: 'PT02P',
  stageAndStatus: {
    // id: '84bf2a99-da73-4a5a-93f2-41f657149910',
    stage: 'Engaged',
    status: 'On Pathway',
  },
  medicalProfile: {
    id: '875806e4-53d4-4636-a1bd-7b2a4440019b',
    patientId: 'fb3479bc-b59a-4c45-9cd0-742bcb508820',
    planId: 'H01679-953-043',
    primaryCarePhysician: 'Diarmid Middlewick',
    primary: true,
    createdAt: '2023-06-17T02:19:12.285005+00:00',
    lastUpdatedAt: '2023-06-17T02:19:12.285005+00:00',
    payor: 'Humana',
    groupName: 'PCP Group Name 77',
    phone: null,
    fax: null,
    addressLine1: '1439 Arizona Drive',
    addressLine2: '4th Floor',
    city: 'Indianapolis',
    state: 'IN',
    zip: '30645',
    county: null,
  },
  patientViewersHistory: [
    {
      id: 'bd2e089b-c489-4ad9-bb7a-7149c7108f9f',
      viewedBy: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      viewedAt: '2023-06-17T19:56:29.406Z',
      name: 'E2E Automation ',
      email: 'e2e@tailorcare.com',
    },
  ],
}

const patientWithoutLockedPathway = {
  patientId: '4824136b-e74d-4224-9595-4e43f60b8eaa',
  firstName: 'Wash',
  middleName: null,
  lastName: 'Arbon',
  dob: '06/08/2042',
  mbi: '2UG3F08CG85',
  doNotCall: false,
  referralSource: 'PCP - Other',
  eligible: true,
  dualCoverage: false,
  language: null,
  notes: [
    {
      id: '10b93e75-3c5d-4d88-ab4d-2c6b605098bb',
      author: 'c6ba367d-e893-4ac6-883f-8732d03f9950',
      createdAt: '2023-07-28T18:47:25.618015+00:00',
      note: '<p>ru</p>',
      spokeWith: null,
      sdm: false,
    },
    {
      id: 'f795dd93-983d-4ba1-baa3-68f46b533902',
      author: 'c6ba367d-e893-4ac6-883f-8732d03f9950',
      createdAt: '2023-07-28T18:47:00.660028+00:00',
      note: '<p>ru</p>',
      spokeWith: null,
      sdm: false,
    },
    {
      id: '941a7d97-8cdb-4e37-b30c-f5e3f08d101e',
      author: 'c6ba367d-e893-4ac6-883f-8732d03f9950',
      createdAt: '2023-07-28T18:45:53.385587+00:00',
      note: '<p>ru</p>',
      spokeWith: null,
      sdm: false,
    },
    {
      id: 'f0198dd6-23c3-4e61-b964-ca42d9b88963',
      author: 'c6ba367d-e893-4ac6-883f-8732d03f9950',
      createdAt: '2023-07-28T18:43:12.949131+00:00',
      note: '<p>RU</p>',
      spokeWith: null,
      sdm: false,
    },
    {
      id: 'efa1a157-c646-41bc-89f4-569cb26d7649',
      author: 'c6ba367d-e893-4ac6-883f-8732d03f9950',
      createdAt: '2023-07-28T18:39:16.431592+00:00',
      note: '<p>RU</p>',
      spokeWith: null,
      sdm: false,
    },
    {
      id: '2e123181-5b8f-4e20-b93b-d298c87901e9',
      author: 'c6ba367d-e893-4ac6-883f-8732d03f9950',
      createdAt: '2023-07-18T23:12:48.030999+00:00',
      note: '<p>note</p>',
      spokeWith: null,
      sdm: false,
    },
    {
      id: '9ef202c8-5383-49e4-9663-97a6b5a8505e',
      author: 'c6ba367d-e893-4ac6-883f-8732d03f9950',
      createdAt: '2023-07-18T19:54:49.873414+00:00',
      note: '<p>note</p>',
      spokeWith: null,
      sdm: false,
    },
    {
      id: 'd68f9dcf-7b09-4f3a-8760-844adffa829b',
      author: 'c6ba367d-e893-4ac6-883f-8732d03f9950',
      createdAt: '2023-07-18T19:53:33.201511+00:00',
      note: '<p>note</p>',
      spokeWith: null,
      sdm: false,
    },
  ],
  contacts: [
    {
      id: 'acb83489-97ac-44ae-a3f5-7a0e4cb01363',
      email: null,
      phone: '5602051567  ',
    },
    {
      id: '1e93d7c6-862c-4e68-93fd-9b9b169df786',
      email: 'nstanlickfm@pinterest.com',
      phone: null,
    },
  ],
  addressLine1: '6858 Autumn Leaf Crossing',
  addressLine2: null,
  city: 'Saint Petersburg',
  state: 'FL',
  zip: '81411',
  addressId: 'f6a5987e-57bd-4bbe-9b53-2f758859693a',
  lockDate: null,
  pathwayId: null,
  assignedPathwayId: null,
  assignedUserId: null,
  pathwayType: null,
  navigator: null,
  pathway: null,
  track: null,
  type: null,
  pathwaycode: null,
  stageAndStatus: {
    id: '1907174f-adc0-4b6e-a8e4-4e4514b59611',
    stage: 'Pending Contact',
    status: 'Not Attempted',
  },
  medicalProfile: {
    id: '671b8652-95ec-4253-a068-cf7864844c1f',
    patientId: '4824136b-e74d-4224-9595-4e43f60b8eaa',
    planId: 'H63386-598-083',
    primaryCarePhysician: null,
    primary: true,
    createdAt: '2023-06-07T13:20:17.567799+00:00',
    lastUpdatedAt: '2023-06-07T13:20:17.567799+00:00',
    payor: 'Humana',
    groupName: null,
    phone: null,
    fax: null,
    addressLine1: null,
    addressLine2: null,
    city: null,
    state: null,
    zip: null,
    county: null,
  },
  patientViewersHistory: [
    {
      id: '80fb6089-f57a-460e-a064-c94426fa8f86',
      viewedBy: '4196eaa9-c29f-41f9-9c94-596643bf73ed',
      viewedAt: '2023-08-08T01:36:08.682Z',
      name: 'E2E Automation ',
      email: 'e2e@tailorcare.com',
    },
    {
      id: 'a8f72746-6788-4e48-94a4-264b1817248b',
      viewedBy: '5aef0dd5-1eb1-492f-928d-2e37c82facfb',
      viewedAt: '2023-08-08T01:17:39.021Z',
      name: 'David Grandados',
      email: 'dgranados@tailorcare.com',
    },
  ],
}

export const getPatientWithLockedPathwayServiceResponseMock: GetPatientServiceResponse =
  patientWithLockedPathway

export const getPatientWithoutLockedPathwayServiceResponseMockEmpty: GetPatientServiceResponse =
  patientWithoutLockedPathway
