import {
  Box,
  Divider,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import { FC } from 'react'

import { LoadMSKFormReturns } from 'src/features/msk/domain'
import {
  MSKFormStateAdditionalQuestionsFieldOptions,
  useMSKFormStore,
} from 'src/features/shared/infrastructure'
import { VanillaFormFieldContainer } from 'src/features/shared/presentation'

import {
  QUESTIONS_LABELS,
  fieldOptions,
  getSelectDefaultSx,
  getTextFieldDefaultSx,
} from './helpers'

type MSKFormStepThreePage2Props = {
  loadedMSKForm: LoadMSKFormReturns
}

export const MSKFormStepThreePage2: FC<MSKFormStepThreePage2Props> = ({
  loadedMSKForm,
}) => {
  const { additionalQuestions, setAdditionalQuestions, errors } =
    useMSKFormStore({
      loadedMSKForm,
    })

  const handleFallScreenQ1Change = (event: SelectChangeEvent) => {
    const value = event.target
      .value as MSKFormStateAdditionalQuestionsFieldOptions['v2']
    setAdditionalQuestions({
      fallScreenQ1: value,
    })
  }

  const handleFallScreenQ2Change = (event: SelectChangeEvent) => {
    const value = event.target
      .value as MSKFormStateAdditionalQuestionsFieldOptions['v2']
    setAdditionalQuestions({
      fallScreenQ2: value,
    })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '32px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
        }}
      >
        <VanillaFormFieldContainer
          id={'fallScreenQ1'}
          label={QUESTIONS_LABELS.fallScreenQ1}
          error={!!errors.fallScreenQ1}
          helperText={errors.fallScreenQ1 ? errors.fallScreenQ1 : ''}
        >
          <Select
            id={'fallScreenQ1'}
            size="small"
            value={additionalQuestions.fallScreenQ1}
            onChange={handleFallScreenQ1Change}
            error={!!errors.fallScreenQ1}
            sx={{
              ...getSelectDefaultSx(),
            }}
          >
            {fieldOptions.v2.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.display}
              </MenuItem>
            ))}
          </Select>
        </VanillaFormFieldContainer>

        <VanillaFormFieldContainer
          id={'fallScreenQ2'}
          label={QUESTIONS_LABELS.fallScreenQ2}
          error={!!errors.fallScreenQ2}
          helperText={errors.fallScreenQ2 ? errors.fallScreenQ2 : ''}
        >
          <Select
            id={'fallScreenQ2'}
            size="small"
            value={additionalQuestions.fallScreenQ2}
            onChange={handleFallScreenQ2Change}
            error={!!errors.fallScreenQ2}
            sx={{
              ...getSelectDefaultSx(),
            }}
          >
            {fieldOptions.v2.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.display}
              </MenuItem>
            ))}
          </Select>
        </VanillaFormFieldContainer>
      </Box>

      <Divider />

      <VanillaFormFieldContainer
        label={'Does the patient screen positive as a fall risk?'}
      >
        <TextField
          value={additionalQuestions.fallScreenResult}
          size={'small'}
          disabled={true}
          sx={{
            ...getTextFieldDefaultSx(),
          }}
        />
      </VanillaFormFieldContainer>
    </Box>
  )
}
