import {
  SelectChangeEvent,
  Box,
  Select,
  MenuItem,
  TextField,
} from '@mui/material'
import { FC, ChangeEvent, useEffect } from 'react'
import InputMask from 'react-input-mask'

import { LoadMSKFormReturns } from 'src/features/msk/domain'
import { Patient } from 'src/features/patients/domain'
import {
  useMSKFormStore,
  MSKFormStateAdditionalQuestionsFieldOptions,
} from 'src/features/shared/infrastructure'
import { VanillaFormFieldContainer } from 'src/features/shared/presentation'
import {
  addressRegex,
  cityRegex,
  cleanAddressFieldValue,
  cleanCityFieldValue,
  cleanNameFieldValue,
  cleanPhoneFieldValue,
  cleanStateFieldValue,
  cleanZipFieldValue,
  getMaskedPhone,
  getPatientCurrentContacts,
  nameRegex,
  phoneRegex,
  stateRegex,
  zipRegex,
} from 'src/features/shared/utils'

import {
  QUESTIONS_LABELS,
  getSelectDefaultSx,
  fieldOptions,
  getTextFieldDefaultSx,
} from './helpers'

type MSKFormStepThreePage3Props = {
  patient: Patient
  loadedMSKForm: LoadMSKFormReturns
}

export const MSKFormStepThreePage3: FC<MSKFormStepThreePage3Props> = ({
  patient,
  loadedMSKForm,
}) => {
  const { additionalQuestions, setAdditionalQuestions, errors, deleteError } =
    useMSKFormStore({
      loadedMSKForm,
    })

  const getPcpLabel = () => {
    if (
      patient &&
      patient.medicalProfile &&
      patient.medicalProfile.primaryCarePhysician
    ) {
      return `I am showing your PCP is Dr. ${patient.medicalProfile.primaryCarePhysician}.
      Is that still correct?`
    }
    return 'PCP Information'
  }

  const handleBarriersToBeAwareOfChange = (event: SelectChangeEvent) => {
    const value = event.target
      .value as MSKFormStateAdditionalQuestionsFieldOptions['v4']
    setAdditionalQuestions({
      barriersToBeAwareOf: value,
    })
  }

  const handleBarriersToBeAwareOfTextChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value
    setAdditionalQuestions({
      barriersToBeAwareOfText: value,
    })

    if (value) {
      deleteError('barriersToBeAwareOfText')
    }
  }

  const handleTabletComputerOrPhoneChange = (event: SelectChangeEvent) => {
    const value = event.target
      .value as MSKFormStateAdditionalQuestionsFieldOptions['v2']
    setAdditionalQuestions({
      tabletComputerOrPhone: value,
    })
  }

  const handleAdditionalPhoneChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = cleanPhoneFieldValue(event.target.value)
    setAdditionalQuestions({
      additionalPhone: value,
    })

    if (!value || phoneRegex.test(value)) {
      deleteError('additionalPhone')
    }
  }

  const handlePeopleAllowedToReachOutToWhoChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = cleanNameFieldValue(event.target.value)
    setAdditionalQuestions({
      peopleAllowedToReachOutToWho: value,
    })
    deleteError('peopleAllowedToReachOutToWho')
  }

  const handlePeopleAllowedToReachOutToPhoneChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = cleanPhoneFieldValue(event.target.value)

    if (!value || phoneRegex.test(value)) {
      deleteError('peopleAllowedToReachOutToPhone')
    }

    setAdditionalQuestions({
      peopleAllowedToReachOutToPhone: value,
    })
  }

  const handlePcpNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = cleanNameFieldValue(event.target.value).slice(0, 255)
    setAdditionalQuestions({
      pcpName: value,
    })

    if (!value || nameRegex.test(value)) {
      deleteError('pcpName')
    }
  }

  const handlePcpPhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = cleanPhoneFieldValue(event.target.value)
    setAdditionalQuestions({
      pcpPhone: value,
    })

    if (!value || phoneRegex.test(value)) {
      deleteError('pcpPhone')
    }
  }

  const handlePcpFaxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = cleanPhoneFieldValue(event.target.value)
    setAdditionalQuestions({
      pcpFax: value,
    })

    if (!value || phoneRegex.test(value)) {
      deleteError('pcpFax')
    }
  }

  const handlePcpGroupNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAdditionalQuestions({
      pcpGroupName: event.target.value,
    })
  }

  const handlePcpAddressLine1Change = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = cleanAddressFieldValue(event.target.value)
    setAdditionalQuestions({
      pcpAddressLine1: value,
    })

    if (!value || addressRegex.test(value)) {
      deleteError('pcpAddressLine1')
    }
  }

  const handlePcpAddressLine2Change = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = cleanAddressFieldValue(event.target.value)
    setAdditionalQuestions({
      pcpAddressLine2: value,
    })

    if (!value || addressRegex.test(value)) {
      deleteError('pcpAddressLine2')
    }
  }

  const handlePcpCityChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = cleanCityFieldValue(event.target.value)
    setAdditionalQuestions({
      pcpCity: value,
    })

    if (!value || cityRegex.test(value)) {
      deleteError('pcpCity')
    }
  }

  const handlePcpStateChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = cleanStateFieldValue(event.target.value).slice(0, 2)
    setAdditionalQuestions({
      pcpState: value,
    })

    if (!value || stateRegex.test(value)) {
      deleteError('pcpState')
    }
  }

  const handlePcpZipChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = cleanZipFieldValue(event.target.value)
    setAdditionalQuestions({
      pcpZip: value,
    })

    if (!value || zipRegex.test(value)) {
      deleteError('pcpZip')
    }
  }
  useEffect(() => {
    if (patient.medicalProfile) {
      setAdditionalQuestions({
        pcpName: patient.medicalProfile.primaryCarePhysician || '',
        pcpPhone: patient.medicalProfile.phone || '',
        pcpFax: patient.medicalProfile.fax || '',
        pcpGroupName: patient.medicalProfile.groupName || '',
        pcpAddressLine1: patient.medicalProfile.addressLine1 || '',
        pcpAddressLine2: patient.medicalProfile.addressLine2 || '',
        pcpCity: patient.medicalProfile.city || '',
        pcpState: patient.medicalProfile.state || '',
        pcpZip: patient.medicalProfile.zip || '',
      })
    }

    const { phone2 } = getPatientCurrentContacts(patient)
    if (phone2?.phone) {
      setAdditionalQuestions({
        additionalPhone: phone2.phone.trim(),
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '32px',
      }}
    >
      <VanillaFormFieldContainer
        id={'tabletComputerOrPhone'}
        label={QUESTIONS_LABELS.tabletComputerOrPhone}
        error={!!errors.tabletComputerOrPhone}
        helperText={
          errors.tabletComputerOrPhone ? errors.tabletComputerOrPhone : ''
        }
      >
        <Select
          id={'tabletComputerOrPhone'}
          size="small"
          value={additionalQuestions.tabletComputerOrPhone}
          onChange={handleTabletComputerOrPhoneChange}
          error={!!errors.tabletComputerOrPhone}
          sx={{
            ...getSelectDefaultSx(),
          }}
        >
          {fieldOptions.v2.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.display}
            </MenuItem>
          ))}
        </Select>
      </VanillaFormFieldContainer>

      <VanillaFormFieldContainer
        id={'barriersToBeAwareOf'}
        label={QUESTIONS_LABELS.barriersToBeAwareOf}
        error={!!errors.barriersToBeAwareOf || !!errors.barriersToBeAwareOfText}
      >
        <Box
          sx={{
            display: 'flex',
            columnGap: '16px',
            alignContent: 'flex-start',
          }}
        >
          <Select
            id={'barriersToBeAwareOf'}
            size="small"
            value={additionalQuestions.barriersToBeAwareOf}
            onChange={handleBarriersToBeAwareOfChange}
            error={!!errors.barriersToBeAwareOf}
            sx={{
              ...getSelectDefaultSx(),
            }}
          >
            {fieldOptions.v4.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.display}
              </MenuItem>
            ))}
          </Select>

          {additionalQuestions.barriersToBeAwareOf === 'other' ? (
            <TextField
              label={'What is the barrier?'}
              size={'small'}
              error={!!errors.barriersToBeAwareOfText}
              value={additionalQuestions.barriersToBeAwareOfText}
              onChange={handleBarriersToBeAwareOfTextChange}
              helperText={
                errors.barriersToBeAwareOfText
                  ? errors.barriersToBeAwareOfText
                  : ''
              }
              sx={{
                ...getTextFieldDefaultSx(),
              }}
            />
          ) : null}
        </Box>
      </VanillaFormFieldContainer>

      <VanillaFormFieldContainer
        id={'additionalPhone'}
        label={QUESTIONS_LABELS.additionalPhone}
        error={!!errors.additionalPhone}
      >
        <InputMask
          mask="(999) 999-9999"
          value={getMaskedPhone(additionalQuestions.additionalPhone)}
          onChange={handleAdditionalPhoneChange}
        >
          <TextField
            error={!!errors.additionalPhone}
            helperText={errors.additionalPhone ? errors.additionalPhone : ''}
            size={'small'}
            sx={{
              ...getTextFieldDefaultSx(),
            }}
          />
        </InputMask>
      </VanillaFormFieldContainer>

      <VanillaFormFieldContainer
        id={'peopleAllowedToReachOutTo'}
        label={QUESTIONS_LABELS.peopleAllowedToReachOutTo}
        error={
          !!errors.peopleAllowedToReachOutToWho ||
          !!errors.peopleAllowedToReachOutToPhone
        }
      >
        <Box
          sx={{
            display: 'flex',
            columnGap: '16px',
          }}
        >
          <TextField
            label={'Who?'}
            size={'small'}
            error={!!errors.peopleAllowedToReachOutToWho}
            value={additionalQuestions.peopleAllowedToReachOutToWho}
            onChange={handlePeopleAllowedToReachOutToWhoChange}
            helperText={
              errors.peopleAllowedToReachOutToWho
                ? errors.peopleAllowedToReachOutToWho
                : ''
            }
            sx={{
              ...getTextFieldDefaultSx(),
            }}
          />
          <InputMask
            mask="(999) 999-9999"
            value={getMaskedPhone(
              additionalQuestions.peopleAllowedToReachOutToPhone
            )}
            onChange={handlePeopleAllowedToReachOutToPhoneChange}
          >
            <TextField
              label={'Phone Number'}
              size={'small'}
              error={!!errors.peopleAllowedToReachOutToPhone}
              helperText={
                errors.peopleAllowedToReachOutToPhone
                  ? errors.peopleAllowedToReachOutToPhone
                  : ''
              }
              sx={{
                ...getTextFieldDefaultSx(),
              }}
            />
          </InputMask>
        </Box>
      </VanillaFormFieldContainer>

      <VanillaFormFieldContainer label={getPcpLabel()}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '16px',
          }}
        >
          <TextField
            label={QUESTIONS_LABELS.pcpName}
            size={'small'}
            error={!!errors.pcpName}
            value={additionalQuestions.pcpName}
            onChange={handlePcpNameChange}
            helperText={errors.pcpName ? errors.pcpName : ''}
            sx={{
              ...getTextFieldDefaultSx(),
            }}
          />
          <InputMask
            mask="(999) 999-9999"
            value={getMaskedPhone(additionalQuestions.pcpPhone)}
            onChange={handlePcpPhoneChange}
          >
            <TextField
              label={QUESTIONS_LABELS.pcpPhone}
              size={'small'}
              error={!!errors.pcpPhone}
              helperText={errors.pcpPhone ? errors.pcpPhone : ''}
              sx={{
                ...getTextFieldDefaultSx(),
              }}
            />
          </InputMask>
          <InputMask
            mask="(999) 999-9999"
            value={getMaskedPhone(additionalQuestions.pcpFax)}
            onChange={handlePcpFaxChange}
          >
            <TextField
              label={QUESTIONS_LABELS.pcpFax}
              size={'small'}
              error={!!errors.pcpFax}
              helperText={errors.pcpFax ? errors.pcpFax : ''}
              sx={{
                ...getTextFieldDefaultSx(),
              }}
            />
          </InputMask>
          <TextField
            label={QUESTIONS_LABELS.pcpGroupName}
            size={'small'}
            error={!!errors.pcpGroupName}
            value={additionalQuestions.pcpGroupName}
            onChange={handlePcpGroupNameChange}
            helperText={errors.pcpGroupName ? errors.pcpGroupName : ''}
            sx={{
              ...getTextFieldDefaultSx(),
            }}
          />
          <TextField
            label={QUESTIONS_LABELS.pcpAddressLine1}
            size={'small'}
            error={!!errors.pcpAddressLine1}
            value={additionalQuestions.pcpAddressLine1}
            onChange={handlePcpAddressLine1Change}
            helperText={errors.pcpAddressLine1 ? errors.pcpAddressLine1 : ''}
            sx={{
              ...getTextFieldDefaultSx(),
            }}
          />
          <TextField
            label={QUESTIONS_LABELS.pcpAddressLine2}
            size={'small'}
            error={!!errors.pcpAddressLine2}
            value={additionalQuestions.pcpAddressLine2}
            onChange={handlePcpAddressLine2Change}
            helperText={errors.pcpAddressLine2 ? errors.pcpAddressLine2 : ''}
            sx={{
              ...getTextFieldDefaultSx(),
            }}
          />
          <TextField
            label={QUESTIONS_LABELS.pcpCity}
            size={'small'}
            error={!!errors.pcpCity}
            value={additionalQuestions.pcpCity}
            onChange={handlePcpCityChange}
            helperText={errors.pcpCity ? errors.pcpCity : ''}
            sx={{
              ...getTextFieldDefaultSx(),
            }}
          />
          <TextField
            label={QUESTIONS_LABELS.pcpState}
            size={'small'}
            error={!!errors.pcpState}
            value={additionalQuestions.pcpState}
            onChange={handlePcpStateChange}
            helperText={errors.pcpState ? errors.pcpState : ''}
            sx={{
              ...getTextFieldDefaultSx(),
            }}
          />
          <TextField
            label={QUESTIONS_LABELS.pcpZip}
            size={'small'}
            error={!!errors.pcpZip}
            value={additionalQuestions.pcpZip}
            onChange={handlePcpZipChange}
            helperText={errors.pcpZip ? errors.pcpZip : ''}
            sx={{
              ...getTextFieldDefaultSx(),
            }}
          />
        </Box>
      </VanillaFormFieldContainer>
    </Box>
  )
}
