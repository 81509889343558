import { RouterProvider } from 'react-router-dom'
import {
  LoginPage,
  WarningModal,
  useSessionStorage,
} from 'src/features/shared/presentation'
import { useAuth } from 'src/features/shared/infrastructure'
import router from 'src/router'

function App() {
  const { isAuthenticated } = useAuth()
  const [loggedOut, setLoggedOut] = useSessionStorage('loggedOut', false)

  const handleLogoutModalConfirm = () => {
    setLoggedOut(false)
  }
  return (
    <>
      {isAuthenticated ? <RouterProvider router={router} /> : <LoginPage />}
      <WarningModal
        open={!!loggedOut}
        title={'You have been logged out'}
        message={
          'For security reasons you have been logged out due to inactivity.'
        }
        onConfirm={handleLogoutModalConfirm}
        confirmButtonVariant={'contained'}
        confirmButtonText={'Ok'}
        actionsAlignment={'center'}
      />
    </>
  )
}

export default App
