import { ErrorMessage } from '@hookform/error-message'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
  FormHelperText,
} from '@mui/material'
import { useEffect, useState } from 'react'
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form'
import { useLoadMSKFormQuery } from 'src/features/msk/presentation'

export const ReferredForField = <T extends FieldValues>() => {
  const name = 'referredFor' as FieldPath<T>

  const [selectedBodyRegions, setSelectedBodyRegions] = useState<string[]>([])
  const { loadedMSKForm } = useLoadMSKFormQuery()
  const { setValue, getValues, register } = useFormContext()

  const handleChange = (
    event: SelectChangeEvent<typeof selectedBodyRegions>
  ) => {
    const value = event.target.value
    setSelectedBodyRegions(value as string[])
  }

  useEffect(() => {
    setValue('referredFor', selectedBodyRegions.join(', '))
  }, [selectedBodyRegions, name, setValue, getValues])

  return (
    <Controller
      name={name}
      {...register}
      render={() => (
        <FormControl size={'small'} sx={{ width: '100%' }}>
          <InputLabel id={'referred-for-label'}>Referred For</InputLabel>
          <Select
            labelId={'referred-for-label'}
            id={'referred-for'}
            label={'Referred For'}
            multiple
            value={selectedBodyRegions}
            onChange={handleChange}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200,
                },
              },
            }}
          >
            {loadedMSKForm?.botheredBodyParts?.map((bodyRegion) => (
              <MenuItem key={bodyRegion.id} value={bodyRegion.display}>
                <Checkbox
                  checked={selectedBodyRegions.includes(bodyRegion.display)}
                />
                <ListItemText primary={bodyRegion.display} />
              </MenuItem>
            ))}
          </Select>
          <ErrorMessage
            name={name}
            render={({ message }) => (
              <FormHelperText sx={{ m: 0 }} error={true}>
                {message}
              </FormHelperText>
            )}
          />
        </FormControl>
      )}
    />
  )
}
