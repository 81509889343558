import {
  UseMutateAsyncFunction,
  UseMutateFunction,
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import {
  CreatePatientPostOpTouchpointNoteReturns,
  CreatePatientPostOpTouchpointNoteArgs,
  CreatePatientPostOpTouchpointNoteError,
} from 'src/features/notes/domain'
import {
  GET_PATIENT_QUERY_KEY,
  GET_PATIENT_PAIN_AND_FUNCTION_QUERY_KEY,
} from 'src/features/patients/presentation'
import { GET_PATIENT_NOTES_QUERY_KEY } from 'src/features/notes/presentation'
import { useNotesUseCases } from 'src/features/notes/notes.service-locator'

type UseCreatePatientPostOpTouchpointNoteProps = Omit<
  UseMutationOptions<
    CreatePatientPostOpTouchpointNoteReturns,
    CreatePatientPostOpTouchpointNoteError,
    CreatePatientPostOpTouchpointNoteArgs,
    unknown
  >,
  'mutationFn'
>

type UseCreatePatientPostOpTouchpointNote = (
  props?: UseCreatePatientPostOpTouchpointNoteProps
) => {
  createPatientPostOpTouchpointNote: UseMutateFunction<
    CreatePatientPostOpTouchpointNoteReturns,
    CreatePatientPostOpTouchpointNoteError,
    CreatePatientPostOpTouchpointNoteArgs,
    unknown
  >
  createPatientPostOpTouchpointNoteAsync: UseMutateAsyncFunction<
    CreatePatientPostOpTouchpointNoteReturns,
    CreatePatientPostOpTouchpointNoteError,
    CreatePatientPostOpTouchpointNoteArgs,
    unknown
  >
  createPatientPostOpTouchpointNoteIsLoading: boolean
  createPatientPostOpTouchpointNoteIsError: boolean
  createPatientPostOpTouchpointNoteStatus: string
}

export const useCreatePatientPostOpTouchpointNote: UseCreatePatientPostOpTouchpointNote =
  (props = {}) => {
    const queryClient = useQueryClient()
    const notesUseCases = useNotesUseCases()

    const { onSuccess } = props

    const { mutate, mutateAsync, isLoading, isError, status } = useMutation<
      CreatePatientPostOpTouchpointNoteReturns,
      CreatePatientPostOpTouchpointNoteError,
      CreatePatientPostOpTouchpointNoteArgs,
      unknown
    >({
      mutationFn: (args: CreatePatientPostOpTouchpointNoteArgs) => {
        return notesUseCases.createPatientPostOpTouchpointNote(args)
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({
          queryKey: [GET_PATIENT_QUERY_KEY, variables.patientId],
        })
        queryClient.invalidateQueries({
          queryKey: [GET_PATIENT_NOTES_QUERY_KEY, variables.patientId],
        })
        queryClient.invalidateQueries({
          queryKey: [
            GET_PATIENT_PAIN_AND_FUNCTION_QUERY_KEY,
            variables.patientId,
          ],
        })
        onSuccess?.(data, variables, context)
      },
    })
    return {
      createPatientPostOpTouchpointNote: mutate,
      createPatientPostOpTouchpointNoteAsync: mutateAsync,
      createPatientPostOpTouchpointNoteIsLoading: isLoading,
      createPatientPostOpTouchpointNoteIsError: isError,
      createPatientPostOpTouchpointNoteStatus: status,
    }
  }
