import { useQuery } from '@tanstack/react-query'

import { useNotesUseCases } from 'src/features/notes/notes.service-locator'
import {
  GetPatientNotesError,
  GetPatientNotesReturns,
} from 'src/features/notes/domain'
import { usePatientId } from 'src/features/shared/presentation'

export const GET_PATIENT_NOTES_QUERY_KEY = 'getPatientNotes'

type UseGetPatientNotesQueryProps = {
  patientId?: string
  throwGetPatientIdError?: boolean
}

export const useGetPatientNotesQuery = ({
  patientId,
  throwGetPatientIdError = true,
}: UseGetPatientNotesQueryProps = {}) => {
  const notesUseCases = useNotesUseCases()

  const _patientId = usePatientId({ patientId, throwGetPatientIdError })

  const { data, isLoading, isError, isFetching } = useQuery<
    GetPatientNotesReturns | null,
    GetPatientNotesError
  >([GET_PATIENT_NOTES_QUERY_KEY, _patientId], () => {
    return _patientId ? notesUseCases.getPatientNotes(_patientId) : null
  })
  return {
    patientNotes: data ? data.notes : [],
    getPatientNotesIsLoading: isLoading,
    getPatientNotesIsFetching: isFetching,
    getPatientNotesIsError: isError,
  }
}
