import {
  TasksSidebarState,
  setOpenAction,
  setSelectedTabAction,
  setOpenAddTaskFormModalAction,
  setOpenEditTaskFormModalAction,
  setSelectedTaskForEditAction,
  setShowTaskLoaderAction,
  tasksSidebarOpenSelector,
  tasksSidebarSelectedTabSelector,
  tasksSidebarOpenAddTaskFormModalSelector,
  tasksSidebarOpenEditTaskFormModalSelector,
  tasksSidebarSelectedTaskForEditSelector,
  tasksSidebarShowLoaderSelector,
  useAppDispatch,
  useAppSelector,
} from 'src/features/shared/infrastructure'

export const useTasksSidebarStore = () => {
  const dispatch = useAppDispatch()

  const open = useAppSelector(tasksSidebarOpenSelector)

  const selectedTab = useAppSelector(tasksSidebarSelectedTabSelector)

  const openAddTaskFormModal = useAppSelector(
    tasksSidebarOpenAddTaskFormModalSelector
  )

  const openEditTaskFormModal = useAppSelector(
    tasksSidebarOpenEditTaskFormModalSelector
  )

  const selectedTaskForEdit = useAppSelector(
    tasksSidebarSelectedTaskForEditSelector
  )

  const showLoader = useAppSelector(tasksSidebarShowLoaderSelector)

  const setOpen = (open: boolean) => dispatch(setOpenAction(open))

  const setSelectedTab = (selectedTab: TasksSidebarState['selectedTab']) =>
    dispatch(setSelectedTabAction(selectedTab))

  const setOpenAddTaskFormModal = (open: boolean) =>
    dispatch(setOpenAddTaskFormModalAction(open))

  const setOpenEditTaskFormModal = (open: boolean) =>
    dispatch(setOpenEditTaskFormModalAction(open))

  const setSelectedTaskForEdit = (
    task: TasksSidebarState['selectedTaskForEdit']
  ) => dispatch(setSelectedTaskForEditAction(task))

  const setShowLoader = (showLoader: boolean) =>
    dispatch(setShowTaskLoaderAction(showLoader))

  return {
    open,
    selectedTab,
    setOpen,
    setSelectedTab,
    openAddTaskFormModal,
    openEditTaskFormModal,
    setOpenAddTaskFormModal,
    setOpenEditTaskFormModal,
    selectedTaskForEdit,
    setSelectedTaskForEdit,
    showLoader,
    setShowLoader,
  }
}
