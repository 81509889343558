import ReactDOM from 'react-dom/client'
import { Authenticator } from '@aws-amplify/ui-react'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import * as Sentry from '@sentry/react'

import {
  AuthProvider,
  IdleProvider,
  ReactQueryProvider,
  MUIThemeProvider,
  ErrorPage,
} from './features/shared/presentation'
import { store } from './features/shared/infrastructure'
import bootstrap from './bootstrap'
import { toastContainerConfig } from './config'
import App from './App'
import AppWithoutLogin from './app-without-login'
import * as settings from 'src/config/settings'
import { sentryConfig } from './config/sentry-config'

bootstrap()

Sentry.init(sentryConfig)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Sentry.ErrorBoundary fallback={<ErrorPage />}>
    <Provider store={store}>
      <ReactQueryProvider>
        <Authenticator.Provider>
          <AuthProvider>
            <MUIThemeProvider>
              <IdleProvider>
                {settings.LOGIN_DISABLED ? <AppWithoutLogin /> : <App />}
                <ToastContainer {...toastContainerConfig} />
              </IdleProvider>
            </MUIThemeProvider>
          </AuthProvider>
        </Authenticator.Provider>
      </ReactQueryProvider>
    </Provider>
  </Sentry.ErrorBoundary>
)
