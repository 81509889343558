import { Auth } from 'aws-amplify'
import { Box, Stack, Typography, Divider, Icon } from '@mui/material'
import { FC } from 'react'
import { NavLink, Link, To } from 'react-router-dom'

import { useAuth } from 'src/features/shared/infrastructure'

import tcLogo from 'src/assets/images/tc-logo-white.svg'

export const NAVBAR_VERTICAL_DESKTOP_WIDTH = 120

type NavbarItemProps = {
  icon: string
  to: To
  label?: string
}

type NavbarVerticalItemProps = NavbarItemProps

export const NavbarVerticalItem: FC<NavbarVerticalItemProps> = ({
  to,
  icon,
  label,
}) => {
  return (
    <NavLink
      to={to}
      style={({ isActive }) => ({
        textDecoration: 'none',
        borderRadius: '20px 20px 20px 20px',
        backgroundColor: isActive ? 'rgba(146, 229, 218, 0.16)' : 'transparent',
        '&:hover': { backgroundColor: 'rgba(146, 229, 218, 0.16)' },
      })}
    >
      <Stack
        direction={'column'}
        alignItems={'center'}
        textAlign={'center'}
        rowGap={1}
        p={1.5}
        sx={{
          cursor: 'pointer',
        }}
      >
        <Icon>{icon}</Icon>
        {label ? (
          <Typography
            variant={'caption'}
            fontFamily={'Inter'}
            fontWeight={500}
            sx={{ color: 'common.white' }}
          >
            {label}
          </Typography>
        ) : null}
      </Stack>
    </NavLink>
  )
}

export const NavbarVertical: FC = () => {
  const { setAuthIsLoading } = useAuth()
  const handleLogOutClick = () => {
    setAuthIsLoading(true)
    Auth.signOut()
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        backgroundColor: 'green.300',
        height: '100vh',
        width: `${NAVBAR_VERTICAL_DESKTOP_WIDTH}px`,
        borderRadius: '0 20px 20px 0',
        px: 2,
        py: 5,
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <Stack
        height={'100%'}
        direction={'column'}
        justifyContent={'space-between'}
      >
        <Stack>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Link to={'/queues'}>
              <img src={tcLogo} alt="tailorcare logo" height={32} />
            </Link>
          </Box>
          <Divider sx={{ borderColor: 'green.200', mt: 4, mb: 7 }} />
          <Stack component={'nav'} rowGap={6}>
            <NavbarVerticalItem
              to={'/patients'}
              icon={'people_outline'}
              label={'All Patients'}
            />
            <NavbarVerticalItem
              to={'/eligibility'}
              icon={'folder_open'}
              label={'Eligibility'}
            />
          </Stack>
        </Stack>
        <Stack
          direction={'column'}
          alignItems={'center'}
          textAlign={'center'}
          rowGap={1}
          p={1.5}
          sx={{
            cursor: 'pointer',
          }}
        >
          <Icon
            sx={{
              color: 'common.white',
              cursor: 'pointer',
            }}
            onClick={handleLogOutClick}
          >
            logout
          </Icon>
        </Stack>
      </Stack>
    </Box>
  )
}
