import { FC, useEffect } from 'react'
import {
  Box,
  Typography,
  styled,
  Chip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
} from '@mui/material'
import DOMPurify from 'dompurify'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { useGetPatientNotesQuery } from 'src/features/notes/presentation'
import { FullSectionLoader } from 'src/features/shared/presentation'
import { Note } from 'src/features/notes/domain'
import { formatDate } from 'src/features/shared/utils/date.utils'

import {
  ADDITIONAL_QUESTION_LABELS,
  questionOrder,
  questionsWithDateVaules,
} from './helpers'
import { reactQuillLinkExternalClickEventListener } from 'src/config'

const NoteChip = styled(Chip)(() => ({
  backgroundColor: 'white',
  border: '1px solid rgba(0, 0, 0, 0.38)',
}))

type QuestionsAndAnswersData = {
  question: string
  value: any
}

// TODO: refactor this component to extract the inner components
export const PatientNotesList: FC = () => {
  const { patientNotes, getPatientNotesIsLoading, getPatientNotesIsFetching } =
    useGetPatientNotesQuery()

  const getQuestionAndValue = (data: Note['data'] | null) => {
    if (data && !!Object.keys(data).length) {
      return Object.entries(data).map(([key, value]) => {
        const question =
          ADDITIONAL_QUESTION_LABELS[
            key as keyof typeof ADDITIONAL_QUESTION_LABELS
          ]

        return { question, value }
      })
    }
  }

  const QuestionsAndAnswers = ({
    data,
  }: {
    data: QuestionsAndAnswersData[]
  }) => {
    const orderedData: QuestionsAndAnswersData[] = []
    questionOrder.forEach((questionKey: string) => {
      const dataQuestion = data.find(
        (item: QuestionsAndAnswersData) =>
          item.question === ADDITIONAL_QUESTION_LABELS[questionKey]
      )
      if (dataQuestion && dataQuestion.value !== 'N/A') {
        orderedData.push({
          question: dataQuestion.question,
          value: questionsWithDateVaules.includes(questionKey)
            ? formatDate(dataQuestion.value)
            : dataQuestion.value,
        })
      }
    })

    return (
      <>
        {orderedData
          .filter(({ question }) => question)
          .map(({ question, value }, index) => {
            return (
              <Box key={index} sx={{ maxWidth: '90%' }}>
                <Box sx={{ marginBottom: 2 }}>
                  <Typography variant="body1">{question}</Typography>
                  <div
                    style={{ fontWeight: 'bold' }}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(String(value), {
                        ALLOWED_TAGS: [],
                      }),
                    }}
                  />
                </Box>
              </Box>
            )
          })}
      </>
    )
  }

  const NoteHeader = ({ note }: { note: Note }) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          pt: 1,
          '& .ql-syntax': { textWrap: 'wrap' },
        }}
      >
        <Typography variant="caption" color={'rgba(0, 0, 0, 0.38)'}>
          {note.type} {note.header}
        </Typography>
        <Box sx={{ ml: 1 }}>
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(note.note),
            }}
          />
        </Box>
      </Box>
    )
  }

  const NoteChips = ({ note }: { note: Note }) => {
    if (note.spokeWith || note.sdm)
      return (
        <Box sx={{ display: 'flex', columnGap: '4px', ml: 2, mb: 2 }}>
          {note.spokeWith ? (
            <NoteChip label={`Spoke with: ${note.spokeWith}`} />
          ) : null}
          {note.sdm ? <NoteChip label={'SDM Occurred'} /> : null}
        </Box>
      )

    return null
  }

  useEffect(() => {
    // KNOWN ISSUE: patch to open note links in new browser tab
    document.addEventListener('click', reactQuillLinkExternalClickEventListener)
    return () => {
      document.removeEventListener(
        'click',
        reactQuillLinkExternalClickEventListener
      )
    }
  }, [])

  if (getPatientNotesIsLoading || getPatientNotesIsFetching) {
    return <FullSectionLoader />
  }

  return (
    <Box sx={{ maxHeight: '800px', overflowY: 'scroll' }}>
      {patientNotes.length ? (
        <>
          <Divider />

          {patientNotes.map((note, index) => {
            const questionAndValue = getQuestionAndValue(note.data)

            return questionAndValue ? (
              <Box key={note.id}>
                <Accordion elevation={0} disableGutters square>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="note-content"
                    id="note-header"
                    sx={{ '.MuiAccordionSummary-content': { m: 0 } }}
                  >
                    <NoteHeader note={note} />
                  </AccordionSummary>
                  <AccordionDetails sx={{ ml: 2 }}>
                    <QuestionsAndAnswers data={questionAndValue} />
                  </AccordionDetails>
                </Accordion>
                <NoteChips note={note} />
                {patientNotes.length - 1 !== index ? <Divider /> : null}
              </Box>
            ) : (
              <Box key={note.id}>
                <Box sx={{ px: 2 }}>
                  <NoteHeader note={note} />
                  <Box sx={{ ml: -2 }}>
                    <NoteChips note={note} />
                  </Box>
                </Box>
                {patientNotes.length - 1 !== index ? <Divider /> : null}
              </Box>
            )
          })}
          {patientNotes.length ? <Divider /> : null}
        </>
      ) : null}
    </Box>
  )
}
