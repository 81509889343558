import { Box, SxProps, Theme } from '@mui/material'
import { FC } from 'react'

import { WithChildren } from 'src/features/shared/types'

type MSKFormStepContainerProps = {
  sx?: SxProps<Theme>
}

export const MSKFormStepContainer: FC<
  WithChildren<MSKFormStepContainerProps>
> = ({ children, sx }) => {
  return (
    <Box
      id="msk_form_step_container"
      data-testid="msk_form_step_container"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '32px',
        padding: '16px',
        width: {
          xs: '50%',
        },
        maxWidth: {
          xs: '50%',
        },
        height: {
          xs: '600px',
        },
        maxHeight: {
          xs: '600px',
        },
        overflowY: 'auto',
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}
