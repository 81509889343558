import { FC } from 'react'
import { styled } from '@mui/material/styles'
import Chip, { ChipProps } from '@mui/material/Chip'
import { Link, LinkProps } from 'react-router-dom'

type LinkChipProps = {
  linkProps: LinkProps
  chipProps: ChipProps
}

export const DangerChip = styled(Chip)(() => ({
  backgroundColor: 'white',
  color: '#D32F2F',
  border: '1px solid #D32F2F80',
}))

export const TagChip = styled(Chip)(() => ({
  color: 'black',
}))

export const PatientHeaderChip = styled(Chip)(() => ({
  backgroundColor: 'rgba(0, 0, 0, 0.04)',
  color: 'black',
  height: '24px',
  width: 'min-content',
  minWidth: '47px',
  fontSize: '11px',
  fontWeight: 500,
}))

export const MSKChip = styled(Chip)(() => ({
  backgroundColor: 'white',
  border: '1px solid rgba(0, 0, 0, 0.26)',
}))

export const StyledLinkChipInnerLink = styled(Link)(() => ({
  textDecoration: 'none',
}))

export const LinkChip: FC<LinkChipProps> = ({ linkProps, chipProps }) => {
  return (
    <StyledLinkChipInnerLink {...linkProps}>
      <Chip {...chipProps} />
    </StyledLinkChipInnerLink>
  )
}
