import { FC } from 'react'
import {
  Box,
  Button,
  Checkbox,
  Stack,
  Typography,
  Dialog,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  DialogContent,
  styled,
} from '@mui/material'
import { toast } from 'react-toastify'
import {
  FormProvider,
  SubmitHandler,
  useForm,
  Controller,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { format } from 'date-fns'
import * as yup from 'yup'

import { useTasksSidebarStore } from 'src/features/shared/infrastructure'
import {
  BackdropLoader,
  ControlledDatePicker,
  ModalDialogTitle,
  useGetNavigatorsQuery,
} from 'src/features/shared/presentation'
import { useGetPatientQuery } from 'src/features/patients/presentation'
import {
  useUpdateTask,
  useCreateTask,
  TaskComments,
} from 'src/features/tasks/presentation'
import { CreateTaskArgs } from 'src/features/tasks/domain'

const TaskFormModalDialogContent = styled(DialogContent)(() => ({
  width: '496px',
}))

type CreateTaskFormFields = {
  title: string
  description?: string
  dueDate: Date
  assignedUserId: string
  urgent: boolean
}

const createTaskFormSchema: yup.Schema<CreateTaskFormFields> = yup
  .object()
  .shape({
    dueDate: yup.date().required(),
    assignedUserId: yup.string().required(),
    title: yup.string().required(),
    urgent: yup.boolean().required(),
    description: yup.string(),
  })

export const AddTaskForm: FC = () => {
  const { setOpenAddTaskFormModal, setShowLoader } = useTasksSidebarStore()
  const { createTask } = useCreateTask()
  const { patientHeaderData } = useGetPatientQuery()
  const { navigators } = useGetNavigatorsQuery()

  const formMethods = useForm<CreateTaskFormFields>({
    resolver: yupResolver(createTaskFormSchema),
    defaultValues: {
      dueDate: new Date(),
      assignedUserId: '',
      urgent: false,
      description: '',
      title: '',
    },
  })

  const submitHandler: SubmitHandler<CreateTaskFormFields> = (data) => {
    const args: CreateTaskArgs = {
      patientId: patientHeaderData ? patientHeaderData.id : '',
      title: data.title,
      dueDate: format(data.dueDate, 'MM/dd/yy'),
      assignedUserId: data.assignedUserId,
      urgent: data.urgent,
    }

    if (data.description) {
      args.description = data.description
    }

    setShowLoader(true)
    createTask(args, {
      onSuccess: () => {
        toast.success(`Task ${data.title} succesfully created!`)
        setOpenAddTaskFormModal(false)
      },
      onError: () => {
        toast.error(`Task creation failed!`)
      },
      onSettled: () => {
        setShowLoader(false)
      },
    })
  }

  const handleCancelClick = () => {
    setOpenAddTaskFormModal(false)
  }

  if (!patientHeaderData) return null

  return (
    <>
      <ModalDialogTitle>Add Task</ModalDialogTitle>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(submitHandler)}>
          <TaskFormModalDialogContent>
            <Box>
              <Box sx={{ marginBottom: '56px' }}>
                <Stack direction={'row'} gap={'32px'}>
                  <Typography color={'rgba(0, 0, 0, 0.6)'} component={'span'}>
                    Patient
                  </Typography>
                  <Typography component={'span'}>
                    {patientHeaderData?.name}
                  </Typography>
                </Stack>
              </Box>
              <Stack direction={'column'} gap={'56px'}>
                <TextField
                  label={'Title'}
                  variant={'outlined'}
                  error={!!formMethods.formState.errors['title']}
                  helperText={
                    formMethods.formState.errors['title']
                      ? (formMethods.formState.errors['title']
                          ?.message as string)
                      : undefined
                  }
                  {...formMethods.register('title')}
                />
                <TextField
                  label={'Description'}
                  variant={'outlined'}
                  error={!!formMethods.formState.errors['description']}
                  helperText={
                    formMethods.formState.errors['description']
                      ? (formMethods.formState.errors['description']
                          ?.message as string)
                      : 'optional'
                  }
                  {...formMethods.register('description')}
                />
                <Stack direction={'row'} gap={'32px'}>
                  <ControlledDatePicker
                    name="dueDate"
                    datePickerProps={{
                      label: 'To Do Date',
                      slotProps: {
                        textField: {
                          size: 'medium',
                          sx: {
                            flex: { xs: '0 0 160px' },
                          },
                        },
                      },
                    }}
                  />

                  <Controller
                    name="assignedUserId"
                    control={formMethods.control}
                    render={({ field, fieldState }) => (
                      <FormControl fullWidth>
                        <InputLabel id={'edit-task-form-tc-member-label'}>
                          TC Team Member
                        </InputLabel>
                        <Select
                          labelId={'edit-task-form-tc-member-label'}
                          id={'edit-task-form-tc-member'}
                          label={'TC Team Member'}
                          error={!!fieldState.error}
                          value={field.value}
                          onChange={(event) => {
                            field.onChange(event.target.value)
                          }}
                        >
                          {navigators.map((navigator) => (
                            <MenuItem key={navigator.id} value={navigator.id}>
                              {navigator.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {fieldState.error ? (
                          <FormHelperText error={!!fieldState.error}>
                            {fieldState.error ? fieldState.error.message : ''}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    )}
                  />
                </Stack>
                <Controller
                  name={'urgent'}
                  control={formMethods.control}
                  render={({ field, fieldState }) => (
                    <Box>
                      <FormControlLabel
                        componentsProps={{
                          typography: {
                            variant: 'body1',
                          },
                        }}
                        control={
                          <Checkbox
                            {...field}
                            color={'primary'}
                            checked={field.value}
                          />
                        }
                        label={'Urgent'}
                      />
                      <FormHelperText
                        variant={'outlined'}
                        error={!!fieldState.error}
                      >
                        {fieldState.error?.message || 'optional'}
                      </FormHelperText>
                    </Box>
                  )}
                />
              </Stack>
            </Box>
          </TaskFormModalDialogContent>
          <DialogActions>
            <Button color="secondary" onClick={handleCancelClick}>
              CANCEL
            </Button>
            <Button type="submit" color="secondary">
              SAVE
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </>
  )
}

type EditTaskFormFields = {
  dueDate: Date
  assignedUserId: string
  urgent: boolean
  comment?: string
}

const editTaskFormSchema: yup.Schema<EditTaskFormFields> = yup.object().shape({
  dueDate: yup.date().required(),
  assignedUserId: yup.string().required(),
  urgent: yup.boolean().required(),
  comment: yup.string(),
})

export const EditTaskForm: FC = () => {
  const { updateTask } = useUpdateTask()
  const { selectedTaskForEdit, setOpenEditTaskFormModal, setShowLoader } =
    useTasksSidebarStore()
  const { navigators } = useGetNavigatorsQuery()

  const formMethods = useForm<EditTaskFormFields>({
    resolver: yupResolver(editTaskFormSchema),
    defaultValues: {
      dueDate: new Date(selectedTaskForEdit?.dueDate || ''),
      assignedUserId: selectedTaskForEdit?.assignedUserId || '',
      urgent: selectedTaskForEdit?.urgent || false,
      comment: '',
    },
  })

  const submitHandler: SubmitHandler<EditTaskFormFields> = (data) => {
    setShowLoader(true)
    updateTask(
      {
        taskId: selectedTaskForEdit?.id || '',
        payload: {
          dueDate: format(data.dueDate, 'MM/dd/yy'),
          assignedUserId: data.assignedUserId,
          urgent: data.urgent,
          comment: data.comment,
        },
      },
      {
        onSuccess: () => {
          toast.success(
            `Task ${selectedTaskForEdit?.title} succesfully updated!`
          )
          setOpenEditTaskFormModal(false)
        },
        onError: () => {
          toast.error(`Task ${selectedTaskForEdit?.title} update failed!`)
        },
        onSettled: () => {
          setShowLoader(false)
        },
      }
    )
  }

  const handleCancelClick = () => {
    setOpenEditTaskFormModal(false)
  }

  if (!selectedTaskForEdit) return null

  return (
    <>
      <ModalDialogTitle>Edit Task</ModalDialogTitle>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(submitHandler)}>
          <TaskFormModalDialogContent>
            <Box>
              <Box sx={{ marginBottom: '56px' }}>
                <Stack direction={'row'} gap={'32px'}>
                  <Typography color={'rgba(0, 0, 0, 0.6)'} component={'span'}>
                    Patient
                  </Typography>
                  <Typography component={'span'}>
                    {selectedTaskForEdit.patientName}
                  </Typography>
                </Stack>
              </Box>
              <Stack direction={'column'} gap={'56px'}>
                <TextField
                  value={selectedTaskForEdit.title}
                  inputProps={{ readOnly: true }}
                  label={'Title'}
                  variant={'outlined'}
                  disabled
                />
                <TextField
                  value={selectedTaskForEdit.description}
                  inputProps={{ readOnly: true }}
                  label={'Description'}
                  variant={'outlined'}
                  disabled
                />
                <TaskComments task={selectedTaskForEdit} />
                <TextField
                  label={'Comment'}
                  variant={'outlined'}
                  placeholder={'Left a message'}
                  error={!!formMethods.formState.errors['comment']}
                  helperText={
                    formMethods.formState.errors['comment']
                      ? (formMethods.formState.errors['comment']
                          ?.message as string)
                      : undefined
                  }
                  {...formMethods.register('comment')}
                />
                <Stack direction={'row'} gap={'32px'}>
                  <ControlledDatePicker
                    name="dueDate"
                    datePickerProps={{
                      label: 'To Do Date',
                      slotProps: {
                        textField: {
                          size: 'medium',
                          sx: {
                            flex: { xs: '0 0 160px' },
                          },
                        },
                      },
                    }}
                  />

                  <Controller
                    name="assignedUserId"
                    control={formMethods.control}
                    render={({ field, fieldState }) => (
                      <FormControl fullWidth>
                        <InputLabel id={'edit-task-form-tc-member-label'}>
                          TC Team Member
                        </InputLabel>
                        <Select
                          labelId={'edit-task-form-tc-member-label'}
                          id={'edit-task-form-tc-member'}
                          label={'TC Team Member'}
                          value={field.value}
                          onChange={(event) => {
                            field.onChange(event.target.value)
                          }}
                        >
                          {navigators.map((navigator) => (
                            <MenuItem key={navigator.id} value={navigator.id}>
                              {navigator.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {fieldState.error ? (
                          <FormHelperText error={!!fieldState.error}>
                            {fieldState.error ? fieldState.error.message : ''}
                          </FormHelperText>
                        ) : null}
                      </FormControl>
                    )}
                  />
                </Stack>
                <Controller
                  name={'urgent'}
                  control={formMethods.control}
                  render={({ field, fieldState }) => (
                    <Box>
                      <FormControlLabel
                        componentsProps={{
                          typography: {
                            variant: 'body1',
                          },
                        }}
                        control={
                          <Checkbox
                            {...field}
                            color={'primary'}
                            checked={field.value}
                          />
                        }
                        label={'Urgent'}
                      />
                      <FormHelperText
                        variant={'outlined'}
                        error={!!fieldState.error}
                      >
                        {fieldState.error?.message}
                      </FormHelperText>
                    </Box>
                  )}
                />
              </Stack>
            </Box>
          </TaskFormModalDialogContent>
          <DialogActions>
            <Button color="secondary" onClick={handleCancelClick}>
              CANCEL
            </Button>
            <Button type="submit" color="secondary">
              SAVE
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </>
  )
}

export const TaskAddFormModal: FC = () => {
  const { openAddTaskFormModal, setOpenAddTaskFormModal, showLoader } =
    useTasksSidebarStore()
  const handleModalFormClose = () => {
    setOpenAddTaskFormModal(false)
  }

  return (
    <Dialog
      onClose={handleModalFormClose}
      open={openAddTaskFormModal}
      sx={{ zIndex: 2 }}
    >
      <AddTaskForm />
      <BackdropLoader open={showLoader} />
    </Dialog>
  )
}

export const TaskEditFormModal: FC = () => {
  const { openEditTaskFormModal, setOpenEditTaskFormModal, showLoader } =
    useTasksSidebarStore()
  const handleModalFormClose = () => {
    setOpenEditTaskFormModal(false)
  }

  return (
    <Dialog
      onClose={handleModalFormClose}
      open={openEditTaskFormModal}
      sx={{ zIndex: 2 }}
    >
      <EditTaskForm />
      <BackdropLoader open={showLoader} />
    </Dialog>
  )
}
