import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'
import { SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import {
  FormLayoutWithCancelSave,
  MedicalHistoryField,
  FormControlledNoteField,
  ReferralDateField,
  ReferralSourceField,
  ReferralTypeField,
  ReferredForField,
  ReferringProviderField,
  BodyRegionField,
} from 'src/features/shared/presentation/components/form-fields'

import {
  NoteFormProps,
  useCreatePatientReferralUploadNote,
} from 'src/features/notes/presentation'
import { mapToCreatePatientReferralUploadNoteArgs } from 'src/features/notes/adapters'
import { useNotesStore } from 'src/features/shared/infrastructure'
import { DevTool } from '@hookform/devtools'

export type ReferralUploadNoteFormFields = {
  note: string
  referralSource: string
  spokeWith?: string
  referringProvider: string
  referralDate: Date | null
  referralType?: string
  referredFor: string
  medicalHistory: string
  bodyRegionToNote?: string
  communication?: string
}

type ReferralUploadNoteFormProps = NoteFormProps

export const ReferralUploadNoteForm = ({
  patient,
  setShowLoader,
}: ReferralUploadNoteFormProps) => {
  const { createPatientReferralUploadNoteAsync } =
    useCreatePatientReferralUploadNote()
  const { noteText } = useNotesStore()

  const createReferralUploadNoteFormSchema: yup.Schema<ReferralUploadNoteFormFields> =
    yup.object().shape({
      note: yup.string().required('Note is required'),
      referralSource: yup.string().required('This field is required'),
      spokeWith: yup.string(),
      referringProvider: yup.string().required('This field is required'),
      referralDate: yup.date().required('This field is required'),
      referralType: yup.string().required('This field is required'),
      referredFor: yup.string().required('This field is required'),
      medicalHistory: yup.string().required('This field is required'),
      bodyRegionToNote: yup.string(),
    })

  const formMethods = useForm<ReferralUploadNoteFormFields>({
    resolver: yupResolver(createReferralUploadNoteFormSchema),
    defaultValues: {
      note: noteText,
      referralSource: patient.referralSource || '',
      spokeWith: '',
      referringProvider: '',
      referralDate: new Date(),
      referralType: '',
      referredFor: '',
      medicalHistory: '',
      bodyRegionToNote: '',
    },
  })

  const submitHandler: SubmitHandler<ReferralUploadNoteFormFields> = async (
    data
  ) => {
    setShowLoader?.(true)

    try {
      const createPatientReferralUploadNoteArgs =
        mapToCreatePatientReferralUploadNoteArgs(patient, data)

      if (patient.stageAndStatus && patient.stageAndStatus.stage) {
        await createPatientReferralUploadNoteAsync(
          createPatientReferralUploadNoteArgs
        )

        toast.success('Patient Note successfully created!')
        toast.success('Patient Status successfully updated!')
      } else {
        await createPatientReferralUploadNoteAsync(
          createPatientReferralUploadNoteArgs
        )
        toast.success('Patient Note successfully created!')
      }
    } catch (error) {
      toast.error('Failed creating patient note!')
      toast.error('Patient Status update failed!')
    }

    setShowLoader?.(false)
  }

  return (
    <FormLayoutWithCancelSave
      onSubmit={submitHandler}
      formMethods={formMethods}
      renderCommunicationField={false}
    >
      <Grid container rowSpacing={2}>
        <Grid item sm={12} pb={2}>
          <FormControlledNoteField />
        </Grid>
        <Grid item container columnSpacing={2} sm={12}>
          <Grid item sm={4}>
            <ReferralSourceField />
          </Grid>
          <Grid item sm={3.5}>
            <ReferringProviderField />
          </Grid>
          <Grid item sm={3}>
            <ReferralDateField />
          </Grid>
        </Grid>
        <Grid item container columnGap={2} sm={12}>
          <Grid item sm={2.5}>
            <ReferralTypeField />
          </Grid>
          <Grid item sm={3}>
            <ReferredForField />
          </Grid>
        </Grid>
        <Grid item container sm={4}>
          <BodyRegionField control={formMethods.control} />
        </Grid>
        <Grid container item sm={10.5}>
          <MedicalHistoryField />
        </Grid>
      </Grid>
      <DevTool control={formMethods.control} placement={'top-left'} />
    </FormLayoutWithCancelSave>
  )
}
