import { Box, SelectChangeEvent, TextField } from '@mui/material'
import { ChangeEvent, FC } from 'react'

import {
  MSKFormStateIntakeAssessment,
  useMSKFormStore,
} from 'src/features/shared/infrastructure'
import { LoadMSKFormReturns } from 'src/features/msk/domain'
import {
  CheckboxGroup,
  MultiSelect,
  MultiSelectOption,
  YesNoRadioGroup,
} from 'src/features/shared/presentation'
import {
  mapActionToCheckboxGroupOption,
  mapBodyRegionToNoteToMultiSelectOption,
} from 'src/features/msk/adapters'
import {
  bodyRegionsToNoteMultiSelectOptions,
  QUESTIONS_LABELS,
} from 'src/features/msk/presentation'

type IntakeAssessmentActionsProps = {
  loadedMSKForm: LoadMSKFormReturns
  currentIntakeAssessment: MSKFormStateIntakeAssessment
}

export const IntakeAssessmentActions: FC<IntakeAssessmentActionsProps> = ({
  loadedMSKForm,
  currentIntakeAssessment,
}) => {
  const {
    addOpenToTryAction,
    addPreviouslyTriedAction,
    deleteError,
    deleteOpenToTryAction,
    deletePreviouslyTriedAction,
    errors,
    setBodyRegionToNote,
    setCurrentlyUsingOpioids,
    setOtherPreviouslyTriedActionText,
  } = useMSKFormStore({
    loadedMSKForm,
  })
  const selectedBodyRegionsToNote = currentIntakeAssessment.bodyRegionToNote
  const selectedPreviouslyTriedActions =
    currentIntakeAssessment.previouslyTriedActions
  const selectedActionsOpenToTry = currentIntakeAssessment.actionsOpenToTry
  const previouslyTriedActionsOptions = loadedMSKForm.previouslyTriedActions
  const actionsOpenToTryOptions = loadedMSKForm.actionsOpenToTry

  const patientHasPreviouslyTriedOpioids = selectedPreviouslyTriedActions.find(
    (action) => action.id === 'opioids'
  )

  const patientHasPreviouslyTriedOtherAction =
    selectedPreviouslyTriedActions.find((action) => action.id === 'other')

  const getCurrentlyUsingOpioidsValue = () => {
    if (currentIntakeAssessment.currentlyUsingOpioids === true) {
      return 'yes'
    }

    if (currentIntakeAssessment.currentlyUsingOpioids === false) {
      return 'no'
    }

    return ''
  }

  const getOtherPreviouslyTriedActionText = () => {
    if (currentIntakeAssessment.otherPreviouslyTriedActionText) {
      return currentIntakeAssessment.otherPreviouslyTriedActionText
    }

    return ''
  }

  const handleCurrentlyUsingOpioidsChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value

    setCurrentlyUsingOpioids(value === 'yes')

    deleteError('currentlyUsingOpioids')
  }

  const handleOtherPreviouslyTriedActionTextChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value

    setOtherPreviouslyTriedActionText(value)

    if (value) {
      deleteError('otherPreviouslyTriedActionText')
    }
  }

  const handleChangePreviouslyTried = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked
    const selectedOption = {
      id: event.target.value,
    }

    if (checked) {
      addPreviouslyTriedAction(selectedOption)
    }

    if (!checked) {
      deletePreviouslyTriedAction(selectedOption)
    }
  }

  const handleChangeActionsOpenToTry = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked
    const selectedOption = { id: event.target.value }

    if (checked) {
      addOpenToTryAction(selectedOption)
    }

    if (!checked) {
      deleteOpenToTryAction(selectedOption)
    }
  }

  const handleBodyRegionToNoteChange = (
    event: SelectChangeEvent<MultiSelectOption['value'][]>
  ) => {
    const {
      target: { value },
    } = event
    // On autofill we get a stringified value.
    const selectedValueList =
      typeof value === 'string' ? value.split(',') : value
    setBodyRegionToNote(
      bodyRegionsToNoteMultiSelectOptions
        .filter((bodyRegionOption) =>
          selectedValueList.includes(bodyRegionOption.id)
        )
        .map((bodyRegionOption) => bodyRegionOption.id)
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '32px',
      }}
    >
      <MultiSelect
        displayOptionType="checkbox"
        id="msk_form_body_region_to_note"
        label={QUESTIONS_LABELS.bodyRegionToNote}
        options={bodyRegionsToNoteMultiSelectOptions.map(
          mapBodyRegionToNoteToMultiSelectOption
        )}
        selectedOptions={selectedBodyRegionsToNote}
        handleChange={handleBodyRegionToNoteChange}
      />

      <CheckboxGroup
        label={`What have you previously tried for your ${currentIntakeAssessment.botheredBodyPart.display}?`}
        name={'previouslyTriedActions'}
        options={previouslyTriedActionsOptions.map(
          mapActionToCheckboxGroupOption
        )}
        selectedOptions={selectedPreviouslyTriedActions.map(
          (action) => action.id
        )}
        handleChange={handleChangePreviouslyTried}
      />
      {patientHasPreviouslyTriedOtherAction ? (
        <TextField
          label={'Specify other action'}
          size={'small'}
          error={!!errors.otherPreviouslyTriedActionText}
          value={getOtherPreviouslyTriedActionText()}
          onChange={handleOtherPreviouslyTriedActionTextChange}
          helperText={
            errors.otherPreviouslyTriedActionText
              ? errors.otherPreviouslyTriedActionText
              : ''
          }
        />
      ) : null}
      {patientHasPreviouslyTriedOpioids ? (
        <YesNoRadioGroup
          label={'Are you currently using opioids to control your pain?'}
          id={'currently_using_opioids_question'}
          name={'currentlyUsingOpioids'}
          helperText={
            errors.currentlyUsingOpioids ? 'Please select an answer' : ''
          }
          error={!!errors.currentlyUsingOpioids}
          onChange={handleCurrentlyUsingOpioidsChange}
          value={getCurrentlyUsingOpioidsValue()}
        />
      ) : null}
      <CheckboxGroup
        label={`What options are you open to trying for your ${currentIntakeAssessment.botheredBodyPart.display}?`}
        name={'actionsOpenToTry'}
        options={actionsOpenToTryOptions.map(mapActionToCheckboxGroupOption)}
        selectedOptions={selectedActionsOpenToTry.map((action) => action.id)}
        handleChange={handleChangeActionsOpenToTry}
      />
    </Box>
  )
}
