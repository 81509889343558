import { ErrorMessage } from '@hookform/error-message'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material'
import {
  Controller,
  FieldPath,
  FieldValues,
  useFormContext,
} from 'react-hook-form'
import { levelScores } from 'src/features/shared/presentation'

export const FunctionLevelField = <T extends FieldValues>() => {
  const name = 'functionLevel' as FieldPath<T>
  const { register } = useFormContext()

  return (
    <Controller
      name={name}
      {...register}
      render={({ field }) => (
        <FormControl size={'small'} sx={{ width: '100%' }}>
          <InputLabel id={'functionLevel-label'}>Function</InputLabel>
          <Select
            labelId={'functionLevel-label'}
            id={'functionLevel'}
            label={'Function'}
            value={field.value}
            onChange={(event) => {
              field.onChange(event.target.value)
            }}
          >
            <MenuItem value={''}>Add Score</MenuItem>
            {levelScores.map((functionLevelOption) => (
              <MenuItem
                key={functionLevelOption.id}
                value={functionLevelOption.label}
              >
                {functionLevelOption.label}
              </MenuItem>
            ))}
          </Select>
          <ErrorMessage
            name={name}
            render={({ message }) => (
              <FormHelperText sx={{ mx: 0, whiteSpace: 'nowrap' }} error={true}>
                {message}
              </FormHelperText>
            )}
          />
        </FormControl>
      )}
    />
  )
}
